export default jQuery =>
    (function($) {
        "use strict";

        var $window = $(window);
        var $html = $("html");
        // var $body = $("body");

        function object(o) {
            var f = object.f,
                i,
                len,
                n,
                prop;
            f.prototype = o;
            n = new f();
            for (i = 1, len = arguments.length; i < len; ++i) {
                for (prop in arguments[i]) {
                    n[prop] = arguments[i][prop];
                }
            }
            return n;
        }
        object.f = function() {};

        var ua = navigator.userAgent;
        // var isTablet = false;
        var isiPad = false;
        if (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") == -1) {
            // isTablet = true;
        } else if (ua.indexOf("iPad") > 0) {
            // isTablet = true;
            isiPad = true;
        }

        // var isIPod = ua.indexOf("iPod") > -1 ? true : false;
        // var isIPhone = ua.indexOf("iPhone") > -1 ? true : false;
        // var isAndroid = ua.indexOf("Android") > -1 ? true : false;

        if (isiPad) {
            $html.addClass("is-iPad");
        }

        $.extend({
            tabPanel: function() {
                $.fn.selectTab = function(options) {
                    options = $.extend(
                        {},
                        $.fn.selectTab.defaultOptions,
                        options
                    );

                    this.on("click.selectTab", function(e) {
                        e.preventDefault();
                        var $this = $(this),
                            tabDisp = options.tabDisp,
                            panelDisp = options.panelDisp,
                            slctAria = "#" + $this.attr(options.panelId),
                            $tablist = $this.parents('[role="tablist"]'),
                            $tabArea = $(slctAria).parent();
                        if ($(slctAria).length < 1) return false;
                        $tablist.find("a").attr(tabDisp, false);
                        $this.attr(tabDisp, true);
                        $tabArea
                            .children("[role=tabpanel]")
                            .not(slctAria)
                            .attr(panelDisp, true);
                        $(slctAria).attr(panelDisp, false);
                    });
                };

                $.fn.selectTab.defaultOptions = {
                    tabDisp: "aria-selected",
                    panelId: "aria-controls",
                    panelDisp: "aria-hidden"
                };
                $(".js-tabBox [role=tab]").selectTab();
            },
            smoothScroll: function() {
                $('a[href^="#"]')
                    .not(
                        'a[href^="#0"], a[href="#"], a.selected, .js-navMenu a, .js-modalBtn, .js-tabBox a, .js-noScroll a, .js-noScroll'
                    )
                    .click(function() {
                        // スクロールの速度
                        var speed = 500; // ミリ秒
                        // アンカーの値取得
                        var href = $(this).attr("href");
                        // 移動先を取得
                        var $target;
                        if (href == "#" || href == "" || href == "#top") {
                            $target = $("html");
                        } else {
                            $target = $(href);
                        }
                        // 移動先を数値で取得
                        var position = $target.offset().top;
                        // スムーススクロール
                        if (!$(this).hasClass("selected")) {
                            $("body, html").animate(
                                {scrollTop: position},
                                speed,
                                "swing"
                            );
                            if (href == "#" || href == "" || href == "#top") {
                                //
                            } else {
                                // $target.find(":focusable").focus().blur();
                            }
                        }
                        return false;
                    });
            },
            modalOpen: function() {
                // var self = this;
                var $html = $("html");
                var $menuOffset = $(".js-menuOffset");
                var $menuOffset_R = $(".js-menuOffset--r");

                var $modalBtn = $(".js-modalBtn");
                var $modalOverlay = $(".js-modalOverlay");
                var $modalOverlayInner = $(".modalOverlay__inner");
                var openFlg = false;

                $modalBtn.each(function(i) {
                    var $this = $(this);
                    var $modalBox = $($this.attr("href"));

                    //var $modalBox = $(".js-searchModalBox");
                    var $closeBtn = $(".js-modalBoxClose");

                    $this.click(function(e) {
                        e.preventDefault();
                        // var $this = $(this);
                        if (openFlg == false) {
                            $.gnavClose();
                            setTimeout(function() {
                                openMenu(this);
                            }, 10);
                        } else {
                            closeMenu(this);
                        }
                    });

                    $closeBtn.click(function(e) {
                        e.preventDefault();
                        if (openFlg == false) {
                            //openMenu(this);
                        } else {
                            closeMenu(this);
                        }
                    });
                    $(".modalOverlay__close").click(function(e) {
                        if (openFlg == false) {
                            //openMenu(this);
                        } else {
                            closeMenu(this);
                        }
                    });

                    var openMenu = function(_this) {
                        $modalBox.show();
                        $html.addClass("modal-overlay-lock");
                        $menuOffset.addClass("modal-overlay-offset");
                        $menuOffset_R.addClass("modal-overlay-offset-r");
                        $modalOverlay.css({opacity: 0, display: "block"});
                        $modalOverlayInner.css(
                            "min-height",
                            $modalBox.innerHeight()
                        );
                        $modalOverlay.animate(
                            {opacity: 1},
                            200,
                            "swing",
                            function() {
                                openFlg = true;
                                $window.resize();
                            }
                        );
                    };
                    var closeMenu = function(_this) {
                        $modalOverlay.fadeOut(200, "swing", function() {
                            $modalBox.hide();
                            $modalBox.css("min-height", "");
                            $html.removeClass("modal-overlay-lock");
                            $menuOffset.removeClass("modal-overlay-offset");
                            $menuOffset_R.removeClass("modal-overlay-offset-r");
                            openFlg = false;
                        });
                    };
                });

                var w1 = $(window).width();
                $html.addClass("overlay-lock-test");
                var w2 = $(window).width();
                $html.removeClass("overlay-lock-test");
                $(
                    "<style type='text/css'>.modal-overlay-lock{padding-right:" +
                        (w2 - w1) +
                        "px;box-sizing:border-box;}</style>"
                ).appendTo("head");
                $(
                    "<style type='text/css'>.modal-overlay-offset{margin-left:" +
                        (w2 - w1) / -2 +
                        "px;} .modal-overlay-offset-r{margin-right:" +
                        (w2 - w1) +
                        "px;}</style>"
                ).appendTo("head");
            },
            toggleAccordion: function() {
                var _hash = location.hash;
                var $accordionBox = $(".js-accordionBox");

                $accordionBox.each(function(index) {
                    var $this = $(this);
                    if ("#" + $this.attr("id") === _hash) {
                        $accordionBox
                            .removeClass("is-show")
                            .addClass("is-hide")
                            .find(".js-accordionMenu")
                            .css("display", "none");
                        $this
                            .removeClass("is-hide")
                            .addClass("is-show")
                            .find(".js-accordionMenu")
                            .css("display", "block");
                    }
                    var $accordionBtn = $this.find(".js-accordionBtn").eq(0);
                    var $accordionMenu = $this.find(".js-accordionMenu").eq(0);
                    var $accordionClose = $this
                        .find(".js-accordionClose")
                        .last();
                    var AccordionBox = $this;
                    var accordionTop = AccordionBox.offset().top - 84;
                    var openFlg = false;
                    $accordionBtn.click(function(e) {
                        // var $this = $(this);
                        if (openFlg == false) {
                            openMenu(this);
                        } else {
                            closeMenu(this);
                        }
                    });
                    $accordionClose.click(function(e) {
                        if (!$accordionMenu.is(":animated")) {
                            AccordionBox.removeClass("is-show").addClass(
                                "is-hide"
                            );
                            accordionTop = AccordionBox.offset().top - 84;
                            $("body, html").animate(
                                {scrollTop: accordionTop},
                                300,
                                "swing"
                            );
                            $accordionMenu.slideUp(300, function() {
                                openFlg = false;
                            });
                        }
                    });
                    function openMenu(_this) {
                        if (!$accordionMenu.is(":animated")) {
                            AccordionBox.removeClass("is-hide").addClass(
                                "is-show"
                            );
                            $accordionMenu.slideDown(300, function() {
                                openFlg = true;
                                $window.resize();
                            });
                        }
                    }
                    function closeMenu(_this) {
                        if (!$accordionMenu.is(":animated")) {
                            AccordionBox.removeClass("is-show").addClass(
                                "is-hide"
                            );
                            $accordionMenu.slideUp(300, function() {
                                openFlg = false;
                                $window.resize();
                            });
                        }
                    }
                });

                $accordionBox
                    .not(".is-show")
                    .find(".js-accordionMenu")
                    .css("display", "none");
            },
            moreBox: function() {
                var $moreBox = $(".js-moreBox");
                var $moreBtn = $(".js-moreBtn");
                // var $moreMenu = $(".js-moreContents");

                $moreBtn.click(function(e) {
                    var $this = $(this);
                    var MoreBox = $moreBox.has($this);
                    var MoreMenu = MoreBox.find(".js-moreContents");
                    if (!MoreMenu.is(":animated")) {
                        if (!MoreBox.hasClass("is-show")) {
                            MoreBox.removeClass("is-hide").addClass("is-show");
                            MoreMenu.slideDown(200, function() {
                                $window.resize();
                            });
                        } else {
                            MoreMenu.slideUp(200, function() {
                                $window.resize();
                                MoreBox.removeClass("is-show").addClass(
                                    "is-hide"
                                );
                            });
                        }
                    }
                });
            },
            pagtopBtn: function() {
                var $pageTop = $(".js-pageTop");
                var footerTop;
                //$pageTop.hide();
                var $window = $(window);
                var pagetopFlg = $pageTop.length;
                // let prePosition = 0;
                footerTop =
                    $(".globalFooterNav").length > 0
                        ? $(".globalFooterNav").offset().top
                        : 0;
                if (pagetopFlg > 0) {
                    $window.on("load resize", function() {
                        footerTop =
                            $(".globalFooterNav").length > 0
                                ? $(".globalFooterNav").offset().top
                                : 0;
                    });
                }
                $window.scroll(function() {
                    var position = $window.scrollTop();
                    var position2 = position + $window.height();
                    if (position2 <= footerTop) {
                        $pageTop.removeClass("is-abs");
                    } else {
                        $pageTop.addClass("is-abs");
                    }
                    if (position > 100) {
                        $pageTop.addClass("is-show");
                    } else {
                        $pageTop.removeClass("is-show");
                    }
                    // prePosition = position;
                });
            },
            scrollAnimate: function() {
                var windowWidth;
                var deviceType;
                var scrollHeight = $(document).height() - $window.height();

                $window.on("load resize", function() {
                    windowWidth = window.innerWidth;
                    scrollHeight = $(document).height() - $window.height();
                    if (windowWidth <= 630) {
                        deviceType = "sp";
                    } else {
                        deviceType = "pc";
                    }
                });

                var _scrollObj = {
                    _this: this,
                    itemObj: null,
                    animateFlg: false,
                    animateFlg2: false,
                    animateFlg3: false
                };
                // var currentPosition = 0;
                $(".js-ignitePoint").each(function(i) {
                    var $this = $(this);
                    var objectTop;
                    var objectOffsetTop;
                    var objectOffsetTop2;
                    if (deviceType == "pc") {
                        objectTop = $this.offset().top - 400;
                    } else {
                        objectTop = $this.offset().top - 300;
                    }
                    objectOffsetTop = objectTop - 200;
                    objectOffsetTop2 = objectTop + 300;

                    // var contentsPosition = i;

                    var thisAnimateObj = object(_scrollObj);

                    thisAnimateObj.itemObj = $this;

                    $window.on("load resize", function() {
                        if (deviceType == "pc") {
                            objectTop = $this.offset().top - 400;
                        } else {
                            objectTop = $this.offset().top - 100;
                        }
                        objectOffsetTop = objectTop - 200;
                        objectOffsetTop2 = objectTop + 300;
                    });

                    $window.on("load scroll", function() {
                        var position = $window.scrollTop();
                        if (
                            (position > objectTop &&
                                thisAnimateObj.animateFlg != true) ||
                            (position >= scrollHeight &&
                                thisAnimateObj.animateFlg != true)
                        ) {
                            thisAnimateObj.animateFlg = true;
                            thisAnimateObj.itemObj.addClass("is-animate");
                        }
                        if (
                            (position > objectOffsetTop &&
                                thisAnimateObj.animateFlg2 != true) ||
                            (position >= scrollHeight &&
                                thisAnimateObj.animateFlg2 != true)
                        ) {
                            thisAnimateObj.animateFlg2 = true;
                            thisAnimateObj.itemObj.addClass("is-animate1");
                        }
                        if (
                            (position > objectOffsetTop2 &&
                                thisAnimateObj.animateFlg3 != true) ||
                            (position >= scrollHeight &&
                                thisAnimateObj.animateFlg3 != true)
                        ) {
                            thisAnimateObj.animateFlg3 = true;
                            thisAnimateObj.itemObj.addClass("is-animate2");
                        }
                    });
                });
            }
        });

        // $(function() {
        //     $.tabPanel();
        //     $.smoothScroll();
        //     // $.modalOpen();
        //     $.toggleAccordion();
        //     $.moreBox();
        //     $.pagtopBtn();
        //     $.scrollAnimate();
        // });
    })(jQuery);
