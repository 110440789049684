import React from "react";
import PropTypes from "prop-types";

class MediaTwo extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">
                            <span>
                                战略合作 | 近30万首BGM曲库的互联网商用音乐聚合与变现平台
                            </span>
                        </h1>
                    </header>
                    <div className="mainContents__box mediaContantBox">
                        <div className="mediaContantBox__header">
                            <div className="mediaContantBox__header__inner">
                                <p className="mediaContantBox__category">
                                    IP孵化器公众号
                                </p>
                                <p className="mediaContantBox__date">
                                    <time dateTime="2018-11-16">
                                        2018-11-16
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="mediaContantBox__main">
                            <div className="mediaContantBox__main__inner">
                                <figure>
                                    <img
                                        src="/assets/img/2018-11-16/01.jpg"
                                        alt=""
                                    />
                                    <figcaption>
                                        今天下午，金革音乐与奕颗贝壳正式签署了战略合作伙伴协议，授予奕颗贝壳20万首歌曲销售代理权。
                                    </figcaption>
                                </figure>

                                <p>
                                    金革唱片于2005年3月正式挂牌上市交易，成为台湾第一家上柜唱片公司，是华人地区极具影响力的音乐厂牌之一。
                                </p>

                                <p>
                                    <strong>
                                        金、革二字代表古代八音中最重要的两个音，象征着“最好的音乐”。
                                    </strong>
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-16/02.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    1981年金革唱片成立至今，始终秉持着“曲曲皆好听、打动您的心”的理念来全心全意推广精致音乐。以独特的眼光与品味，代理国内外最优质且多元的音乐内容，涵纳了德国〈hänssler〉、英国〈Signum〉、法国〈Transart〉、美国〈Steinway
                                    &
                                    Sons〉等顶级古典品牌，以及英国爵士乐大厂〈Jellymould〉精雕细琢的顶尖制作。并积极网罗全球各地的独立创作人如：ABIAH、Caroline
                                    Campbell、Giorgia Fumanti、John
                                    Owen-Jones、Sidsel
                                    Storm等。率先带领凯文科恩、雷恩寇伯、班得瑞等新世纪名家作品，开创新生活情境的音乐文化风潮。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-16/03.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    <strong>
                                        你也许没有听说过“金革”，但你一定听过“班得瑞”
                                    </strong>
                                </p>

                                <p>
                                    瑞士AVC旗下的新世纪音乐团体。以环境音乐为主，强调轻柔、纯净、空灵飘渺的仙境般的听觉感受。来自瑞士一尘不染的音符，正是由金革唱片将其引进到中国，也是国内唯一的正版发行公司。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-16/04.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    值得庆贺的是本次签约的20万首歌曲版权销售代理就包括班得瑞在内的众多优质版权音乐。
                                </p>

                                <p>
                                    <strong>
                                        奕颗贝壳一跃成为富有庞大曲库的互联网配乐平台。
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MediaTwo;
