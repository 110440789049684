import {
    ASYNC_START,
    CURRENT_USER,
    LOGIN,
    NEED_SIGIN,
    LOGOUT,
    UPDATEINFO,
    UPDATEINFO_PAGE_UNLOADED,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,
    currentUser: null,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case CURRENT_USER: {
            return {
                ...state,
                currentUser: action.error ? null : action.payload.user,
                loading: false,
            };
        }
        case LOGIN: {
            return {
                ...state,
                currentUser: action.error ? null : action.payload.user,
                loading: false,
            };
        }
        case NEED_SIGIN:
        case LOGOUT: {
            return {
                ...state,
                currentUser: null,
            };
        }
        case ASYNC_START:
            if (action.subtype === CURRENT_USER) {
                return { ...state, loading: true };
            }
            return state;
        case UPDATEINFO: {
            return {
                ...state,
                currentUser: action.error ? null : action.payload.user,
                errors: action.error ? action.payload.errors : null,
            };
        }
        case UPDATEINFO_PAGE_UNLOADED: {
            return {
                ...defaultState
            };
        }
        
        default:
            return state;
    }
};
