import { AUDIO_SUSUME } from "../constants/actionTypes";

const defaultState = {
    audios: [],
    total: 0,
    pager: { pageSize: 10, current: 1 },
    error: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case AUDIO_SUSUME: {
            const error = !!action.error;
            const {
                send,
                payload: { total, audios },
            } = action;

            const { pager } = send || {};
            const pagination = pager || state.pager;

            return {
                total: total || 0,
                pager: pagination,
                audios,
                error,
            };
        }
        default:
            return state;
    }
};
