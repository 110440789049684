import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "components/Loading";

import "./Purchase.less";

const mapStateToProps = state => ({ ...state.purchase });

const mapDispatchToProps = dispatch => ({});

class Purchase extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { loading } = this.props;
        return (
            <div className="mypageContents c132-purchase">
                <Loading loading={loading} />
                <div className="mainContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">购买完成</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="l-formBox">
                            <p className="l-formBox__linkBtn">
                                <Link to="/" className="m-btn-type0">
                                    返回音乐页
                                </Link>
                            </p>
                            <p className="l-formBox__linkBtn">
                                <Link
                                    to="/user/purchase"
                                    className="m-btn-type0"
                                >
                                    查看购买纪录
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Purchase);
