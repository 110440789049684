import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import $ from 'jquery';
// import axios from 'axios';
import {
    HOME_PAGE_LOADED,
    HOME_PAGE_UNLOADED,
    HOME_OPENID,
    MUSIC_SEARCH_LABEL_LOAD,
    AUDIO_SUSUME,
    PARTNER_LIST,
    PLAYLIST_GET_INFO,
    MUSIC_SEARCH_LABEL_LOAD_All,
} from "constants/actionTypes";

import Loading from "../Loading";
import { Layout } from "antd";
import agent from "agent";
import Recommend from "./recommend";
import { redirectTo,noShowPage } from "dispatch";
import { Link } from "react-router-dom";

// import custom css
import "./home.less";
const { Content } = Layout;
const queryString = require("query-string");
const jquery = require("jquery");
import { styleLabelsOnHome, styleLabels, styleLabelMulti } from "constants/music";
const mapStateToProps = state => ({
    ...state.home,
    currentUser: state.header.currentUser,
    labelDatas: state.musicSearch.labelsData,
    susume: state.susume.audios,
    partner: state.partner,
    playlistInfos: state.playlist.info.infos,
    labels: state.labels.labelsDataAll,
});

const mapDispatchToProps = dispatch => ({
    onLoad: payload => dispatch({ type: HOME_PAGE_LOADED, payload, skipTracking: true }),
    getOpenId: openId => dispatch({ type: HOME_OPENID,openId}),
    // onLoad: (tab, pager, payload) =>
    // dispatch({type: HOME_PAGE_LOADED, tab, pager, payload}),
    onUnload: () => dispatch({ type: HOME_PAGE_UNLOADED }),
    loadLabel: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD,
            payload: agent.Music.searchLabels(),
        });
    },
    getSusume: send =>
        dispatch({
            type: AUDIO_SUSUME,
            payload: agent.Audio.getSusume(send),
            send,
        }),
    getPartnerList: send =>
        dispatch({
            type: PARTNER_LIST,
            payload: agent.Partner.getList(send),
            send,
        }),
    getPlaylistInfos: send =>
        dispatch({
            type: PLAYLIST_GET_INFO,
            payload: agent.PlayList.getInfo(send),
            send,
        }),
    loadLabelAll: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD_All,
            payload: agent.Music.searchLabelAlls(),
        });
    },
});

class Home extends React.Component {
    static propTypes = {
        onUnload: PropTypes.func.isRequired,
        onLoad: PropTypes.func.isRequired,
        labelDatas: PropTypes.object.isRequired,
        loadLabel: PropTypes.func.isRequired,
        getOpenId: PropTypes.func.isRequired,
        loadLabelAll:PropTypes.func.isRequired,
        getSusume: PropTypes.func.isRequired,
        susume: PropTypes.array.isRequired,
        getPartnerList: PropTypes.func.isRequired,
        partner: PropTypes.object.isRequired,
        playlistInfos: PropTypes.array.isRequired,
        getPlaylistInfos: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        currentUser: PropTypes.object,
        labels: PropTypes.object.isRequired,
    };

    state = {
        loading: true,
        label: styleLabelsOnHome[0],
        subLabels: {},
        keyword:"",
    };

    id = 0;

    componentWillUnmount() {
        clearTimeout(this.id);
        this.props.onUnload();
    }

    componentDidMount() {
        var url = location.search; //获取url中"?"符后的字串 
        var theRequest = new Object(); 
        if((url.indexOf("?") != -1)==false){
            if((global.isWeixin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1)){
                console.log(1)
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe8482c2b628422a9&redirect_uri=https://www.musiness.vip&response_type=code&scope=snsapi_base&#wechat_redirect";
            }
        }
        if (url.indexOf("?") != -1) { 
            var str = url.substr(1); 
            var strs = str.split("&"); 
            for ( var i = 0; i < strs.length; i++) { 
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]); 		
            } 
            window.wxCode = theRequest.code;
            console.log(theRequest)
            // var urls="/hehe/sns/oauth2/access_token?appid=wxe8482c2b628422a9&secret=adc8690b4c3f9b6e8ed635c886be5251&code="+theRequest.code+"&grant_type=authorization_code";
            // axios.get(urls).then((res)=>{
            //     console.log(res.data)
            //     window.openId =  res.data.openid;
            //     console.log(window.openId)
            //     this.props.getOpenId(res.data.openid);
            //     // console.log(res.data.message)
            //     // this.setState({
            //     //     arr:res.data.message
            //     // })
            // })
            // $.ajax({
            //     url: 'http://query.yahooapis.com/v1/public/yql',
            //     dataType:'jsonp',
            //     data:{
            //         q: "select * from json where url=\'"+urls+"'",
            //         format:"json"
            //     },
            //     success: function (data) {//客户端jquery预先定义好的callback函数,成功获取跨域服务器上的json数据后,会动态执行这个callback函数
            //         console.log(data.query.results.json.openid);
            //     },
            // })
            // $.ajax({
            //     async:false,
            //     url: urls,
            //     type: "get",
            //     dataType: 'jsonp',
            //     jsonp:"callback",
            //     jsonpCallback: "jsonpFunc",
            //     // data: qsData,
            //     beforeSend: function(){
            //     //jsonp 方式此方法不被触发.原因可能是dataType如果指定为jsonp的话,就已经不是ajax事件了
            //     },
            //     success: function (json) {//客户端jquery预先定义好的callback函数,成功获取跨域服务器上的json数据后,会动态执行这个callback函数
            //     },
            //     error: function(xhr){
            //         //jsonp 方式此方法不被触发.原因可能是dataType如果指定为jsonp的话,就已经不是ajax事件了
            //         //请求出错处理
            //         console.log(xhr)
            //         alert("请求出错(请检查相关度网络状况.)");
            //     }
            // });
        } 
        this.props.loadLabel();
        this.props.loadLabelAll();
        this.props.getSusume();
        this.props.getPartnerList({ pager: { current: 1, pageSize: 12 } });
        if (!this.props.playlistInfos.length) this.props.getPlaylistInfos();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return null;
    }

    changeLabel(label) {
        this.setState({ label });
    }

    changeSubLabel(label) {
        if (styleLabelMulti.has(this.state.label)) {
            this.setState(state => {
                let savedLabels = new Set(state.subLabels[this.state.label] || []);
                if (savedLabels.has(label)) savedLabels.delete(label);
                else savedLabels.add(label);

                return {
                    subLabels: {
                        ...state.subLabels,
                        [this.state.label]: [...savedLabels],
                    },
                };
            });
        } else {
            const lab={
                page: 1,
                [this.state.label]: label,
            }
            const stringTiao ={
                tiao: noShowPage.baseEecode(lab),
            }
            redirectTo("/music/search", 
                stringTiao
            );
        }
    }

    subLabelToSearchPage() {
        const lab={
            page: 1,
            [this.state.label]: this.state.subLabels[this.state.label].join(","),
        }
        const stringTiao ={
            tiao: noShowPage.baseEecode(lab),
        }
        redirectTo("/music/search", 
            stringTiao
        );
    }

    getLicense(id) {
        redirectTo(`/music/musicid/${id}`);
    }

    gotoPlayTypePage(id) {
        redirectTo(`/topicmusic?id=${id}`);
    }
    gotoSubscribe(){
        redirectTo(`/subscribermembers`);
    }
    //响应事件
    handleKeywordSubmit = e => {
        e.preventDefault();
        this.handleSearchClick(this.state.keyword); 
        //埋点 用户搜索关键词提交到后台        
        jquery.ajax({
            url:agent.getApiUrl()+'/userdata/inssearchkw',
            data:{"keyword":this.state.keyword},
            success:function(data){
                console.log(JSON.stringify(data));
                console.log("搜索成功");
            },
            error:function(err){
                console.log("搜索错误 "+err);
            }
        });
    };
    //响应函数
    handleSearchClick(value){
        const params = {
            page: 1,
            keyword:value,
        };
        const stringTiao ={
            tiao: noShowPage.baseEecode(params),
        }
        const stringified =queryString.stringify(stringTiao);
      
        redirectTo(`/music/search?`+stringified); 
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    render() {
        if (this.state.loading) {
            clearTimeout(this.id);
            this.id = setTimeout(
                (() => {
                    this.setState(state => {
                        state.loading = false;
                        return state;
                    });
                }).bind(this),
                500
            );

            return <Loading />;
        }
        
        const topLabels = styleLabelsOnHome.map((key, i) => {
            const isCurrent = key === this.state.label;
            console.log(key+" 这是测试")
            const text = isCurrent ? (
                <a href="javascript:;" className="is-current">
                    {styleLabels[key]}
                </a>
            ) : (
                styleLabels[key]
            );

            return (
                <dd
                    style={{ cursor: "pointer" }}
                    className="topKv__kindList__item"
                    onClick={e => this.changeLabel(key)}
                    key={i}
                >
                    {text}
                </dd>
            );
        });

        const currentLabel = this.props.labelDatas[this.state.label];
        if (!Array.isArray(currentLabel)) {
            return <Loading />;
        }
        const subLabels = currentLabel.map((x, i) => {
            const { id, title } = x;
            const savedLabels = new Set(this.state.subLabels[this.state.label] || []);
            const isCurrent = savedLabels.has(id);

            return styleLabelMulti.has(this.state.label) ? (
                <li className="topKv__navList01__item topKv__navList01__item-check" key={i}>
                    <label className="u-checkbox">
                        <input type="checkbox" checked={isCurrent} onChange={e => this.changeSubLabel(id)} />
                        <span>{title}</span>
                    </label>
                </li>
            ) : (
                <li className="topKv__navList01__item" key={i} onClick={e => this.changeSubLabel(id)}>
                    <a href="javascript:;" className={["m-btn-type2", isCurrent ? "active" : null].filter(x => x).join(" ")}>
                        <span>{title}</span>
                    </a>
                </li>
            );
        });
        let num=0;
        const partnerList = this.props.partner.data.map((x, i) => {
            const { thumb: src, title: alt = "" } = x;
           
            if (num>=6) {
                return 
            }
            num++;
            return (
                <li className="partnerList__item" key={i}>
                    <img src={agent.getApiUrl(src)} alt={alt} />
                </li>
            );
        });
        let playListNum=0;
        /**类别标签布局 */
        const playList = this.props.playlistInfos.map((x, i) => {
            const { title, subtitle, playlistid, thumb} = x;
            const {labels} = this.props;
            if (playListNum>=8) {
                return 
            }
            playListNum++;
            return (
                <li className="topCategoryList__item" key={i}>
                    <a href="javascript:;" onClick={e => this.gotoPlayTypePage(playlistid,labels)}>
                        <div className="topCategoryList__img">
                            <img src={agent.getApiUrl(thumb)} alt={title} />
                        </div>
                        <p className="topCategoryList__txt1">
                            {title}
                            <br />
                            <span style={{fontSize:'18px'}}>{subtitle}</span>
                        </p>
                    </a>
                </li>
            );
        });
        const currentUser = this.props.currentUser;
        const {keyword } = this.state;
        return (
            <Content id="c02b478-home">
                <div className="topKv">
                    <div className="topKv__inner">
                        <h2 className="topKv__mainLead">300,000+正版配乐曲库，智能匹配您的需求</h2>
                        <div className="topKv__search searchBox">
                            {/**搜索框提交点击事件 */}
                            <form onSubmit={this.handleKeywordSubmit}>
                                <input 
                                    type="text" 
                                    className="searchSkin fontSize" 
                                    value={keyword} 
                                    onChange={this.handleKeywordChange}
                                    placeholder="您可以搜索：用途、情绪、风格、乐器、创作家……" />
                                <input type="submit" name="submit" value="搜寻" id="searchSubmit" />
                            </form>
                        </div>
                        <div className="topKv__kind">
                            <dl className="topKv__kindList">
                                <dt className="topKv__kindList__dt">分类搜索 :</dt>
                                {topLabels}
                            </dl>
                        </div>

                        <div className="topKv__pageNav is-current">
                            <div className="topKv__pageNav__main">
                                <ul className="topKv__navList01">{subLabels}</ul>
                                {styleLabelMulti.has(this.state.label) && (
                                    <p className="topKv__navList__submit">
                                        <input
                                            onClick={e => this.subLabelToSearchPage()}
                                            className="u-submitSkin"
                                            type="submit"
                                            name="submit"
                                            value="开始搜索"
                                        />
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="topContents">
                    <div className="topContents__inner">
                        <section className="topMusicSection">
                            <p className="topSection__title title_size_35">因为音乐而遇见，因为遇见而找到最合适的。</p>
                            <p className="bottom_line"></p>
                        </section>
                        <section className="topSection topMusicSection">
                            <header className="topSection__header">
                                <h2 className="topSection__title">推荐曲目</h2>
                            </header>
                            <div className="topSection__main">
                                {/**推荐曲目div */}
                                <div className="topMusicList m-columnBox" data-column="4" data-column-sp="2">
                                    {/**渲染加载recommend文件的格式 */}
                                    <Recommend data={this.props.susume} onClick={this.getLicense.bind(this)} />
                                </div>
                                <p className="topSection__link">
                                    <Link to="/music/search" className="m-btn-type1--grn">
                                        查看全部曲目
                                    </Link>
                                </p>
                            </div>
                        </section>

                        <section className="topSection topCategorySection">
                            <header className="topSection__header">
                                <h2 className="topSection__title">不同类别 尽情挑选</h2>
                            </header>
                            <div className="topSection__main">
                                <div className="topCategoryList">
                                    <ul className="topCategoryList__items">
                                        <li className="topCategoryList__item">
                                            <a href="javascript:;" onClick={e => this.gotoSubscribe()}>
                                                <div className="topCategoryList__img">
                                                    <img src="/assets/img/182.jpg" alt="订阅会员" />
                                                </div>
                                                <p className="topCategoryList__txt1">
                                                    订阅会员
                                                    <br />
                                                    <span style={{fontSize:'18px'}}>Subscription</span>
                                                </p>
                                            </a>
                                        </li>
                                        {playList}
                                    </ul>{/**跳转playList常量，返回布局方式 */}
                                </div>
                            </div>
                        </section>

                        <section className="topSection topCategorySection">
                            <header className="topSection__header">
                                <h2 className="topSection__title">我们的合作伙伴</h2>
                            </header>
                            <div className="topSection__main">
                                <div className="partnerList">
                                    <ul className="partnerList__items">{partnerList}</ul>
                                    {/* <ul className="partnerList__items">
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_01.jpg' />
                                        </li>
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_02.jpg' />
                                        </li>
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_03.jpg' />
                                        </li>
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_04.jpg' />
                                        </li>
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_05.jpg' />
                                        </li>
                                        <li className="partnerList__item" >
                                            <img src='/assets/img/partner/logo_06.jpg' />
                                        </li>
                                    </ul> */}
                                </div>
                                <p className="topSection__link">
                                    <a
                                        href="javascript:;"
                                        onClick={() => redirectTo("/partner")}
                                        className="m-btn-type1--grn"
                                    >
                                        更多
                                    </a>
                                </p>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="topFooter">
                    <div className="topFooter__inner">
                        <div className="topFooter__contents">
                            <p className="topFooter__lead">
                                <img src="assets/img/txt_lets.svg" alt="Let's upload Music" />
                            </p>
                            <p className="topFooter__postBtn">
                                {currentUser ? (
                                    <Link to="/become">成为卖家</Link>
                                ) : (
                                    <Link to="/login">成为卖家</Link>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </Content>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
