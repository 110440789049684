import React from "react";
//import PropTypes from "prop-types";
class InfoIcp extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center"}}>
                <img src="/assets/img/icp.jpg" style={{ width: "50%"}}/>
            </div>
        );
    }
}
export default InfoIcp ;
