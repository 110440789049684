import { MUSIC_SEARCH_LABEL_LOAD ,MUSIC_SEARCH_LABEL_LOAD_All,MUSIC_SEARCH_LABEL_LOAD_SON} from "../constants/actionTypes";

const defaultState = {
    labelsData: {},
};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MUSIC_SEARCH_LABEL_LOAD: {
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const labelsData = action.payload.condition;
            return {
                ...state,
                fetchError,
                labelsData,
            };
        }
       
        case MUSIC_SEARCH_LABEL_LOAD_All: {
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const labelsDataAll = action.payload.condition;
            return {
                ...state,
                fetchError,
                labelsDataAll,
            };
        }
        case MUSIC_SEARCH_LABEL_LOAD_SON: {
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const labelsDataAllSon = action.payload.condition;
            return {
                ...state,
                fetchError,
                labelsDataAllSon,
            };
        }
        default: {
            return state;
        }
    }
};
