import React from "react";
import PropTypes from "prop-types";
import agent from "agent";
import { connect } from "react-redux";
import { Form } from "antd";
import { Link } from "react-router-dom";

import CompanySellerInfo from "./CompanySellerInfo";
import UploadCompanyImg from "./UploadCompanyImg";
import MakerInfoBlock from "./MakerInfoBlock";
import CompanyAgreement from "./CompanyAgreement";
import { redirectTo } from "dispatch";

import { SELLERREGISTER, SELLERREGISTER_PAGE_UNLOADED } from "constants/actionTypes";

const mapStateToProps = state => ({
    ...state.auth,
    ...state.user,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (send, callback) =>
        dispatch({
            type: SELLERREGISTER,
            payload: agent.SellerRegister.register(send),
            callback,
        }),

    onUnload: () => dispatch({ type: SELLERREGISTER_PAGE_UNLOADED }),
});

class CompanySellerRegister extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            Family_Name: "",
            Given_Name: "",
            contactPhone: "",
            licenseFrontImg: "",
            makerHeadImg: [],
            makerList: [],
            count: 0,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        userLogin: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        registeSuccess: PropTypes.bool,

        onSubmit: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
    };

    static defaultProps = {
        registeSuccess: false,
    };

    maxMakerInfo = 50;

    makerInfoBlocks = {};

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
    }

    handleCompanyInfoVal = e => {
        const state = {};
        if (typeof e.target.id == "undefined") {
            state[e.target.name] = e.target.value;
        } else {
            state[e.target.id] = e.target.value;
        }
        this.setState(state);
    };

    handleLicenseImageVal = send => {
        this.setState(send);
    };

    refMakerInfoBlocks(id, ref) {
        this.makerInfoBlocks[id] = ref;
        Object.keys(this.makerInfoBlocks).map(x => {
            if (!this.makerInfoBlocks[x]) delete this.makerInfoBlocks[x];
        });
    }

    async handleMakerListAdd() {
        try {
            const makerInfoBlocks = Object.values(this.makerInfoBlocks);
            for (let i = 0; i < makerInfoBlocks.length; i++) {
                await makerInfoBlocks[i].fireCheck();
            }
            await this._makerAdd();
        } catch (e) {
            return false;
        }
    }

    _makerAdd() {
        if (this.state.makerList.length < this.maxMakerInfo) {
            this.setState(state => {
                state.makerList.push({ id: state.count });
                return { makerList: state.makerList, count: state.count + 1 };
            });
        }
    }

    handleMakerListDel(data) {
        this.setState(state => {
            state.makerList.splice(state.makerList.indexOf(data), 1);
            return { makerList: state.makerList };
        });
    }

    async _submit() {
        let imgFile = {};
        let makerInf = {};
        const makerInfoBlocks = Object.values(this.makerInfoBlocks);

        for (let i = 0; i < makerInfoBlocks.length; i++) {
            const makerInfo = await makerInfoBlocks[i].fireCheck();
            imgFile[`creator_avatar${i + 1}`] = makerInfo.avatar;
            delete makerInfo.avatar;
            makerInf[i + 1] = makerInfo;
        }

        let send = {
            company: true,
            family_name: this.state.sellerFamilyName,
            given_name: this.state.sellerGivenName,
            contact_number: this.state.contactPhone,
            shenfenzheng: this.state.licenseFrontImg,
            certificate: "身份证",
            creator: JSON.stringify(makerInf),
            ...imgFile,
        };

        this.props.onSubmit(send, () => {
            redirectTo("/sellerregister/success");
        });
    }

    async handleSubmit() {
        try {
            await Promise.all([
                this.CompanyInfo.companyInfoCheck(),
                this.UploadCompanyImg.companyImgCheck(),
                this.CompanyAgreement.companyAgreementCheck(),
            ]);

            const makerInfoBlocks = Object.values(this.makerInfoBlocks);
            for (let i = 0; i < makerInfoBlocks.length; i++) {
                await makerInfoBlocks[i].fireCheck();
            }
            // submit
            await this._submit();
        } catch (e) {
            return false;
        }
    }

    getSubNodeValue(mainNode, tagName) {
        let tagValue = "";
        let tags = mainNode.getElementsByTagName("*");
        for (let i = 0; i < tags.length; i++) {
            if ((!!tags[i].id && tags[i].id == tagName) || (!!tags[i].name && tags[i].name == tagName)) {
                tagValue = tags[i].value;
            }
        }
        return tagValue;
    }

    onRefCompanyInfo = ref => {
        this.CompanyInfo = ref;
    };

    onRefCompanyImg = ref => {
        this.UploadCompanyImg = ref;
    };

    onRefCompanyAgreement = ref => {
        this.CompanyAgreement = ref;
    };

    componentWillUnmount() {
        this.props.onUnload();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.registeSuccess && agent.getToken()) {
            redirectTo("/sellerregister/success");
            return false;
        }
        return true;
    }

    render() {
        const { currentUser } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">成为卖家</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="buypageForm">
                            <div className="buypageForm__inner">
                                <div className="buypageForm__items">
                                    <CompanySellerInfo
                                        onRef={this.onRefCompanyInfo}
                                        handleCompanyInfoVal={this.handleCompanyInfoVal.bind(this)}
                                        currentUser={currentUser}
                                    />
                                    <UploadCompanyImg
                                        onRef={this.onRefCompanyImg}
                                        handleImageVal={this.handleLicenseImageVal.bind(this)}
                                    />
                                    <div id="makerlist">
                                        {this.state.makerList.map(data => {
                                            return (
                                                <MakerInfoBlock
                                                    isList={true}
                                                    key={data.id}
                                                    onRef={this.refMakerInfoBlocks.bind(this, data.id)}
                                                    onRemove={this.handleMakerListDel.bind(this, data)}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div
                                        className="buypageForm__addbtn"
                                        style={{
                                            visibility:
                                                this.state.makerList.length >= this.maxMakerInfo ? "hidden" : "visible",
                                        }}
                                    >
                                        <button
                                            className="m-btn-type1--grn--ss"
                                            onClick={this.handleMakerListAdd.bind(this)}
                                            disable={this.state.makerList.length >= this.maxMakerInfo ? "true" : "false"}
                                        >
                                            添加创作人
                                        </button>
                                    </div>
                                    <CompanyAgreement onRef={this.onRefCompanyAgreement} />
                                    <p className="buypageForm__submit">
                                        <input
                                            className="u-submitSkin is-on"
                                            name="submit"
                                            type="button"
                                            value="下一步"
                                            onClick={this.handleSubmit}
                                            readOnly
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const CompanySellerRegisterForm = Form.create()(CompanySellerRegister);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySellerRegisterForm);
