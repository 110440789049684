import {
    ASYNC_START,
    MUSIC_PLAY,
    FAVORITE_DELETE,
    FAVORITE_ADD,
    PLAYER_UNLOADED,
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case MUSIC_PLAY:
            return {
                ...state,
                audio: action.musicPlay,
            };
        case FAVORITE_DELETE:
        case FAVORITE_ADD: {
            if (!state.audio || action.error) {
                return state;
            }
            const audio = { ...state.audio };

            if (action.send.audioid == audio.audioid) {
                audio.likeUpdate = false;
                if (action.type == FAVORITE_DELETE) {
                    audio.like = null;
                } else {
                    audio.like = action.payload.like;
                }
                return { ...state, audio };
            }
            return state;
        }

        case ASYNC_START: {
            if (
                action.subtype === FAVORITE_DELETE ||
                action.subtype === FAVORITE_ADD
            ) {
                if (!state.audio || action.error) {
                    return state;
                }
                const audio = { ...state.audio };

                if (action.send.audioid == audio.audioid) {
                    audio.likeUpdate = true;

                    return { ...state, audio };
                }
            }
            return state;
        }
        case PLAYER_UNLOADED:
            return {
                ...state,
                audio: "",
            };
        default:
            return state;
    }
};
