import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Form, Input, Button, Upload, message } from "antd";
import Loading from "../Loading";
import { CAPTCHA_UPDATE_INFO, UPDATEINFO ,UPDATEINFO_PAGE_UNLOADED} from "constants/actionTypes";
import agent from "agent";
import { redirectTo ,HtmlUtil} from "dispatch";
import Image from "components/Common/Image";
import ClassCropperModal from 'components/ClassCropperModal/ClassCropperModal';


//const MAX_FILE_SIZE = 5 * 1024 * 1024 // 文件最大限制为5M
const { Content } = Layout;

const mapStateToProps = state => ({
    ...state.user,
    ...state.updateInfo,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: ({ phone, email, captcha, avatar, nickname, weibo, info }, callback) =>
        dispatch({
            type: UPDATEINFO,
            payload: agent.User.userUpdateInfo({
                phone,
                email,
                captcha,
                avatar,
                nickname,
                weibo,
                p_description: info,
            }),
            callback,
        }),
    onClick: (email, done) => dispatch({ type: CAPTCHA_UPDATE_INFO, payload: agent.Captcha.create(email), done }),
    onUnload: () => dispatch({ type: UPDATEINFO_PAGE_UNLOADED }),
});

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function beforeUpload(file) {
    // const isJPG = file.type === "image/png";
    // if (!isJPG) {
    //     message.error("You can only upload JPG file!");
    // }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("照片大小必须小于2MB");
    }
    return false;
}
class UpdateInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            classModalVisible: false,
            loading: false,
            classModalFile: null,
            classResultImgUrl: null,
            uploadModalFile: null,
        }
    }
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        onUnload: PropTypes.func.isRequired,
        form: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        errors: PropTypes.array,
        // location: PropTypes.object.isRequired,
        // match: PropTypes.object.isRequired
    };
    state = {
        captchaDisabled: false,
        captchaLock: -1,
    };
    handleChange = info => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            return;
        } //if (info.file.status === "done")
        else {
            // Get this url from response in real world.
            getBase64(info.file).then(imageUrl => {
                this.setState({
                    imageUrl,
                    loading: false,
                });
            });
        }
    };
    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }
    componentDidUpdate(prevProps) {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
        
    }
    showFormItemServerError() {
        const errors = this.props.errors;
        if (!errors) {
            const { captchaDisabled } = this.state;
            if (captchaDisabled) {
                return;
            }
            this.setState({ captchaDisabled: true });
           
            let lock = 60;
            this.setState({ captchaLock: lock });
            const c = setInterval(() => {
                lock--;
                this.setState({ captchaLock: lock });
                if (lock <= 0) {
                    this.setState({ captchaDisabled: false });
                    clearInterval(c);
                }
            }, 1000);
           
            return;
        }
        const { form } = this.props;
        Object.keys(errors).map(key => {
            const message = errors[key];
            if (message.message === "Invalid captcha") {
                message.param = "code";
                message.msg = "验证码输入错误";
                message.value = form.getFieldValue("code");
            }
            if (message.message === "nickname must be 0-32 chars long") {
                message.param = "nickname";
                message.msg = "名字长度0-32";
                message.value = form.getFieldValue("nickname");
            }
            if (message.message === "The user is registered") {
                message.param = "bindInfo";
                message.msg = "该账号已注册";
                message.value = form.getFieldValue("bindInfo");
            }
            //
            switch (message.param) {
                case "code":
                    form.setFields({
                        code: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
                case "nickname":
                    form.setFields({
                        nickname: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
                case "bindInfo":
                    form.setFields({
                        bindInfo: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const isNull = !values.bindInfo;
                if (!isNull) {
                    const bindInfo = values.bindInfo;
                    if (
                        (!/^\d+(\.\d{1,2})?$/.test(bindInfo) || bindInfo.length != 11) &&
                        !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(bindInfo)
                    ) {
                        this.props.form.setFields({
                            bindInfo: {
                                value: bindInfo,
                                errors: [new Error("请输入正确的手机号/邮箱！")],
                            },
                        });
                        return false;
                    }
                }
                let bind = {};
                if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(values.bindInfo)) {
                    if (!values.code || values.code.length < 6) {
                        this.props.form.setFields({
                            code: {
                                value: values.code,
                                errors: [new Error("验证码输入有误！")],
                            },
                        });
                        return false;
                    }
                    bind = { email: values.bindInfo, captcha: values.code };
                } else if (/^\d+(\.\d{1,2})?$/.test(values.bindInfo) && values.bindInfo.length === 11) {
                    if (!values.code || values.code.length < 6) {
                        this.props.form.setFields({
                            code: {
                                value: values.code,
                                errors: [new Error("验证码输入有误！")],
                            },
                        });
                        return false;
                    }
                    bind = { phone: values.bindInfo, captcha: values.code };
                }
                if (values.headimg) {
                    bind = { ...bind, avatar: this.state.uploadModalFile };
                }
                this.props.onSubmit(
                    {
                        ...bind,
                        nickname: values.nickname,
                        weibo: values.personalbo,
                        info: values.personalinfo,
                    },
                    this.handleCancel
                );
            }
        });
    };

    sendCode = e => {
        e.preventDefault();
        let bindInfo = this.props.form.getFieldValue("bindInfo");

        if (
            (!/^\d+(\.\d{1,2})?$/.test(bindInfo) || bindInfo.length != 11) &&
            !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(bindInfo)
        ) {
            this.props.form.setFields({
                bindInfo: {
                    value: bindInfo,
                    errors: [new Error("请输入正确的手机号/邮箱！")],
                },
            });
            return false;
        } else {
            this._getIdentifyingCode(bindInfo);
        }
    };
    _getIdentifyingCode = field => {
        const done = e => {
        };
        this.props.onClick(field, done);
    };
    handleCancel() {
        //console.info(1);
        //this.props.onUnload();
        //redirectTo("/user/mypage")
        window.location="/user/mypage";
    }
    renderCaptcha = () => {
        const { captchaDisabled, captchaLock } = this.state;
        const className = [];
        if (captchaDisabled) {
            className.push("isDisabled");
        }
        return (
            <Link to="" className={className.join(" ")} onClick={this.sendCode}>
                {captchaLock > 0 ? (
                    <span>
                        正在发送验证码（
                        {captchaLock}）
                    </span>
                ) : (
                    <span> 发送验证码</span>
                )}
            </Link>
        );
    };
    handleClassFileChange = e => {
        const file = e.file;
        if (file) {
            // if (file.size <= MAX_FILE_SIZE) {
            this.setState({
                classModalFile: file // 先把上传的文件暂存在state中
            },
            () => {
                this.setState({
                    classModalVisible: true // 然后弹出modal
                })
            }
            )
            // } else {
            //     console.log('文件过大')
            // }
        }
    }
    handleGetResultImgUrl = key => file => {
        this.setState({
            [key]: file
        })
    }
    render() {
        const { loading, currentUser } = this.props;
        const { getFieldDecorator } = this.props.form;
        const {
            classModalVisible,
            classModalFile,
            classResultImgUrl,
        } = this.state;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        const emailOrPhone = !currentUser.phone ? currentUser.email : currentUser.phone;
        const uploadButton = (
            <Image
                width={200}
                border={true}
                url={!currentUser.avatar ? "/assets/img/img_avatar_edit.png" : agent.getApiUrl(currentUser.avatar)}
            />
        );
        //const imageUrl = this.state.imageUrl;
        const nickname = !currentUser.nickname ? "" : currentUser.nickname;
        return (
            <Content>
                <div className="mypageContents">
                    <div className="mypageContents__inner2">
                        <header className="mainHeading">
                            <h1 className="mainHeading__title">修改个人资料</h1>
                        </header>
                        <div className="buypageBox">
                            <div className="buypageForm">
                                <Form onSubmit={this.handleSubmit}>
                                    <div className="buypageForm__inner">
                                        <div className="buypageForm__items">
                                            <div className="buypageForm__item">
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt u-alsT">酷炫头像</dt>
                                                    <dd className="buypageForm__dd">
                                                        <div className="form__avatarBox__unset">
                                                            <label htmlFor="avatar">
                                                                <Form.Item>
                                                                    {getFieldDecorator("headimg", {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: "",
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Upload
                                                                            showUploadList={false}
                                                                            beforeUpload={beforeUpload}
                                                                            onChange={this.handleClassFileChange}
                                                                            accept="image/*"
                                                                            listType="picture"
                                                                        >
                                                                            {classResultImgUrl ? (
                                                                                <Image
                                                                                    width={200}
                                                                                    border={true}
                                                                                    url={classResultImgUrl}
                                                                                />
                                                                            ) : (
                                                                                uploadButton
                                                                            )}
                                                                        </Upload>
                                                                    )}
                                                                </Form.Item>
                                                            </label>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>

                                            <div className="buypageForm__item">
                                                <dl className="buypageForm__dl buypageForm__separate">
                                                    <dt className="buypageForm__dt">
                                                        <label htmlFor="">
                                                            已绑定手机号
                                                            <br />
                                                            /邮箱
                                                        </label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">{emailOrPhone}</dd>
                                                </dl>
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label htmlFor="">
                                                            变更绑定手机号
                                                            <br />
                                                            /邮箱
                                                        </label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <Form.Item>
                                                            {getFieldDecorator("bindInfo", {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "请输入手机号/邮箱!",
                                                                    },
                                                                ],
                                                            })(<Input type="text" className="u-inputSkin02" />)}
                                                        </Form.Item>
                                                    </dd>
                                                </dl>
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label htmlFor="">验证码</label>
                                                    </dt>
                                                    <dd className="buypageForm__dd buypageForm__dd--s">
                                                        <ul className="buypageForm__inList2">
                                                            <li className="buypageForm__inList2__item">
                                                                <Form.Item>
                                                                    {getFieldDecorator("code", {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: "",
                                                                            },
                                                                        ],
                                                                    })(<Input type="text" className="u-inputSkin02" />)}
                                                                </Form.Item>
                                                            </li>
                                                            <li className="buypageForm__inList2__item">
                                                                {this.renderCaptcha()}
                                                            </li>
                                                        </ul>
                                                    </dd>
                                                </dl>
                                            </div>

                                            <div className="buypageForm__item">
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label htmlFor="">昵称</label>
                                                        <br />
                                                        <span className="buypageForm__note u-Color--red2">（非必填）</span>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <Form.Item>
                                                            {getFieldDecorator("nickname", {
                                                                initialValue: HtmlUtil.htmlDecode(nickname) || "",
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "",
                                                                    },
                                                                ],
                                                            })(<Input type="text" className="u-inputSkin02" />)}
                                                        </Form.Item>
                                                    </dd>
                                                </dl>
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label htmlFor="">个人微博</label>
                                                        <br />
                                                        <span className="buypageForm__note u-Color--red2">（非必填）</span>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <Form.Item>
                                                            {getFieldDecorator("personalbo", {
                                                                initialValue: HtmlUtil.htmlDecode(currentUser.weibo) || "",
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "",
                                                                    },
                                                                ],
                                                            })(<Input type="text" className="u-inputSkin02" />)}
                                                        </Form.Item>
                                                    </dd>
                                                </dl>
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt u-alsT">
                                                        <label htmlFor="">个人简介</label>
                                                        <br />
                                                        <span className="buypageForm__note u-Color--red2">（非必填）</span>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <Form.Item>
                                                            {getFieldDecorator("personalinfo", {
                                                                initialValue: HtmlUtil.htmlDecode(currentUser.p_description)|| "",
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "",
                                                                    },
                                                                ],
                                                            })(
                                                                <textarea
                                                                    name="kanso"
                                                                    rows="4"
                                                                    cols="40"
                                                                    className="u-textareaSkin "
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="form__submitList">
                                        <li className="form__submitList__item">
                                            <Form.Item>
                                                <Button className="u-submitSkin" type="primary" htmlType="submit" block>
                                                    确认修改
                                                </Button>
                                            </Form.Item>
                                        </li>
                                        <li className="form__submitList__item">
                                            <button className="u-submitSkin--gry" onClick={this.handleCancel.bind(this)}>
                                                取消
                                            </button>
                                        </li>
                                    </ul>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('classResultImgUrl')}
                        onShowImg={this.handleGetResultImgUrl('uploadModalFile')}
                        onThumb={this.handleGetResultImgUrl('thumb')}
                    />
                )}
            </Content>
        );
    }
}
const WrappedDemo = Form.create()(UpdateInfo);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
