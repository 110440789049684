import React from "react";
import SidebarAvatar from "./SidebarAvatar";
import { connect } from "react-redux";
// import { Form } from 'antd';
import agent from "agent";
import PropTypes from "prop-types";
import "./uploadmusic.less";
import {
    MUSIC_SEARCH_LABEL_LOAD_SON,
    PRODUCER_CREATE_AUDIO,
    PRODUCER_GET_CREATOR,
    PRODUCER_GET_AUDIO_DETAIL,
    PRODUCER_UPDATE_AUDIO,
    MUSIC_PAGE_UNLOADED,
} from "constants/actionTypes";
import Loading from "components/Loading";
import Header from "./Header";
import { redirectTo } from "dispatch";
import { Form, Input ,Layout} from "antd";
import ClassCropperModal from 'components/ClassCropperModal/ClassCropperModal'
const { Content } = Layout;

class UploadMusic extends React.Component {
    
    static propTypes = {
        form: PropTypes.object.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        loadLabel: PropTypes.func.isRequired,
        createAudio: PropTypes.func.isRequired,
        updateAudio: PropTypes.func.isRequired,
        conditions: PropTypes.object,
        loading: PropTypes.bool,
        creator: PropTypes.object.isRequired,
        getCreators: PropTypes.func.isRequired,
        getDetail: PropTypes.func.isRequired,
        detail: PropTypes.object.isRequired,
        errors: PropTypes.array,
        onUnload: PropTypes.func.isRequired,
    };

    static defaultProps = {
        headers: {
            authorization: "authorization-text",
        },
    };

    els = {};
    tips = {
        tip1: {
            __html:
                '为了让买方对歌曲有更正确的想象，您的歌曲名称必须准确的描述它所拥有的元素、氛围，并且以高相关性、简洁的、专业的方式命名，这样将会提高歌曲被成功搜寻的机会。',
        },
        tip2: {
            __html:
                "为了确保封面图片更好的展示，请您对上传的JPG图片进行适当的调整。图片的选择上我们建议您使用适当情境的图片，或使用您的专辑图片、品牌logo等，来精确的代表您的音乐商品。请注意您所上传的图片不得有侵害版权的情形！",
        },
        tip3: {
            __html:
                '您所上传的音乐档案应为44.1kHZ  16-Bit stereo，并为WAV格式，歌曲需为正确的长度，不得在前后留有空白，最短不应低于30秒，我们将会在歌曲加上浮水声，作为Demo下载使用，以保护您音乐的版权。为了提升购买意愿，我们建议您上传的歌曲应经过良好的混音、后制程序，以确保音乐的品质。审核过程中，若音乐清晰度或品质不佳，MUSINESS将有权退回你的上架申请。',
        },
        tip4: {
            __html:
                '如果您的音乐获得过某些奖项，或是曾经被某些专案使用，我们希望能够了解，这能够让我们更好的为您的歌曲进行宣传。',
        },
        tip5: {
            __html:
                '<p>建议用大类标签曲风/乐器/情绪/人声/速度/地区 子分类请参照product1然后使用滚轮式选择简化创作人需要自行键入标签之动作让使用更方便；标签将是决定歌曲能不能被正确搜寻到的关键，请记住标签并不是越多越好，我们希望您可为您的音乐作品选择最适当的标签，让搜寻更加便利 曲风－请选择最接近的歌曲风格／乐器－请挑选乐曲中的主要乐器，一般来说主要乐器为中高音乐器，主要演奏重要旋律，您也可以选择次要的两种可以明显感受到的乐器 ／情绪－请挑选乐曲中能够营造的情绪，情绪的感受非常困难也非常主观，您可以按照您的感受选择／人声－请依照歌曲中主要人声类别选择／速度－请选择歌曲速度范围，若是不清楚，可以使用具有Tap功能的器材或是APP进行测速／地区－若您的歌曲有人声，您可以选择相应的语言；若您的歌曲有特殊的异国风情，即使无人声也可以选择相应的地区，如：法式香颂、西班牙舞曲等。若皆无，则选择无国界</p>'
            ,
        },
    };
    state = {
        formDatas: {
            audio: {},
            thumb: {},
            title: "",
            description: "",
            creator_id: 0,
            composer: "",
            // conditions
            speed: "",
            area: "",
            vocal: "",
            style: [],
            emotion: [],
            instrument: [],
            scene: [],
            tag: "",
            scope:0,
            tag_two: "",
        },
        thumb: "",
        onSubmit: false,
        composerAsCreator: false,
        id: "",
        title: "发布音乐",
        classModalVisible: false,
        loading: false,
        classModalFile: null,
        classResultImgUrl: null,
        uploadModalFile: null,
    };

    componentDidMount() {
        const { userLogin} = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        this.props.loadLabel();
        if (!this.props.conditions||!Object.keys(this.props.conditions).length) this.props.loadLabel();
        this.props.getCreators({ pager: {current: 1,pageSize: 500} });

        const [, id] = location.search.match(/id=([^&]+)/) || [];

        if (id) {
            this.setState({ title: "更新音乐" });
            this.setState({ id });
            this.props.getDetail(id);
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.detail !== this.props.detail) {
            const {
                description,
                title,
                thumb,
                creator,
                inverted_condition,
                area,
                composer,
                tag,
                speed,
                vocal,
                scope,
                tag_two,
            } = this.props.detail;
            const conditions = {};
            Object.keys(inverted_condition).map(
                x => (conditions[x] = inverted_condition[x].split(","))
            );

            this.setState(state => {
                return {
                    ...state,
                    thumb,
                    formDatas: {
                        ...state.formDatas,
                        description,
                        area,
                        title,
                        composer,
                        creator_id: creator,
                        tag,
                        speed,
                        vocal,
                        scope,
                        tag_two,
                        ...conditions,
                    },
                };
            });
        }
    }

    xss(name) {
        return this.tips[name];
    }

    showTip(ref) {
        this.els[ref].nextSibling.classList.toggle("hidden");
    }

    createOptions(list) {
        return [
            <option value="0" key={-1}>
                -
            </option>,
            list.map((x, i) => {
                const { id, title } = x;
                return (
                    <option value={id} key={i}>
                        {title}
                    </option>
                );
            }),
        ];
    }

    handleSubmit(e) {
        e.preventDefault();
        const { createAudio, updateAudio } = this.props;
        this.setState({ onSubmit: true });

        this.props.form.validateFieldsAndScroll(err => {
            if (err) return;

            if (!this.state.id) {
                const fileFields = [
                    this.state.formDatas.audio.name,
                    this.state.formDatas.thumb.name,
                ].every(x => x);
                if (!fileFields) return;
            }

            const callback = () => {
                window.location="/seller/mymusic";
                //redirectTo("/seller/mymusic");
            };

            let {
                audio,
                thumb,
                title,
                description,
                creator_id,
                composer,
                scope,
                ...conditions
            } = this.state.formDatas;
            const conditionFormat = {};

            if (this.state.composerAsCreator && creator_id) {
                const [creator] = this.props.creator.list.filter(
                    x => x.id === parseInt(creator_id)
                );
                composer = creator.nickname;
            }
            if (!creator_id || creator_id===0) {
                return
            }
            Object.keys(conditions).map(key => {
                const value = conditions[key];
                if (Array.isArray(value)) {
                    conditionFormat[key] = [...new Set(value)]
                        .filter(x => x)
                        .join(",");
                } else {
                    conditionFormat[key] = value;
                }
            });
            thumb=this.state.uploadModalFile;
            const send = {
                audio,
                title,
                thumb,
                description,
                creator_id,
                composer,
                scope,
                condition: JSON.stringify(conditionFormat),
            };
            if (this.state.id) updateAudio(send, this.state.id, callback);
            else createAudio(send, callback);
        });
    }

    valueOnChange(name, value) {
        this.setState(state => {
            const formDatas = state.formDatas;
            if (name.indexOf(".") >= 0) {
                const [key, index] = name.split(".");
                formDatas[key][index] = value;
            } else {
                formDatas[name] = value;
            }
            return { formDatas };
        });
    }

    toggleComposerAsCreator() {
        this.setState({ composerAsCreator: !this.state.composerAsCreator });
    }
    handleClassFileChange = e => {
        const file = e.target.files[0];
        if (file) {
            // if (file.size <= MAX_FILE_SIZE) {
            this.setState({
                classModalFile: file // 先把上传的文件暂存在state中
            },
            () => {
                this.setState({
                    classModalVisible: true // 然后弹出modal
                })
            }
            )
            // } else {
            //     console.log('文件过大')
            // }
        }
    }
    handleGetResultImgUrl = key => file => {
        this.setState({
            [key]: file
        })

    }
    handleSetImgUrl = key => file => {
        
        this.setState(state => {
            const formDatas = state.formDatas;
            formDatas[key] = file;
            return { formDatas };
        });
    }
   
    render() {
        const { getFieldDecorator } = this.props.form;
        const { loading, conditions,errors} = this.props;
        if (!conditions) {
            return (
                <div className="musicContents">
                    <Loading loading={loading} />
                    <div className="musicContents__inner">
                        <div className="musicKv">
                            <div className="musicKv__titleBox">
                                <h1 className="musicKv__title">&nbsp;</h1>
                                <p className="musicKv__name">&nbsp;</p>
                            </div>
                        </div>
                        <div className="musicInfo">&nbsp;</div>
                    </div>
                </div>
            );
        }
        const {
            emotion = [],
            instrument = [],
            scene = [],
            speed = [],
            vocal = [],
            style = [],
            area = [],
        } = this.props.conditions;
        const styleOptions = this.createOptions(style);
        const speedOptions = this.createOptions(speed);
        const sceneOptions = this.createOptions(scene);
        const emotionOptions = this.createOptions(emotion);
        const instrumentOptions = this.createOptions(instrument);
        const vocalOptions = this.createOptions(vocal);
        const areaOptions = this.createOptions(area);

        let creatorList = this.props.creator.list.map(x => ({
            id: x.id,
            title: x.nickname,
        }));
        if (!this.props.currentUser.company)
            creatorList = creatorList.slice(0, 1);

        const creatorOptions = this.createOptions(creatorList);
       
        const show=!errors ? 'showErr  hidden':'showErr show';
        const showErrors=!errors ? null:errors[0].message;
        const content = (name, rules, type) => {
            let input = null;

            switch (type) {
                case "textarea":
                    input = (
                        <Input.TextArea
                            row={4}
                            cols={40}
                            onChange={e =>
                                this.valueOnChange(name, e.target.value)
                            }
                            className="u-textareaSkin"
                        />
                    );
                    break;
                default:
                    input = (
                        <Input
                            onChange={e =>
                                this.valueOnChange(name, e.target.value)
                            }
                            className="u-inputSkin02"
                        />
                    );
            }

            return (
                <Form.Item>
                    {getFieldDecorator(name, {
                        initialValue: this.state.formDatas[name] || "",
                        rules: rules || [],
                    })(input)}
                </Form.Item>
            );
        };

        const showThumb =
            !this.state.id &&
            !this.state.formDatas.thumb.name &&
            this.state.onSubmit;
        const showAudio =
            !this.state.id &&
            !this.state.formDatas.audio.name &&
            this.state.onSubmit;
        const showCreator =
            !this.state.id &&
            this.state.formDatas.creator_id===0 &&
            this.state.onSubmit;
        const {
            classModalVisible,
            classModalFile,
            classResultImgUrl,
        } = this.state;
        return (
            <Content>
                <div className="mymusicContents">
                    <div className="mymusicContents__inner">
                        <Header title={"卖家个人中心"} />
                        <SidebarAvatar />
                        <div className="mainBox02">
                            <Loading loading={loading} />
                            <Form>
                                <div className="mymusicBox">
                                    <h2 className="mymusicBox__title">
                                        {this.state.title}
                                    </h2>
                                    <div className="mymusicForm">
                                        <div className="buypageForm__inner">
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item is-qBtn">
                                                    <div className="buypageForm__Qbtn">
                                                        <button
                                                            type="button"
                                                            defaultChecked={false}
                                                            ref={x =>
                                                                (this.els[
                                                                    "tip1"
                                                                ] = x)
                                                            }
                                                            onClick={this.showTip.bind(
                                                                this,
                                                                "tip1"
                                                            )}
                                                        />
                                                        <div className="form__balloon is-top hidden">
                                                            <div className="form__balloon__inner">
                                                                <p
                                                                    dangerouslySetInnerHTML={this.xss(
                                                                        "tip1"
                                                                    )}
                                                                />
                                                            </div>
                                                            <span className="form__balloon__tale" />
                                                        </div>
                                                    </div>
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="musicName">
                                                                音乐名称
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            {content("title", [
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "请填写音乐名称",
                                                                },
                                                            ])}
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item is-qBtn">
                                                    <div className="buypageForm__Qbtn">
                                                        <button
                                                            type="button"
                                                            defaultChecked={false}
                                                            ref={x =>
                                                                (this.els[
                                                                    "tip2"
                                                                ] = x)
                                                            }
                                                            onClick={this.showTip.bind(
                                                                this,
                                                                "tip2"
                                                            )}
                                                        />
                                                        <div className="form__balloon is-top hidden">
                                                            <div className="form__balloon__inner">
                                                                <p
                                                                    dangerouslySetInnerHTML={this.xss(
                                                                        "tip2"
                                                                    )}
                                                                />
                                                            </div>
                                                            <span className="form__balloon__tale" />
                                                        </div>
                                                    </div>
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                音乐封面
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <label >
                                                                {this.state.thumb && (
                                                                    <img
                                                                        src={ classResultImgUrl?classResultImgUrl:agent.getApiUrl(
                                                                            this.state
                                                                                .thumb
                                                                        )}
                                                                    />
                                                                )}
                                                                {!this.state.thumb && (
                                                                    <img
                                                                        src={ classResultImgUrl?classResultImgUrl:"/assets/img/upload_img.png"}
                                                                    />
                                                                )}
                                                                <input
                                                                    type="file"
                                                                    onChange={this.handleClassFileChange }
                                                                    style={{display:"none"}}
                                                                />
                                                            </label>
                                                            {showThumb && (
                                                                <div className="ant-form-explain">
                                                                    请上传音乐封面
                                                                </div>
                                                            )}
                                                        </dd>
                                                    </dl>
                                                </div>
                                                {this.state.title=='更新音乐'?"":
                                                    <div className="buypageForm__item is-qBtn">
                                                        <div className="buypageForm__Qbtn">
                                                            <button
                                                                type="button"
                                                                defaultChecked={false}
                                                                ref={x =>
                                                                    (this.els[
                                                                        "tip3"
                                                                    ] = x)
                                                                }
                                                                onClick={this.showTip.bind(
                                                                    this,
                                                                    "tip3"
                                                                )}
                                                            />
                                                            <div className="form__balloon is-top hidden">
                                                                <div className="form__balloon__inner">
                                                                    <p
                                                                        dangerouslySetInnerHTML={this.xss(
                                                                            "tip3"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <span className="form__balloon__tale" />
                                                            </div>
                                                        </div>
                                                        <dl className="buypageForm__dl">
                                                            <dt className="buypageForm__dt">
                                                                <label htmlFor="">
                                                                    上传音乐
                                                                </label>
                                                            </dt>
                                                            <dd className="buypageForm__dd">
                                                                <input
                                                                    type="file"
                                                                    onChange={e =>
                                                                        this.valueOnChange(
                                                                            "audio",
                                                                            e.target
                                                                                .files[0]
                                                                        )
                                                                    }
                                                                />
                                                                {showAudio && (
                                                                    <div className="ant-form-explain">
                                                                        请上传音乐
                                                                    </div>
                                                                )}
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                }
                                                <div className="buypageForm__item is-qBtn">
                                                    <div className="buypageForm__Qbtn">
                                                        <button
                                                            type="button"
                                                            defaultChecked={false}
                                                            ref={x =>
                                                                (this.els[
                                                                    "tip4"
                                                                ] = x)
                                                            }
                                                            onClick={this.showTip.bind(
                                                                this,
                                                                "tip4"
                                                            )}
                                                        />
                                                        <div className="form__balloon is-top hidden">
                                                            <div className="form__balloon__inner">
                                                                <p
                                                                    dangerouslySetInnerHTML={this.xss(
                                                                        "tip4"
                                                                    )}
                                                                />
                                                            </div>
                                                            <span className="form__balloon__tale" />
                                                        </div>
                                                    </div>
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt u-alsT">
                                                            <label htmlFor="">
                                                                音乐描述
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            {content(
                                                                "description",
                                                                [
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "请填写音乐描述",
                                                                    },
                                                                ],
                                                                "textarea"
                                                            )}
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt u-alsT">
                                                            <label htmlFor="">
                                                                授权范围
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__inItem">
                                                            <div className="u-selectWrapp">
                                                                <select onChange={e =>
                                                                    this.valueOnChange(
                                                                        "scope",
                                                                        e
                                                                            .target
                                                                            .value
                                                                    )
                                                                }>
                                                                    <option value='0' selected = {this.state.formDatas.scope==0?"selected":""}>
                                                                    全球
                                                                    </option>
                                                                    <option value='1' selected = {this.state.formDatas.scope==1?"selected":""}>
                                                                    中国大陆
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        {!this.state
                                                            .composerAsCreator && (
                                                            <dt className="buypageForm__dt u-alsT">
                                                                <label htmlFor="">
                                                                作曲人
                                                                </label>
                                                            </dt>
                                                        )}
                                                        <dd className="buypageForm__dd">
                                                            {!this.state
                                                                .composerAsCreator && (
                                                                <div className="buypageForm__inItem">
                                                                    <input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                        onChange={e =>
                                                                            this.valueOnChange(
                                                                                "composer",
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .formDatas
                                                                                .composer ||
                                                                            ""
                                                                        }
                                                                        placeholder="Jason Wu、周杰伦"
                                                                    />
                                                                </div>
                                                            )}
                                                            <div className="buypageForm__inItem">
                                                                <label className="u-checkbox2">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            this
                                                                                .state
                                                                                .composerAsCreator
                                                                        }
                                                                        onChange={e =>
                                                                            this.toggleComposerAsCreator()
                                                                        }
                                                                    />
                                                                    <span>
                                                                        作曲人同创作人
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <div className="u-selectWrapp">
                                                                    <select
                                                                        onChange={e =>
                                                                            this.valueOnChange(
                                                                                "creator_id",
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .formDatas
                                                                                .creator_id ||
                                                                            ""
                                                                        }
                                                                    >
                                                                        {
                                                                            creatorOptions
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {showCreator && (
                                                                    <div className="ant-form-explain">
                                                                        请选择创作人 
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mymusicBox">
                                    <div className="mymusicForm">
                                        <div className="buypageForm__inner">
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt u-alsT">
                                                            <label htmlFor="">
                                                                歌曲标签
                                                            </label>
                                                        </dt>
                                                        <div className="buypageForm__Qbtn">
                                                            <button
                                                                type="button"
                                                                defaultChecked={false}
                                                                ref={x =>
                                                                    (this.els[
                                                                        "tip5"
                                                                    ] = x)
                                                                }
                                                                onClick={this.showTip.bind(
                                                                    this,
                                                                    "tip5"
                                                                )}
                                                            />
                                                            <div className="form__balloon is-top hidden" style={{width:"600px"}}>
                                                                <div className="form__balloon__inner">
                                                                    <p
                                                                        dangerouslySetInnerHTML={this.xss(
                                                                            "tip5"
                                                                        )}
                                                                    />
                                                                </div>
                                                                <span className="form__balloon__tale" />
                                                            </div>
                                                        </div>
                                                        <dd className="buypageForm__dd">
                                                            <div className="buypageForm__inTitle">
                                                                风格（复选）{" "}
                                                                <span className="buypageForm__inNote">
                                                                    最多2个
                                                                </span>
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "style.0",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .style[0] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    styleOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "style.1",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .style[1] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    styleOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                乐器（复选）{" "}
                                                                <span className="buypageForm__inNote">
                                                                    最多2个
                                                                </span>
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "instrument.0",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .instrument[0] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    instrumentOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "instrument.1",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .instrument[1] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    instrumentOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                情绪（复选）{" "}
                                                                <span className="buypageForm__inNote">
                                                                    最多3个
                                                                </span>
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "emotion.0",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .emotion[0] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    emotionOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "emotion.1",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .emotion[1] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    emotionOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "emotion.2",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .emotion[2] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    emotionOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                场景（复选）{" "}
                                                                <span className="buypageForm__inNote">
                                                                    最多3个
                                                                </span>
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "scene.0",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .scene[0] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    sceneOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "scene.1",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .scene[1] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    sceneOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>

                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "scene.2",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .scene[2] ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    sceneOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                人声（单一）
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "vocal",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .vocal ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    vocalOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                速度（单一）
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "speed",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .speed ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    speedOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                地区（单一）
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <ul className="buypageForm__inList">
                                                                    <li className="buypageForm__inList__item">
                                                                        <div className="u-selectWrapp">
                                                                            <select
                                                                                onChange={e =>
                                                                                    this.valueOnChange(
                                                                                        "area",
                                                                                        e
                                                                                            .target
                                                                                            .value
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .formDatas
                                                                                        .area ||
                                                                                    ""
                                                                                }
                                                                            >
                                                                                {
                                                                                    areaOptions
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="buypageForm__inTitle">
                                                                自定义（单一）
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <input
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.valueOnChange(
                                                                            "tag",
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            .formDatas
                                                                            .tag ||
                                                                        ""
                                                                    }
                                                                    placeholder="请输入标签,限定6个字"
                                                                    className="u-inputSkin02"
                                                                    maxLength="6"
                                                                />
                                                            </div>
                                                            <div className="buypageForm__inItem">
                                                                <input
                                                                    type="text"
                                                                    onChange={e =>
                                                                        this.valueOnChange(
                                                                            "tag_two",
                                                                            e.target
                                                                                .value
                                                                        )
                                                                    }
                                                                    value={
                                                                        this.state
                                                                            .formDatas
                                                                            .tag_two ||
                                                                        ""
                                                                    }
                                                                    placeholder="请输入标签,限定6个字"
                                                                    className="u-inputSkin02"
                                                                    maxLength="6"
                                                                />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={show}>
                                    <p className="errorInfo"> 上传错误:{showErrors}</p>
                                </div>
                                <p className="myMusicForm__submit">
                                    <input
                                        className="u-submitSkin"
                                        onClick={this.handleSubmit.bind(this)}
                                        type="submit"
                                        name="submit"
                                        value="发布"
                                    />
                                </p>
                            </Form>
                        </div>
                    </div>
                </div>
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('classResultImgUrl')}
                        onShowImg={this.handleGetResultImgUrl('uploadModalFile')}
                        onThumb={this.handleSetImgUrl('thumb')}
                    />
                )}
            </Content>
        );
    }
}

export default connect(
    state => ({
        ...state.user,
        ...state.seller,
        conditions: state.labels.labelsDataAllSon,
        userLogin: state.app.userLogin,
        creator: state.creator,
        detail: state.mymusic.detail,
    }),
    dispatch => ({
        loadLabel: () => {
            return dispatch({
                type: MUSIC_SEARCH_LABEL_LOAD_SON,
                payload: agent.Music.searchLabelAll(),
            });
        },
        createAudio: (send, callback,done) => {
            return dispatch({
                type: PRODUCER_CREATE_AUDIO,
                payload: agent.Producer.createAudio(send),
                callback,
            });
        },
        updateAudio: (send, id, callback) => {
            return dispatch({
                type: PRODUCER_UPDATE_AUDIO,
                payload: agent.Producer.updateAudio(send, id),
                callback,
            });
        },
        getCreators: (send, callback) =>
            dispatch({
                type: PRODUCER_GET_CREATOR,
                payload: agent.Producer.getCreators(send),
                send,
                callback,
            }),
        getDetail: (id, callback) =>
            dispatch({
                type: PRODUCER_GET_AUDIO_DETAIL,
                payload: agent.Producer.getAudioDetail(id),
                id,
                callback,
            }),
        onUnload: () => dispatch({ type:MUSIC_PAGE_UNLOADED }),
    })
)(Form.create({ name: "upload-music" })(UploadMusic));
