import {CURRENT_USER, LOGIN, LOGOUT} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case CURRENT_USER:
            return {
                ...state,
                currentUser: action.error ? null : action.payload.user
            };
        case LOGIN: {
            return {
                ...state,
                currentUser: action.error ? null : action.payload.user
            };
        }
        case LOGOUT: {
            return {
                ...state,
                currentUser: null
            };
        }
        default:
            return state;
    }
};
