import {
    PRODUCER_CREATE_CREATOR,
    PRODUCER_GET_CREATOR,
    PRODUCER_DELETE_CREATOR,
    PRODUCER_GET_CREATOR_DETAIL,
    UNLOAD_CREATOR,
} from "constants/actionTypes";

const defaultState = {
    pagination: { pageSize: 10, current: 1 },
    total: 0,
    list: [],
};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PRODUCER_GET_CREATOR: {
            const { creators, total } = action.payload;
            const error = !!action.error;
            let pager = state.pagination;
            if (action.send) {
                pager = action.send.pager;
            }
            return {
                ...state,
                pagination: pager,
                total: total,
                error: error,
                loading: false,
                list: creators,
            };
        }
        case PRODUCER_DELETE_CREATOR: {
            const { error } = action;
            const { id } = action.send;
            let list = state.list;
            if (!error) list = state.list.filter(x => x.id !== id);

            return {
                ...state,
                list,
            };
        }
        case PRODUCER_GET_CREATOR_DETAIL: {
            const error = !!action.error;
            const { creator } = action.payload;
            const test = action.payload;
            const { id } = action.send;
            return {
                ...state,
                creator,
                test,
                id,
                error,
            };
        }
        case UNLOAD_CREATOR: {
            const creator = {};
            return {
                ...state,
                creator,
            };
        }
        case PRODUCER_CREATE_CREATOR:
        default:
            return state;
    }
};
