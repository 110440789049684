import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import agent from "agent";
import { Pagination } from "antd";
import { PARTNER_LIST } from "constants/actionTypes";

class Partner extends React.Component {
    static propTypes = {
        partner: PropTypes.object.isRequired,
        getPartnerList: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.getPartnerList({ pager: { current: 1, pageSize: 100 , orderby: "-weight" } });
    }

    componentDidUpdate() {
        console.log(this.props.partner.pager);
    }

    onPageChange(page) {
        const {
            pager: { pageSize },
        } = this.props.partner;

        this.props.getPartnerList({
            pager: {
                pageSize,
                current: page,
            },
        });
    }

    render() {
        const {
            total,
            pager: { pageSize, current },
            data,
        } = this.props.partner;
        //console.log(total, pageSize, current);
        const partnerList = data.map((x, i) => {
            const { thumb: src, title: alt = "" } = x;
            return (
                <li className="partnerList__item m-columnBox__item" key={i}>
                    <img src={agent.getApiUrl(src)} alt={alt} />
                </li>
            );
        });

        return (
            <div className="mypageContents aboutContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">合作商</h1>
                    </header>
                    <div className="partnerBox">
                        <div className="partnerBox__inner">
                            <div className="partnerBox__main">
                                <div className="partnerList m-columnBox" data-column="4" data-column-sp="2">
                                    <ul className="partnerList__items m-columnBox__items">{partnerList}</ul>
                                </div>
                            </div>
                            <div className="c02b478-music-table" style={{textAlign:"center"}}>
                                <div className="resultPagination ">
                                    <Pagination
                                        defaultPageSize={pageSize}
                                        pageSize={pageSize}
                                        defaultCurrent={1}
                                        current={current}
                                        total={total}
                                        hideOnSinglePage={true}
                                        onChange={this.onPageChange.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        partner: state.partner,
    }),
    dispatch => ({
        getPartnerList: send =>
            dispatch({
                type: PARTNER_LIST,
                payload: agent.Partner.getList(send),
                send,
            }),
    })
)(Partner);
