import React from "react";
import { connect } from "react-redux";
import { SEND_CORT_POPUP,SHOW_SHOP_CAR } from "../../constants/actionTypes";
import PropTypes from "prop-types";
import { Form, Modal } from "antd";
import agent from "agent";
import { addCart, redirectTo, noLoginAddCart } from "dispatch";
import "./AddShopCar.less";
const jquery = require("jquery");
const mapStateToProps = state => ({
    ...state.cart,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: ({ link, songName, tortReason},done) =>
        dispatch({
            type: SEND_CORT_POPUP,
            payload: agent.Captcha.sendEmail({
                link,
                songName,
                tortReason,
            }),
            done,
        }),
    showCarPopup: addAudio => {
        return dispatch({ type: SHOW_SHOP_CAR, addAudio });
    },
});

class AddShopCar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chooseuse:0,
            price:0,
            choosechannel:0,
            scope:0,
            success:false,
            term:"0",
            price_id:0,
            error:false,
        };
    }
    static propTypes = {
        addAudio: PropTypes.shape({
            showAddCar: PropTypes.bool,
            addAudio:PropTypes.object,
        }).isRequired,
        form: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        errors: PropTypes.array,
        showCarPopup:PropTypes.func.isRequired,
        priceGroup:PropTypes.array,
        userLogin:PropTypes.bool,
    };
    static defaultProps = {
        addAudio: {
            showAddCar: false,
            addAudio:{},
        },
    };
    componentDidUpdate(prevProps) {
        const { priceGroup,addAudio } = this.props;
        if (priceGroup!=prevProps.priceGroup) {
            if (priceGroup) {
                for (let i = 0; i < priceGroup.length; i++) {
                    const element = priceGroup[i];
                    if (element.is_min==1) {
                        this.setState({
                            chooseuse:element.father_id,
                            price:!addAudio.addAudio.api_id?element.price:"99",
                            choosechannel:element.id,
                            term:element.term,
                            price_id:element.price_id,
                        })
                    }
                }
            }
            this.setState({
                success:false,
            })
            return;
        }
        if (this.state.chooseuse!=0) {
            return;
        }
        if (priceGroup) {
            for (let i = 0; i < priceGroup.length; i++) {
                const element = priceGroup[i];
                if (element.is_min==1) {
                    this.setState({
                        chooseuse:element.father_id,
                        price:!addAudio.addAudio.api_id?element.price:"99",
                        choosechannel:element.id,
                        term:element.term,
                        price_id:element.price_id,
                    })
                }
            }
        }
    }
    //关闭弹窗
    closeShow() {
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        const {showCarPopup } = this.props;
        this.setState({
            error:false,
        })
        const send = {
            showAddCar:false,
            addAudio: {},
        };
        showCarPopup(send);
    }
   
    //改变用途
    changeUse(id){
        const { addAudio}=this.props.addAudio;
        const { priceGroup } = this.props;
        if (id==this.state.chooseuse) {
            return;
        }
        for (let i = 0; i < priceGroup.length; i++) {
            const element = priceGroup[i];
            if (element.father_id==id) {
                this.setState({
                    choosechannel:element.id,
                    price_id:element.price_id,
                    price:!addAudio.api_id?element.price:"99",
                    term:element.term,
                })
                break;
            }
        }
        this.setState({
            chooseuse:id,
        })
    }

    //改变渠道
    changeChooseChannel(data){
        const { addAudio}=this.props.addAudio;
        this.setState({
            price:!addAudio.api_id?data.price:"99",
            choosechannel:data.id,
            term:data.term,
            price_id:data.price_id,
        })
    }

    //加入购物车
    submintCar(){
        const { userLogin } = this.props;
        const { addAudio}=this.props.addAudio;
        if (this.state.chooseuse==0||this.state.choosechannel==0) {
            Modal.error({
                title: '渠道忘记选了哦!',
            });
            return;
        }
        //需要提交的数据
        console.log(addAudio.scope )
        let scope = ''
        if(addAudio.scope == 1){
            scope = addAudio.scope
        }else{
            scope = this.state.scope
        }
        console.log(this.state.scope)
        const send = {
            audioid:addAudio.audioid,
            userid:addAudio.author.userid,
            purpose:this.state.chooseuse,
            price:this.state.price,
            area:this.state.price_id,
            term:this.state.term,
            scope:scope || 0,
        }
        const done = () => {
            const {errors}=this.props;
            this.setState({
                success:true,
            })
            if (errors) {
                this.setState({
                    error:true,
                })
            }else{
                this.setState({
                    error:false,
                })
            }
        };
        if (!userLogin) {
            //未登录状态
            noLoginAddCart(send);
            this.setState({
                success:true,
                error:false,
            })
        }else{
            //添加购物车
            addCart(send,done);
        }
        
       
    }
    
    //改变授权范围
    chanheScope(scope){
        this.setState({
            scope:scope,
        })
    }

    //去购物车结算
    goToCar(){

        //关闭弹出窗
        this.closeShow();

        //跳转购物车结算
        redirectTo("/cart");

    }

    //用途列表
    renderCell(data, i) {
        const { priceGroup } = this.props;
        let noShow=0;
        for (let j = 0; j < priceGroup.length; j++) {
            const element = priceGroup[j];
            if (element.father_id==data.id&&element.price<0) {
                noShow=data.id;
            }
        }
        if (data.father_id<1&&noShow!=data.id) {
            return  <a key={`use-${i}`} onClick={this.changeUse.bind(this,data.id)} className={this.state.chooseuse==data.id?"choose-current":""}>{data.title}</a>;
        }
    }

    //渠道列表
    renderChannelCell(data, i) {
        if (this.state.chooseuse==data.father_id) {
            return  <a key={`channel-${i}`} onClick={this.changeChooseChannel.bind(this,data)} className={this.state.choosechannel==data.id?"choose-current":""} >{data.title}</a>;
        }
    }
    render() {
        const { priceGroup ,errors} = this.props;
        const { showAddCar ,addAudio} = this.props.addAudio;
        const showClass = showAddCar ? "isShow" : "modalBox";
        if (!showAddCar) {
            return null;
        }
        let stys={display:"block"};
        let sty={display:"none"};
        if (this.state.success) {
            sty={display:"block",height:"auto"};
            stys={display:"none",height:"auto"};
        }
        const term = this.state.term == "0"?"随片永久":"一年";
        return (
            <div className={showClass} id="add_shop_car" style={{WebkitOverflowScrolling: "touch"}}>
                <div className="modalOverlay" />
                <div className="choose-container modalBox__inner" style={sty}>
                    <div className=" close-choose"><a style={{cursor:"pointer"}} onClick={this.closeShow.bind(this)}><img height="24" src="/assets/img/u50.png"/></a></div>
                    <div className=" add_car_success">
                        <div className="dialog__title"><img src="/assets/img/u306.png"/><span>{!this.state.error?"已成功加入购物车!":errors[0].message}</span></div>
                        <div className="dialog__txt"><a className="margin_right_25" onClick={this.closeShow.bind(this)} style={{cursor:"pointer"}}>继续选购</a><a onClick={this.goToCar.bind(this)} style={{cursor:"pointer"}}>去购物车结算</a></div>
                    </div>
                </div>
                <div className="choose-container modalBox__inner" style={stys}>
                    <div className=" close-choose"><a style={{cursor:"pointer"}} onClick={this.closeShow.bind(this)}><img height="24" src="/assets/img/u50.png"/></a></div>
                    <div className=" choose-ti"><img src="/assets/img/u55.png"/>请选择所选音乐的购买用途，以便为您确认购买价格。</div>
                    <div>
                        <ul className=" choose-list">
                            <li>
                                <div className=" choose-left">购买用途：</div>
                                <div className=" choose-right purpose">
                                    {priceGroup.map(this.renderCell.bind(this))}
                                </div>
                            </li>
                            <li>
                                <div className=" choose-left">投放渠道：</div>
                                <div className=" choose-right channel">
                                    {priceGroup.map(this.renderChannelCell.bind(this))}
                                </div>
                            </li>
                            <li>
                                <div className=" choose-left">授权地区：</div>
                                {addAudio.scope==0?<div className=" choose-right region">
                                    <a onClick={this.chanheScope.bind(this,0)} className={this.state.scope==0?"choose-current":""}>全球</a>
                                    <a onClick={this.chanheScope.bind(this,1)} className={this.state.scope==1?"choose-current":""}>中国大陆</a>
                                </div>:<div className=" choose-right region">
                                    <a className="choose-current">中国大陆</a>
                                </div>}
                                
                            </li>
                            <li>
                                <div className=" choose-left">授权期限：</div>
                                <div className=" choose-right"><a className=" choose-current">{term}</a></div>
                            </li>
                        </ul>
                    </div>
                    <div className=" choose-total">总价：<span>￥{this.state.price}</span><p className="notice_price">* 价格均为人民币，如您选择台币支付，默认汇率为4.5</p></div>
                    <div className=" choose-add"><a onClick={this.submintCar.bind(this)} style={{cursor:"pointer"}}>添加至购物车</a></div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(AddShopCar);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
