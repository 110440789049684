import React from "react";
import agent from "agent";
import { CONTACT_SEND } from "constants/actionTypes";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input } from "antd";

class Contact extends React.Component {
    static propTypes = {
        send: PropTypes.func.isRequired,
        form: PropTypes.object,
    };

    state = {
        form: {
            name: "",
            email: "",
            title: "",
            content: "",
        },
        modalVisible: false,
    };

    onChange(name, value) {
        this.setState(state => ({
            form: {
                ...state.form,
                [name]: value,
            },
        }));
    }

    onSubmit() {
        this.props.form.validateFieldsAndScroll(err => {
            if (err) return;
            this.props.send(this.state.form, () => {
                this.setState({ modalVisible: true });
            });
        });
    }

    closeModal() {
        this.setState({ modalVisible: false });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const modal = this.state.modalVisible && (
            <div className="modalBox" style={{ display: "block" }}>
                <div className="modalOverlay" />
                <div className="modalBox__inner">
                    <div id="modalForm1" className="modalForm">
                        <div className="modalForm__header">
                            <p className="modalForm__title" style={{ color: "black" }}>
                                消息
                            </p>
                            <p className="modalForm__close">
                                <button type="button" onClick={this.closeModal.bind(this)} />
                            </p>
                        </div>
                        <div className="modalForm__inner">
                            <div className="buypageBox">
                                <div className="buypageForm" style={{ fontSize: "1.4rem" }}>
                                    <div style={{ height: 100, width: "100%", display: "flex", justifyContent: "center" }}>
                                        提交成功！
                                    </div>
                                    <p className="buypageForm__submit">
                                        <input
                                            onClick={this.closeModal.bind(this)}
                                            className="u-submitSkin"
                                            type="submit"
                                            name="submit"
                                            value="确认"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="mypageContents">
                {modal}
                <Form>
                    <div className="mypageContents__inner2">
                        <header className="mainHeading">
                            <h1 className="mainHeading__title">联系我们</h1>
                        </header>
                        <div className="buypageBox">
                            <div className="buypageForm">
                                <div className="buypageForm__inner" style={{ "max-width": "890px" }}>
                                    <div className="buypageForm__items">
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">客服电话</label>
                                                </dt>
                                                <dd className="buypageForm__dd">18939713804</dd>
                                            </dl>
                                        </div>

                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">微信Wechat</label>
                                                </dt>
                                                <dd className="buypageForm__dd">imusiness</dd>
                                            </dl>
                                        </div>

                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">电子邮箱</label>
                                                </dt>
                                                <dd className="buypageForm__dd">sales@musiness.vip</dd>
                                            </dl>
                                        </div>

                                
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">地址</label>
                                                </dt>
                                                <dd className="buypageForm__dd">MUSINESS音乐营销中心：上海市徐家汇均瑶国际大厦23层</dd>
                                                <dt className="buypageForm__dt"></dt>
                                                <dd className="buypageForm__dd">MUSINESS音乐版权咨询中心：上海市静安区南京西路580号仲益大厦37层</dd>
                                                <dt className="buypageForm__dt"></dt>
                                                <dd className="buypageForm__dd">MUSINESS舞台&大屏：上海市大华虎城中心广场</dd>
                                                <dt className="buypageForm__dt"></dt>
                                                <dd className="buypageForm__dd">MUSINESS香港办事处：香港亚洲贸易中心28层</dd>
                                                <dt className="buypageForm__dt"></dt>
                                                <dd className="buypageForm__dd">中国台湾：金弦音乐Melody Music，金鼎视听录音 KingTing. Recording and Audio post.</dd>
                                            </dl>
                                        </div>
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">姓名</label>
                                                </dt>
                                                <dd className="buypageForm__dd">
                                                    <Form.Item>
                                                        {getFieldDecorator("name", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请填写姓名",
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                className="u-inputSkin02"
                                                                onChange={e => this.onChange("name", e.target.value)}
                                                                placeholder="姓名"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>

                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">联系电话</label>
                                                </dt>
                                                <dd className="buypageForm__dd">
                                                    <Form.Item>
                                                        {getFieldDecorator("email", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    pattern: /(^(\d{3,4}-)?\d{6,8}$)|(^(\d{3,4}-)?\d{6,8}(-\d{1,5})?$)|(\d{11})/,
                                                                    message: "请填写正确联系电话",
                                                                },
                                                                // {
                                                                //     type: "email",
                                                                //     message: "请填写正确邮件地址",
                                                                // },
                                                            ],
                                                        })(
                                                            <Input
                                                                className="u-inputSkin02"
                                                                onChange={e => this.onChange("email", e.target.value)}
                                                                placeholder="联系电话"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>

                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt">
                                                    <label htmlFor="">邮箱</label>
                                                </dt>
                                                <dd className="buypageForm__dd">
                                                    <Form.Item>
                                                        {getFieldDecorator("title", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    type:'email',
                                                                    message: "请填写邮箱",
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                className="u-inputSkin02"
                                                                onChange={e => this.onChange("title", e.target.value)}
                                                                placeholder="邮箱"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt u-alsT">
                                                    <label htmlFor="">联系内容</label>
                                                </dt>
                                                <dd className="buypageForm__dd">
                                                    <Form.Item>
                                                        {getFieldDecorator("content", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请填写联系内容",
                                                                },
                                                            ],
                                                        })(
                                                            <Input.TextArea
                                                                rows={4}
                                                                cols={40}
                                                                className="u-inputSkin02"
                                                                onChange={e => this.onChange("content", e.target.value)}
                                                                placeholder="联系内容"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>

                                            <p className="buypageForm__submit u-mt2em u-mb1em">
                                                <input
                                                    onClick={this.onSubmit.bind(this)}
                                                    className="u-submitSkin"
                                                    type="submit"
                                                    name="submit"
                                                    value="提交"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default connect(
    () => ({}),
    dispatch => ({
        send: (send, callback) =>
            dispatch({
                type: CONTACT_SEND,
                payload: agent.Contact.send(send),
                callback,
            }),
    })
)(Form.create()(Contact));
