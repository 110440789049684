import {
    MUSIC_SEARCH_LABEL_LOAD,
    MUSIC_SEARCH_LEFT,
    MUSIC_SEARCH,
    ASYNC_START,
    MUSIC_SEARCH_PAGE_UNLOAD,
    SHOW_TORT_POPUP,
    SEND_CORT_POPUP,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,
    fetchError: false,
    total: 0,
    labelsData: {},
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MUSIC_SEARCH: {
            return {
                ...state,
                fetchError: !!action.error,
                total: action.error ? 0 : action.payload.total,
                loading: false,
            };
        }

        case ASYNC_START:
            if (action.subtype === MUSIC_SEARCH) {
                return { ...state, loading: true };
            }
            break;
        case MUSIC_SEARCH_LEFT:
            return {
                ...state,
                changes: action.changes,
            };
        case MUSIC_SEARCH_PAGE_UNLOAD:
            return { ...defaultState, labelsData: state.labelsData };
        case MUSIC_SEARCH_LABEL_LOAD: {
            const fetchError = !!action.error;
            const labelsData = fetchError ? {} : action.payload.condition;
            return {
                ...state,
                fetchError,
                labelsData,
            };
        }
        case SHOW_TORT_POPUP: {
            return {
                ...state,
                isTortShow: action.isTortShow,
            };
        }
        case SEND_CORT_POPUP:
            return {
                ...state,
                isTortShow: { tortShow: "1" },
            };
        default:
            return state;
    }

    return state;
};
