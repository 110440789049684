import agent from "agent";

import {
    PEAKS_ADD,
    CART_LOAD,
    CART_ADD,
    FAVORITE_ADD,
    FAVORITE_DELETE,
    CART_DELETE,
    CURRENT_USER,
    PURCHASE_LOAD,
    HISTORY_PURCHASE_LOAD,
    SELLER_PROFILE,
    PRODUCER_GET_CREATOR_DETAIL,
    MUSIC_SEARCH_LABEL_LOAD,
    ORDER_DELETE,
    MUSIC_SEARCH_LABEL_LOAD_SON,
    NO_LOGIN_ADD_CART,
    CLEAR_CART_AUDIOS,
    ADD_MUSIC_STATIS,
    MUSIC_NOISE,
} from "constants/actionTypes";
import Base64  from 'base-64';
import { store, history } from "./store";
const dispatch = store.dispatch;
const queryString = require("query-string");

export const redirectTo = (to, params = {}) => {
    if (typeof to !== "string") {
        history.push(to);
    } else {
        const searchString = Object.keys(params).length
            ? "?" + agent.buildURLQuery(params)
            : "";
        history.push(to + searchString);
    }
};

export const addPeaks = (audioid, peaks) => {
    dispatch({
        type: PEAKS_ADD,
        payload: agent.Music.addPeaks(audioid, peaks),
    });
};
export const loadCart = () => {
    return dispatch({ type: CART_LOAD, payload: agent.Cart.load() });
};
export const addCart = (send,done) => {
    dispatch({
        type: CART_ADD,
        payload: agent.Cart.add(send),
        send,
        callback: loadCart,
        done,
    });
};
export const deleteCart = cartids => {
    return dispatch({
        type: CART_DELETE,
        payload: agent.Cart.delete(cartids),
        callback: loadCart,
    });
};
export const addFavorite = send => {
    dispatch({
        type: FAVORITE_ADD,
        payload: agent.Favorite.add(send.audioid),
        send,
    });
};
export const rmFavorite = (send, callback) => {
    dispatch({
        type: FAVORITE_DELETE,
        payload: agent.Favorite.delete(send.like_id),
        callback,
        send,
    });
};

export const loadCurrentUser = () =>
    dispatch({ type: CURRENT_USER, payload: agent.Auth.current() });

export const loadPurchaseAll = params => {
    return dispatch({
        type: PURCHASE_LOAD,
        payload: agent.Order.list(params),
        params,
    });
};
export const loadHistoryPurchaseAll = params => {
    return dispatch({
        type: HISTORY_PURCHASE_LOAD,
        payload: agent.Order.listHistory(params),
        params,
    });
};

export const loadSellerProfileInfo = () => {
    return dispatch({
        type: SELLER_PROFILE,
        payload: agent.Seller.loadSellerProfileInfo(),
    });
};

export const loadCreatorDetail = send => {
    return dispatch({
        type: PRODUCER_GET_CREATOR_DETAIL,
        payload: agent.Producer.getCreatorDetail(send),
        send,
    });
};
/**返回金额 */
export const showMoney = moeny => {
    moeny =  parseFloat(moeny);
    if (!moeny) {
        moeny = 0;
    }
    return "¥"+moeny / 100 ;
};
export const showWithDrawMoney = moeny => {
    moeny =  parseFloat(moeny);
    if (!moeny) {
        moeny = 0;
    }
    return "¥"+moeny ;
};
/**返回金额 */
export const remittance = moeny => {
    moeny =  parseFloat(moeny);
    if (!moeny) {
        moeny = 0;
    }
    return moeny / 100 ;
};
/**返回订单号 */
export const showOrderId = orderid => {
    if (!orderid) {
        orderid = "";
    }
    return "";
};
export const showTime = time => {
    const scen=(parseInt(time) % 60)>9?(parseInt(time) % 60):('0'+(parseInt(time) % 60));
    return parseInt(parseInt(time) / 60) + ":" +scen;
};
export const loadLabel = () => {
    return dispatch({
        type: MUSIC_SEARCH_LABEL_LOAD,
        payload: agent.Music.searchLabels(),
    });
};
export const deleteOrder=(orderid) => {
    return dispatch({
        type: ORDER_DELETE,
        payload: agent.Order.deleteOrder(orderid),
    });
};
export const loadLabels=() => {
    return dispatch({
        type: MUSIC_SEARCH_LABEL_LOAD_SON,
        payload: agent.Music.searchLabelAll(),
    });
};
export const HtmlUtil = {
    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode:function (html){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement ("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined ) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
        temp = null;
        return output;
    },
    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode:function (text){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }
};
export const noLoginAddCart = car => {
    dispatch({ type: NO_LOGIN_ADD_CART, car });
};
export const clearCart = () => {
    dispatch({ type: CLEAR_CART_AUDIOS });
};
export const checkTiao=(sty,tiao)=>{
    if (tiao) {
        return tiao[sty];
    }
    return "0";
}
export const noShowPage = {
  
    baseDecode:function (tiao){
        let deco={};
        if (tiao) {
            deco=queryString.parse(Base64.decode(tiao));
        }
        return deco;
    },
    baseEecode:function (text){
        let enco='';
        if (text) {
            enco=Base64.encode(queryString.stringify(text));
        }
        return enco;
    }
};
export const addMusicStatis = send => {
    return dispatch({
        type: ADD_MUSIC_STATIS,
        payload: agent.MusicStatis.addMusicStatis(send),
    });
};
export const noise = () => {
    return dispatch({
        type: MUSIC_NOISE,
        payload: agent.Producer.noise(),
    });
};
