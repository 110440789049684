import React from "react";
let timer1 = undefined;
let num = 1;
import axios from 'axios';
class visionChina extends React.Component {
    constructor(props) {
        super(props);
        this.state = {secondsElapsed: 0};
    }
    
    componentDidMount() {
        this.timerStart1();
    }
    
    componentWillUnmount() {
        clearInterval(timer1);
    }
    
    record1 = () => {
        let size = 100;
        var url="http://183b37f077.imwork.net/audio/visionChina?size="+size+"&offset="+(num-1)*size;
        axios.get(url).then((res)=>{
            console.log(111)
            console.log(num)
            num++;
            console.log(res.data)
            axios.post("/audio/instock",res.data).then(res=>{
                console.log('res=>',res);            
            })
            if(num>40){
                clearInterval(timer1);
            }
            // console.log(res.data.message)
            // this.setState({
            //     arr:res.data.message
            // })
        })
        
        this.setState((prevState) => ({
            secondsElapsed: prevState.secondsElapsed + 1
        }));
        console.log("timer1");
    }

    
    timerStart1 = () =>  {
        this.record1()
        timer1 = setInterval(() => this.record1(), 60000);
    }
    
    timerStop1= () => {
        clearInterval(timer1);
    }
    timers= () => {
        var url="http://183b37f077.imwork.net/audio/visionChina?size="+100+"&offset="+1;
        axios.get(url).then((res)=>{
            console.log(111)
            console.log(num)
            num++;
            console.log(res.data)
            // axios({
            //     method:"post",
            //     headers:{'Content-type':'application/json'},
            //     url:"http://test-instock.visualchina.com/audio/instock",
            //     audioEntityArray:res.data
            // }).then(function(res){
            //     console.log(res);
            // }).catch(function(error){
            //     alert('get下载失败')
            //     console.log(error);
            // });

            axios.post("/audio/instock",res.data, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }}).then(res=>{
                console.log('res=>',res);    
                console.log(res);        
            })
            // if(num>8){
            //     clearInterval(timer1);
            // }
            // console.log(res.data.message)
            // this.setState({
            //     arr:res.data.message
            // })
        })
    }
    render() {
        return (
            <div>
                <div>
                Seconds Elapsed: {this.state.secondsElapsed}
                </div>
                <div>
                    <button onClick={this.timerStart1}>timerStart1</button>
                    <button onClick={this.timerStop1}>timerStop1</button>
                </div>
            </div>
        );
    }
}
export default visionChina;
