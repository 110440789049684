import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { redirectTo } from "dispatch";
import "./SubscriberMembers.less"
import { KEEP_IDENTIY } from "constants/actionTypes";
const mapStateToProps = state => ({
    userLogin: state.app.userLogin,
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = dispatch => ({
    addIdentiy: (send) =>
        dispatch({
            type: KEEP_IDENTIY,
            send,
        }),
});
class SubscribeMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false,
        };
    }
    static propTypes = {
        // loading: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        currentUser: PropTypes.object,
        addIdentiy: PropTypes.func.isRequired,
    };
    componentDidUpdate(prevProps) {

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const param = nextProps.match.params.identiy;
        console.log(param)
        window.localStorage.setItem("identiy", param);
        return { identiy: param };

    }
    gotToPay(type) {
        const { currentUser } = this.props;
        //加type参数做登录之后的页面返回
        if (!currentUser) {
            redirectTo("/login?type=2");
            return;
        }
        // if (type<=3) {
        //     if (currentUser.is_member==21||currentUser.is_member==22||currentUser.is_member==23) {
        //         this.setState({
        //             isShow:true,
        //         })
        //         return;
        //     }
        // }else{
        //     if (currentUser.is_member==11||currentUser.is_member==12||currentUser.is_member==13) {
        //         this.setState({
        //             isShow:true,
        //         })
        //         return;
        //     }
        // }
        if (currentUser.is_member != 0 && currentUser.is_member != 3) {
            this.setState({
                isShow: true,
            })
            return;
        }
        redirectTo("/SubscribePay?type=" + type);
    }
    closeShow() {
        this.setState({
            isShow: false,
        })
    }
    render() {
        const sty = this.state.isShow ? { display: "block" } : { display: "none" };
        const red = {
            color: '#9A0000'
        }
        return (
            <div className="mypageContents fixPriceContents subscribeMembers" id="subscribeMembers">
                <div className="modalOverlay" style={sty} />
                <div className="choose-container modalBox__inner" style={sty}>
                    <div className=" close-choose"><a onClick={this.closeShow.bind(this)} style={{ cursor: "pointer" }}><img height="24" src="/assets/img/u50.png" /></a></div>
                    <div className=" add_car_success">
                        <div className="dialog__title"><span>您已是MUSINESS订阅会员，请于会员到期后再续订，感谢您的理解！</span></div>
                        <div className="dialog__txt"><a onClick={this.closeShow.bind(this)} style={{ cursor: "pointer" }}>确认</a></div>
                    </div>
                </div>
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">开通会员畅享金V曲库</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="fixPriceMain ">
                            <div className="fixPriceMain__inner">
                                <div className="memberbox">
                                    <div className=" member">
                                        <div className=" clear"></div>
                                        <div className=" member-pro-ti">开通会员五大优势</div>
                                        <div className=" member-show-ti" style={{ textAlign: 'center', margin: '15px 0', fontWeight: '600', color: '#9A0000' }}>* 曲目旁标“金V”的为订阅会员曲库</div>
                                        <div className=" member-show-pc" style={{ margin: '20px 0 60px' }}>
                                            <ul>
                                                <li>
                                                    <div><img src="assets/img/member4-01.png" /></div>
                                                    <div style={red} className=" member-show-ti" >订阅范围含近80000首优质曲库</div>
                                                </li>
                                                <li>
                                                    <div><img src="assets/img/member4-02.png" /></div>
                                                    <div className=" member-show-ti">全球授权或中国(含港澳台地区)授权</div>
                                                </li>
                                                <li>
                                                    <div><img src="assets/img/member4-04.png" /></div>
                                                    <div style={red} className=" member-show-ti" >超出订阅范围，订阅会员享受全场8折优惠</div>
                                                </li>
                                                <li>
                                                    <div><img style={{ marginLeft: "26%" }} src="assets/img/member4-03.png" /></div>
                                                    <div style={{ marginLeft: "12%" }} className=" member-show-ti">5首/月免费购买下载</div>
                                                </li>
                                                <li>
                                                    <div><img src="assets/img/member4-05.png" /></div>
                                                    <div className=" member-show-ti">随片永久</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className=" member-show-phone">
                                            <ul className=" member-show-phone-ul01">
                                                <li>
                                                    <div><img src="assets/img/member4-02.png" /></div>
                                                    <div className=" member-show-ti">全球授权或中国(含港澳台地区)授权</div>
                                                </li>
                                                <li>
                                                    <div><img src="assets/img/member4-04.png" /></div>
                                                    <div className=" member-show-ti" style={red}>超出订阅范围，订阅会员享受全场8折优惠</div>
                                                </li>
                                                <li>
                                                    <div><img src="assets/img/member4-05.png" /></div>
                                                    <div className=" member-show-ti">随片永久</div>
                                                </li>
                                            </ul>
                                            <ul className=" member-show-phone-ul02">
                                                <li>
                                                    <div><img src="assets/img/member4-01.png" /></div>
                                                    <div className=" member-show-ti" style={red}>订阅范围含近80000首优质曲库</div>
                                                </li>

                                                <li>
                                                    <div><img src="assets/img/member4-03.png" /></div>
                                                    <div className=" member-show-ti">5首/月免费购买下载</div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="member-de">
                                            <div className="member-debox">
                                                <div className=" member-ti member-ti01">青铜会员</div>
                                                <div className=" member-ti-de02">
                                                    <div className=" member-buy">
                                                        <ul>
                                                            <li>
                                                                <div className=" member-buy-left">
                                                                    <span style={{"color":"#9A0000"}}>￥30/月(每月5首)</span>
                                                                    <p className="pay-now red_te_jia" style={{"color":"black "}}>原价￥1500</p>
                                                                    {/* <span style={{textDecoration: "line-through"}}>￥ 30/月</span> */}
                                                                    {/* <p className="pay-now red_te_jia">特价¥18.8/月</p> */}
                                                                    {/* <div className="pay-error"><span>￥ 30</span>/月</div> */}
                                                                    {/* <div className="pay-now"><span>特价￥ 18.8</span>/月</div> */}
                                                                </div>
                                                                <div className=" member-buy-right" onClick={this.gotToPay.bind(this, 1)}>点击购买</div>
                                                            </li>
                                                            <li>
                                                                <div className=" member-buy-left"><span style={{"color":"#9A0000"}}>￥68/季(每月5首)</span><p className="pay-now red_te_jia"><s>￥90/季(3个月)</s><s/></p></div>
                                                                <div className=" member-buy-right" onClick={this.gotToPay.bind(this, 2)}>点击购买</div>
                                                            </li>
                                                            <li>
                                                                <div className=" member-buy-left"><span style={{"color":"#9A0000"}}>￥218/年(每月5首)</span><p className="pay-now red_te_jia"><s>￥360/年(12个月)</s><s/></p></div>
                                                                <div className=" member-buy-right" onClick={this.gotToPay.bind(this, 3)}>点击购买</div>
                                                            </li>
                                                        </ul>
                                                        {/* <a>省下 ￥11.2</a>  */}
                                                    </div>
                                                </div>

                                                <div className=" member-ti-de">
                                                    <div className=" member-de-ti member-de-ti01">可授权用途：</div>
                                                    <div className=" member-de-de member-de-de01">
                                                        <ul>
                                                            <li>短视频/自媒体视频/直播背景音乐</li>
                                                            <li>音频背景音乐/FM/广播/有声读物</li>
                                                            <li>非商用（国家官方机构发起的有公益证明文件的公益项目/非盈利教学）</li>
                                                            <div className=' member-de-div01'><span>可购买歌曲数量：</span></div>
                                                            <div className=' member-de-div02'>5首/月免费购买下载</div>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="member-de">
                                            <div className="member-debox">
                                                <div className=" member-ti member-ti02">黄金会员</div>
                                                <div className=" member-ti-de02">
                                                    <div className=" member-buy" >
                                                        <ul>
                                                            <li>
                                                                <div className=" member-buy-left"><span style={{"color":"#9A0000"}}>￥199/月(每月5首)</span><p className="pay-now red_te_jia" style={{"color":"black "}}>最高价值￥7500</p></div>
                                                                <div className=" member-buy-right member-buy-right02" onClick={this.gotToPay.bind(this, 4)}>点击购买</div>
                                                            </li>
                                                            <li>
                                                                <div className=" member-buy-left"><span style={{"color":"#9A0000"}}>￥518/季(每月5首)</span><p className="pay-now red_te_jia"><s>￥597/季(3个月)</s><s/></p></div>
                                                                <div className=" member-buy-right member-buy-right02" onClick={this.gotToPay.bind(this, 5)}>点击购买</div>
                                                            </li>
                                                            <li>
                                                                <div className=" member-buy-left"><span style={{"color":"#9A0000"}}>￥1688/年(每月5首)</span><p className="pay-now red_te_jia"><s>￥2388/年(12个月)</s><s/></p></div>
                                                                <div className=" member-buy-right member-buy-right02" onClick={this.gotToPay.bind(this, 6)}>点击购买</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className=" member-ti-de">
                                                    <div className=" member-de-ti member-de-ti02">可授权用途：</div>
                                                    <div className=" member-de-de member-de-de02">
                                                        <ul>
                                                            <li>短视频/自媒体视频/直播背景音乐</li>
                                                            <li>音频背景音乐/FM/广播/有声读物</li>
                                                            <li>非商用（国家官方机构发起的有公益证明文件的公益项目/非盈利教学）</li>
                                                            <li>连续剧/动画/综艺</li>
                                                            <li>商业广告/宣传片/H5</li>
                                                            <div className=' member-de-div03'><span>可购买歌曲数量：</span></div>
                                                            <div className=' member-de-div02'>5首/月免费购买下载</div>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribeMembers);
