import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Pagination,Modal } from "antd";
import {
    PRODUCER_GET_CREATOR,
    PRODUCER_DELETE_CREATOR,
} from "constants/actionTypes";
import { Link } from "react-router-dom";
import agent from "agent";
import Loading from "components/Loading";

import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import "./pager.less";
import { redirectTo } from "dispatch";
const confirm = Modal.confirm;

const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    creator: state.creator,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    getCreators: (send, callback) => {
        dispatch({
            type: PRODUCER_GET_CREATOR,
            payload: agent.Producer.getCreators(send),
            send,
            callback,
        });
    },
    deleteCreator: (send, callback) =>
        dispatch({
            type: PRODUCER_DELETE_CREATOR,
            payload: agent.Producer.deleteCreator(send, callback),
            send,
            callback,
        }),
});

class SellerMakerList extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        currentUser: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        pagination: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        makerList: PropTypes.array,

        creator: PropTypes.object.isRequired,
        getCreators: PropTypes.func.isRequired,
        deleteCreator: PropTypes.func.isRequired,
    };

    static defaultProps = {
        headers: {
            authorization: "authorization-text",
        },
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin, getCreators } = this.props;

        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        getCreators({ pager: this.props.creator.pagination });
    }

    // deleteCreator(id) {

    //     this.props.deleteCreator({ id });
    // }
    showDeleteConfirm (id) {
        const {deleteCreator}=this.props;
        confirm({
            title: '您确定要删除此条记录吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                deleteCreator({id});
            },
            onCancel() {
                
            },
        });
    }
    onPageChange(page) {
        this.loadData(page);
    }
    loadData(page) {
        const { current, pageSize } = this.props.pagination;
        this.props.getCreators({
            pager: { current: page || current, pageSize },
        });
    }

    render() {
        const {
            currentUser,
            creator: { list: makerList, total: total, pagination },
            loading,
        } = this.props;
        console.info(pagination);
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }

        const { current, pageSize } = pagination;

        const pageOptions = {
            defaultCurrent: 1,
            defaultPageSize: pageSize,
            current,
            total,
        };
        const pager = currentUser.company && pageOptions.total > 0 ? (
            <Pagination
                {...pageOptions}
                onChange={this.onPageChange.bind(this)}
            />
        ) :"";
        const comment = makerList.length
            ? !currentUser.company &&
              ""
            : "创作人未登录";

        const maker_list = currentUser.company
            ? makerList
            : makerList.slice(0, 1);

        const makerlist = maker_list.map((data, i) => {
            return (
                <div className="creatorItem" key={i}>
                    <div className="creatorItem__avatar">
                        <div className="creatorItem__avatar__img">
                            <img src={agent.getApiUrl(data.avatar)} alt="" />
                        </div>
                        <p className="creatorItem__avatar__name">
                            {data.nickname}
                        </p>
                    </div>
                    {currentUser.company ? (
                        <div className="creatorItem__utility">
                            <ul className="creatorItem__utility__items">
                                <li className="creatorItem__utility__item creatorItem__utility__detail">
                                    <Link
                                        to={`/seller/makerview?id=${data.id}`}
                                        className="detailBtn"
                                    />
                                </li>
                                {/**企业账户的创作人编辑区域 */}
                                <li className="creatorItem__utility__item creatorItem__utility__download">
                                    <Link
                                        className="editBtn"
                                        to={`/seller/makeredit?id=${data.id}`}
                                    />
                                </li>
                                <li className="creatorItem__utility__item creatorItem__utility__trashbox">
                                    <button
                                        onClick={e =>
                                            this.showDeleteConfirm(data.id)
                                        }
                                        className="trashbox"
                                    />
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <div className="creatorItem__utility">
                            <ul className="creatorItem__utility__items">
                                {/**个人卖家的创作人修改 */}
                                <li className="creatorItem__utility__item creatorItem__utility__download">
                                    <button
                                        className="editBtn"
                                        onClick={e =>
                                            (location.href = `/seller/makeredit?id=${
                                                data.id
                                            }`)
                                        }
                                    />
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            );
        });
       
        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox">
                            <h2 className="mymusicBox__title">创作人</h2>
                            {(!makerList.length || currentUser.company) && (
                                <p className="mymusicBox__postBtn">
                                    <Link to="/seller/makeredit?type=usercreate">
                                        添加创作人
                                    </Link>
                                </p>
                            )}
                            <div className="creatorItemWrapper">
                                <Loading loading={loading} />
                                {makerlist}
                                <p className="u-noteTxt u-Color--red2">
                                    {comment}
                                </p>
                                <div className="c02b478-comanymakerlist">
                                    <div className="resultPagination">
                                        {pager}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const SellerMakerListForm = Form.create()(SellerMakerList);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SellerMakerListForm);
