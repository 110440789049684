import React from "react";
import {Route, Switch} from "react-router-dom";
import PropTypes from "prop-types";

import Search from "./Search";
import Search1 from "./Search1";
import TopicAll from "./TopicAll";
import TopicOne from "./TopicOne";
import MusicOne from "./MusicOne";

class Music extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    };
    render() {
        const {match} = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/search`} component={Search} />
                <Route path={`${match.url}/material`} component={Search1} />
                <Route
                    path={`${match.url}/topic/:topicone`}
                    component={TopicOne}
                />
                <Route path={`${match.url}/topic`} component={TopicAll} />
                <Route
                    path={`${match.url}/musicid/:musicone`}
                    component={MusicOne}
                />
                <Route path={`${match.url}`} component={Search} />
            </Switch>
        );
    }
}
export default Music;
