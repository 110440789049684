import React from "react";
import { connect } from "react-redux";
import {
    SHOW_FORGET_POPUP,
    FORGOT_PASSWORD_CAPTCHA,
    FORGET_PASSWORD,
} from "../../constants/actionTypes";
import PropTypes from "prop-types";
import { Input, Button, Form } from "antd";
import { Link } from "react-router-dom";
import "components/User/User.less";
import agent from "agent";
const mapStateToProps = state => ({
    ...state.forgetPassword,
});

const mapDispatchToProps = dispatch => ({
    showForgetPopup: isShow => {
        return dispatch({ type: SHOW_FORGET_POPUP, isShow });
    },
    onClick: (email, done) =>
        dispatch({ type: FORGOT_PASSWORD_CAPTCHA, payload: agent.Captcha.getCreate(email), done }),
    onSubmit: parma =>
        dispatch({
            type: FORGET_PASSWORD,
            payload: agent.User.userForgetPassword(parma),
        }),
});

class ForgetPassword extends React.Component {
    static propTypes = {
        isShow: PropTypes.shape({
            forgetShow: PropTypes.bool.isRequired,
        }).isRequired,
        showForgetPopup: PropTypes.func.isRequired,
        form: PropTypes.object,
        onClick: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };
    static defaultProps = {
        isShow: {
            forgetShow: false,
        },
    };
    state = {
        captchaDisabled: false,
        captchaLock: -1,
    };
    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
    }
    showFormItemServerError() {
        const errors = this.props.errors;
        if (!errors) {
            const { captchaDisabled } = this.state;
            if (captchaDisabled) {
                return;
            }
            this.setState({ captchaDisabled: true });
          
            let lock = 60;
            this.setState({ captchaLock: lock });
            const c = setInterval(() => {
                lock--;
                this.setState({ captchaLock: lock });
                if (lock <= 0) {
                    this.setState({ captchaDisabled: false });
                    clearInterval(c);
                }
            }, 1000);
            return;
        }
        const { form } = this.props;
        Object.keys(errors).map(key => {
            const message = errors[key];
            if (message.message === "Invalid captcha") {
                message.param = "captcha";
                message.msg = "验证码错误";
                message.value = form.getFieldValue("code");
            } else if (message.message === "Invalid phone or email") {
                message.param = "phoneOrEmail";
                message.msg = "请输入正确的手机号/邮箱！";
                message.value = form.getFieldValue("phoneOrEmail");
            } else if (
                message.message === "password must be at least 6 chars long"
            ) {
                message.param = "newPassword";
                message.msg = "密码至少6位！";
                message.value = form.getFieldValue("newPassword");
            }else if (message.message === "Not yet registered"){
                message.param = "phoneOrEmail";
                message.msg = "该手机/邮箱尚未注册!";
                message.value = form.getFieldValue("phoneOrEmail");
            }

            switch (message.param) {
                case "phoneOrEmail":
                    form.setFields({
                        phoneOrEmail: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
                case "captcha":
                    form.setFields({
                        code: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
                case "newPassword":
                    form.setFields({
                        newPassword: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
            }
        });
    }
    sendCode = e => {
        e.preventDefault();
        let bindInfo = this.props.form.getFieldValue("phoneOrEmail");

        if (
            (!/^\d+(\.\d{1,2})?$/.test(bindInfo) || bindInfo.length != 11) &&
            !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(bindInfo)
        ) {
            this.props.form.setFields({
                phoneOrEmail: {
                    value: bindInfo,
                    errors: [new Error("请输入正确的手机号/邮箱！")],
                },
            });
            return false;
        } else {
            this._getIdentifyingCode(bindInfo);
            //this.props.onClick(bindInfo);
        }
    };
    _getIdentifyingCode = field => {
        const done = e => {
        };
        this.props.onClick(field, done);
    };
    renderCaptcha = () => {
        const { captchaDisabled, captchaLock } = this.state;
        const className = [];
        if (captchaDisabled) {
            className.push("isDisabled");
        }
        return (
            <Link to="" className={className.join(" ")} onClick={this.sendCode}>
                {captchaLock > 0 ? (
                    <span>
                        正在发送验证码（
                        {captchaLock}）
                    </span>
                ) : (
                    <span> 发送验证码</span>
                )}
            </Link>
        );
    };
    closeShow() {
        this.props.form.resetFields("phoneOrEmail");
        this.props.form.resetFields("newPassword");
        this.props.form.resetFields("confimPassword");
        this.props.form.resetFields("code");
        const { isShow, showForgetPopup } = this.props;
        const send = {
            ...isShow,
            forgetShow: false,
        };
        showForgetPopup(send);
    }
    handleFprgetSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (
                    !this.checkPhone(values.phoneOrEmail) &&
                    !this.checkEmail(values.phoneOrEmail)
                ) {
                    return false;
                }
                if (values.newPassword !== values.confimPassword) {
                    this.props.form.setFields({
                        confimPassword: {
                            value: values.confimPassword,
                            errors: [new Error("输入密码不一致！")],
                        },
                    });
                    return false;
                }
                if (values.code.length < 6) {
                    this.props.form.setFields({
                        code: {
                            value: values.code,
                            errors: [new Error("验证码输入有误！")],
                        },
                    });
                    return false;
                }
                const parma = {
                    email: values.phoneOrEmail,
                    password: values.newPassword,
                    captcha: parseInt(values.code),
                };
                this.props.onSubmit(parma);
            }
        });
    };
    checkPhone(valus) {
        if (!/^\d+(\.\d{1,2})?$/.test(valus) || valus.length != 11) {
            this.props.form.setFields({
                phoneOrEmail: {
                    value: valus,
                    errors: [new Error("请输入正确的手机号或邮箱！")],
                },
            });
            return false;
        }
        return true;
    }
    checkEmail(valus) {
        if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(valus)) {
            this.props.form.setFields({
                phoneOrEmail: {
                    value: valus,
                    errors: [new Error("请输入正确的手机号或邮箱！")],
                },
            });
            return false;
        }
        return true;
    }
    render() {
        const { forgetShow } = this.props.isShow;
        const showClass = forgetShow ? "isShow" : "modalBox";
        const { getFieldDecorator } = this.props.form;
        if (!forgetShow) {
            return null;
        }
        return (
            <div className={showClass}>
                <div className="modalOverlay" />
                <div className="modalBox__inner">
                    <div id="modalForm1" className="modalForm" tabIndex="0">
                        <div className="modalForm__header">
                            <p className="modalForm__title">忘记密码</p>
                            <p className="modalForm__close">
                                <button
                                    onClick={this.closeShow.bind(this)}
                                    type="button"
                                />
                            </p>
                        </div>
                        <div className="modalForm__inner">
                            <div className="buypageBox">
                                <div className="buypageForm">
                                    <Form onSubmit={this.handleFprgetSubmit}>
                                        <div className="buypageForm__inner">
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                手机号 / 邮箱
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "phoneOrEmail",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message:
                                                                                    "请输入正确的手机号或邮箱!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                重设密码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "newPassword",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入新密码",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="password"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                再次输入密码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "confimPassword",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请确认新密码",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="password"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                输入验证码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd buypageForm__dd--s">
                                                            <ul className="buypageForm__inList2">
                                                                <li className="buypageForm__inList2__item">
                                                                    <Form.Item>
                                                                        {getFieldDecorator(
                                                                            "code",
                                                                            {
                                                                                rules: [
                                                                                    {
                                                                                        required: false,
                                                                                        message:
                                                                                            "",
                                                                                    },
                                                                                ],
                                                                            }
                                                                        )(
                                                                            <Input
                                                                                type="text"
                                                                                className="u-inputSkin02"
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </li>
                                                                <li className="buypageForm__inList2__item u-noWrap">
                                                                    {this.renderCaptcha()}
                                                                </li>
                                                            </ul>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <p className="buypageForm__submit">
                                                <Button
                                                    className="u-submitSkin"
                                                    type="primary"
                                                    block
                                                    htmlType="submit"
                                                >
                                                    确认送出
                                                </Button>
                                            </p>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(ForgetPassword);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
