// import agent from "../agent";
import React from "react";
import {connect} from "react-redux";
// import PropTypes from "prop-types";
import {Layout} from "antd";
import Loading from "../Loading";

const {Content} = Layout;

const mapStateToProps = state => ({...state.musicTopicOne});

const mapDispatchToProps = dispatch => ({});

class TopicOne extends React.Component {
    static propTypes = {
        // redirectTo: PropTypes.string,
        // appName: PropTypes.string.isRequired,
        // appLoaded: PropTypes.bool,
        // onRedirect: PropTypes.func.isRequired,
        // linkTo: PropTypes.func.isRequired
    };
    state = {
        loading: true,
        topicone: ""
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let loading = false;
        const param = nextProps.match.params.topicone;
        if (prevState.topicone != param) {
            // TODO: send loading
            loading = true;
        }
        return {topicone: param, loading};
    }

    id = 0;
    componentWillUnmount() {
        clearTimeout(this.id);
    }

    render() {
        if (this.state.loading) {
            clearTimeout(this.id);
            this.id = setTimeout(
                (() => {
                    this.setState(state => {
                        state.loading = false;
                        return state;
                    });
                }).bind(this),
                500
            );

            return (
                <Content>
                    <Loading />
                </Content>
            );
        }
        return <Content>topicone {this.state.topicone}</Content>;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopicOne);
