import {
    CART_DELETE,
    CART_ADD,
    CART_LOAD,
    ASYNC_START,
    CART_ON_CHECK_CHANGE,
    CART_CREATE_ORDER,
    CART_CREATE_FREE_ORDER,
    LOGOUT,
    NO_LOGIN_ADD_CART,
    CLEAR_CART_AUDIOS,
    SHOW_SHOP_CAR,
    CHECK_PRICE_GROUP,
    CART_PAGE_UNLOAD,
    CLOSE_CODE_URL,
    SHOW_CODE_URL,
    CHECK_ORDER_BY_ORDER_ID,
} from "../constants/actionTypes";
const defaultState = {
    cartCount: 0,
    serverCartData: "",
    loading: false,
    checkedList: [],
    plainOptions: [],
    indeterminate: false,
    checkAll: true,
    createedOrderId: "",
    audioCart:[],
    addAudio:{},
    showAddCar:false,
    priceGroup:[],
    payUrl:"",
    remittance:0,
    order:{},
    codeUrl:"",
    prepayId:"",
    codeShow:false,
    orderState:0,
    price:"",
};
export default (state = { ...defaultState }, action) => {
    console.log(action)
    switch (action.type) {
        case CART_ADD: {
            if (action.error) {
                return {
                    ...state,
                    errors: action.error ? action.payload.errors : null,
                };
            }
            const cartCount = state.cartCount + 1;
            state.audioCart=[];
            return {
                ...state,
                cartCount,
                errors: action.error ? action.payload.errors : null,
            };
        }
        case NO_LOGIN_ADD_CART: {
            if (action.error) {
                return state;
            }
            let isHave=false;
            for (let i = 0; i < state.audioCart.length; i++) {
                const element = state.audioCart[i];
                if (element.audioid===action.car.audioid) {
                    isHave=true
                }
            }
            if (!isHave) {
                state.audioCart.push(action.car);
            }
            const cartCount =  state.audioCart.length;
            return {
                ...state,
                cartCount,
            };
        }
        case CLEAR_CART_AUDIOS: {
            if (action.error) {
                return state;
            }
            state.audioCart=[];
            const cartCount =  state.audioCart.length;
            return {
                ...state,
                cartCount,
            };
        }
        case CART_LOAD: {
            const fetchError = !!action.error;
            const serverCartData = fetchError ? [] : action.payload.cart;
            const plainOptions = [];
            for (const record of serverCartData) {
                plainOptions.push(record.id);
            }
            const checkedList = [...plainOptions];
            const cartCount = serverCartData.length;
            return {
                ...state,
                fetchError,
                serverCartData,
                loading: false,
                cartCount,
                plainOptions,
                checkedList,
            };
        }
        case CART_CREATE_ORDER:
        case CART_CREATE_FREE_ORDER: {
            const fetchError = !!action.error;
            const payUrl = fetchError||!action.payload.pay_url ? "" : action.payload.pay_url;
            let remittance;
            if(action.type === CART_CREATE_FREE_ORDER){
                remittance = 10;
            }else {
                remittance = fetchError||!action.payload.remittance?0:action.payload.remittance;
            }
            const order = fetchError||!action.payload.order?"":action.payload.order;
            const codeUrl = fetchError||!action.payload.code_url?"":action.payload.code_url;
            const prepayId = fetchError||!action.payload.prepayId?"":action.payload.prepayId;
            console.log(prepayId)
            const codeShow = fetchError||!action.payload.code_url?false:true;
            return {
                ...state,
                fetchError,
                payUrl,
                remittance,
                loading: false,
                order,
                codeUrl,
                prepayId,
                codeShow,
            };
        }
        case CART_ON_CHECK_CHANGE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ASYNC_START:
            if (
                action.subtype === CART_LOAD ||
                action.subtype === CART_CREATE_ORDER
            ) {
                return { ...state, loading: true };
            }
            return state;

        case LOGOUT: {
            state.audioCart=[];
            defaultState.audioCart=[];
            return {
                ...state,
                ...defaultState,
            };
        }
        case CART_DELETE: {
            return {
                ...state,
                ...action.payload,
                checkAll: true,
            };
        }
        case SHOW_SHOP_CAR: {
            return {
                ...state,
                addAudio:action.addAudio,
            };
        }
        case CHECK_PRICE_GROUP: {
            return {
                ...state,
                priceGroup:!action.error?action.payload.priceGroup:[],
            };
        }
        case CART_PAGE_UNLOAD: {
            const cartCount=state.cartCount;
            return {
                ...defaultState,
                cartCount,
            };
        }
        case CLOSE_CODE_URL: {
            return {
                ...state,
                codeShow:false,
                orderState:0,
            };
        }
        case SHOW_CODE_URL: {
            const codeShow=action.send.codeShow;
            const codeUrl=action.send.codeUrl;
            const orderid=action.send.orderid;
            const price=action.send.price;
            return {
                ...state,
                codeShow,
                codeUrl,
                orderid,
                price,
            };
        }
        case CHECK_ORDER_BY_ORDER_ID: {
            const fetchError = !!action.error;
            const orderState = fetchError ? 0 : action.payload.orderState;
            return {
                ...state,
                orderState,
            };
        }
        default:
            return state;
    }
};
