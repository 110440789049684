import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import News from "./News";
import MediaOne from "./MediaOne";
import MediaTwo from "./MediaTwo";
import MediaThree from "./MediaThree";
import MediaList from "./MediaList";
import MediaDetail from "./MediaDetail";

class Media extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        const { match } = this.props;
        return (
            <div className="c132-user">
                <Switch>
                    <Route
                        path={`${match.url}/medialist`}
                        component={MediaList}
                    />

                    <Route
                        path={`${match.url}/mediaone`}
                        component={MediaOne}
                    />
                    <Route
                        path={`${match.url}/mediatwo`}
                        component={MediaTwo}
                    />
                    <Route
                        path={`${match.url}/mediathree`}
                        component={MediaThree}
                    />
                    <Route
                        path={`${match.url}/newsid/:newsid`}
                        component={MediaDetail}
                    />
                    <Route path={`${match.url}`} component={News} />
                </Switch>
            </div>
        );
    }
}
export default Media;
