import {
    FAVORITE,
    FAVORITE_DELETE,
    ASYNC_START,
    FAVORITE_PAGE_UNLOADED,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,
    fetchError: false,
    total: 0,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case FAVORITE: {
            return {
                ...state,
                fetchError: !!action.error,
                total: action.error ? 0 : action.payload.total,
                loading: false,
            };
        }
        case ASYNC_START:
            if (
                action.subtype === FAVORITE ||
                action.subtype === FAVORITE_DELETE
            ) {
                return { ...state, loading: true };
            }
            break;
        case FAVORITE_PAGE_UNLOADED:
            return { ...defaultState };
        default:
            return state;
    }

    return state;
};
