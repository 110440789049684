import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Input, Button, Form } from "antd";
//import { Link } from "react-router-dom";
import "./Receipt.less";
//import agent from "agent";
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

class Receipt extends React.Component {
    static propTypes = {
        modalData: PropTypes.object,
        closeShow: PropTypes.func.isRequired,
        visible: PropTypes.bool,
        form: PropTypes.object,
        errors: PropTypes.array,
        receiptTicket: PropTypes.func.isRequired,
    }


    componentDidUpdate(prevProps) {

    }
    handleFprgetSubmit = e => {
        e.preventDefault();
        const { receiptTicket, modalData } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const jsonstr = !modalData.attachment ? {} : eval('(' + modalData.attachment + ')');
                const send = {
                    ...jsonstr,
                    nashuiName: values.nashuiName,
                    nashuiMail: values.email,
                    nashuiAddress: values.nashuiAddress,
                    nashuiPhone: values.nashuiPhone,
                    nashui: values.nashui,
                    bankInfo: values.bankInfo,
                    bankNumber: values.bankNumber,
                }
                receiptTicket(modalData.id, send);
            }
        });
    };


    render() {
        const { visible, closeShow } = this.props;
        const showClass = visible ? "isShow" : "modalBox";
        const { getFieldDecorator } = this.props.form;
        if (!visible) {
            return null;
        }
        return (
            <div className={showClass} id="receipt">
                <div className="modalOverlay" />
                <div className="modalBox__inner">
                    <div id="modalForm1" className="modalForm" tabIndex="0">
                        <div className="modalForm__header">
                            <p className="modalForm__title" style={{ color: "#333" }}>申请开票</p>
                            <p className="modalForm__close">
                                <button
                                    onClick={closeShow}
                                    type="button"
                                />
                            </p>
                        </div>
                        <div className="modalForm__inner">
                            <div className="buypageBox">
                                <div className="buypageForm">
                                    
                                    <Form onSubmit={this.handleFprgetSubmit}>
                                        
                                        <div className="buypageForm__inner">
                                            <p style={{color: 'red',fontSize: '14px',marginBottom:' 10px' }}>登记后电子发票将于7日内发送到您的电子邮箱。</p>
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt add_star">
                                                            <label htmlFor="">
                                                                名称
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "nashuiName",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入名称!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt add_star">
                                                            <label htmlFor="">
                                                                邮箱
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "email",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                type: 'email',
                                                                                message: '请输入正确的邮箱!',
                                                                            },
                                                                            {
                                                                                required: true,
                                                                                message: '请输入邮箱!',
                                                                            }
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt add_star">
                                                            <label htmlFor="">
                                                                纳税人识别号
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "nashui",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入纳税人识别号!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                地址
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "nashuiAddress",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message:
                                                                                    "请输入地址!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                电话
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "nashuiPhone",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message:
                                                                                    "请输入电话号!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="number"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                开户银行
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "bankInfo",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message:
                                                                                    "请输入开户行及账号!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                银行账号
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "bankNumber",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: false,
                                                                                message: "请输入银行账号!",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="number"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <p className="buypageForm__submit">
                                                <Button
                                                    className="u-submitSkin"
                                                    type="primary"
                                                    block
                                                    htmlType="submit"
                                                >
                                                    提交
                                                </Button>
                                            </p>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(Receipt);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
