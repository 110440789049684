import React from "react";
import PropTypes from "prop-types";

class Image extends React.Component {
    static propTypes = {
        width: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
        fullWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        url: PropTypes.string.isRequired,
        border: PropTypes.bool,
    };

    render() {
        let { width = 200, fullWidth, url, border = false } = this.props;

        if (window.screen.width > 1160 && fullWidth) width = fullWidth;
        let unit = "px";

        if (typeof width === "string") {
            [, width, unit] = width.match(/([\d.]+)(.*)/);
        }

        const style = {
            backgroundImage: `url(${url})`,
            border: "dashed 2px #cccccc",
            width: `${width}${unit}`,
            height: `${width}${unit}`,
            borderRadius: `${width / 2}${unit}`,
            backgroundClip: "padding-box",
            backgroundSize: "cover",
            backgroundPosition: "center center",
        };

        if (!border) delete style.border;

        return <div style={style} />;
    }
}

export default Image;
