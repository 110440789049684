import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import Mypage from "./Mypage";
import PurchaseOne from "./PurchaseOne";
import PurchaseAll from "./PurchaseAll";
import Customized from "./Customized";
import Profile from "./Profile";
import UpdateInfo from "./UpdateInfo";
import Subscribe from "./Subscribe";
import "./User.less";

class User extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        const { match } = this.props;
        return (
            <div className="c132-user">
                <Switch>
                    <Route path={`${match.url}/mypage`} component={Mypage} />
                    <Route
                        path={`${match.url}/purchase/:purchaseid`}
                        component={PurchaseOne}
                    />
                    <Route
                        path={`${match.url}/purchase`}
                        component={PurchaseAll}
                    />
                    <Route
                        path={`${match.url}/customized`}
                        component={Customized}
                    />
                    <Route
                        path={`${match.url}/subscribe`}
                        component={Subscribe}
                    />
                    <Route path={`${match.url}/profile`} component={Profile} />
                    <Route
                        path={`${match.url}/updateinfo`}
                        component={UpdateInfo}
                    />
                    <Route path={`${match.url}`} component={Mypage} />
                </Switch>
            </div>
        );
    }
}
export default User;
