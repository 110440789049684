import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import agent from "agent";
import { ADD_ON_CLICK } from "constants/actionTypes";
import { redirectTo } from "dispatch";
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({

    addOnClick: (send) =>
        dispatch({
            type: ADD_ON_CLICK,
            payload: agent.MusicStatis.addMusicSta(send),
        }),
});

class AddOn extends React.Component {
    static propTypes = {
        addon: PropTypes.string,
        addOnClick: PropTypes.func.isRequired,
    };
    state = {
        addon: "",
       
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const param = nextProps.match.params.addon;
        console.log(param)
        const { addOnClick } = nextProps;
        const send={
            audioid:'1',
        };
        if (param=='5') {
            send.style="subscribe";
        }else{
            send.style="email_add";
        }
        addOnClick(send);
        if (param=='1') {
            redirectTo(`/music/search`);
        }else if (param=='3') {
            redirectTo(`/topicmusic?id=CUWRRAJGIYVNTVDP9F88CBA97DB494EA`);
        }else if (param=='4') {
            redirectTo(`/composition`);
        }else if (param=='2') {
            redirectTo(`/`);
        }else if(param=='5'){
            redirectTo(`/subscribermembers`);
        }else{
            window.localStorage.setItem("identiy", param);
            window.identiy = param;
            console.log(window.identiy)
            redirectTo(`/`);
        }
        return { addon: param };
    }
   
    render() {
        return (
            <div>
                11
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOn);
