import React from "react";
//import PropTypes from "prop-types";
//import data from "components/Common/about_us_text";

export default class AboutusPage extends React.Component {
    render() {
        return (
            <div className="mypageContents aboutContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">版权声明</h1>
                    </header>
                    <div className="aboutBox">
                        <div className="aboutBox__inner" style={{paddingBottom:"60px"}}>
                            <p>&nbsp;</p>
                            <p>一、由于MUSINESS平台上的作品均由MUSINESS用户自行上传，故MUSINESS没有能力审查作品是否存在侵权等情节，但为切实维护权利人的合法权益，MUSINESS依据《中华人民共和国侵权责任法》、《中国人民共和国著作权法》、《信息网络传播权保护条例》等法律法规及相关规章制度的规定，采取如下知识产权保护措施：</p>
                            <p>&nbsp;&nbsp;&nbsp;1、MUSINESS向MUSINESS用户传达版权保护理念，提示用户不得上传和传播侵权作品，提示用户严格遵守法律法规及尊重他人合法权益。</p>
                            <p>&nbsp;&nbsp;&nbsp;2、对于违反规定，上传侵权作品的MUSINESS用户，MUSINESS将依照《MUSINESS平台用户协议》《MUSINESS卖家协议》等规定对其进行处理。</p>
                            <p>&nbsp;&nbsp;&nbsp;3、MUSINESS对于有理由确信存在明显侵权、违法、违反公共利益等情形的作品时，MUSINESS有权不事先通知并有权删除该等侵权作品。</p>
                            <p>&nbsp;&nbsp;&nbsp;4、MUSINESS支持权利人将侵权情况告知MUSINESS，MUSINESS也愿意与权利人合作净化网络空间，减少侵权行为的发生。MUSINESS将在接到符合要求的权利通知后，审查确认后删除涉嫌侵权的作品和相关内容。</p>
                            <p>&nbsp;&nbsp;&nbsp;5、用户购买MUSINESS平台上的作品，在未购买前，用户可下载带有声音浮水印的试听版作品，但不具有商业使用的权利。在用户购买成功后，用户依照购买流程中填写的购买用途、授权地区、授权项目名称、项目投放渠道等信息享有该作品的单次授权，即仅享有在前述购买用途、授权地区、授权项目的范围内使用该购买作品的权利。不得复制、共用、改编、转售或以其他方式使用该作品。用户应自觉遵守著作权法及其他相关法律的规定，不得侵犯本网站及权利人的合法权利。</p>
                            <p>&nbsp;&nbsp;&nbsp;6、MUSINESS动员用户对MUSINESS上的作品进行全面监督，MUSINESS会在第一时间对举报进行核实，及时清理侵权作品。</p>
                            <p>二、MUSINESS（网址：www.musiness.vip）的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、网站构架、网站版面的安排、网页设计、经由MUSINESS或广告商向用户呈现的广告或资讯的知识产权均属MUSINESS所有，&ldquo;MUSINESS&rdquo;、&ldquo;奕颗贝壳&rdquo;均是MUSINESS平台公司的注册商标。未经MUSINESS许可，任何人不得复制或模仿MUSINESS，也不得在非MUSINESS（www.musiness.vip）所属服务器上建立镜像。MUSINESS的知识产权受法律保护，未经MUSINESS书面许可，任何单位或个人不得以任何理由和方式使用。凡侵犯MUSINESS知识产权的，MUSINESS必依法追究其法律责任。</p>
                            <p>三、MUSINESS用户所发表的言论仅代表其个人，不代表MUSINESS观点。用户在MUSINESS（www.musiness.vip）发表言论的，视为其已经知道并理解这一声明。</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
