import { MUSIC_SEARCH_LABEL_LOAD, NEWS_ONE, NEWS_ONE_UNLOAD,PREVI_OUS} from "../constants/actionTypes";

const defaultState = {
    labelsData: {},
    loading: true,
    mediaDetail: "",
    prav: {},
};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MUSIC_SEARCH_LABEL_LOAD: {
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const labelsData = action.payload.condition;
            return {
                ...state,
                fetchError,
                labelsData,
            };
        }
        case PREVI_OUS:{
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const prav = action;
            return {
                ...state,
                fetchError,
                prav,
            };
        }
        case NEWS_ONE: {
            const fetchError = !!action.error;
            if (fetchError) {
                return state;
            }
            const mediaDetail = action.payload.newone;
            return {
                ...state,
                loading: false,
                fetchError,
                mediaDetail,
            };
        }
        case NEWS_ONE_UNLOAD:
            return { ...defaultState };
        default: {
            return state;
        }
    }
};
