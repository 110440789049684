import React from "react";

class AppSvg extends React.Component {
    render() {
        return (
            <svg
                className="SvgIcon hidden"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
            >
                <symbol>
                    <g id="playIcon">
                        <path className="a" d="M25.92,17.5,12.46,26.16V8.84Z" />
                    </g>
                    <g id="pauseIcon">
                        <g>
                            <rect
                                className="a"
                                x="11.55"
                                y="8.84"
                                width="4"
                                height="17.32"
                            />
                            <rect
                                className="a"
                                x="19.45"
                                y="8.84"
                                width="4"
                                height="17.32"
                            />
                        </g>
                    </g>
                    <g id="nextIcon">
                        <polygon
                            className="a"
                            points="23.27 8.84 23.27 17.5 9.8 8.84 9.8 26.16 23.27 17.5 23.27 26.16 25.2 26.16 25.2 8.84 23.27 8.84"
                        />
                    </g>
                    <g id="backIcon">
                        <polygon
                            className="a"
                            points="25.2 8.84 11.73 17.5 11.73 8.84 9.8 8.84 9.8 26.16 11.73 26.16 11.73 17.5 25.2 26.16 25.2 8.84"
                        />
                    </g>
                    <g id="favIcon">
                        <path
                            className="b"
                            d="M30.12,6.36A8.26,8.26,0,0,0,24,3.73,8.13,8.13,0,0,0,18.28,6a7.62,7.62,0,0,0-.78.91,8.39,8.39,0,0,0-.78-.9A8.08,8.08,0,0,0,11,3.73,8.25,8.25,0,0,0,4.89,6.36,10.45,10.45,0,0,0,2.5,13.44,10.07,10.07,0,0,0,4,18.75a17.22,17.22,0,0,0,3.53,4c2.7,2.38,5.78,4.3,8.37,6.8l0,0h0v0l1.58,1.66,1.55-1.68,0,0a51.87,51.87,0,0,1,4.7-3.86l1.57-1.21A22.44,22.44,0,0,0,31,18.75a10.07,10.07,0,0,0,1.51-5.31A10.5,10.5,0,0,0,30.12,6.36Zm-1,11.28A20.29,20.29,0,0,1,24,22.81,79.11,79.11,0,0,0,17.6,28l-.1.1-.1-.1c-2.68-2.58-5.92-4.64-8.45-6.87a15.29,15.29,0,0,1-3.1-3.52,7.88,7.88,0,0,1-1.21-4.2A8.28,8.28,0,0,1,6.49,7.79a5.71,5.71,0,0,1,4.35-1.92H11a5.92,5.92,0,0,1,4.25,1.68A6.45,6.45,0,0,1,17,11,.55.55,0,0,0,18,11a6.45,6.45,0,0,1,1.75-3.45A5.92,5.92,0,0,1,24,5.88h.12a5.71,5.71,0,0,1,4.35,1.92,8.28,8.28,0,0,1,1.85,5.65A7.88,7.88,0,0,1,29.15,17.64Z"
                        />
                    </g>
                    <g id="favIconIn">
                        <path
                            className="a"
                            d="M28.89,7.34a6.16,6.16,0,0,0-4.73-2.08H24a6.49,6.49,0,0,0-4.6,1.83,7.76,7.76,0,0,0-2,3.72,6.87,6.87,0,0,0-1.88-3.72A6.49,6.49,0,0,0,11,5.26h-.14A6.15,6.15,0,0,0,6.11,7.34a8.88,8.88,0,0,0-2,6,8.41,8.41,0,0,0,1.28,4.47,15.46,15.46,0,0,0,3.2,3.63c1.09,1,2.3,1.88,3.57,2.85a54.31,54.31,0,0,1,4.86,4l.45.48.45-.47a52.52,52.52,0,0,1,4.85-4l1.55-1.19a21,21,0,0,0,5.23-5.3,8.41,8.41,0,0,0,1.28-4.47A8.88,8.88,0,0,0,28.89,7.34Z"
                        />
                    </g>
                    <g id="soundIcon">
                        <path
                            className="a"
                            d="M19.21,6.56,8.49,12.77H2.67C1.2,12.77,0,14.32,0,16.2v2.62c0,1.89,1.2,3.43,2.67,3.43H8.53l10.68,6.19Z"
                        />
                        <path
                            className="a"
                            d="M31,26.61a1.47,1.47,0,0,1-.94-.34,1.5,1.5,0,0,1-.22-2.11A10.74,10.74,0,0,0,32,17.5a10.74,10.74,0,0,0-2.17-6.66A1.5,1.5,0,1,1,32.17,9,13.62,13.62,0,0,1,35,17.5a13.62,13.62,0,0,1-2.84,8.55A1.48,1.48,0,0,1,31,26.61Z"
                        />
                        <path
                            className="a"
                            d="M26.1,22.85a1.51,1.51,0,0,1-1-.35A1.49,1.49,0,0,1,25,20.39a4.33,4.33,0,0,0,1-2.77,4.29,4.29,0,0,0-1-2.76,1.5,1.5,0,1,1,2.3-1.92,7.44,7.44,0,0,1,0,9.37A1.52,1.52,0,0,1,26.1,22.85Z"
                        />
                    </g>
                </symbol>
            </svg>
        );
    }
}

export default AppSvg;
