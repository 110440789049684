import { CAPTCHA_UPDATE_INFO } from "../constants/actionTypes";
const defaultState = {};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case CAPTCHA_UPDATE_INFO:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
            };
        default:
            return state;
    }
};
