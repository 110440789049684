import React from "react";
import PropTypes from "prop-types";
import "./LeftItems.less";
import Loading from "components/Loading";
const queryString = require("query-string");
import {checkTiao,noShowPage} from "dispatch";

class Music extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkboxes: [],
        };
    }
    static propTypes = {
        show: PropTypes.string.isRequired,
        location: PropTypes.object.isRequired,
        handleSearchClick: PropTypes.func.isRequired,
        handleResetClick: PropTypes.func.isRequired,
        handleChangeClick: PropTypes.func.isRequired,
        emotion: PropTypes.array,
        loading: PropTypes.bool.isRequired,
    };
    handleChangeClick = e => {
        const { handleChangeClick } = this.props;
        handleChangeClick("emotion");
    };
    onChange(e) {
        if (e.target.checked) {
            let checks = this.state.checkboxes;
            checks.push(e.target.value);
            this.setState({
                checkboxes: checks,
            });
        } else {
            let checks = this.state.checkboxes;
            for (let i = 0; i < this.state.checkboxes.length; i++) {
                const element = this.state.checkboxes[i];
                if (e.target.value == element) {
                    checks.splice(i, 1);
                    this.setState({
                        checkboxes: checks,
                    });
                }
            }
        }
    }
    UNSAFE_componentWillReceiveProps (){
        const query = queryString.parse(this.props.location.search);
        const tiao =noShowPage.baseDecode(query.tiao);
        const emotion=checkTiao("emotion",tiao)
        let arry = [];
        if (emotion) {
            arry = emotion.split(",");
        }
        this.setState({
            checkboxes: arry,
        });
    }
    componentDidMount() {
        const query = queryString.parse(this.props.location.search);
        const tiao =noShowPage.baseDecode(query.tiao);
        const emotion=checkTiao("emotion",tiao)
        let arry = [];
        if (emotion) {
            arry = emotion.split(",");
        }
        this.setState({
            checkboxes: arry,
        });
    }
    handleSearchClick(val) {
        const { handleSearchClick } = this.props;

        handleSearchClick("emotion", this.state.checkboxes.toString());
    }
    handleResetClick = e => {
        this.setState({
            checkboxes: [],
        });
        const { handleResetClick } = this.props;
        handleResetClick("emotion");
    };
    renderCell(record) {
        const check = this.state.checkboxes;
        return (
            <li
                key={record.id}
                className="navList01__item navList01__item-check"
            >
                <label className="u-checkbox">
                    <input
                        type="checkbox"
                        value={record.id}
                        onChange={this.onChange.bind(this)}
                        checked={
                            check.indexOf(record.id.toString()) == -1
                                ? ""
                                : "checked"
                        }
                    />
                    <span>{record.title}</span>
                </label>
            </li>
        );
    }
    render() {
        const { show, emotion, loading } = this.props;
        const playClass =
            "accordionBox " + (show == "emotion" ? "is-open" : "is-close");
        const emotionData = emotion;
        return (
            <div className={playClass}>
                <p
                    className="accordionBox__title"
                    onClick={this.handleChangeClick.bind(this)}
                >
                    情绪
                </p>
                <div className="accordionBox__contents">
                    <ul
                        className="navList01"
                        data-column="2"
                        data-column-sp="3"
                    >
                        {emotionData != null ? (
                            emotionData.map(this.renderCell.bind(this))
                        ) : (
                            <Loading loading={loading} />
                        )}
                    </ul>
                    <p className="navList__resetBtn">
                        <a
                            href="javascript:void(0)"
                            onClick={this.handleResetClick.bind(this)}
                        >
                            重新选择
                        </a>
                    </p>

                    <p className="navList__submit">
                        <button
                            className="u-submitSkin-s"
                            onClick={this.handleSearchClick.bind(this)}
                        >
                            开始查询
                        </button>
                    </p>
                </div>
            </div>
        );
    }
}
export default Music;
