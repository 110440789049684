import React from "react";
import { connect } from "react-redux";
import agent from "agent";
import { PUBLIC_MAKER_LIST } from "constants/actionTypes";
import PropTypes from "prop-types";
import "./maker.less";
import { Pagination } from "antd";
import { redirectTo } from "dispatch";

class Maker extends React.Component {
    static propTypes = {
        list: PropTypes.object.isRequired,
        getList: PropTypes.func.isRequired,
    };

    state = {
        tip: null,
        keyword: "",
    };

    componentDidMount() {
        const { pager } = this.props.list;
        this.props.getList({ pager });
    }

    gotoDetail(id) {
        //此用户下不会有音乐，所以重定向到搜索
        if(id==343)
            redirectTo(`/music/search`);
        else
            redirectTo(`/makerdetail?id=${id}`);
    }

    showTip(id) {
        this.setState({ tip: id });
    }

    onPageChange(page) {
        const { pager } = this.props.list;
        this.props.getList({
            pager: { ...pager, current: page },
            keyword: this.state.keyword,
        });
    }

    onChangeKeyword(e) {
        this.setState({
            keyword: e.target.value,
        });
    }

    onSearch= e => {
        e.preventDefault();
        const { pager } = this.props.list;
        this.props.getList({ pager, keyword: this.state.keyword });
    }

    render() {
        const { data = [], pager = {}, total } = this.props.list;
        const { pageSize, current } = pager;
        console.info(data)
        return (
            <div className="mypageContents musicmakerContents" id="creators_index">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">创作人</h1>
                    </header>
                    <div className="partnerBox">
                        <div className="partnerBox__inner">
                            <div className="summaryBox__search searchBox">
                                <form onSubmit={this.onSearch}>
                                    <input
                                        onChange={this.onChangeKeyword.bind(this)}
                                        type="text"
                                        className="searchSkin"
                                        placeholder="搜索创作人"
                                    />
                                    <input
                                        type="submit"
                                        name="submit"
                                        value="搜寻"
                                        id="searchSubmit"
                                    />
                                </form>
                            </div>
                            <div className="partnerBox__main">
                                <div
                                    className="musicmakerList m-columnBox"
                                    data-column="4"
                                    data-column-sp="3"
                                    style={{ overflow: "unset" }}
                                >
                                    <ul
                                        className="musicmakerList__items m-columnBox__items"
                                        style={{ overflow: "unset" }}
                                    >
                                        {data.map((x, i) => {
                                            const {
                                                avatar,
                                                id,
                                                description,
                                                nickname,
                                            } = x;

                                            return (
                                                <li onClick={this.gotoDetail.bind(this,id)}
                                                    className="musicmakerList__item m-columnBox__item"
                                                    key={i}
                                                >
                                                    {/* <div style={{ backgroundImage: `url(${agent.getApiUrl(avatar)})`,}}
                                                        className="musicmakerList__img"
                                                    /> */}
                                                    <div className="musicmakerList__img"><img src={agent.getApiUrl(avatar)} alt=""/></div>
                                                    <p onClick={this.showTip.bind(this,id)}
                                                        className="musicmakerList__txt"
                                                    >
                                                        {nickname}
                                                    </p>
                                                    {this.state.tip === id && (
                                                        <div className="list__balloon is-top">
                                                            <div className="list__balloon__inner">
                                                                <p>
                                                                    {
                                                                        description
                                                                    }
                                                                </p>
                                                            </div>
                                                            <span className="list__balloon__tale" />
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="c02b478-music-table">
                                <div className="resultPagination ">
                                    <Pagination
                                        defaultPageSize={pageSize}
                                        defaultCurrent={1}
                                        current={current}
                                        total={total}
                                        hideOnSinglePage={true}
                                        onChange={this.onPageChange.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        list: state.maker.list,
    }),
    dispatch => ({
        getList: send =>
            dispatch({
                type: PUBLIC_MAKER_LIST,
                payload: agent.Maker.getList(send),
                send,
            }),
    })
)(Maker);
