import {
    PAY_CUSTOMIZED
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
       
        case PAY_CUSTOMIZED: {
            const fetchError = !!action.error;
            const payUrl = fetchError ? "" : action.payload.pay_url;
            return {
                ...state,
                fetchError,
                payUrl,
            };
        }
       
        default:
            return state;
    }
};
