import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Upload, Form, Input,Layout } from "antd";
import { DlWraper, getBase64, form } from "components/Seller/MakerEdit";
import Image from "components/Common/Image";
import ClassCropperModal from 'components/ClassCropperModal/AvatorUpdate'

const { Content } = Layout;
const dls = (form, state, { fileOnChange, beforeUpload,handleClassFileChange, getFieldDecorator }) =>
    form.map((x, i) => {
        const { type, label, name, rules, dtClassList, row = 6, placeholder, fieldClassList } = x;
        const fieldClass = fieldClassList.join(" ");

        switch (type) {
            case "textarea":
            case "input": {
                let input = null;
                if (type === "input")
                    input = (
                        <Input
                            onChange={e => fileOnChange(name, e.target.value)}
                            placeholder={placeholder}
                            className={fieldClass}
                        />
                    );
                else
                    input = (
                        <Input.TextArea
                            onChange={e => fileOnChange(name, e.target.value)}
                            row={row}
                            placeholder={placeholder}
                            className={fieldClass}
                        />
                    );

                const content = (
                    <Form.Item>
                        {getFieldDecorator(name, {
                            initialValue: state.formdata[name],
                            rules: rules || [],
                        })(input)}
                    </Form.Item>
                );
                return <DlWraper key={i} label={label} dtClassList={dtClassList} content={content} />;
            }
            case "avatar": {
                const content = [
                    <div className="form__avatarBox__" style={{ maxHeight: "160px", maxWidth: "160px" }} key={0}>
                        <label htmlFor="avatar">
                            <Upload
                                name={name}
                                listType="picture-card"
                                showUploadList={false}
                                beforeUpload={file => beforeUpload(file, name)}
                                onChange={handleClassFileChange}
                            >
                                <Image border={true} width={160} url={state.imageUrl} />
                            </Upload>
                                   
                        </label>
                        <p style={{ position:' absolute',fontSize:' .7em',top: '181px', left: '306px'}}>*请上传 2M 以内, JPG/RNG图片</p>
                    </div>,
                    state.onCheck && !state.formdata[name] && (
                        <div key={1} style={{ color: "red", margin: "5px" }}>
                            请上传头像!
                        </div>
                    ),
                ];
                return <DlWraper key={i} label={label} dtClassList={dtClassList} content={content} />;
            }
        }
    });

class MakerInfo extends React.Component {
    state = {
        formdata: {},
        shouldValid: false,
        onCheck: false,
        imageUrl: "/assets/img/img_avatar_edit.png",
        classModalVisible: false,
        classModalFile: null,
        classResultImgUrl: null,
        uploadModalFile: null,
    };
    static propTypes = {
        form: PropTypes.object.isRequired,
        onRemove: PropTypes.func,
        onRef: PropTypes.func,
        isList: PropTypes.bool,
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    fileOnChange(name, value) {
        this.setState(state => {
            return {
                formdata: {
                    ...state.formdata,
                    [name]: value,
                },
            };
        });
    }

    beforeUpload(file, name) {
        getBase64(file).then(imageUrl => {
            this.setState({ imageUrl });
        });
        this.setState(state => {
            return {
                formdata: {
                    ...state.formdata,
                    [name]: file,
                },
            };
        });
        return false;
    }

    async fireCheck() {
        return new Promise((resolve, reject) => {
            // if (!this.props.isList && !this.state.shouldValid) return resolve(true);

            this.setState({ onCheck: true });

            this.props.form.validateFieldsAndScroll(err => {
                if (err) {
                    reject(err);
                } else if (!this.state.uploadModalFile) {
                    reject("没有上传头像");
                } else {
                    this.state.formdata.avatar=this.state.uploadModalFile;
                    resolve(this.state.formdata);
                }
            });
        });
    }

    toggleShouldVliad() {
        this.setState({ shouldValid: !this.state.shouldValid });
    }
    handleClassFileChange = e => {
        console.info(e)
        const file = e.file;
        if (file) {
            // if (file.size <= MAX_FILE_SIZE) {
            this.setState({
                classModalFile: file // 先把上传的文件暂存在state中
            },
            () => {
                this.setState({
                    classModalVisible: true // 然后弹出modal
                })
            }
            )
            // } else {
            //     console.log('文件过大')
            // }
        }
    }
    handleGetResultImgUrl = key => file => {
        this.setState({
            [key]: file
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            classModalVisible,
            classModalFile,
        } = this.state;
        const lists = dls(form, this.state, {
            fileOnChange: this.fileOnChange.bind(this),
            beforeUpload: this.beforeUpload.bind(this),
            handleClassFileChange:this.handleClassFileChange.bind(this),
            getFieldDecorator,
        });

        return (
            <Content id="makeredit">
                <div>
                    <br />
                    <Form>
                        <div className="buypageForm__item">
                            {!this.props.isList && (
                                <div>
                                    <p className="buypageForm__txt u-mb0">
                                        <label className="u-checkbox2">
                                            <input
                                                type="checkbox"
                                                checked={this.state.shouldValid}
                                                onChange={this.toggleShouldVliad.bind(this)}
                                            />
                                            <span>我是创作人｜请完善下列创作人信息&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </label>
                                    </p>
                                </div>
                            )}
                            {this.props.isList && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button onClick={this.props.onRemove} className="m-btn-type1--grn--ss">
                                        删除
                                    </button>
                                </div>
                            )}
                            {lists}
                        </div>
                    </Form>
                </div>
                
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('imageUrl')}
                        onShowImg={this.handleGetResultImgUrl('uploadModalFile')}
                        onThumb={this.handleGetResultImgUrl('avatar')}
                    />
                )}
            </Content>
        );
    }
}
export default connect()(Form.create()(MakerInfo));
