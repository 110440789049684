import React from "react";
import PropTypes from "prop-types";
import agent from "agent";
import { Link } from "react-router-dom";
import StaticWave from "utils/staticWave";
import Download from "components/Common/Download";
import Share from "components/Common/Share";
import { showTime ,HtmlUtil,addMusicStatis} from "dispatch";
import Loading from "components/Loading";
import "./FavoriteTableCell.less";

class FavoriteTableCell extends React.Component {
    static propTypes = {
        audio: PropTypes.shape({
            id: PropTypes.number.isRequired,
            audioid: PropTypes.string.isRequired,
            thumb: PropTypes.string.isRequired,
            audio: PropTypes.string.isRequired,
            author: PropTypes.numberisRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            speed: PropTypes.number.isRequired,
            playing: PropTypes.bool.isRequired,
        }).isRequired,

        playMusic: PropTypes.func.isRequired,
        addCart: PropTypes.func.isRequired,
        clickFavorite: PropTypes.func.isRequired,
        labels: PropTypes.object.isRequired,
    };

    state = {
        shareVisible: false,
        shareData: { url: "", title: "" },
    };

    handlePlayClick = e => {
        const { audio, playMusic } = this.props;
        const send = {
            ...audio,
            playing: !audio.playing,
        };
        const musicsta = {
            audioid:audio.id,
            style:'play',
        }
        if (!audio.playing) {
            addMusicStatis(musicsta);
        }
        playMusic(send);
    };
    handleCartClick = e => {
        e.preventDefault();
        const { audio, addCart } = this.props;
        const send = {
            ...audio,
        };
        addCart(send);
    };
    handleShareClick(data) {
        const { audioid, title } = data;
        let tit=HtmlUtil.htmlDecode(title)
        this.setState({
            shareVisible: true,
            shareData: {
                url: location.origin + "/music/musicid/" + audioid,
                title:tit,
            },
        });
    }

    handleFavoriteClick = e => {
        const { audio, clickFavorite } = this.props;
        const send = {
            ...audio,
        };
        clickFavorite(send);
    };

    closeShareModel() {
        this.setState({ shareVisible: false });
    }
    showTags(condition, inverted_condition,speed) {
        let show = "";
        for (let key in inverted_condition) {
            let value = (inverted_condition[key] + "").split(",");
            for (let i = 0; i < condition[key].length; i++) {
                const element = condition[key][i];
                for (let k = 0; k < value.length; k++) {
                    const val = value[k];
                    if (element.id == val) {
                        show += element.title + " / ";
                    }
                }
            }
        }
        if(speed){
            for(let i = 0;i<condition.speed.length;i++){
                if(speed == condition.speed[i].id){
                    const element = condition.speed[i].title;
                    show += element+ " / ";
                }
                else{
                    const element ="";
                    show += element; 
                }
            }
        }
        if (!show) {
            return (
                <span>
                    &nbsp;
                    <br /> &nbsp;
                </span>
            );
        }
        return show;
    }
    render() {
        const {
            id,
            audioid,
            thumb,
            composer,
            creatorInfo,
            title,
            inverted_condition,
            duration,
            speed,
            playing,
            like,
            likeUpdate,
            author: { nickname, username },
            minprice,
            api_id,
            author,
        } = this.props.audio;
        const {labels}=this.props
        const playClass = "playBtn " + (playing ? "is-play" : "is-pause");
        const urls="url("+agent.getApiUrl(thumb)+")";
        const nname=!creatorInfo?(composer || nickname || username):creatorInfo.nickname;
        const pre="99";
        const vImg=author.userid == 'IVKPCCUGYVS317TCF32BC7BE385C027F'||author.userid == 'WCIRSZ7MUOMGZ4RUE1A728276B7BD15B'||author.userid == 'XBNTVMZD2KVJ5FRK81027164D4516A4C'?true:false;
        if (!labels) {
            return (
                <Loading/>
            )
        }
        return (
            <div
                className="favItem musicItem c02b478-favorite-table-cell"
                audioid={audioid}
                id={`favorite-table-cell-${id}`}
            >
                <div className="musicItem__jacket" style={{backgroundImage: urls}}>
                </div>
                <Share
                    url={this.state.shareData.url}
                    title={this.state.shareData.title}
                    visible={this.state.shareVisible}
                    onClose={this.closeShareModel.bind(this)}
                />
                {vImg?<div className="v_img_style"> <img className="v_style" src="/assets/img/sub_v.png"/></div>:""}
                <div className="musicItem__txtBox">
                    <div className="musicItem__header">
                        <p className="musicItem__playBtn">
                            <button className={playClass} onClick={this.handlePlayClick.bind(this)}>
                                <svg viewBox="0 0 35 35">
                                    <g className="playIcon">
                                        <use xlinkHref="#playIcon" />
                                    </g>
                                    <g className="pauseIcon">
                                        <use xlinkHref="#pauseIcon" />
                                    </g>
                                </svg>
                            </button>
                        </p>
                        <div className="musicItem__header__inner">
                            <p className="musicItem__title">
                                <Link to={`/music/musicid/${audioid}`}>{HtmlUtil.htmlDecode(title)}</Link>
                                {vImg?<img className="phone_v_style" src="/assets/img/sub_v.png"/>:""}
                            </p>
                            <p className="musicItem__name">by {HtmlUtil.htmlDecode(nname)}</p>
                        </div>
                        <div className="audio_price">
                            ¥{!api_id?minprice:pre}
                        </div>
                    </div>

                    <div className="musicItem__wave">
                        <StaticWave key={`cell-${audioid}`} {...this.props} />
                        <p className="musicItem__wave__time">{showTime(duration)}</p>
                    </div>

                    <div className="musicItem__utility">
                        <p className="musicItem__utility__category">
                            {/* 显示查出来的标签 */}
                            {this.showTags(labels, inverted_condition,speed)}
                        </p>
                        <ul className="musicItem__utility__items">
                            <li className="musicItem__utility__item musicItem__utility__fav">
                                <button
                                    className={`favBtn ` + (like && `is-fav`)}
                                    onClick={this.handleFavoriteClick.bind(this)}
                                    disabled={likeUpdate}
                                >
                                    <svg viewBox="0 0 35 35">
                                        <g className="favIconIn">
                                            <use xlinkHref="#favIconIn" />
                                        </g>
                                        <g className="favIcon">
                                            <use xlinkHref="#favIcon" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="musicItem__utility__item musicItem__utility__share">
                                <a href="javascript:;" onClick={this.handleShareClick.bind(this, { audioid, title })}>
                                    <span>分享</span>
                                </a>
                            </li>
                            <li className="musicItem__utility__item musicItem__utility__cart">
                                <Link to="javascript:void(0)" onClick={this.handleCartClick.bind(this)} />
                            </li>
                            <li className="musicItem__utility__item musicItem__utility__download">
                                <Download audioid={audioid} />
                            </li>
                        </ul>
                    </div>
                </div>
                <p className="musicItem__utility__trashbox u-spHide">
                    <button className="trashbox" disabled={likeUpdate} onClick={this.handleFavoriteClick.bind(this)} />
                </p>
            </div>
        );
    }
}

export default FavoriteTableCell;
