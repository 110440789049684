import { Link } from "react-router-dom";
import React from "react";

class SellerRegisteSuccess extends React.Component {
    render() {
        return (
            <div className="mainContents">
                <div className="mainContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">您的卖家申请正在审核中，请耐心等待!</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="l-formBox">
                            <p className="l-formBox__linkBtn">
                                <Link
                                    to="/seller/profile"
                                    className="m-btn-type0"
                                >
                                    个人中心
                                </Link>
                            </p>
                            <p className="l-formBox__linkBtn">
                                <Link to="/" className="m-btn-type0">
                                    返回首页
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SellerRegisteSuccess;
