import React from "react";
import { connect } from "react-redux";
import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import { Form, Input, Upload, Layout } from "antd";
import PropTypes from "prop-types";
import { PRODUCER_UPDATE_CREATOR, PRODUCER_GET_CREATOR, PRODUCER_CREATE_CREATOR } from "constants/actionTypes";
import agent from "agent";
import { loadCreatorDetail, redirectTo, HtmlUtil } from "dispatch";
import ClassCropperModal from 'components/ClassCropperModal/AvatorUpdate'
import Loading from "components/Loading";
const queryString = require("query-string");
const { Content } = Layout;
export const getBase64 = async file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return await new Promise(resolve => {
        reader.addEventListener("load", () => resolve(reader.result));
    });
};

export const form = [
    {
        type: "avatar",
        label: "酷炫头像",
        name: "avatar",
        dtClassList: ["buypageForm__dt", "u-alsT"],
        fieldClassList: [""],
    },
    {
        type: "input",
        label: "艺名",
        name: "nickname",
        dtClassList: ["buypageForm__dt"],
        placeholder: "请填写创作人艺名",
        fieldClassList: ["u-inputSkin02"],
        rules: [{ required: true, message: "请填写创作人艺名!" }],
    },
    {
        type: "textarea",
        label: "个人介绍",
        row: 4,
        name: "description",
        dtClassList: ["buypageForm__dt", "u-alsT"],
        placeholder: "请填写创作人个人介绍,最多500个字",
        maxlength: "500",
        fieldClassList: ["u-textareaSkin"],
        rules: [{ required: true, message: "请填写创作人个人介绍,最多500个字!" }],
    },
    {
        type: "input",
        label: "作品链接",
        name: "link_a",
        dtClassList: ["buypageForm__dt"],
        placeholder: "请填写创作人作品链接",
        fieldClassList: ["u-inputSkin02"],
        rules: [{ required: true, message: "请输入在其他平台上发布的原创作品链接!" }],
    },
];

class DlWraper extends React.Component {
    static propTypes = {
        dtClassList: PropTypes.array.isRequired,
        label: PropTypes.string.isRequired,
 
        content: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired, PropTypes.array.isRequired]),
    };

    render() {

        return (
            <dl className="buypageForm__dl">
                <dt className={this.props.dtClassList.join(" ")}>{this.props.label}</dt>
                <dd className="buypageForm__dd">{this.props.content}
                </dd>
            </dl>
        );
    }
}

class MakerEdit extends React.Component {
    static propTypes = {
        creator: PropTypes.object,
        location: PropTypes.object.isRequired,
        form: PropTypes.object,
        updateCreator: PropTypes.func,
        createCreator: PropTypes.func,
        getCreators: PropTypes.func,
    };

    state = {
        formdata: {},
        imageUrl: "/assets/img/img_avatar_edit.png",
        title: "编辑创作人",
        type: "edit",
        classModalVisible: false,
        classModalFile: null,
        classResultImgUrl: null,
        uploadModalFile: null,
    };

    componentDidMount() {
        if (location.search === "?type=usercreate") this.setState({ title: "登录创作人", type: "create" });
        else if (location.search === "?type=create") this.setState({ title: "创建创作人", type: "create" });
        else if (!/id=\d+/.test(location.search)) {
            location.href = "/seller/makerlist";
        } else {
            const { location } = this.props;
            const query = queryString.parse(location.search);
            loadCreatorDetail({ ...query });
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (JSON.stringify(nextProps.creator) !== JSON.stringify(this.props.creator)) {
            // const [data] = nextProps.creator;
            let data = {};
            if (nextProps.creator) {
                data = nextProps.creator;
            }

            const { avatar, nickname, description, link_a } = data;

            this.setState({
                formdata: { avatar, description, nickname, link_a },
                imageUrl: agent.getApiUrl(avatar),
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(err => {
            if (err) return;
            if (this.state.type === "edit") {
                this.props.updateCreator(
                    {
                        ...this.state.formdata,
                        creatorId: parseInt(location.search.match(/id=(\d+)/)[1]),
                        avatar: this.state.uploadModalFile ? this.state.uploadModalFile : "",
                    },
                    () => {
                        redirectTo("/seller/makerlist");
                    }
                );
            } else if (this.state.type === "create") {
                this.props.createCreator({ ...this.state.formdata, avatar: this.state.uploadModalFile, }, () => {
                    redirectTo("/seller/makerlist");
                });
            }
        });
    }

    fileOnChange(e, name, value) {
        this.setState(state => {
            return {
                formdata: {
                    ...state.formdata,
                    [name]: value,
                },
            };
        });
    }

    beforeUpload(file, name) {
        getBase64(file).then(imageUrl => {
            this.setState({ imageUrl });
        });
        this.setState(state => {
            return {
                formdata: {
                    ...state.formdata,
                    [name]: file,
                },
            };
        });
        return false;
    }
    handleClassFileChange = e => {
        const file = e.file;
        if (file) {
            // if (file.size <= MAX_FILE_SIZE) {
            this.setState({
                classModalFile: file // 先把上传的文件暂存在state中
            },
            () => {
                this.setState({
                    classModalVisible: true // 然后弹出modal
                })
            }
            )
        }
    }
    handleGetResultImgUrl = key => file => {
        this.setState({
            [key]: file
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const {
            classModalVisible,
            classModalFile,
            //classResultImgUrl,
        } = this.state;
        if (location.search !== "?type=usercreate") {
            if (JSON.stringify(this.state.formdata) == "{}") {
                return (
                    <Loading />
                );
            }
        }
        const dls = form.map((x, i) => {
            const { type, label, name, rules, dtClassList, row = 6, placeholder, fieldClassList ,} = x;
            const fieldClass = fieldClassList.join(" ");
     
            switch (type) {
                case "textarea":
                case "input": {
                    let input = null;
                    if (type === "input")
                        input = (
                            <Input
                                onChange={e => this.fileOnChange.bind(this, e, name)(e.target.value)}
                                placeholder={placeholder}
                                className={fieldClass}
                            />
                        );
                    else
                        input = (
                            <Input.TextArea
                                onChange={e => this.fileOnChange.bind(this, e, name)(e.target.value)}
                                row={row}
                                placeholder={placeholder}
                                className={fieldClass}
                            />
                        );

                    const content = (
                        <Form.Item>
                            {getFieldDecorator(name, {
                                initialValue: HtmlUtil.htmlDecode(this.state.formdata[name] || ""),
                                rules: rules || [],
                            })(input)}
                        </Form.Item>

                    );
                    return <DlWraper key={i} label={label} dtClassList={dtClassList} content={content}/>;
                }
                case "avatar": {
                    const content = (
                        <div className="form__avatarBox" style={{ maxHeight: "160px" }}>
                            <label htmlFor="avatar">
                                <Upload
                                    name={name}
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={file => this.beforeUpload.bind(this, file, name)()}
                                    onChange={this.handleClassFileChange}
                                >
                                    <img src={this.state.imageUrl} alt="" />
                                     
                                </Upload>
                            </label>
                        
                        </div>
                    );
                    return <DlWraper key={i} label={label} dtClassList={dtClassList} content={content} />;
                }
            }
        });

        return (
            <Content id="makeredit">
                <div className="mymusicContents">
                    <div className="mymusicContents__inner">
                        <Header title={"卖家个人中心"} />
                        <SidebarAvatar />
                        <div className="mainBox02">
                            <div className="mymusicBox">
                                <h2 className="mymusicBox__title">{this.state.title}</h2>

                                <div className="mymusicForm">
                                    <Form>
                                        <div className="buypageForm__items">
                                            <div className="buypageForm__item">{dls}</div>

                                        </div>

                                        <ul className="form__submitList">
                                            <li className="form__submitList__item">
                                                <input
                                                    onClick={this.handleSubmit.bind(this)}
                                                    className="u-submitSkin"
                                                    type="submit"
                                                    name="submit"
                                                    value="确认"
                                                />
                                            </li>
                                            <li className="form__submitList__item">
                                                <input
                                                    onClick={e => (location.href = "/seller/makerlist")}
                                                    className="u-submitSkin--gry"
                                                    type="submit"
                                                    value="取消"
                                                />
                                            </li>
                                        </ul>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {classModalVisible && (
                    <ClassCropperModal
                        uploadedImageFile={classModalFile}
                        onClose={() => {
                            this.setState({ classModalVisible: false })
                        }}
                        onSubmit={this.handleGetResultImgUrl('imageUrl')}
                        onShowImg={this.handleGetResultImgUrl('uploadModalFile')}
                        onThumb={this.handleGetResultImgUrl('avatar')}
                    />
                )}
            </Content>
        );
    }
}

export { DlWraper };

export default connect(
    state => ({
        creator: state.creator.creator,
    }),
    dispatch => ({
        updateCreator: (send, callback) =>
            dispatch({
                type: PRODUCER_UPDATE_CREATOR,
                payload: agent.Producer.updateCreator(send),
                send,
                callback,
            }),
        getCreators: send =>
            dispatch({
                type: PRODUCER_GET_CREATOR,
                payload: agent.Producer.getCreators(send),
            }),
        createCreator: (send, callback) =>
            dispatch({
                type: PRODUCER_CREATE_CREATOR,
                payload: agent.Producer.createCreator(send),
                callback,
            }),
    })
)(Form.create({ name: "maker_edit" })(MakerEdit));
