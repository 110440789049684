import { PRODUCER_GET_AUDIO, PRODUCER_GET_AUDIO_DETAIL,MUSIC_PAGE_UNLOADED } from "constants/actionTypes";

const defaultState = {
    all: {
        fetchError: false,
        total: 0,
        list: [],
        pagination: { pageSize: 10, current: 1 },
    },
    unreviewed: {
        fetchError: false,
        total: 0,
        list: [],
        pagination: { pageSize: 10, current: 1 },
    },
    normal: {
        fetchError: false,
        total: 0,
        list: [],
        pagination: { pageSize: 10, current: 1 },
    },
    reject: {
        fetchError: false,
        total: 0,
        list: [],
        pagination: { pageSize: 10, current: 1 },
    },
    detail: {},
};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PRODUCER_GET_AUDIO: {
            const { error, payload, send } = action;
            const { pager, status } = send;
            const { audios, total } = payload;
            return {
                ...state,
                [status]: {
                    fetcnError: !!error,
                    total,
                    list: audios || [],
                    pagination: pager,
                },
            };
        }
        case PRODUCER_GET_AUDIO_DETAIL: {
            const { error, payload } = action;
            return {
                ...state,
                detail: error ? {} : payload.audio,
            };
        }
        case MUSIC_PAGE_UNLOADED: {
            return {
                ...defaultState
            };
        }
        default:
            return state;
    }
};
