import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const mapStateToProps = state => ({ ...state.fixPrice });

const mapDispatchToProps = dispatch => ({});

class FixPrice extends React.Component {
    static propTypes = {
        // loading: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
       
    };

    render() {
        return (
            <div className="mypageContents aboutContents becomeContents">
                {/* <Loading loading={loading} /> */}
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">成为卖家</h1>
                    </header>
                    <div className="becomeBox">
                        <div className="becomeBox__inner">
                            <div className="becomeBox__pointList">
                                <ul className="becomeBox__pointList__items">
                                    <li className="becomeBox__pointList__item"><span>交易记录</span></li>
                                    <li className="becomeBox__pointList__item"><span>版权收益</span></li>
                                    <li className="becomeBox__pointList__item"><span>灵活提现</span></li>
                                    <li className="becomeBox__pointList__item"><span>定制合作</span></li>
                                </ul>
                            </div>
                            <div className="becomeApplyBox">
                                <p className="becomeBox__txt">将您的音乐上传至MUSINESS，享受平台聚合服务，成功将作品多次变现。</p>
                                <p className="becomeBox__btn"><Link className="m-btn-type0" to={`/sellerregister`}>继续申请</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FixPrice);
