import agent from "agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Layout, Button, Form, Input,Radio} from "antd";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import {
    ORDER_ONE,
    ORDER_LIST,
    ORDER_ONE_PAGE_UNLOAD,
    ORDER_CREATE_PURCHASE,
} from "constants/actionTypes";
import { loadCart,redirectTo ,HtmlUtil} from "dispatch";
const { Content } = Layout;
const { TextArea } = Input;
const jquery = require("jquery");

const mapStateToProps = state => ({ 
    ...state.orderOne ,
    ...state.user,
    userLogin: state.app.userLogin
});

const mapDispatchToProps = dispatch => ({
    loadPage: orderid => {
        if (orderid) {
            return dispatch({
                type: ORDER_ONE,
                orderid,
                payload: agent.Order.one(orderid),
            });
        }
        // list
        return dispatch({
            type: ORDER_LIST,
            orderid: "",
            payload: agent.Order.list(),
        });
    },
    unLoadOrder: () => {
        return dispatch({ type: ORDER_ONE_PAGE_UNLOAD });
    },
    createPurchase: (orderid, parmas) => {
        return dispatch({
            type: ORDER_CREATE_PURCHASE,
            orderid,
            payload: agent.Purchase.create(orderid, parmas),
        });
    },
});

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            //支付方式 1支付宝,2银行卡转账
            value: 1,
            remittance:0,
        };
    }
    static propTypes = {
        orderone: PropTypes.string.isRequired,
        orderoneData: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        loadPage: PropTypes.func.isRequired,
        unLoadOrder: PropTypes.func.isRequired,
        createPurchase: PropTypes.func.isRequired,
        form: PropTypes.object,
        errors: PropTypes.array,
        payUrl: PropTypes.string,
        remittance: PropTypes.string,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
    };
    state = {
        orderone: "--",
    };
    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        loadCart();
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const param = nextProps.match.params.orderone;
        if (prevState.orderone != param) {
            //  send loading
            nextProps.loadPage(param);
        }
        return { orderone: param };
    }

    componentWillUnmount() {
        
        this.props.unLoadOrder();
    }
    componentDidUpdate(prevProps) {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
        //支付宝付款
        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }
        //银行卡转账
        if(this.props.remittance){
            if (this.state.remittance==1) {
                return;
            }
            this.setState({
                remittance:1,
            })
            jquery("body").css("overflow","hidden");
            jquery("body").css("height","100%");
        }
    }
    onSubmit(parmas) {
        const { createPurchase, orderoneData } = this.props;
        if (!orderoneData || !orderoneData.orderid) {
            return;
        }
        //添加订单信息
        createPurchase(orderoneData.orderid, parmas);
    }

    //点击支付
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                //校验电话号码
                if (!this.checkPersonal(values.phoneNumber)) {
                    return false;
                }
                //提交数据
                this.onSubmit({
                    area: values.area,
                    description: values.description,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    payType: this.state.value.toString(),
                    username: values.username,
                });
            }
        });
    };
    checkPersonal(phoneNumber) {
        if (
            !/^\d+(\.\d{1,2})?$/.test(phoneNumber) ||
            phoneNumber.length != 11
        ) {
            this.props.form.setFields({
                phoneNumber: {
                    value: phoneNumber,
                    errors: [new Error("请输入正确的手机号！")],
                },
            });
            return false;
        }
        return true;
    }
    checkPhone = e => {
        const phone=e.target.value;
        this.props.form.validateFields((err, values) => {
            if (!/^\d+(\.\d{1,2})?$/.test(phone) || phone.length != 11) {
                this.props.form.setFields({
                    phoneNumber: {
                        value: phone,
                        errors: [new Error("请输入正确的手机号！")],
                    },
                });
            }
        })
    }
    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    handleClose(){
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        this.setState({
            remittance:0,
        })
        redirectTo("/user/purchase?isHistory=1");
    }
    showFormItemServerError() {
        const errors = this.props.errors;
        if (errors) {
            Object.keys(errors).map(key => {
                let message = errors[key];
                switch (message.param) {
                    case "phoneNumber":
                        this.props.form.setFields({
                            phoneNumber: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                }
            });
        }
    }
   

    renderFrom = () => {
        const { getFieldDecorator } = this.props.form;
        const { currentUser} = this.props;
        const show = this.state.show;
        const isRequired = !show;
        const name=!currentUser.company?HtmlUtil.htmlDecode(currentUser.nickname):HtmlUtil.htmlDecode(currentUser.company_name);
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom:"15px",
            marginLeft:"40px",
        };
        return (
            <Form onSubmit={this.handleSubmit}>
                <div className="buypageForm__items my-items">
                    <div className="order_title_1">
                        <p className="order_title_inner">
                            <span className="font_title_1" >授权项目信息:</span>
                            <span className="font_title_2">(&nbsp;提示:以下内容是授权证书的关键信息,一旦提交不支持修改,请务必准确填写&nbsp;)</span>
                           
                        </p>  
                    </div>
                    <div className="buypageForm__item">
                        <dl className="buypageForm__dl margin_bottom_36">
                            <dt className="buypageForm__dt add_star">
                                <label htmlFor="">授权项目:</label>
                            </dt>
                            <dd className="buypageForm__dd">
                                <Form.Item>
                                    {getFieldDecorator("area", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入授权项目名称！",
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="text"
                                            className="u-inputSkin02 border"
                                            placeholder="请输入授权项目名称"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                       
                        <dl className="buypageForm__dl">
                            <dt className="buypageForm__dt" >
                                <label style={{marginLeft:"11px"}} htmlFor="">项目描述:</label>
                            </dt>
                            <dd className="buypageForm__dd line_height_13">
                                <Form.Item>
                                    {getFieldDecorator("description", {
                                        rules: [{required: false,}],
                                    })(
                                        <TextArea
                                            style={{resize:"none"}}
                                            type="text"
                                            className="u-inputSkin02 border"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                    </div>
                    <div className="buypageForm__item">
                        <dl className="buypageForm__dl">
                            <dt className="buypageForm__dt add_star">
                                <label htmlFor="">被授权者:</label>
                            </dt>
                            <dd className="buypageForm__dd">
                                <Form.Item>
                                    {getFieldDecorator("username", {
                                        initialValue: name,
                                        rules: [
                                            {
                                                required: isRequired,
                                                message:
                                                    "请输入被授权者!",
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="text"
                                            className="u-inputSkin02 border"
                                            placeholder="最终使用音乐的个人姓名/公司名"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                            <dt className="buypageForm__dt add_star margin_top_18" >
                                <label htmlFor="">联系电话:</label>
                            </dt>
                            <dd className="buypageForm__dd_phone margin_top_18">
                                <Form.Item>
                                    {getFieldDecorator(
                                        "phoneNumber",{
                                            initialValue: HtmlUtil.htmlDecode(currentUser.phone),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入电话!",
                                                },
                                            ],
                                        }
                                    )(
                                        <Input
                                            type="number"
                                            className="u-inputSkin02 border"
                                            placeholder="请输入电话"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                            <dt className="buypageForm__dt margin_left_30 margin_top_18 add_star">
                                <label htmlFor="">联系邮箱:</label>
                            </dt>
                            <dd className="buypageForm__dd_email margin_top_18">
                                <Form.Item>
                                    {getFieldDecorator("email", {
                                        initialValue: HtmlUtil.htmlDecode(currentUser.email),
                                        rules: [ { required: true, message: "请输入邮箱!",},
                                        ],
                                    })(
                                        <Input
                                            type="email"
                                            className="u-inputSkin02 border"
                                            placeholder="请输入邮箱"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                    </div>
                    <div className="order_title_1 margin_top" style={{height:"auto"}}>
                        <p className="order_title_inner">
                            <span className="font_title_1" >支付方式:</span>
                        </p>  
                    </div>
                    <div className="buypageForm__item">
                        <dl className="buypageForm__dl margin_bottom_36 pay_style">
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio style={radioStyle} value={1}>
                                    支付宝
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    银行卡转账
                                </Radio>
                            </Radio.Group>
                        </dl>
                        <div className="notice_pay_red"> * 银行卡转账支持人民币和台币。价格均为人民币，如您选择台币支付，默认汇率为4.5。</div>
                    </div>
                </div>

                <div className="buypageForm__submit">
                    <Form.Item>
                        <Button
                            className="u-submitSkin"
                            type="primary"
                            onClick={this.handleSubmit.bind(this)}
                            block
                        >
                            支付
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        );
    };
    render() {
        const { loading, currentUser, orderoneData } = this.props;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        if (!orderoneData) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        const isShow = this.state.remittance==1 ? "dialog-bg" : "hidden";
        return (
            <div className="mypageContents" id="order_one">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">结账</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="cashiers" style={{paddingBottom: "1%",backgroundColor: "#fff",paddingTop:"4.6875%"}}>
                            <div className="cashiers__inner">
                                <p>
                                    <span style={{ fontFamily: "'Arial Negreta', 'Arial'",fontWeight: "400",color: "#666666"}}>选择音乐<span className="order_left_and_right">＞</span></span>
                                    <span style={{ fontFamily: "'Arial Negreta', 'Arial'",fontWeight: "700",color: "#339E9D"}}>填写信息</span>
                                    <span style={{ fontFamily: "'Arial Negreta', 'Arial'",fontWeight: "400",color: "#666666"}}><span className="order_left_and_right">＞</span>支付授权<span className="order_left_and_right">＞</span>下载音乐</span>
                                </p>
                            </div>
                        </div>

                        <div className="buypageForm" style={{paddingTop: "3%",backgroundColor:"#fff"}}>
                            <div className="buypageForm__inner">
                                {this.renderFrom()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isShow} style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container scroll-y modalBox__inner">
                        <div className=" booker-ti"><span>订单已提交！</span></div>
                        <div className=" book-code"><span style={{color:"red"}}>订单号：</span>{orderoneData.orderid}</div>
                        <div className=" book-de">您可自行选择币种汇款至对应账户，成功汇款后，请将<span>订单号</span>与<span>汇款账号后五位</span>发送至<a style={{fontWeight:"600"}}>sales@musiness.vip</a>； 或 至<a style={{fontWeight:"600"}}>个人中心—音乐购买记录—未付款</a>页面填写相关信息。</div>
                        <div className=" book-de">在您提交汇款信息后，MUSINESS将在24小时之内进行审核，审核通过后，您可在<a style={{fontWeight:"600"}}>个人中心—音乐购买记录—已付款</a>页面下载<span>无水印歌曲</span>及<span>授权证书</span>。</div>
                        <div>
                            <div className=" table-ti"><strong>收款账号信息如下：</strong></div>
                            <div>
                                <div className=" table-name">
                                    <div className=" wid20">币种</div>
                                    <div className=" wid20">金额</div>
                                    <div className=" wid60">银行卡信息</div>
                                </div>
                                <div className=" table-de">
                                    <ul>
                                        <li>
                                            <div className=" wid20 line-78">人民币</div>
                                            <div className=" wid20 color-green line-78">{orderoneData.price/100}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>上海小仙虎文化传媒有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>中国工商银行股份有限公司上海市上外支行</div>
                                                <div className=" table-all"><span>账号：</span>1001274409300013770</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" wid20 line-78">台币</div>
                                            <div className=" wid20 color-green line-78">{orderoneData.price/100*4.5}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>啟動音樂文創有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>上海商業儲蓄銀行（世貿分行）</div>
                                                <div className=" table-all"><span>账号：</span>43102000032270</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div><a onClick={this.handleClose.bind(this)} className="cancle">已阅读，点击关闭</a></div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(Order);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
