import React from "react";
//import PropTypes from "prop-types";
//import data from "components/Common/about_us_text";

export default class AboutusPage extends React.Component {
    render() {
        return (
            <div className="mypageContents aboutContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">卖家协议</h1>
                    </header>
                    <div className="aboutBox">
                        <div className="aboutBox__inner">
                            <p>&nbsp;</p>
                            <p>正式成为上海小仙虎文化传媒有限公司（下称&ldquo;MUSINESS&rdquo;）卖家用户前，请您仔细阅读（未成年人应在其法定监护人的陪同下阅读）本《卖家协议》（下称&ldquo;本协议&rdquo;）中的各个条款，点击已阅读并同意《卖家协议》按钮后，视为您已经仔细阅读并理解本协议中各个条款，同意本协议条款各项内容，您同意本协议将适用于您使用本网站及借助本网站实施的任何活动。</p>
                            <p>本协议的发布时间为2019年2月18日星期一。</p>
                            <p>名词解释</p>
                            <p>&ldquo;账号&rdquo;指的是您在本网站开设并使用的账号。</p>
                            <p>&ldquo;用户&rdquo;&ldquo;客户&rdquo;&ldquo;会员&rdquo;指的是访问或使用本网站的自然人、法人或其他组织。</p>
                            <p>&ldquo;您&rdquo; &ldquo;卖方&rdquo;&ldquo;卖家&rdquo;指的是通过本网站提供服务或作品的用户。</p>
                            <p>&ldquo;买方&rdquo;&ldquo;买家&rdquo;指的是希望或已经通过本网站从卖方手中购买卖方服务或作品的用户。</p>
                            <p>&ldquo;正版商业授权&rdquo;指的是用户将其享有完整版权的作品上传至MUSINESS平台并授权MUSINESS平台其他用户购买并下载该版权作品，授权买家用于且仅能用于一定范围的商业用途。上传或下载版权作品，均须遵循MUSINESS平台的规定，MUSINESS不对版权作品权属负任何法律责任。</p>
                            <p>&ldquo;分销&rdquo;&ldquo;销售&rdquo;指用户通过MUSINESS平台上传音乐作品进行授权和售卖活动。</p>
                            <p>&ldquo;非独家销售&rdquo; 是指用户将其享受完整版权的作品，既可以在MUSINESS平台www.musiness.vip及MUSINESS指定的合作网站进行销售，同时可再发布于其它网站进行有偿网络销售。卖方可以通过与MUSINESS签订《卖家协议》，约定权利义务。</p>
                            <p>&ldquo;MUSINESS&rdquo;&ldquo;我们&rdquo;指的是上海小仙虎文化传媒有限公司。</p>
                            <p>&ldquo;本网站&rdquo;&ldquo;MUSINESS平台&rdquo;指的是由上海小仙虎文化传媒有限公司运作的网站，访问网址为：www.musiness.vip，以及相关的其他MUSINESS服务、工具或应用。</p>
                            <p>&ldquo;案&rdquo;&ldquo;案子&rdquo;&ldquo;项目&rdquo;指的是由买方通过MUSINESS平台提供给卖方的工作项目或任务。</p>
                            <p>&ldquo;平台服务费&rdquo;指的是买方通过本网站提交项目需求且在需求交易成功后须向MUSINESS支付的费用。</p>
                            <p>&ldquo;项目分成费&rdquo;指的是卖方通过本网站获得项目时或者作品授权交易成功后需向MUSINESS支付的一定比例的收益分成。</p>
                            <p>&ldquo;托管支付&rdquo;指的是买方将项目所涉款项打入MUSINESS指定账户后，MUSINESS依据双方的指示将相关款项支付予卖方的行为。</p>
                            <p>&ldquo;知识产权&rdquo;指的是在世界范围内存在的部分或所有知识产权以及上述权利指涉的客体，包括但不限于：（1）专利、著作权、商标以及其他对具有商业价值的信息进行保密的权利；以及（2）就（1）项下内容进行申请登记的权利，不论上述内容是否已发表、已经登记或能够获得登记或者已在任何法律体系下实际存在。</p>
                            <p>&ldquo;完整版权&rdquo;指卖方拥有售卖作品的所有权利，并可对作品进行合法授权。</p>
                            <p>&ldquo;一次性授权&rdquo;&ldquo;随片永久&rdquo;指的是用户上架到MUSINESS平台的作品采用&ldquo;单次购买&rdquo;中的同一个项目一次性的永久授权。MUSINESS约定以项目为单位对音乐进行授权，即同一首音乐在同一项目中视为一次授权，同一首音乐在不同项目中视为多次授权。&nbsp;其中，仅以下情况可视为同一个项目：&nbsp;同一部影视剧、综艺、动画中的不同集数；同一系列纪录片、短视频、有声书、课程；某品牌同一次运营活动；作为同一个平台的UGC素材。</p>
                            <p>&ldquo;定价规则&rdquo;是指MUSINESS网站中对于出售作品的价格规定，包括但不限于通过各种渠道、授权方式、范围、类别出售作品的所有价格，以及参与活动、促销或在合集中销售的价格规定。</p>
                            <p>一、交易流程</p>
                            <ol>
                                <li>1、 您须如实填写详细个人资料，并完成MUSINESS要求的身份验证程序，即实名认证。</li>
                                <li>2、 您须在遵守本协议时，必须同时遵守《MUSINESS平台用户协议》及其他与MUSINESS、买家达成的协议。</li>
                                <li>3、 您可以通过以下两种方式为MUSINESS买家提供作品或服务：</li>
                            </ol>
                            <p>（1）您自行上传作品到MUSINESS平台，完成作品相关信息（如作品名字、创作者名字、作品简介、关键词、定价等）的编辑工作。</p>
                            <p>若MUSINESS发现您上传的作品违反MUSINESS的规定或相关法律、法规，MUSINESS有权不经告知立即将用户的作品从MUSINESS平台上删除，并有权停止对用户提供服务。</p>
                            <p>（2）当买方在MUSINESS平台发布案子，您可以通过约稿、投标形式与买方进行交易：根据买方发布在MUSINESS平台上的案子要求创作作品后，通过网络传输的方式交稿，进行原创作品授权交易。该原创作品版权归属与授权方式由买卖双方协商确认。在该种方式下，您有权将原创作品的demo/小样在MUSINESS或MUSINESS以外的其他网站进行展示，但不得提供任何形式的源文件下载。</p>
                            <ol>
                                <li>4、 买家在MUSINESS平台上购买后，可下载完整的版权作品。如果买家在购买作品24小时内没有向MUSINESS提出投诉，即完成交易。如果买家在购买作品24小时内发现作品的格式、大小或其他信息与您在平台发布作品的信息描述有严重出入并且不能正常使用时，有权向MUSINESS投诉，MUSINESS在接到买家投诉并确认事实成立后，有权要求并督促您进行更正或退款处理。</li>
                                <li>5、 您同意授权MUSINESS向买方分销您的作品，并按照买方购买的授权方式，授权买方在其所拥有、制作、创作、剪辑或以其他方式使用的作品中（包括但不限于广告、短视频、影视剧、计算机软件等），以商业目的或非商业目的复制、剪辑、重制、公开播放、通过信息网络传播、发表、表演、汇编或以其他方式使用您的作品。MUSINESS有权代表您向买家出具作品授权书。</li>
                                <li>6、 若您的作品被认定为侵权等情形，买家要求退款的，可以以书面形式向您提出，也可以通过本平台向您提出。若通过本平台提出的，并提供证据确实存在侵权情形的，您应当在被要求退款之日起5个工作日内作答复，若您在收到退款请求后5个工作日内未作任何答复，则视为您默认同意退款。您同意MUSINESS介入后对相关证据进行保全，并有权冻结所涉交易款项。</li>
                            </ol>
                            <p>二、完整版权保证</p>
                            <ol>
                                <li>1、 您保证并确认，您拥有所上传的作品的完整版权，并有权在不侵犯任何人合法权利的情况下授权该作品。</li>
                                <li>2、 您保证并确认，在签订本协议时未授权中国音乐著作权协会、中国音像著作权集体管理协会或其他类似著作权集体管理组织（&ldquo;著作权集体管理组织&rdquo;）处分您的作品的任何财产权利。如果您在授权MUSINESS分销您的作品的期限内加入任何著作权集体管理组织，您应当向该组织声明对MUSINESS的授权情况。</li>
                                <li>3、 若您为原创作者，您需要保证，在制作音乐作品的过程中所用到的软件和音频信息，包括但不限于宿主软件、采样、录音、音源、插件及音色库，均为经合法授权的正版软件，且在授权范围内使用，不存在侵犯任何第三方权利的情况。</li>
                                <li>4、 您需要保证，您的作品在上传到MUSINESS平台后，不再将该作品另行以专有许可、排他性许可等方式向第三方授权，或以权利信托等方式排除，不得给买家合法使用该作品造成任何不利影响。</li>
                            </ol>
                            <p>三、授权期限与授权地域</p>
                            <ol>
                                <li>1、 如果您非因版权问题或遭到投诉，而对作品进行下架或删除操作（&ldquo;下架作品&rdquo;），您同意MUSINESS平台在30天内可以继续售卖下架作品（&ldquo;缓冲期&rdquo;）。若您在缓冲期内转让该作品版权的，应及时通知MUSINESS平台，否则MUSINESS仍将您视为作品的权利人，在此期间下架作品的收益仍按本协议中约定的方式进行结算。</li>
                                <li>2、 在您上传作品的过程中，您可以选择您作品的授权地域，若没有选择授权区域，则默认MUSINESS可将您的作品在全球范围内授权。</li>
                                <li>3、 您同意您上传的作品一旦交易成功，该作品将不可撤销的以随片永久的方式，在授权范围内供买方使用。</li>
                            </ol>
                            <p>四、上传的作品</p>
                            <ol>
                                <li>1、 卖家上传的作品以非独家销售的方式在MUSINESS平台上销售，您可以将该作品授权给任何第三方使用，但授权给第三方使用时不得以免费或明显低于市场的价格，也不得损害或影响MUSINESS平台销售该作品。</li>
                                <li>2、 卖家上传至MUSINESS平台的作品应达到基本的适合销售的质量以满足MUSINESS的质量要求，MUSINESS有权审核并决定是否将该作品上架。</li>
                                <li>3、 出于宣传推广的目的，卖家授权MUSINESS将作品用于MUSINESS或MUSINESS有关的业务活动中，MUSINESS不再另行向卖家支付使用费。</li>
                                <li>4、 您在上传作品时应当告知MUSINESS是否同意MUSINESS对该作品以打折、促销、参与活动等方式进行销售（&ldquo;非正常销售&rdquo;）。如果您未做出不同意的意思表示，MUSINESS可以在不通知您的情况下对该作品进行非正常销售，所得价格按本协议之分配方式分配。</li>
                            </ol>
                            <p>五、作品的下架或删除</p>
                            <p>1、若您因违规或违约而被锁定账号，您须返还所得，并支付完赔偿款或违约金后，方可下架或删除作品。</p>
                            <p>2、当第三方向MUSINESS提出权利通知，向MUSINESS出示权属证明，主张MUSINESS卖家上传的作品侵犯其合法权益的，MUSINESS可以自行判断并删除涉嫌侵权的作品。如第三方提出披露上传会员相关信息要求的，MUSINESS有权在形式审查第三方身份后将上传用户信息披露给第三方。</p>
                            <p>3、MUSINESS有权不展示MUSINESS认为不适合出售、涉嫌侵权、违反法律法规以及国家政策等作品。</p>
                            <p>六、分销方式与定价方式</p>
                            <p>1、您同意给予MUSINESS分销您的作品所必须的知识产权授权，包括但不限于关于授权作品现行著作权法规定的所有权利种类（复制权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、出版权、发表权、汇编权、邻接权等）及为推广宣传您的作品而必要使用您的姓名（含别名、艺名、企业字号、简称、商标等）、肖像等。该等授权不需您另行出具授权委托书。</p>
                            <p>2、您须遵守MUSINESS的定价规则。MUSINESS有权根据市场情况和自身发展原因，自行对定价做出调整（&ldquo;价格调整&rdquo;），且定价规则的变更自MUSINESS平台以公示方式展示相关信息时立即生效。如您不同意MUSINESS经价格调整后的定价规则，请您及时将作品下架，否则，将认为您同意MUSINESS的定价规则。</p>
                            <p>3、您须同意MUSINESS在不同分销渠道展示您的作品，并同意MUSINESS针对不同渠道对您的作品设置不同的定价规则。</p>
                            <p>七、分成方式</p>
                            <p>1、您每销售一次授权，MUSINESS将从您的收益中保留一部分费用作为服务费。</p>
                            <p>2、MUSINESS将收取您上传至MUSINESS平台非独家销售作品收益中的40%作为平台服务费，您需要自行承担并缴纳剩余收益所产生的一切税费，或由MUSINESS公司代扣代缴税费。代扣代缴是MUSINESS公司之权利而不是MUSINESS公司之义务。</p>
                            <p>3、通过渠道售卖的作品会产生一部分的渠道费用，这部分费用将由您和MUSINESS按照分成比例（您承担60%，MUSINESS承担40%）共同承担。</p>
                            <p>八、提现</p>
                            <p>1、您的最终收益将在您的个人账户中显示。</p>
                            <p>2、您需要完成MUSINESS平台实名认证后才可提现，若没有完成实名认证，则不能进行提现操作。</p>
                            <p>3、提现的金额需大于100元，小于5万元。</p>
                            <p>4、在您递交提现申请后，MUSINESS将对您的提现操作进行审核，审核通过后，MUSINESS将于次月15日前向您的实名认证账户支付相应的提现费用。</p>
                            <p>九、版权</p>
                            <p>1、您的上传的作品必须在下架30天后才能进行版权转让的操作。</p>
                            <p>2、在您作品的转让协议中，必须标明您在MUSINESS平台的售卖行为，且需要在转让协议中说明在MUSINESS平台的售卖情况，并确保在MUSINESS平台购买音乐的客户可以正常使用音乐。</p>
                            <p>3、若因您的转让行为造成MUSINESS平台或MUSINESS客户与作品版权方的版权纠纷，MUSINESS平台将追究您的法律责任，并要求您赔偿版权纠纷给MUSINESS或MUSINESS客户带来的经济损失。</p>
                            <p>十、免责声明</p>
                            <p>1、MUSINESS对您的作品的审核并不能保证您作品的原创性或者版权合法性，若您上传的作品侵犯了他人的合法权益，MUSINESS将协助原版权所有者追究您的法律责任，并要求您赔偿由于版权纠纷对MUSINESS及其客户带来的经济损失。</p>
                            <p>2、MUSINESS对客户购买音乐使用情况的检测并不保证客户按照授权使用作品，客户对音乐作品的使用情况与MUSINESS无关。</p>
                            <p>3、若因客户违规使用您的作品产生纠纷，由双方自行解决，MUSINESS不负任何因此造成的法律责任和损失。MUSINESS可协助您提供售卖信息、订单记录等信息，但您的追责行为与MUSINESS无关，MUSINESS不保证您可以获得您的维权诉求。</p>
                            <p>4、买卖作品交易双方中出现任何一方违反法律或相关协议的情形，由双方自行解决，MUSINESS不负任何因此造成的法律责任和损失。若致使MUSINESS遭受经济损失，违约方应全额承担此损失。但MUSINESS有义务秉着客观公正原则提供双方交易中产生的各种证据，以保障交易双方权利不受侵害。</p>
                            <p>十一、违规违法行为及处理</p>
                            <p>1、违规违法行为，是指违反法律、法规及相关规章制度规范性文件、《MUSINESS平台用户协议》、《卖家协议》等MUSINESS规定，损害MUSINESS用户、MUSINESS或第三方合法权益的行为。</p>
                            <p>2、您有违规行为的，MUSINESS将有权根据违规情况及损害后果对您处以冻结提现、扣除销售款、违约金、严重警告、取消出售资格、封存账户、禁言、扣除积分、删除作品、删除账户等处罚。MUSINESS用户有违法行为的，MUSINESS有权向国家有关部门举报并追究其法律责任。</p>
                            <p>3、违规违法的执行</p>
                            <p>（1）用户的违规违法行为，通过MUSINESS会员、权利人的投诉或MUSINESS排查发现。</p>
                            <p>（2）您同意授权MUSINESS有权对用户间的争议，以非专业者的知识水平标准对相关争议事项进行调处。</p>
                            <p>（3）MUSINESS用户对于违规违法行为作出的承诺或说明与本规则相悖的，MUSINESS不予采信。除证据有误或判断错误的情形外，对违规违法行为的处罚不中止、不撤销。</p>
                            <p>十二、其他规定</p>
                            <p>1、MUSINESS有权随时修改本协议条款，并只需公示于MUSINESS平台，修改后的条款应于公示即时生效。如果不同意MUSINESS对本协议相关条款所做的修改，您有权停止使用MUSINESS提供的网络服务。如果您继续使用网络服务，则视为您接受MUSINESS对本协议相关条款所做的修改。</p>
                            <p>2、若本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
