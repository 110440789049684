// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { APP_LOAD, LOGOUT, CURRENT_USER } from "../constants/actionTypes";
import { Route, Switch } from "react-router-dom";

import Header from "components/Header";
import Footer from "components/Footer";
import Music from "components/Music";
import Cart from "components/Cart";
import Order from "components/Order";
import Purchase from "components/Purchase";
import User from "components/User";
import Player from "components/Player";

import Home from "components/Home";
import Login from "components/Login";
import Register from "components/Register";
import SellerRegister from "components/SellerRegister";
import Seller from "components/Seller";
import Favorite from "components/Favorite";
import visionChina from 'components/visionChina';
import TopicMusic from "components/TopicMusic";
import Partner from "components/Partner";
import HelpCenter from "components/HelpCenter";
import Contact from "components/Contact";
import Maker from "components/Maker";
import MakerDetail from "components/Maker/detail";
import FixPrice from "components/FixPrice";
import Composition from "components/Composition";
import Become from "components/FixPrice/become";
import Loading from "components/Loading";
import AppSvg from "components/AppSvg";
import UpdatePassword from "./User/UpdatePassword";
import ForgetPassword from "components/ForgetPassword";
import Media from "components/Media";
import TeamSell from "components/StaticPages/TeamSell";
import TeamUse from "components/StaticPages/TeamUse";
import TeamQuan from "components/StaticPages/TeamQuan";
import InfoImages from "components/Common/InfoImages";
import InfoIcp from "components/Common/InfoIcp";
import agent from "agent";
import { redirectTo, loadLabel,loadLabels } from "dispatch";
import CallBackTort from "components/Music/CallBackTort";
import AddShopCar from "components/Music/AddShopCar";
import AddOn from "components/AddOn";
import WxCode from "components/Common/WxCode";
import {
    TermsOfSalePage,
    TermsOfUsePage,
    TermsOfViolationrulesPage,
    TermsOfLogupPage,
    MediaContantPage,
} from "components/StaticPages/index";
import SubscriberMembers from "components/SubscriberMembers";
import SubscribePay from "components/SubscriberMembers/SubscribePay";
import AboutusPage from "components/StaticPages/Aboutus";
import "./App.less";
import { loadCart } from "dispatch";

// require plugins
const jquery = require("jquery");
require("jquery.easing");
require("picturefill");
require("picturefill/dist/plugins/mutation/pf.mutation"); // not sure if this does anything
const gnav = require("utils/loader").default(jquery);

const mapStateToProps = state => ({ ...state.app, playAudio: state.player });

const mapDispatchToProps = dispatch => ({
    loadCurrentUser: done =>
        dispatch({
            type: CURRENT_USER,
            payload: agent.Auth.current(),
            done,
        }),

    onLoad: () => dispatch({ type: APP_LOAD }),
    logout: () => dispatch({ type: LOGOUT }),
});

class App extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,

        logout: PropTypes.func.isRequired,
        playAudio: PropTypes.object.isRequired,
    };

    componentDidMount() {
        // ready funcs
        //记录用户来源 
        var tmpstr=document.location.toString(); 
        
        tmpstr=tmpstr.split("?");
        var source={source:0}; 
        
        if(tmpstr.length>1)
        {
            var param=tmpstr[1];  
            source.source=param.split("=")[1];
        }
        jquery.ajax({
            url:agent.getApiUrl()+'/userdata/usersource',
            data:source,
            success:function(data){
                console.log(JSON.stringify(data)); 
            },
            error:function(err){
                console.log("错误 "+err);
            }
        });
        // ready funcs
        gnav.ready();
        const { loadCurrentUser, onLoad } = this.props;
        if (agent.getToken()) {
            loadCurrentUser(onLoad);
            // update cart
            loadCart();
        } else {
            onLoad();
        }
        loadLabel();
        loadLabels();
        var _hmt = _hmt || [];
        (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?250e699bc551fd7fe289f5e2d1245f09";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }
    linkTo = e => {
        // all links are here
        e.preventDefault();
        e.stopPropagation();

        const key = (e => {
            if (e.key) {
                return e.key;
            }
            const href = e.currentTarget.attributes["href"];
            if (!href) {
                return "";
            }
            return href.value;
        })(e);
        // close menu
        jquery(".js-navClose").click();
        if (key && key === "/logout") {
            this.props.logout();
            return;
        }
        if (key && key.match(/^\//)) {
            redirectTo(key);
            return;
        }
        console.log("linkTo():", key);
        return false;
    };

    render() {
        const props = {
            linkTo: this.linkTo.bind(this),
            location: this.props.location,
        };
        const { playAudio, loading } = this.props;
        const classNmae =
            "l-wrapper" + (playAudio.audio ? " is-musicBar-on" : "");
        return (
            <div className={classNmae}>
                <Header {...props} />
                <AppSvg />
                <main role="main" className="main">
                    {loading ? (
                        <Loading loading={loading} />
                    ) : (
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route path="/music" component={Music} />
                            <Route path="/cart" component={Cart} />
                            <Route path="/order" component={Order} />
                            <Route path="/purchase" component={Purchase} />
                            <Route path="/user" component={User} />
                            <Route
                                path="/sellerregister"
                                component={SellerRegister}
                            />
                            <Route path="/seller" component={Seller} />
                            <Route path="/favorite" component={Favorite} />
                            <Route path="/visionChina" component={visionChina} />
                            {/* static pages */}
                            <Route
                                path="/about/:about"
                                component={MediaContantPage}
                            />
                            <Route
                                path="/partner/:partner"
                                component={MediaContantPage}
                            />
                            <Route
                                path="/page/:page"
                                component={MediaContantPage}
                            />
                            <Route
                                path="/terms/sale"
                                component={TermsOfSalePage}
                            />
                            <Route
                                path="/terms/use"
                                component={TermsOfUsePage}
                            />
                            <Route
                                path="/terms/violationrules"
                                component={TermsOfViolationrulesPage}
                            />
                            <Route
                                path="/terms/logup"
                                component={TermsOfLogupPage}
                            />
                            <Route path="/topicmusic" component={TopicMusic} />
                            <Route path="/partner" component={Partner} />
                            <Route path="/aboutus" component={AboutusPage} />
                            <Route path="/helpcenter" component={HelpCenter} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/maker" component={Maker} />
                            <Route path="/media" component={Media} />
                            <Route
                                path="/makerdetail"
                                component={MakerDetail}
                            />
                            <Route
                                path="/free"
                                component={MakerDetail}
                            />
                            <Route path="/fixprice" component={FixPrice} />
                            <Route path="/teamSell" component={TeamSell} />
                            <Route path="/teamUse" component={TeamUse} />
                            <Route path="/teamQuan" component={TeamQuan} />
                            <Route path="/become" component={Become} />
                            <Route path="/culture" component={InfoImages} />
                            <Route path="/icp" component={InfoIcp} />
                            <Route path="/addon/:addon" component={AddOn} />
                            <Route path="/wxcode" component={WxCode} />
                            <Route path="/subscribermembers" component={SubscriberMembers} />
                            <Route path="/subscribepay" component={SubscribePay} />
                            
                            <Route
                                path="/composition"
                                component={Composition}
                            />
                            {/* /static pages */}
                            <Route path="/" component={Home} />
                        </Switch>
                    )}
                </main>
                <Footer {...props} />
                <Player />
                <UpdatePassword />
                <ForgetPassword />
                <CallBackTort />
                <AddShopCar />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);