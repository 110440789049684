import React from "react";
import { connect } from "react-redux";
import Waveform from "./Waveform";
import { MUSIC_PLAY, SHOW_SHOP_CAR, CHECK_PRICE_GROUP } from "../../constants/actionTypes";
import PropTypes from "prop-types";
import agent from "agent";
import "./Player.less";

import { addCart, addFavorite, rmFavorite, redirectTo } from "dispatch";

const mapStateToProps = state => ({
    ...state.player,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    playMusic: musicPlay => {
        return dispatch({ type: MUSIC_PLAY, musicPlay });
    },
    showCarPopup: addAudio => {
        return dispatch({ type: SHOW_SHOP_CAR, addAudio });
    },
    checkPriceGroup: id => {
        return dispatch({
            type: CHECK_PRICE_GROUP,
            payload: agent.PriceGroup.get(id),
        });
    },
});

class Player extends React.Component {
    static propTypes = {
        playMusic: PropTypes.func.isRequired,
        audio: PropTypes.object,
        userLogin: PropTypes.bool,
        showCarPopup: PropTypes.func.isRequired,
        checkPriceGroup: PropTypes.func.isRequired,
    };

    clickFavorite(audio) {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return;
        }
        if (audio.like) {
            rmFavorite({ audioid: audio.audioid, like_id: audio.like.id });
        } else {
            addFavorite({ audioid: audio.audioid });
        }
    }

    handleAddCart(audio) {
        const { userLogin, showCarPopup, checkPriceGroup } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return;
        }
        console.log(addCart())
        const send = {
            showAddCar: true,
            addAudio: audio,
        };
        checkPriceGroup(audio.price_group_id);
        showCarPopup(send);
    }

    toggle() {
        if (this.props.audio) window.document.getElementById("root").children[0].classList.toggle("is-musicBar-on");
    }

    render() {
        const { playMusic, audio } = this.props;
        const props = {
            playMusic,
            audio,
            addCart: this.handleAddCart.bind(this),
            clickFavorite: this.clickFavorite.bind(this),
        };
        console.log(props)
        return (
            <div className="fixedPlayer">
                {this.props.audio && <div onClick={e => this.toggle()} className="fixedPlayer__toggle" />}
                <Waveform {...props} />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Player);
