import React from "react";
import PropTypes from "prop-types";

class MediaThree extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">
                            <span>
                                战略合作|头部IP公司忘语文化与MUSINESS签订音乐独家采购协议
                            </span>
                        </h1>
                    </header>
                    <div className="mainContents__box mediaContantBox">
                        <div className="mediaContantBox__header">
                            <div className="mediaContantBox__header__inner">
                                <p className="mediaContantBox__category">
                                    IP孵化器公众号
                                </p>
                                <p className="mediaContantBox__date">
                                    <time dateTime="2018-11-21">
                                        2018-11-21
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="mediaContantBox__main">
                            <div className="mediaContantBox__main__inner">
                                <p>
                                    <strong>
                                        忘语文化与奕颗贝壳Musiness互联网商用音乐
                                    </strong>
                                    签订音乐独家采购协议。约定：忘语文化及其旗下控股公司所使用的全部音乐作品从奕颗贝壳Musiness独家采购。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-21/01.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    忘语，中国网络仙侠文学第一人，“凡人流”风格的开创者，江苏省网络作家协会副主席，著有《凡人修仙传》等等头部IP文学作品。
                                    <strong>
                                        2015福布斯中国原创文学风云榜第一名；2016福布斯中国原创文学风云榜年度男生作品第一；2017胡润原创文学IP价值榜综合题材第二名/仙侠题材第一名；2011-2017百度指数网络小说人气榜单连续六年前三！
                                    </strong>
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-21/02.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    2012年开始《凡人修仙传》就以网页、PC端游戏、手游等多种开发形式引起不俗反响。2018
                                    动画番剧及真人电视剧正式开拍由金色传媒、阅文集团联合出品。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-21/03.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    忘语著有另一部小说《魔天记》，荣获2014年中国网络文学年度好作品优秀奖；2014-2017年百度指数网络小说人气榜单
                                    连续三年前五。2015年4月由《魔天记》改编成的微电影《魔天劫》由上海丝芭影视有限公司出品上映。5月《魔天记》改编为手游由上海方寸信息科技有限公司、网易游戏联合出品。同年并改编成漫画由上海童石网络科技有限公司发布。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-11-21/04.jpg"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    2015年12月忘语新著《玄界之门》荣获2016年百度风云榜武侠仙侠搜索榜
                                    第二名；2016年
                                    “纪念中国共产党成立95周年和中国工农红军长征胜利80周年”主题
                                    网络文学专项奖；2016年
                                    中国作家协会重点作品扶持项目；2016年
                                    福布斯·中国原创文学风云榜年度男生作品第一；2017年胡润原创文学IP价值榜第27名；
                                    <br />
                                    2017年《玄界之门》漫画版由凯撒文化与腾讯动漫联合出品。2018年《玄界之门》手游、影视剧由凯撒文化出品、发布。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MediaThree;
