import React from "react";
import PropTypes from "prop-types";
import {NEWS_ONE } from "constants/actionTypes";
import agent from "agent";
import { connect } from "react-redux";

const mapStateToProps = state => ({
    ...state.newsOne,
});
const mapDispatchToProps = dispatch => ({
    loadPage: newsid => {
        return dispatch({
            type: NEWS_ONE,
            newsid,
            payload: agent.News.one(newsid),
        });
    },
})
class MediaOne extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        loadPage: PropTypes.func.isRequired,
        newsid: PropTypes.string.isRequired,
    };
    state = {
        newsid: "",
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const param = nextProps.match.params.newsid;
        
        if (prevState.newsid != param) {
            //  send loading
            nextProps.loadPage(param);
           
        }
    }

    render() {
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">
                            <span>
                                数据智能助力文创产业发展主题活动在沪举行
                            </span>
                        </h1>
                    </header>
                    <div className="mainContents__box mediaContantBox">
                        <div className="mediaContantBox__header">
                            <div className="mediaContantBox__header__inner">
                                <p className="mediaContantBox__category">
                                    中国网
                                </p>
                                <p className="mediaContantBox__date">
                                    <time dateTime="2018-10-31">
                                        2018-10-31
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="mediaContantBox__main">
                            <div className="mediaContantBox__main__inner">
                                <p>
                                    中国网10月26日讯
                                    25日，由上海市徐汇区文化局主办，游族网络创新创业中心和MobData承办的以“数据智能助力文创产业发展”为主题的活动上海市徐汇区宜山路711号游族大厦举行。本次活动邀请了多位行业内资深专家，有MobData大数据解决方案总监杨一波，镁客网CTO、AI
                                    Lab负责人仇锦锋，奕颗贝壳互联网商用音乐公司创始人兼CEO童小言等一同探讨文创产业如何利用好大数据、AI智能等新技术，实现产业智能升级。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-10-31/01.png"
                                        alt=""
                                    />
                                </figure>

                                <p>
                                    其中，奕颗贝壳互联网商用音乐公司创始人兼CEO童小言的分享聚焦在音乐领域，她表示：AI技术可以使音乐创作与制作的过程更加便捷，在未来将会有更进阶的协同作词、自动匹配场景推荐配乐、协同作曲等更多功能被开发出来，为音乐创作赋予更多的可能。
                                </p>

                                <figure>
                                    <img
                                        src="/assets/img/2018-10-31/02.png"
                                        alt=""
                                    />
                                    <figcaption>
                                        奕颗贝壳互联网商用音乐公司创始人兼CEO童小言现场发言。
                                    </figcaption>
                                </figure>

                                <p>
                                    童小言还和大家讲述了原本作为两本畅销书作家的她踏足音乐领域的有趣渊源。童小言曾在世界500强顶尖德企先后负责中国区市场部、战略发展部业务分析与计划，后赴台湾政治大学攻读MBA，也是法国巴黎高等商学院ESCP-Europe管理学硕士专业的留学海归，作为美国商业最高荣誉协会BGS
                                    （Beta Gamma
                                    Sigma）终身荣誉会员，登上过《职业》杂志2014年5月封面人物，荣获2015年台湾EMBA商管盃竞赛冠军。然而，她一直钟爱艺术、文化、学习新事物及创作。“童言”就是她在作家圈的笔名，而“童小言”是她成为一名音乐作词人后的身份。短短一年内，她还创作发表了十余首二次元音乐作品，她表示：原本认为音乐是一种自我表达，其实音乐也可以让人脑洞大开，把自己天马行空的一面在音乐创作中尽情体现。
                                </p>

                                <p>
                                    也是因为兼具商业及艺术创作经历，她创立了奕颗贝壳互联网商用音乐公司，希望用自己的商科专业带给音乐领域新灵感，更好地做到商用音乐的聚合与变现，扶持创作人，打造音乐IP孵化生态社群，营造中国良好的音乐创作和版权意识及交易环境。
                                </p>

                                <p>
                                    据悉，此场活动为徐汇区文化局组织举办的“徐汇区文创产业沙龙系列活动”之一，本次系列活动将持续一个季度，聚焦演艺艺术、设计、影视传媒、数字内容等文化创意重点行业，通过行业大咖分享、产业论坛、政策解读等多种形式，推广“海派之源”品牌，提升徐汇区文化创意产业园区服务能级，搭建区域内企业之间的沟通协作平台。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaOne);
