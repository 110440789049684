import agent from "agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FAVORITE, FAVORITE_PAGE_UNLOADED,MUSIC_SEARCH_LABEL_LOAD_All } from "constants/actionTypes";

import FavoriteTable from "components/Music/comps/FavoriteTable";
import Loading from "components/Loading";
import Avatar from "components/Avatar";
import { redirectTo } from "dispatch";
import "./Favorite.less";

const mapStateToProps = state => ({
    ...state.user,
    ...state.favorite,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadFavorite: (pager, payload) => {
        return dispatch({
            type: FAVORITE,
            pager,
            payload,
        });
    },
    unloadFavorite: () => {
        return dispatch({ type: FAVORITE_PAGE_UNLOADED });
    },
    loadLabelAll: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD_All,
            payload: agent.Music.searchLabelAlls(),
        });
    },
});

class MyFavorite extends React.Component {
    constructor(props) {
        super(props);
    }
    static propTypes = {
        currentUser: PropTypes.object,
        loadFavorite: PropTypes.func.isRequired,
        unloadFavorite: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        total: PropTypes.number.isRequired,
        loadLabelAll:PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.unloadFavorite();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        this.props.loadLabelAll();
    }

    loadData(pager, params) {
        const { loadFavorite } = this.props;
        loadFavorite(pager, agent.Favorite.load(params));
    }
    back(e){
        redirectTo("/user/mypage");
    }
    render() {
        const { location, match, currentUser, total, loading } = this.props;
        if (!currentUser) {
            return null;
        }
        return (
            <div className="mypageContents" id ="myfavorite">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">喜爱列表</h1>
                    </header>
                    <div className="mainContents__box myloveBox">
                        <div className="myloveBox__inner">
                            <div className="avatarBox hidden">
                                <div className="avatarBox__img">
                                    <Avatar
                                        src={agent.getApiUrl(
                                            currentUser.avatar
                                        )}
                                        alt=""
                                    />
                                </div>
                                <p className="avatarBox__name">
                                    {currentUser.nickname}
                                </p>
                            </div>
                            <div className="resultContents">
                                <Loading loading={loading} />
                                <p className="resultTxt">
                                    您已经关注了
                                    <span>{total}</span>
                                    首音乐
                                    <button onClick={this.back.bind(this)} className='backHome'>返回</button>
                                </p>
                             
                                <FavoriteTable
                                    loadData={this.loadData.bind(this)}
                                    location={location}
                                    match={match}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyFavorite);
