import React from 'react';
import PropTypes from 'prop-types';
import agent from 'agent';
import { connect } from 'react-redux';
import { PLAYLIST_GET_INFO, PLAYLIST_GET_TYPE, TOPIC_DETAIL_UNLOAD,MUSIC_SEARCH_LABEL_LOAD_All} from 'constants/actionTypes';
import { DISCOUNT_OPEN } from 'constants/music';
import { redirectTo } from 'dispatch';
import OtherTopic from 'components/TopicMusic/OtherTopic';
import MusicItem from 'components/TopicMusic/MusicItem';
import { Pagination } from 'antd';
import Loading from "components/Loading";
import moment from "moment";
import "./TopicMusic.less";

class TopicMusic extends React.Component {
    static propTypes = {
        playlistInfos: PropTypes.array.isRequired,
        getPlaylistInfos: PropTypes.func.isRequired,
        getPlaylistType: PropTypes.func.isRequired,
        audioList: PropTypes.object.isRequired,
        search: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        unLoadTopic:PropTypes.func.isRequired,
        labels: PropTypes.object.isRequired,
        loadLabelAll: PropTypes.func.isRequired,
    };

    state = {
        topicId: null,
    };

    defaultPager = { pageSize: 10, current: 1 };

    selectRef = null;

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.playlistInfos != this.props.playlistInfos) {
            if (!this.props.playlistInfos.filter(x => x.playlistid === this.state.topicId).length) {
                redirectTo('/');
            }

            this.selectRef.value = this.state.topicId;
        }

        if (prevState.topicId === null && this.state.topicId) {
            this.selectRef.value = this.state.topicId;
        }

        if (prevProps.search !== this.props.search) {
            this.setTopicId();
        }
    }

    componentDidMount() {
        if (!this.props.playlistInfos.length) this.props.getPlaylistInfos();
        this.setTopicId();
        const {loadLabelAll}=this.props;
        loadLabelAll();
    }
    setTopicId() {
        const search = this.props.search.match(/id=([^&]+)/);
        if (search) {
            const [, topicId] = search;
            this.setState({ topicId });
            this.selectRef.value = topicId;
            this.props.getPlaylistType({ topicId, pager: this.defaultPager });
        } else redirectTo('/');
    }

    changePlayList(e) {
        this.setState({ topicId: e.target.value });
        this.props.getPlaylistType({
            topicId: e.target.value,
            pager: {
                ...this.props.audioList[this.state.topicId].pager,
                current: 1,
            },
        });
        redirectTo({
            pathname: location.pathname,
            search: `?id=${e.target.value}`,
        });
    }

    getTopic() {
        const [info = {}] = this.props.playlistInfos.filter(x => x.playlistid === this.state.topicId) || [];
        const { title, subtitle,status } = info;
        return { title, subtitle,status };
    }

    onPageChange(page) {
        this.props.getPlaylistType({
            topicId: this.state.topicId,
            pager: {
                pageSize: this.props.audioList[this.state.topicId].pager.pageSize,
                current: page,
            },
        });
    }
    render() {
        const { title, subtitle,status } = this.getTopic();
        let { audios: audioList = [], total, pager = this.defaultPager } = this.props.audioList[this.state.topicId] || {};
        const { pageSize, current } = pager;
        const getPlaylistType = this.props.getPlaylistType;
        const {labels}=this.props
        let img = '';
        let imgContent = '';
        if (title == '短视频') {
            img = ' topicKv_a musicKv_a ';
            imgContent = 'musicContents topicContents_a';
        } else if (title == '商业广告') {
            img = 'topicKv_b musicKv_b';
            imgContent = 'musicContents topicContents_b';
        } else if (title == '舞会派对') {
            img = 'topicKv_c musicKv_c';
            imgContent = 'musicContents topicContents_c';
        } else if (title == '当季节庆') {
            img = 'topicKv_d musicKv_d';
            imgContent = 'musicContents topicContents_d';
        } else if (title == '电玩游戏') {
            img = 'topicKv_e musicKv_e';
            imgContent = 'musicContents topicContents_e';
        } else if (title == '都会时尚') {
            img = 'topicKv_f musicKv_f';
            imgContent = 'musicContents topicContents_f';
        } else if (title == '动画卡通') {
            img = 'topicKv_g musicKv_g';
            imgContent = 'musicContents topicContents_g';
        } else if (title == '典礼开场') {
            img = 'topicKv_h musicKv_h';
            imgContent = 'musicContents topicContents_h';
        } else if (title == '氛围配乐') {
            img = 'topicKv_i musicKv_i';
            imgContent = 'musicContents topicContents_i';
        }else if (title == 'VLOG 旅行篇') {
            img = 'topicKv_71 musicKv_71';
            imgContent = 'musicContents topicContents_71';
        } else if (title == 'VLOG 生活篇') {
            img = 'topicKv_72 musicKv_72';
            imgContent = 'musicContents topicContents_72';
        }
        return (
            <div className={imgContent} id="topic_music">
                <div className="musicContents__inner">
                    <div className={img}>
                        <div className="musicKv__titleBox">
                            <h1 className="topicKv__title">{title}</h1>
                            <p className="topicKv__txt">{subtitle}</p>
                        </div>
                    </div>

                    <div className="otherMusicList" style={{padding: "4.6875% 3.125% 3.8125%"}}>
                        <div className="musicList__header">
                            <h2 className="musicList__title">其他分类主题</h2>
                            <div className="musicList__header__select">
                                <div className="u-selectWrapp">
                                    <select ref={ref => (this.selectRef = ref)} onChange={this.changePlayList.bind(this)}>
                                        {this.props.playlistInfos.map((x, i) => {
                                            const { playlistid, title,weight } = x;
                                            if (weight<1) {
                                                return
                                            }
                                            return (
                                                <option key={i} value={playlistid}>
                                                    {title}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="resultItemWrapper">
                            {audioList.map((x, i) => {
                                return <MusicItem key={i} data={x} type={this.state.topicId} page={'playlist'} labels={labels}/>;
                            })}
                            {status==2&&<div className="" style={{width: "700px",marginTop:"25px"}}>
                                <p style={{fontWeight: 800}}>活动说明：</p>
                                <p>1. 活动期间特价产品的售价为99元，每期活动结束后恢复原价。本期活动截止时间:{moment().endOf('month').format("YYYY.MM.DD")}。</p>
                                <p>2. Musiness保留法律范围内允许的对本活动的最终解释权。</p>
                            </div>}
                            
                        </div>
                        <div className="c02b478-music-table">
                            {!audioList.length?<Loading />: 
                                <div className="creatorpage-ov" >
                                    {/**分类页的显示跳十页 */}
                                    <div className="resultPagination">
                                        <Pagination
                                            defaultPageSize={pageSize}
                                            defaultCurrent={1}
                                            current={current}
                                            hideOnSinglePage={true}
                                            total={total}
                                            onChange={this.onPageChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                       
                    </div>

                    <div className="musicBnrArea">
                        <OtherTopic excludes={[this.state.topicId]} getPlaylistType={getPlaylistType} />
                        <div className="bnrArea01" style={{ display: DISCOUNT_OPEN ? '' : 'none' }}>
                            <h2 className="bnrArea01__title">优惠折扣</h2>
                            <div className="m-columnBox" data-column="2" data-column-sp="1">
                                <ul className="m-columnBox__items">
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        playlistInfos: state.playlist.info.infos,
        audioList: state.playlist.list,
        search: state.router.location.search,
        labels: state.labels.labelsDataAll,
    }),
    dispatch => ({
        getPlaylistInfos: send =>
            dispatch({
                type: PLAYLIST_GET_INFO,
                payload: agent.PlayList.getInfo(send),
                send,
            }),
        getPlaylistType: send =>
            dispatch({
                type: PLAYLIST_GET_TYPE,
                payload: agent.PlayList.getType(send),
                send,
            }),
        unLoadTopic: () => 
            dispatch({ 
                type: TOPIC_DETAIL_UNLOAD
            }),
        loadLabelAll: () => {
            return dispatch({
                type: MUSIC_SEARCH_LABEL_LOAD_All,
                payload: agent.Music.searchLabelAlls(),
            });
        },
    })
)(TopicMusic);
