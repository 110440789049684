import React from "react";
import WaveSurfer from "wavesurfer.js";
import PropTypes from "prop-types";
import agent from "agent";
import { Link } from "react-router-dom";
import { Slider, Row, Col } from "antd";
import Download from "components/Common/Download";
import formateTime from "utils/formateTime";
import Share from "components/Common/Share";
import { addPeaks, HtmlUtil } from "dispatch";
import "./Player.less";
export default class Waveform extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            sound: true,
            inputValue: 0.5,
            time: 0,
            shareVisible: false,
            shareData: { url: "", title: "" },
        };
        this.wavesurfer = null;
        this.intervalcounter = -1;
    }
    static propTypes = {
        audio: PropTypes.shape({
            id: PropTypes.number.isRequired,
            audioid: PropTypes.string.isRequired,
            thumb: PropTypes.string.isRequired,
            audio: PropTypes.string.isRequired,
            author: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
            composer: PropTypes.string,
            description: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            speed: PropTypes.number.isRequired,
            playing: PropTypes.bool.isRequired,
            merged_peaks: PropTypes.string,
            like: PropTypes.object,
            likeUpdate: PropTypes.bool,
            creatorInfo: PropTypes.object,
        }).isRequired,
        playMusic: PropTypes.func.isRequired,
        addCart: PropTypes.func.isRequired,
        clickFavorite: PropTypes.func.isRequired,
    };
    static defaultProps = {
        musicUrl: "",
        audio: {
            id: 0,
            audioid: "",
            thumb: "",
            audio: "",
            author: {},
            creatorInfo: {},
            title: "",
            description: "",
            duration: 0,
            speed: 0,
            playing: true,
            merged_peaks: "",
            like: null,
            likeUpdate: false,
        },
    };
    componentDidMount() {
        const { playing, merged_peaks } = this.props.audio;
        if (!this.wavesurfer) {
            const backend = merged_peaks ? "MediaElement" : "WebAudio";
            this.wavesurfer = WaveSurfer.create({
                container: "#wave-player",
                waveColor: "#c4c8dc",
                progressColor: "#6c718c",
                normalize: true,
                barWidth: 4,
                height: 30,
                playing: playing,
                pos: 0,
                volume: 0.5,
                audioRate: 1,
                backend,
            });

            this.wavesurfer.on("ready", () => {
                this.wavesurfer.play();
                clearInterval(this.intervalcounter);
                this.intervalcounter = setInterval(() => {
                    this.setState({
                        time: this.wavesurfer.getCurrentTime(),
                    });
                }, 1000);
                if (this.wavesurfer.backend.mergedPeaks) {
                    let peaks = this.wavesurfer.backend.mergedPeaks.toString();
                    const audioid = this.props.audio.audioid;
                    addPeaks(audioid, peaks);
                }
            });
        }
    }
    componentDidUpdate(prevProps) {
        const { audio, merged_peaks } = this.props.audio;
        if (!this.wavesurfer) {
            return;
        }
        if (audio != prevProps.audio.audio) {
            clearInterval(this.intervalcounter);
            if (merged_peaks != null) {
                const arr = merged_peaks.split(",");
                let arrs = [];
                for (let i = 0; i < arr.length; i++) {
                    arrs.push(parseFloat(arr[i]));
                }
                this.wavesurfer.load(agent.getApiUrl(audio));
                return;
            } else {
                this.wavesurfer.load(agent.getApiUrl(audio));
                return;
            }

        }
        this.wavesurfer.setVolume(this.state.inputValue);
        if (this.props.audio.playing) {
            if (!prevProps.audio.playing) {
                this.wavesurfer.playPause();
            } else {
                this.wavesurfer.play();
            }
        } else {
            this.wavesurfer.pause();
        }
    }
    handlePlayClick = e => {
        const { audio, playMusic } = this.props;
        const send = {
            ...audio,
            playing: !audio.playing,
        };
        playMusic(send);
    };
    handleCartClick = e => {
        e.preventDefault();
        const { audio, addCart } = this.props;
        const send = {
            ...audio,
        };
        addCart(send);

    };
    handleShareClick(data) {
        const { audioid, title } = data;
        this.setState({
            shareVisible: true,
            shareData: {
                url: location.origin + "/music/musicid/" + audioid,
                title,
            },
        });
    }

    closeShareModel() {
        this.setState({ shareVisible: false });
    }

    handleFavoriteClick = e => {
        e.preventDefault();

        const { audio, clickFavorite } = this.props;
        const send = {
            ...audio,
        };
        clickFavorite(send);
    };

    onChangeSound = value => {
        if (Number.isNaN(value)) {
            return;
        }
        this.setState({
            inputValue: value,
        });
    };

    // soundSet = e => {
    //     this.wavesurfer.setMute(!this.sound);
    //     console.info(2222222222);
    //     this.setState({
    //         sound: !this.sound,
    //     });
    // };
    render() {
        const {
            // id,
            audioid,
            thumb,
            // audio,
            author: { nickname, username },
            creatorInfo,
            title,
            // description,
            //duration,
            //speed,
            composer,
            playing,
            like,
            likeUpdate,
        } = this.props.audio;
        const nname = !creatorInfo ? (composer || nickname || username) : creatorInfo.nickname;
        const { inputValue } = this.state;
        const times = formateTime(this.state.time);
        const playClass = "playBtn " + (playing ? "is-play" : "is-pause");
        const urls = "url(" + agent.getApiUrl(thumb) + ")";
        return (
            <div>
                <Share
                    url={this.state.shareData.url}
                    title={this.state.shareData.title}
                    visible={this.state.shareVisible}
                    onClose={this.closeShareModel.bind(this)}
                />
                <div className="fixedPlayer__inner">
                    <div className="fixedPlayer__control">
                        <div className="fixedPlayer__control__item fixedPlayer__backBtn">
                            <button className="backBtn">
                                <svg viewBox="0 0 35 35">
                                    <g className="backIcon">
                                        <use xlinkHref="#backIcon" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="fixedPlayer__control__item fixedPlayer__playBtn">
                            <button className={playClass} onClick={this.handlePlayClick.bind(this)}>
                                <svg viewBox="0 0 35 35">
                                    <g className="playIcon">
                                        <use xlinkHref="#playIcon" />
                                    </g>
                                    <g className="pauseIcon">
                                        <use xlinkHref="#pauseIcon" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="fixedPlayer__control__item fixedPlayer__nextBtn">
                            <button className="nextBtn">
                                <svg viewBox="0 0 35 35">
                                    <g className="nextIcon">
                                        <use xlinkHref="#nextIcon" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className="fixedPlayer__sound">
                        <div className="fixedPlayer__soundBtn">
                            <button className="soundBtn">
                                <svg viewBox="0 0 35 35">
                                    <g className="soundIcon">
                                        <use xlinkHref="#soundIcon" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="fixedPlayer__soundPanel">
                            <Row>
                                <Col span={12}>
                                    <Slider
                                        min={0}
                                        max={1}
                                        onChange={this.onChangeSound.bind(this)}
                                        value={typeof inputValue === "number" ? inputValue : 0}
                                        step={0.01}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="fixedPlayer__wave">
                        <div className="fixedPlayer__wave__img" id="wave-player" />
                        <p className="fixedPlayer__wave__time">{times}</p>
                    </div>
                </div>
                <div className="fixedPlayer__btm">
                    <div className="fixedPlayer__btmInner">
                        <div className="fixedPlayer__info">
                            <div className="fixedPlayer__jacket" style={{ backgroundImage: urls }}>
                                {/* <img src={agent.getApiUrl(thumb)} alt="" /> */}
                            </div>
                            <p className="fixedPlayer__title">
                                <Link to={`/music/musicid/${audioid}`}>{HtmlUtil.htmlDecode(title)}</Link>
                            </p>
                            <p className="fixedPlayer__name"> by {HtmlUtil.htmlDecode(nname)}</p>
                        </div>

                        <div className="fixedPlayer__utility">
                            <ul className="fixedPlayer__utility__items">
                                <li className="fixedPlayer__utility__item fixedPlayer__utility__fav">
                                    <button
                                        className={`favBtn ` + (like && `is-fav`)}
                                        disabled={likeUpdate}
                                        onClick={this.handleFavoriteClick.bind(this)}
                                    >
                                        <svg viewBox="0 0 35 35">
                                            <g className="favIcon">
                                                <use xlinkHref="#favIcon" />
                                            </g>
                                            <g className="favIconIn">
                                                <use xlinkHref="#favIconIn" />
                                            </g>
                                        </svg>
                                    </button>
                                </li>
                                <li className="fixedPlayer__utility__item fixedPlayer__utility__share">
                                    <a href="javascript:;" onClick={this.handleShareClick.bind(this, { audioid, title })}>
                                        <span>share</span>
                                    </a>
                                </li>
                                <li className="fixedPlayer__utility__item fixedPlayer__utility__cart">
                                    <Link to="javascript:void(0)" onClick={this.handleCartClick.bind(this)} />
                                </li>
                                <li className="fixedPlayer__utility__item fixedPlayer__utility__download">
                                    <Download audioid={audioid} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
