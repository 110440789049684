import React from "react";
import PropTypes from "prop-types";

class CommonContent extends React.Component {
    static propTypes = {
        data: PropTypes.array,
        title: PropTypes.string,
        publish: PropTypes.string,
    };

    render() {
        const html = this.props.data.map((x, i) => {
            const { tag, classes, text } = x;
            if (tag === "p")
                return (
                    <p
                        key={i}
                        className={classes}
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={text}
                    />
                );
            return (
                <h3
                    key={i}
                    className={classes}
                    dangerouslySetInnerHTML={text}
                />
            );
        });

        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">
                            <span>{this.props.title}</span>
                        </h1>
                    </header>
                    <div className="mainContents__box mediaContantBox">
                        {this.props.publish && (
                            <div className="mediaContantBox__header">
                                <div className="mediaContantBox__header__inner">
                                    <p className="mediaContantBox__category">
                                        本协议发布时间
                                    </p>
                                    <p className="mediaContantBox__date">
                                        <time>{this.props.publish}</time>
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="mediaContantBox__main">
                            <div className="mediaContantBox__main__inner">
                                {html}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonContent;
