import agent from "agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import {
    MUSIC_PLAY,
    MUSIC_ONE,
    MUSIC_SEARCH,
    MUSIC_ONE_PAGE_UNLOAD,
    MUSIC_SEARCH_LABEL_LOAD,
    MUSIC_SEARCH_LABEL_LOAD_All,
    SHOW_SHOP_CAR,
    CHECK_PRICE_GROUP,
} from "constants/actionTypes";
import MusicTable from "components/Music/comps/MusicTable";
import StaticWave from "utils/staticWave";
import Download from "components/Common/Download";
import { redirectTo, showTime, HtmlUtil } from "dispatch";
import Share from "components/Common/Share";

const conditionArrToObject = arr => {
    const data = {};
    for (const record of arr) {
        data[record.id] = record;
    }
    return data;
};
import { addFavorite, rmFavorite } from "dispatch";

const mapStateToProps = state => ({
    ...state.musicOne,
    musicPlay: state.player.audio,
    userLogin: state.app.userLogin,
    conditions: state.musicSearch.labelsData,
});

const mapDispatchToProps = dispatch => ({
    loadPage: audioid => {
        return dispatch({
            type: MUSIC_ONE,
            audioid,
            payload: agent.Music.one(audioid),
        });
    },
    loadSearch: (pager, payload, exclude) => {
        return dispatch({ type: MUSIC_SEARCH, pager, payload, exclude });
    },
    unLoadMusicOne: () => {
        return dispatch({ type: MUSIC_ONE_PAGE_UNLOAD });
    },
    playMusic: musicPlay => {
        return dispatch({ type: MUSIC_PLAY, musicPlay });
    },
    loadLabel: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD,
            payload: agent.Music.searchLabels(),
        });
    },
    loadLabelAll: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD_All,
            payload: agent.Music.searchLabelAlls(),
        });
    },
    showCarPopup: addAudio => {
        return dispatch({ type: SHOW_SHOP_CAR, addAudio });
    },
    checkPriceGroup: id => {
        return dispatch({
            type: CHECK_PRICE_GROUP,
            payload: agent.PriceGroup.get(id),
        });
    },
});

class MusicOne extends React.Component {
    static propTypes = {
        loadSearch: PropTypes.func.isRequired,
        musicone: PropTypes.string.isRequired,
        musiconeData: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        loadPage: PropTypes.func.isRequired,
        unLoadMusicOne: PropTypes.func.isRequired,
        playMusic: PropTypes.func.isRequired,
        musicPlay: PropTypes.object,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        loadLabel: PropTypes.func.isRequired,
        loadLabelAll: PropTypes.func.isRequired,
        conditions: PropTypes.object,
        speed: PropTypes.number.isRequired,
        showCarPopup: PropTypes.func.isRequired,
        checkPriceGroup: PropTypes.func.isRequired,
    };
    state = {
        musicone: "",
        pageSize: 0,
        conditions: {},
        shareVisible: false,
        shareData: { url: "", title: "" },
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        const param = nextProps.match.params.musicone;
        const pageSize = prevState.pageSize;
        if (prevState.musicone != param) {
            //  send loading
            nextProps.loadPage(param);
            if (prevState.musicone && pageSize) {
                // loaded and change page
                const { loadSearch } = nextProps;
                const pager = { pageSize, current: 1 };
                const params = { size: pageSize, page: 1 };
                loadSearch(pager, agent.Music.search(params), param);
            }
        }
        const conditions = {};
        if (Object.keys(nextProps.conditions).length) {
            for (const [key, value] of Object.entries(nextProps.conditions)) {
                conditions[key] = conditionArrToObject(value);
            }
        }
        return { musicone: param, conditions };
    }

    componentDidMount() {
        this.props.loadLabel();
        this.props.loadLabelAll();
        console.log(this.props.musiconeData)

    }

    componentWillUnmount() {
        this.props.unLoadMusicOne();
    }
    handlePlayClick = e => {
        const { musiconeData, playMusic } = this.props;
        const send = {
            ...musiconeData,
            playing: !musiconeData.playing,
        };
        playMusic(send);
    };
    loadData(pager, params) {
        this.setState({ pageSize: pager.pageSize });
        const { loadSearch } = this.props;
        // TODO: chang params fro search
        loadSearch(pager, agent.Music.search(params), this.state.musicone);
    }
    handleFavoriteClick(e) {
        const { userLogin, musiconeData: audio } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return;
        }
        if (audio.like) {
            rmFavorite({ audioid: audio.audioid, like_id: audio.like.id });
        } else {
            addFavorite({ audioid: audio.audioid });
        }
    }
    handleCartClick = e => {
        e.preventDefault();
        const { musiconeData: audio } = this.props;
        const { showCarPopup, checkPriceGroup } = this.props;
        const send = {
            showAddCar: true,
            addAudio: audio,
        };
        checkPriceGroup(audio.price_group_id);
        showCarPopup(send);
    };
    handleShareClick(data) {
        const { audioid, title } = data;
        let tit = HtmlUtil.htmlDecode(title)
        this.setState({
            shareVisible: true,
            shareData: {
                url: location.origin + "/music/musicid/" + audioid,
                title: tit,
            },
        });
    }
    closeShareModel() {
        this.setState({ shareVisible: false });
    }
    render() {
        const { musicPlay, loading, musiconeData, match, location } = this.props;

        if (!musiconeData) {
            return (
                <div className="musicContents">
                    <Loading loading={loading} />
                    <div className="musicContents__inner">
                        <div className="musicKv">
                            <div className="musicKv__titleBox">
                                <h1 className="musicKv__title">&nbsp;</h1>
                                <p className="musicKv__name">&nbsp;</p>
                            </div>
                        </div>
                        <div className="musicInfo">&nbsp;</div>
                    </div>
                </div>
            );
        }
        const {
            id,
            audioid,
            thumb,
            title,
            description,
            duration,
            like,
            likeUpdate,
            creatorInfo,
            inverted_condition: inverted,
            speed,
            composer,
            minprice,
            api_id,
            author,
        } = musiconeData;
        const { conditions } = this.state;
        const inverted_condition = { ...inverted };
        let speedNum = "";
        if (speed != "") {
            if (conditions.speed[speed].id > 0) {
                speedNum = conditions.speed[speed].title;
            } else {
                speedNum = "";
            }
        }
        console.log(speedNum)
        if (Object.keys(conditions).length) {
            for (const [key, value] of Object.entries(inverted_condition)) {
                if (value === 0) continue;

                const condition = conditions[key];
                const data = value.split(",").map(val => {
                    if (condition[val]) {
                        return condition[val].title;
                    }
                    return "";
                });
                inverted_condition[key] = data.join(" ");
            }
        }
        let playing = false;
        if (musicPlay && musicPlay.audioid == audioid) {
            playing = musicPlay.playing;
        }
        musiconeData.playing = playing;
        const playClass = "playBtn " + (playing ? "is-play" : "is-pause");
        const urls = "url(" + agent.getApiUrl(thumb) + ")";
        const pre = 99;
        const vImg = author.userid == 'IVKPCCUGYVS317TCF32BC7BE385C027F' || author.userid == 'WCIRSZ7MUOMGZ4RUE1A728276B7BD15B' || author.userid == 'XBNTVMZD2KVJ5FRK81027164D4516A4C' ? true : false;
        return (
            <div className="musicContents" id="audio_one_css">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={title + ' ' + creatorInfo.nickname + (' 风格 ' + inverted_condition["style"] + ' 情绪 ' + inverted_condition["emotion"] + ' 速度 ' + speedNum  + ' 乐器 ' + inverted_condition["instrument"])}></meta>
                    <title>{title + ' ' + creatorInfo.nickname + '  ｜ musiness商用版权音乐授权网站|奕颗贝壳，轻音乐纯音乐、影视/广告/游戏配乐、罐头音乐，BGM素材购买网站'}</title>
                </Helmet>
                <div className="musicContents__inner" audioid={audioid}>
                    <div className="musicKv">
                        <div className="musicKv__titleBox">
                            <h1 className="musicKv__title">{HtmlUtil.htmlDecode(title)}</h1>
                            <p className="musicKv__name">
                                {!(creatorInfo && creatorInfo.status == 'normal') ? <span>  by {HtmlUtil.htmlDecode(creatorInfo ? creatorInfo.nickname : composer)}</span> : <Link to={`/makerdetail?id=${creatorInfo.id}`}>
                                    by {HtmlUtil.htmlDecode(creatorInfo.nickname)}
                                </Link>}
                            </p>
                        </div>
                    </div>
                    <Share
                        url={this.state.shareData.url}
                        title={this.state.shareData.title}
                        visible={this.state.shareVisible}
                        onClose={this.closeShareModel.bind(this)}
                    />
                    <div className="musicInfo">
                        <div className="infoItem musicItem">
                            <div className="musicItem__jacket" style={{ backgroundImage: urls, paddingBottom: "20.37037%" }}>
                                {/* <img src={agent.getApiUrl(thumb)} alt="" /> */}
                            </div>
                            {vImg ? <div className="one_v_img_style"> <img className="one_v_style" src="/assets/img/sub_v.png" /></div> : ""}
                            <div className="musicItem__txtBox">
                                <div className="musicItem__header">
                                    <p className="musicItem__playBtn" onClick={this.handlePlayClick.bind(this)}>
                                        <button className={playClass}>
                                            <svg viewBox="0 0 35 35">
                                                <g className="playIcon">
                                                    <use xlinkHref="#playIcon" />
                                                </g>
                                                <g className="pauseIcon">
                                                    <use xlinkHref="#pauseIcon" />
                                                </g>
                                            </svg>
                                        </button>
                                    </p>
                                    <div className="musicItem__header__inner">
                                        <p className="musicItem__title">
                                            {HtmlUtil.htmlDecode(title)}
                                            {vImg ? <img className="phone_v_style" src="/assets/img/sub_v.png" /> : ""}
                                        </p>
                                        <p className="musicItem__name"> {!(creatorInfo && creatorInfo.status == 'normal') ? <span>  by {HtmlUtil.htmlDecode(creatorInfo ? creatorInfo.nickname : composer)}</span> : <Link to={`/makerdetail?id=${creatorInfo.id}`}>
                                            by {HtmlUtil.htmlDecode(creatorInfo.nickname)}</Link>}
                                        </p>
                                    </div>
                                    <div className="audio_price">
                                        ¥{!api_id ? minprice : pre}
                                    </div>
                                </div>

                                <div className="musicItem__wave">
                                    <StaticWave key={`one-${audioid}`} audio={musiconeData} />
                                    <p className="musicItem__wave__time">{showTime(duration)}</p>
                                </div>

                                <div className="musicItem__utility">
                                    <ul className="musicItem__utility__items">
                                        <li className="musicItem__utility__item musicItem__utility__fav">
                                            <button
                                                className={`favBtn ` + (like && `is-fav`)}
                                                onClick={this.handleFavoriteClick.bind(this)}
                                                disabled={likeUpdate}
                                            >
                                                <svg viewBox="0 0 35 35">
                                                    <g className="favIcon">
                                                        <use xlinkHref="#favIcon" />
                                                    </g>
                                                    <g className="favIconIn">
                                                        <use xlinkHref="#favIconIn" />
                                                    </g>
                                                </svg>
                                            </button>
                                        </li>
                                        <li className="musicItem__utility__item musicItem__utility__share">
                                            <a href="javascript:;" onClick={this.handleShareClick.bind(this, { audioid, title })}>
                                                <span>分享</span>
                                            </a>
                                        </li>
                                        <li className="musicItem__utility__item musicItem__utility__cart">
                                            <Link to="javascript:void(0)" onClick={this.handleCartClick.bind(this)} />
                                        </li>
                                        <li className="musicItem__utility__item musicItem__utility__download">
                                            <Download audioid={audioid} />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="musicItem__txtBox02">
                                <p className="musicItem__exp">{description}</p>
                            </div>

                            <div className="musicItem__detail">
                                <h2 className="musicItem__detail__title">详情</h2>
                                <div className="musicItem__detail__items">
                                    <div className="musicItem__detail__item">
                                        <dl>
                                            <dt className="musicItem__detail__category">风格</dt>
                                            <dd className="musicItem__detail__tag">{inverted_condition["style"]}</dd>
                                        </dl>
                                    </div>
                                    <div className="musicItem__detail__item">
                                        <dl>
                                            <dt className="musicItem__detail__category">情绪</dt>
                                            <dd className="musicItem__detail__tag">{inverted_condition["emotion"]}</dd>
                                        </dl>
                                    </div>
                                    <div className="musicItem__detail__item">
                                        <dl>
                                            <dt className="musicItem__detail__category">速度</dt>
                                            <dd className="musicItem__detail__tag">{speedNum}</dd>
                                        </dl>
                                    </div>
                                    <div className="musicItem__detail__item">
                                        <dl>
                                            <dt className="musicItem__detail__category">乐器</dt>
                                            <dd className="musicItem__detail__tag">{inverted_condition["instrument"]}</dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="otherMusicList">
                        <h2 className="otherMusicList__title">相似音乐</h2>
                        <MusicTable loadData={this.loadData.bind(this)} location={location} match={match} one={true} inverted_condition={inverted} one_id={id} />
                    </div>

                    <div className="musicBnrArea hidden">
                        <div className="bnrArea01">
                            <h2 className="bnrArea01__title">优惠折扣</h2>
                            <div className="m-columnBox" data-column="2" data-column-sp="1">
                                <ul className="m-columnBox__items">
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="/assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="/assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bnrArea01">
                            <h2 className="bnrArea01__title">活动信息</h2>
                            <div className="m-columnBox" data-column="2" data-column-sp="1">
                                <ul className="m-columnBox__items">
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="/assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img src="/assets/img/bnr_dmy_2.jpg" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MusicOne);
