import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    REGISTER,
    CAPTCHA,
    REGISTER_PAGE_UNLOADED,
    IS_REGISTER,
} from "constants/actionTypes";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import { redirectTo } from "dispatch";
import TermModal from "../Common/TermModal";

const mapStateToProps = state => ({ ...state.auth });
const FormItem = Form.Item;
const query = new URLSearchParams(location.search);//获取参数
const sourceid=query.get('reference');

const mapDispatchToProps = dispatch => ({
    onSubmit: ({
        company_name,
        company_number,
        email,
        password,
        captcha,
        userImage,
        interest,
        industry,
        inviter,
        sourceuser,
        source,
    }) =>
        dispatch({
            type: REGISTER,
            payload: agent.Register.userRegister({
                company_name,
                company_number,
                email,
                password,
                captcha,
                userImage,
                interest,
                industry,
                inviter,
                sourceuser,
                source,
            }),
        }),

    onClick: (email, done) =>
        dispatch({
            type: CAPTCHA,
            payload: agent.Captcha.create(email),
            done,
        }),
    isRegister: (email) =>
        dispatch({
            type: IS_REGISTER,
            payload: agent.Auth.isRegister(email),
        }),
    onUnload: () => dispatch({ type: REGISTER_PAGE_UNLOADED }),
});

class UserRegister extends React.Component {
    constructor(props) {
        super(props);

        this.state = { tabSelectedFlg: "0", modalVisible: false };

        this.changeToCompany = this.changeToCompany.bind(this);
        this.changeToUser = this.changeToUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
        this.closeTermModal = this.closeTermModal.bind(this);
    }

    static propTypes = {
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        registeSuccess: PropTypes.bool,

        onSubmit: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
    };
    static defaultProps = {
        registeSuccess: false,
    };

    onRef = ref => {
        this.child = ref;
    };

    state = {
        captchaDisabled: false,
        captchaLock: -1,
    };

    closeTermModal() {
        this.setState({
            modalVisible: false,
        });
    }

    handleModalClick = e => {
        e.preventDefault();
        this.setState({ modalVisible: true });
    };

    handleSendCode = e => {
        e.preventDefault();
        let sendEmail = "";

        if (this.state.tabSelectedFlg === "0") {
            sendEmail = this.props.form.getFieldValue("email");
        } else {
            sendEmail = this.props.form.getFieldValue("companyEmail");
        }
        this.getIdentifyingCode(sendEmail);
    };
    _getIdentifyingCode = field => {
        const done = e => {
        };
        this.props.onClick(field, done);
    };

    getIdentifyingCode(sendEmail) {
        if (/^\d+(\.\d{1,2})?$/.test(sendEmail) && sendEmail.length === 11) {
            this._getIdentifyingCode(sendEmail);
        } else {
            if (this.state.tabSelectedFlg === "0") {
                if (
                    !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
                        sendEmail
                    )
                ) {
                    this.props.form.setFields({
                        email: {
                            value: sendEmail,
                            errors: [new Error("请输入正确的手机号/邮箱！")],
                        },
                    });
                    return false;
                } else {
                    this._getIdentifyingCode(sendEmail);
                }
            } else {
                if (
                    !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
                        sendEmail
                    )
                ) {
                    this.props.form.setFields({
                        companyEmail: {
                            value: sendEmail,
                            errors: [new Error("请输入正确的手机号/邮箱！")],
                        },
                    });
                    return false;
                } else {
                    this._getIdentifyingCode(sendEmail);
                }
            }
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            const identiy=window.identiy;
            console.log(identiy)
            let send = {};
            if (this.state.tabSelectedFlg === "0") {
                if (values.reinputPassword !== values.password) {
                    this.props.form.setFields({
                        reinputPassword: {
                            value: values.reinputPassword,
                            errors: [new Error("输入密码不一致！")],
                        },
                    });
                    return false;
                }

                if (err) {
                    return false;
                }
                send = {
                    company_name: "",
                    company_number: "",
                    email: values.email,
                    password: values.password,
                    captcha: values.identifyingCode,
                    userImage: values.userImage,
                    sourceuser:values.sourceid,
                    source: "未知",
                    // interest:values.interest,
                    // industry:values.industry,
                };  
            } else {
                if (values.reinputCompanyPassword !== values.companyPassword) {
                    this.props.form.setFields({
                        reinputCompanyPassword: {
                            value: values.reinputCompanyPassword,
                            errors: [new Error("输入密码不一致！")],
                        },
                    });
                    return false;
                }

                if (err) {
                    return false;
                }
                send = {
                    company_name: values.companyName,
                    company_number: values.companyLicenseNumber,
                    email: values.companyEmail,
                    password: values.companyPassword,
                    captcha: values.companyIdentifyingCode,
                    userImage: values.userImage,
                    sourceuser:values.sourceid,
                    source: "未知",
                    // interest:values.companyInterest,
                    // industry:values.companyIndustry,
                };
            }

            if (identiy) {
                send.inviter=identiy;
            } 
            //判断是否来自IP 
            if(sessionStorage.getItem("source")=="ip")
                send.source="IP"; 
            console.log(JSON.stringify(send)+" hello simon")
            this.props.onSubmit(send);
        });
        // this.props.onSubmit({companyName:"", companyLicenseNumber:"", email:"ljm11234@yahoo.co.jp", password:12345678, reInputPassword:12345678, captcha:"5452074", userImage:""});
    };

    showFormItemServerError() {
        const errors = this.props.errors;
        if (!errors) {
            const { captchaDisabled } = this.state;
            if (captchaDisabled) {
                return;
            }
            this.setState({ captchaDisabled: true });
            let lock = 60;
            this.setState({ captchaLock: lock });
            const c = setInterval(() => {
                lock--;
                this.setState({ captchaLock: lock });
                if (lock <= 0) {
                    this.setState({ captchaDisabled: false });
                    clearInterval(c);
                }
            }, 1000);
            return;
        }
       
        const { form } = this.props;
        Object.keys(errors).map(key => {
            const message = errors[key];
            if (message.message === "Invalid captcha") {
                message.param = "captcha";
                message.msg = "验证码错误";
                const key =
                    this.state.tabSelectedFlg === "0"
                        ? "identifyingCode"
                        : "companyIdentifyingCode";
                message.value = form.getFieldValue(key);
            } else if (message.message === "Invalid company number") {
                message.param = "company_number";
                message.msg = "企业号错误";
                message.value = form.getFieldValue("companyLicenseNumber");
            } else if (message.message === "Invalid company name") {
                message.param = "company_name";
                message.msg = "企业名错误";
                message.value = form.getFieldValue("companyName");
            }else if (message.message === "phone already registered"){
                message.param = "email";
                message.msg = "该手机/邮箱已注册!";
                message.value = form.getFieldValue("email");
            }else if (message.message === "The user is registered"){
                message.param = "email";
                message.msg = "该手机/邮箱已注册!";
                message.value = form.getFieldValue("email");
            }

            if (this.state.tabSelectedFlg === "0") {
                switch (message.param) {
                    case "email":
                        form.setFields({
                            email: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "password":
                        form.setFields({
                            password: {
                                value: message.value,
                                errors: [new Error("密码至少6位！")],
                            },
                        });
                        break;
                    case "captcha":
                        form.setFields({
                            identifyingCode: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "userImage":
                        this.setFormItemError("userImage", message.msg);
                        break;
                }
            } else {
                switch (message.param) {
                    case "company_name":
                        form.setFields({
                            companyName: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "company_number":
                        form.setFields({
                            companyLicenseNumber: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "email":
                        form.setFields({
                            companyEmail: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "password":
                        form.setFields({
                            companyPassword: {
                                value: message.value,
                                errors: [new Error("密码至少6位！")],
                            },
                        });
                        break;
                    case "captcha":
                        form.setFields({
                            companyIdentifyingCode: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                    case "userImage":
                        form.setFields({
                            userImage: {
                                value: message.value,
                                errors: [new Error(message.msg)],
                            },
                        });
                        break;
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.registeSuccess && agent.getToken()) {
            redirectTo("/register/success");
            return false;
        }
        return true;
    }

    changeToCompany(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.resetFields();
        this.setState({ tabSelectedFlg: "1" });
    }

    changeToUser(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.resetFields();
        this.setState({ tabSelectedFlg: "0" });
    }

    componentWillUnmount() {
        this.props.onUnload();
    }
    renderCaptcha = () => {
        const { captchaDisabled, captchaLock } = this.state;
        const className = [];
        if (captchaDisabled) {
            className.push("isDisabled");
        }
        return (
            <Link
                to=""
                className={className.join(" ")}
                onClick={this.handleSendCode}
            >
                &gt;{" "}
                {captchaLock > 0 ? (
                    <span>
                        正在发送验证码（
                        {captchaLock}）
                    </span>
                ) : (
                    <span>发送验证码</span>
                )}
            </Link>
        );
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { modalVisible } = this.state; 
        return (
            <div className="mainContents">
                <TermModal
                    modalVisible={modalVisible}
                    termsType={"TermsOfLogupPage"}
                    onClose={this.closeTermModal}
                    maskClick={this.closeTermModal}
                />
                <div className="mainContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">注册用户</h1>
                    </header>
                    <div className="mainContents__box">
                        <Form onSubmit={this.handleSubmit}>
                            <div className="m-tabBox">
                                <ul className="m-tabBox__list" role="tablist">
                                    <li className="m-tabBox__list__item">
                                        <Link
                                            to=""
                                            aria-controls="tab-01"
                                            aria-selected={
                                                this.state.tabSelectedFlg ===
                                                "0"
                                                    ? true
                                                    : false
                                            }
                                            role="tab"
                                            onClick={this.changeToUser}
                                        >
                                            个人账户
                                        </Link>
                                    </li>
                                    <li className="m-tabBox__list__item">
                                        <Link
                                            to=""
                                            aria-controls="tab-02"
                                            aria-selected={
                                                this.state.tabSelectedFlg ===
                                                "1"
                                                    ? true
                                                    : false
                                            }
                                            role="tab"
                                            onClick={this.changeToCompany}
                                        >
                                            企业账户
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="m-tabBox__tabpanel-wrap">
                                <div
                                    className="m-tabBox__tabpanel"
                                    aria-hidden={
                                        this.state.tabSelectedFlg === "0"
                                            ? false
                                            : true
                                    }
                                    id="tab-01"
                                    role="tabpanel"
                                >
                                    <div className="l-formBox">
                                        <div className="l-inputTxt l-formItems">
                                            <ul className="l-inputTxt__items">
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "email",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:
                                                                            this
                                                                                .state
                                                                                .tabSelectedFlg ===
                                                                            "0"
                                                                                ? true
                                                                                : false,
                                                                        message:
                                                                            "请输入手机号/邮箱!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                placeholder="手机号 / 邮箱"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "sourceid",{initialValue:sourceid}
                                                    )(
                                                        <Input 
                                                            type="hidden"
                                                        />
                                                    )
                                                    }
                                                </FormItem>
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "identifyingCode",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:
                                                                            this
                                                                                .state
                                                                                .tabSelectedFlg ===
                                                                            "0"
                                                                                ? true
                                                                                : false,
                                                                        message:
                                                                            "请输入验证码!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                placeholder="验证码"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                            </ul>
                                            <p className="l-formBox__notelink">
                                                {this.renderCaptcha()}
                                            </p>
                                        </div>
                                        <div className="l-inputTxt l-formItems">
                                            <ul className="l-inputTxt__items">
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "password",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:
                                                                            this
                                                                                .state
                                                                                .tabSelectedFlg ===
                                                                            "0"
                                                                                ? true
                                                                                : false,
                                                                        message:
                                                                            "请输入账户密码!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                type="password"
                                                                placeholder="账户密码"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "reinputPassword",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:
                                                                            this
                                                                                .state
                                                                                .tabSelectedFlg ===
                                                                            "0"
                                                                                ? true
                                                                                : false,
                                                                        message:
                                                                            "请再次输入密码!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                type="password"
                                                                placeholder="再次输入密码"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="l-inputTxt l-formItems hidden">
                                            <ul className="l-inputTxt__items">
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "industry",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:false,
                                                                        message:
                                                                            "请输入行业!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                placeholder="行业"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                                <li className="l-inputTxt__item">
                                                    <FormItem>
                                                        {getFieldDecorator(
                                                            "interest",
                                                            {
                                                                rules: [
                                                                    {
                                                                        required:false,
                                                                        message:
                                                                            "请输入兴趣!",
                                                                    },
                                                                ],
                                                            }
                                                        )(
                                                            <Input
                                                                className="u-inputSkin01"
                                                                type="text"
                                                                placeholder="兴趣"
                                                            />
                                                        )}
                                                    </FormItem>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="l-formItems">
                                            <div className="l-checkTxt l-agreeCheck">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "userAgreementCheck",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "0"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请阅读并同意MUSINESS平台用户协议!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <label className="u-checkbox">
                                                            <Input type="checkbox" />
                                                            <span>
                                                                我已阅读并同意&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                                            <Link
                                                                to="javascript:void(0);"
                                                                onClick={this.handleModalClick.bind(
                                                                    this
                                                                )}
                                                            >
                                                                MUSINESS平台用户协议
                                                            </Link>
                                                        </label>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="m-tabBox__tabpanel"
                                aria-hidden={
                                    this.state.tabSelectedFlg === "1"
                                        ? false
                                        : true
                                }
                                id="tab-02"
                                role="tabpanel"
                            >
                                <div className="l-formBox">
                                    <div className="l-inputTxt l-formItems">
                                        <ul className="l-inputTxt__items">
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyName",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请输入企业名称!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            placeholder="企业名称"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyLicenseNumber",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请输入企业营业执照号!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            placeholder="企业营业执照号"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyEmail",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请输入手机号/邮箱!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            placeholder="手机号/邮箱"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyIdentifyingCode",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请输入验证码!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            placeholder="验证码"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                        </ul>
                                        <p className="l-formBox__notelink">
                                            {this.renderCaptcha()}
                                        </p>
                                    </div>

                                    <div className="l-inputTxt l-formItems">
                                        <ul className="l-inputTxt__items">
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyPassword",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请输入账户密码!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            type="password"
                                                            placeholder="账户密码"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "reinputCompanyPassword",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:
                                                                        this
                                                                            .state
                                                                            .tabSelectedFlg ===
                                                                        "1"
                                                                            ? true
                                                                            : false,
                                                                    message:
                                                                        "请再次输入密码!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            type="password"
                                                            placeholder="再次输入密码"
                                                        />
                                                    )}
                                                </FormItem>
                                                <input
                                                    id="userImage"
                                                    name="userImage"
                                                    type="hidden"
                                                    value=""
                                                />
                                                <input
                                                    id="tabSelectedFlg"
                                                    name="tabSelectedFlg"
                                                    type="hidden"
                                                    value="0"
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="l-inputTxt l-formItems hidden">
                                        <ul className="l-inputTxt__items">
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyIndustry",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:false,
                                                                    message:
                                                                        "请输入行业!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            placeholder="行业"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                            <li className="l-inputTxt__item">
                                                <FormItem>
                                                    {getFieldDecorator(
                                                        "companyInterest",
                                                        {
                                                            rules: [
                                                                {
                                                                    required:false,
                                                                    message:
                                                                        "请输入兴趣!",
                                                                },
                                                            ],
                                                        }
                                                    )(
                                                        <Input
                                                            className="u-inputSkin01"
                                                            type="text"
                                                            placeholder="兴趣"
                                                        />
                                                    )}
                                                </FormItem>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="l-formItems">
                                        <div className="l-checkTxt l-agreeCheck">
                                            <FormItem>
                                                {getFieldDecorator(
                                                    "companyAgreementCheck",
                                                    {
                                                        rules: [
                                                            {
                                                                required:
                                                                    this.state
                                                                        .tabSelectedFlg ===
                                                                    "1"
                                                                        ? true
                                                                        : false,
                                                                message:
                                                                    "请阅读并同意MUSINESS平台用户协议!",
                                                            },
                                                        ],
                                                    }
                                                )(
                                                    <label className="u-checkbox">
                                                        <Input type="checkbox" />
                                                        <span>
                                                            我已阅读并同意&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                        <Link
                                                            to="javascript:void(0);"
                                                            onClick={this.handleModalClick.bind(
                                                                this
                                                            )}
                                                        >
                                                            MUSINESS平台用户协议
                                                        </Link>
                                                    </label>
                                                )}
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l-formBox">
                                <p className="l-formBox__submit">
                                    <input
                                        disabled={this.props.inProgress}
                                        className="u-submitSkin is-on"
                                        type="submit"
                                        name="submit"
                                        value="立即注册"
                                    />
                                </p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const RegisterForm = Form.create()(UserRegister);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterForm);
