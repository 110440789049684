import React from 'react';
import PropTypes from 'prop-types';
import agent from 'agent';
import { connect } from 'react-redux';
import StaticWave from 'utils/staticWave';
import Download from 'components/Common/Download';
import { 
    MUSIC_PLAY,
    CHECK_PRICE_GROUP,
    SHOW_SHOP_CAR,
} from 'constants/actionTypes';
import { addFavorite, rmFavorite, showTime,HtmlUtil, addMusicStatis } from 'dispatch';
import { Link } from 'react-router-dom';
import Share from "components/Common/Share";

class MusicItem extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        type: PropTypes.string,
        page: PropTypes.string,
        playMusic: PropTypes.func.isRequired,
        audioid: PropTypes.string,
        userLogin:PropTypes.bool,
        labels: PropTypes.object.isRequired,
        musicPlay: PropTypes.object,
        showCarPopup:PropTypes.func.isRequired,
        checkPriceGroup:PropTypes.func.isRequired,
    };

    state = {
        playing: false,
        shareVisible: false,
        shareData: { url: "", title: "" },
    };
    componentDidUpdate(prevProps) {
        if (prevProps.audioid !== this.props.audioid && this.props.audioid !== this.props.data.audioid) {
            this.setState({ playing: false });
        }
    }

    favorite() {
        const { like, audioid } = this.props.data;
        if (like) {
            const { id: like_id } = like || {};
            rmFavorite({ audioid, like_id, type: this.props.type, page: this.props.page });
        } else addFavorite({ audioid, type: this.props.type, page: this.props.page });
    }

    play() {
        const {musicPlay,data}=this.props;
        const play= !!musicPlay && musicPlay.id === data.id && !!musicPlay.playing;
        const musicsta = {
            audioid:data.id,
            style:'play',
        }
        if (!play) {
            addMusicStatis(musicsta);
        }
        this.props.playMusic({ ...this.props.data, playing: !play });
    }

    handleShareClick(data) {
        const { audioid, title } = data;
        let tit=HtmlUtil.htmlDecode(title);
        this.setState({
            shareVisible: true,
            shareData: {
                url: location.origin + "/music/musicid/" + audioid,
                title:tit,
            },
        });
    }
    handleCartClick(audio) {
        console.log(222222,this.props)
        const { showCarPopup ,checkPriceGroup} = this.props;
        const send = {
            showAddCar:true,
            addAudio: audio,
        };
        checkPriceGroup(audio.price_group_id);
        showCarPopup(send);
    }
    closeShareModel() {
        this.setState({ shareVisible: false });
    }
    showTags(condition, inverted_condition,speed) {
        let show = "";
        if(condition != undefined){
            for (let key in inverted_condition) {
                let value = (inverted_condition[key] + "").split(",");
                for (let i = 0; i < condition[key].length; i++) {
                    const element = condition[key][i];
                    for (let k = 0; k < value.length; k++) {
                        const val = value[k];
                        if (element.id == val) {
                            show += element.title + " / ";
                        }
                    }
                }
            }
            if(speed){
                for(let i = 0;i<condition.speed.length;i++){
                    if(speed == condition.speed[i].id){
                        const element = condition.speed[i].title;
                        show += element+ " / ";
                    }
                    else{
                        const element ="";
                        show += element; 
                    }
                }
            }
        }
        if (!show) {
            return (
                <span>
                    &nbsp;
                    <br /> &nbsp;
                </span>
            );
        }
        return show;
    }
    render() {
        const {
            id,
            thumb,
            like,
            title,
            duration,
            audioid,
            creatorInfo,
            inverted_condition,
            speed,
            composer,
            api_id,
            minprice,
            author,
        } = this.props.data;
        const {musicPlay}=this.props;
        const play= !!musicPlay && musicPlay.id === id && !!musicPlay.playing;
        const urls="url("+agent.getApiUrl(thumb)+")";
        const labels = this.props.labels;
        const pre=99;
        console.info(this.props.data);
        const vImg=author.userid == 'IVKPCCUGYVS317TCF32BC7BE385C027F'||author.userid == 'WCIRSZ7MUOMGZ4RUE1A728276B7BD15B'||author.userid == 'XBNTVMZD2KVJ5FRK81027164D4516A4C'||author.userid == 'XBNTVMZD2KVJ5FRK81027164D4516A4C'?true:false;
        return (
            <div 
                className="resultItem musicItem v_position"
                audioid={audioid}
                id={`music-table-cell-${id}`}
            >
                
                <Share
                    url={this.state.shareData.url}
                    title={this.state.shareData.title}
                    visible={this.state.shareVisible}
                    onClose={this.closeShareModel.bind(this)}
                />
                <div className="musicItem__jacket" style={{backgroundImage: urls}}>
                </div>
                {vImg?<div className="v_img_style"> <img className="v_style" src="/assets/img/sub_v.png"/></div>:""}
                <div className="musicItem__txtBox">
                    <div className="musicItem__header">
                        <p className="musicItem__playBtn">
                            <button
                                className={'playBtn ' + (play ? 'is-play' : 'is-pause')}
                                onClick={this.play.bind(this)}
                            >
                                <svg viewBox="0 0 35 35">
                                    <g className="playIcon">
                                        <use xlinkHref="#playIcon" />
                                    </g>
                                    <g className="pauseIcon">
                                        <use xlinkHref="#pauseIcon" />
                                    </g>
                                </svg>
                            </button>
                        </p>
                        <div className="musicItem__header__inner">
                            <p className="musicItem__title">
                                <Link to={`/music/musicid/${audioid}`}>
                                    {HtmlUtil.htmlDecode(title)}
                                </Link>
                                {vImg?<img className="phone_v_style" src="/assets/img/sub_v.png"/>:""}
                            </p>
                            <p className="musicItem__name">
                                {' '}
                                {!(creatorInfo&&creatorInfo.status=='normal') ? <span>  by {HtmlUtil.htmlDecode(creatorInfo? creatorInfo.nickname:composer)}</span>:<Link to={`/makerdetail?id=${creatorInfo.id}`}>
                                    by {HtmlUtil.htmlDecode(creatorInfo.nickname)}
                                </Link>}
                            </p>
                        </div>
                        <div className="audio_price">
                            ¥{!api_id?minprice:pre}
                        </div>
                    </div>

                    <div className="musicItem__wave">
                        <div className="musicItem__wave__img">
                            <StaticWave audio={this.props.data} />
                        </div>
                        <p className="musicItem__wave__time">{showTime(duration)}</p>
                    </div>

                    <div className="musicItem__utility">
                        <p className="musicItem__utility__category">
                            {/* 显示查出来的标签 */}
                            {this.showTags(labels, inverted_condition,speed)}
                        </p>
                        <ul className="musicItem__utility__items">
                            <li className="musicItem__utility__item musicItem__utility__fav" onClick={() => this.favorite()}>
                                <button className={'favBtn' + (like ? ' is-fav' : '')}>
                                    <svg viewBox="0 0 35 35">
                                        <g className="favIcon">
                                            <use xlinkHref="#favIcon" />
                                        </g>
                                        <g className="favIconIn">
                                            <use xlinkHref="#favIconIn" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="musicItem__utility__item musicItem__utility__share">
                                <a href="javascript:;"
                                    onClick={this.handleShareClick.bind(this, {
                                        title,
                                        audioid,
                                    })}>
                                    <span>SHARE</span>
                                </a>
                            </li>
                            <li
                                className="musicItem__utility__item musicItem__utility__cart"
                                onClick={this.handleCartClick.bind(this,this.props.data)}
                            >
                                <a href="javascript:;" />
                            </li>
                            <li className="musicItem__utility__item musicItem__utility__download">
                                <Download audioid={audioid} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        audioid: state.player.audio ? state.player.audio.audioid : null,
        userLogin: state.app.userLogin,
        musicPlay: state.player.audio,
    }),
    dispatch => ({
        playMusic: musicPlay => {
            return dispatch({ type: MUSIC_PLAY, musicPlay });
        },
        showCarPopup: addAudio => {
            return dispatch({ type: SHOW_SHOP_CAR, addAudio });
        },
        checkPriceGroup:id => {
            return dispatch({
                type: CHECK_PRICE_GROUP,
                payload: agent.PriceGroup.get(id),
            });
        },
    })
)(MusicItem);
