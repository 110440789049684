import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Avatar } from "antd";
import Loading from "../Loading";
import { CURRENT_USER } from "constants/actionTypes";
import agent from "agent";
import { redirectTo } from "dispatch";

const { Content } = Layout;

const mapStateToProps = state => ({
    ...state.user,
    ...state.userProfile,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadCurrentUser: () =>
        dispatch({ type: CURRENT_USER, payload: agent.Auth.current() }),
});

class Profile extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        loadCurrentUser: PropTypes.func.isRequired,
        userLogin: PropTypes.bool.isRequired,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { loadCurrentUser, userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user again
        loadCurrentUser();
    }

    componentWillUnmount() {
        //
    }

    render() {
        const { loading, currentUser } = this.props;
        if (!currentUser && loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        return (
            <Content>
                <Loading loading={loading} />
                <div>Profile</div>
                <div>{JSON.stringify(currentUser)}</div>
                <div>
                    <Avatar
                        size={64}
                        src={agent.getApiUrl(currentUser.avatar)}
                        icon="user"
                    />
                </div>
            </Content>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
