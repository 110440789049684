import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import agent from "agent";
import { producerStatus } from "config/producerStatus";
import { SHOW_POPUP } from "constants/actionTypes"; 
import { loadCurrentUser, redirectTo, noise } from "dispatch";
import { Tooltip } from "antd";

const mapStateToProps = state => ({
    ...state.user,
    ...state.userMypage,
    ...state.updatePassword,
    userLogin: state.app.userLogin,
});
const text=<div>
    <p>通过您的分享链接在MUSINESS平台成功注册的用户，将自动获得5首黄金会员曲库内音乐的兑换权益。每注册一位用户，您将获得2首黄金会员曲库内音乐的兑换权益。快分享您的链接吧！</p></div>

const mapDispatchToProps = dispatch => ({
    showUpdatePopup: isShow => {
        return dispatch({ type: SHOW_POPUP, isShow });
    },
});
class Mypage extends React.Component {
    static propTypes = {
        isShow: PropTypes.shape({
            show: PropTypes.bool.isRequired,
        }).isRequired,
        showUpdatePopup: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
    };
    static defaultProps = {
        isShow: {
            show: false,
        },
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }
    copyurl=async()=>{ 
        var clipboard=await navigator.clipboard;
        const {currentUser } = this.props;
        var url=location.host+"/register?reference="+currentUser.userid;
        await clipboard.writeText(url)
        window.alert("分享链接已复制到剪切板，请粘贴发送给目标用户"); 
    };

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        loadCurrentUser();
    }
    handleUpdateClick() {
        const { isShow, showUpdatePopup } = this.props;
        const send = {
            ...isShow,
            show: true,
        };
        showUpdatePopup(send);
    }
    noise() {
        noise();
    }

    //会员显示
    renderChannelCell = e => {
        const {currentUser } = this.props;
        const sty=currentUser.is_member==0?{display:"none"}:{display:"block"};
        let member="会员已过期";
        if (currentUser.is_member==11) {
            member="青铜会员(月)";
        }else if (currentUser.is_member==12) {
            member="青铜会员(季)";
        }else if (currentUser.is_member==13) {
            member="青铜会员(年)";
        }else if (currentUser.is_member==21) {
            member="黄金会员(月)";
        }else if (currentUser.is_member==22) {
            member="黄金会员(季)";
        }else if (currentUser.is_member==23) {
            member="黄金会员(年)";
        }
        
        const star=currentUser.member_end==currentUser.month_end?{display:"none"}:{display:"block"};
        if (currentUser.is_member==3) {
            return  (<div className=" main-person-detail" key="111" style={{background: "url(../assets/img/m3-03.png) no-repeat #DBDBDB",backgroundPosition: "right top",backgroundSize: "80px"}}>
                <div className=" person-detail-left">
                    <div className="person-detail-ti" style={{color:"#B7B7B7"}}>会员类型：{member}</div>
                    <div className="person-detail-ti" style={{color:"#B7B7B7"}}>会员到期：{currentUser.member_end}</div>
                    <div className="person-detail-pay"><Link to="/subscribermembers" style={{cursor:"pointer",textDecoration: "none"}}>立即续订会员</Link></div>
                </div>
                <div className=" person-detail-right person-detail-right-an">
                    <div className=" detail-right-de">
                        <div className="person-detail-ti " style={{color:"#B7B7B7"}}>本月权益</div>
                        <div className="detail-right-all">
                            <div>
                                <p style={{color:"#B7B7B7"}}><img height="40" src="/assets/img/m3-01.png"/>{currentUser.use_num}</p>
                                <a style={{color:"#B7B7B7"}}>本月剩余可免费购买歌曲数量：{currentUser.use_num}首</a>
                            </div>
                            <div>
                                <p style={{color:"#B7B7B7"}}><img height="40" src="/assets/img/m3_OFF.png"/>20%</p>
                                <a style={{color:"#B7B7B7"}}>订阅外歌曲全场享8折优惠</a>
                            </div>
                        </div>
                        <div className="detail-right-else" style={star}><img height="14" src="/assets/img/star4-01.png"/>您的次月会员权益将于{currentUser.month_end}发放，如本月权益未使用完毕，视为过期作废。</div>
                    </div>
                </div>
            </div>);
        }
        return  (<div className=" main-person-detail" style={sty} key="111">
            <div className=" person-detail-left">
                <div className="person-detail-ti">会员类型：{member}</div>
                <div className="person-detail-ti">会员到期：{currentUser.member_end}</div>
                <div className="person-detail-ti">可购买歌曲数量：5首/月免费购买下载</div>
            </div>
            <div className=" person-detail-right">
                <div className=" detail-right-de">
                    <div className="person-detail-ti">本月权益</div>
                    <div className="detail-right-all">
                        <div>
                            <p><img height="40" src="/assets/img/mime3-01.png"/>{currentUser.use_num}</p>
                            <a>本月剩余可免费购买歌曲数量：{currentUser.use_num}首</a>
                        </div>
                        <div>
                            <p><img height="40" src="/assets/img/mine3_OFF.png"/>20%</p>
                            <a>订阅外歌曲全场享8折优惠</a>
                        </div>
                    </div>
                    <div className="detail-right-else" style={star}><img height="14" src="/assets/img/hongxing.png"/>您的次月会员权益将于{currentUser.month_end}发放，如本月权益未使用完毕，视为过期作废。</div>
                </div>
            </div>
        </div>);
    }
    render() {
        const { loading, currentUser } = this.props;
        if (!currentUser) {
            return null;
        }
        const isProducer = producerStatus.isProducer(currentUser.producer);
        const ur=currentUser.avatar?agent.getApiUrl(currentUser.avatar):"/assets/img/img_avatar2.jpg"
        const sty=currentUser.is_member==0?{display:"none"}:{display:"block"};
        const img_sty=currentUser.is_member==0?{height: "150px", width: "150px",margin: "auto"}:{height: "170px", width: "170px",margin: "auto"};
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">个人中心</h1>
                    </header>
                    <div className="mainContents__box maypageBox">
                        <Loading loading={loading} />
                        <div className="maypageBox__avatar" style={{paddingBottom: "50px"}}>
                            <div className="avatarBox" style={img_sty}>
                                <div className="main-person-logo">
                                    <img src={ur}/>
                                    <span style={sty}>{currentUser.is_member==3?<img src= "/assets/img/v3-16.png"/>:<img src= "/assets/img/coinV3-16.png"/>}</span>
                                </div>
                                <div className="avatarBox__name">
                                    {currentUser.nickname}
                                </div>
                            </div>
                        </div>
                        <div className="maypageBox__main">
                            {this.renderChannelCell()}
                            <div className="maypageBox__linkList">
                                <ul className="maypageBox__linkList__items">
                                    <li className="maypageBox__linkList__item">
                                        <Link
                                            to="/user/subscribe"
                                            className="m-btn-type0"
                                        >
                                            会员订阅记录
                                        </Link>
                                    </li>
                                    <li className="maypageBox__linkList__item">
                                        <Link
                                            to="/cart"
                                            className="m-btn-type0"
                                        >
                                            购物车
                                        </Link>
                                    </li>
                                    <li className="maypageBox__linkList__item">
                                        <Link
                                            to="/user/purchase"
                                            className="m-btn-type0"
                                        >
                                            音乐购买记录
                                        </Link>
                                    </li>
                                    {/* <li className="maypageBox__linkList__item">
                                        <Link to="/user/customized" className="m-btn-type0">
                                            懒人推荐记录
                                        </Link>
                                    </li> */}
                                    {/* </ul>
                                    <ul className="maypageBox__linkList__items"> */}
                                    <li className="maypageBox__linkList__item">
                                        <Link
                                            to="/favorite"
                                            className="m-btn-type1"
                                        >
                                            喜爱列表
                                        </Link>
                                    </li>
                                    <li className="maypageBox__linkList__item">
                                        <Link
                                            to="/user/updateinfo"
                                            className="m-btn-type1"
                                        >
                                            修改个人资料
                                        </Link>
                                    </li>
                                    <li className="maypageBox__linkList__item">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleUpdateClick.bind(
                                                this
                                            )}
                                            className="m-btn-type1"
                                        >
                                            修改密码
                                        </a>
                                    </li>
                                    {/* </ul>
                                    <ul className="maypageBox__linkList__items"> */}
                                    <li className="maypageBox__linkList__item">
                                        {isProducer ? (
                                            <Link
                                                to="/seller/mypage"
                                                className="m-btn-type0--wht"
                                            >
                                                卖家个人中心
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/become"
                                                className="m-btn-type0--wht"
                                            >
                                                成为卖家
                                            </Link>
                                        )}
                                    </li>
                                    <li className="maypageBox__linkList__item">
                                        <input id="copyid" type="hidden" value={agent.apiurl} />
                                        <Link 
                                            to="#"
                                            className="m-btn-type0--wht"
                                            onClick={this.copyurl}
                                            id="sharelink"
                                        > 分享链接 
                                            <Tooltip placement="bottom" trigger="hover" className="backend_color" title={text}>
                                                <span className="notice_withdraw"></span> 
                                            </Tooltip> 
                                        </Link >
                                                                              
                                    </li>
                                </ul>
                                {currentUser.userid=='FAUOKEKXXYC4WFRC08A0E8BDF01F510C'? <ul className="maypageBox__linkList__items">
                                    <li className="maypageBox__linkList__item">
                                        <a href="javascript:void(0)"
                                            onClick={this.noise.bind(this)}
                                            className="m-btn-type1"
                                        >
                                            重新生成音频文件
                                        </a>
                                    </li>
                                </ul>:""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mypage);
