import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import agent from "agent";
import { Form, Input } from "antd";
import {
    SETTLEMENT_UPDATE,
    SETTLEMENT_UPDATE_PAGE_UNLOADED,
} from "constants/actionTypes";

import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import { loadSellerProfileInfo, redirectTo } from "dispatch";

const FormItem = Form.Item;

const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: ({ bank_name, branch_name, card_no, account }) =>
        dispatch({
            type: SETTLEMENT_UPDATE,
            payload: agent.Seller.settlementUpdate({
                bank_name,
                branch_name,
                card_no,
                account,
            }),
        }),
    unloadSettlement: () => {
        return dispatch({ type: SETTLEMENT_UPDATE_PAGE_UNLOADED });
    },
});

class SettlementUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        profile: PropTypes.object,
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        updateSuccess: PropTypes.bool,

        onSubmit: PropTypes.func.isRequired,
        unloadSettlement: PropTypes.func.isRequired,
    };

    static defaultProps = {
        updateSuccess: false,
    };

    componentWillUnmount() {
        this.props.unloadSettlement();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.updateSuccess && agent.getToken()) {
            redirectTo("/seller/settlement");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user profile
        loadSellerProfileInfo();
        this.showFormItemServerError();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                this.props.onSubmit({
                    bank_name: values.bankName,
                    branch_name: values.branchName,
                    card_no: values.cardNo,
                    account: values.account,
                });
            }
        });
    };

    handleCancel = e => {
        e.preventDefault();

        redirectTo("/seller/settlement");
        return false;
    };

    showFormItemServerError() {
        const errors = this.props.errors;

        if (errors) {
            {
                Object.keys(errors).map(key => {
                    let message = errors[key];

                    switch (message.param) {
                        case "bankName":
                            this.props.form.setFields({
                                bankName: {
                                    value: message.value,
                                    errors: [new Error(message.msg)],
                                },
                            });
                            break;
                        case "branchName":
                            this.props.form.setFields({
                                branchName: {
                                    value: message.value,
                                    errors: [new Error(message.msg)],
                                },
                            });
                            break;
                        case "cardNo":
                            this.props.form.setFields({
                                cardNo: {
                                    value: message.value,
                                    errors: [new Error(message.msg)],
                                },
                            });
                            break;
                        case "account":
                            this.props.form.setFields({
                                account: {
                                    value: message.value,
                                    errors: [new Error(message.msg)],
                                },
                            });
                            break;
                    }
                });
            }
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { currentUser, profile } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }

        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox">
                            <h2 className="mymusicBox__title">我的账户</h2>
                            <div className="cashItemWrapper">
                                <div className="cashItem">
                                    <dl className="cashItem__list">
                                        <dt className="cashItem__dt">
                                            银行卡信息
                                        </dt>
                                        <dd className="cashItem__dd">
                                            {profile && profile.bank_name}{" "}
                                            {profile && profile.branch_name}
                                            <br />
                                            {profile && profile.card_no}
                                            <br />
                                            {profile && profile.account}
                                        </dd>
                                    </dl>
                                </div>

                                <div className="mymusicForm">
                                    <h2 className="mymusicBox__sTitle">
                                        {profile && profile.card_no
                                            ? "变更银行卡信息"
                                            : "登录银行卡信息"}
                                    </h2>
                                    <Form onSubmit={this.handleSubmit}>
                                        <div className="buypageForm__items">
                                            <div className="buypageForm__item">
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label>银行名称</label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <FormItem>
                                                            {getFieldDecorator(
                                                                "bankName",
                                                                {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "请输入银行名称!",
                                                                        },
                                                                    ],
                                                                    initialValue:
                                                                        profile &&
                                                                        profile.bank_name,
                                                                }
                                                            )(
                                                                <Input
                                                                    className="u-inputSkin02"
                                                                    placeholder="银行名称"
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </dd>
                                                </dl>
                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label>支行名称</label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <FormItem>
                                                            {getFieldDecorator(
                                                                "branchName",
                                                                {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "请输入支行名称!",
                                                                        },
                                                                    ],
                                                                    initialValue:
                                                                        profile &&
                                                                        profile.branch_name,
                                                                }
                                                            )(
                                                                <Input
                                                                    className="u-inputSkin02"
                                                                    placeholder="支行名称"
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </dd>
                                                </dl>

                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label>银行卡号</label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <FormItem>
                                                            {getFieldDecorator(
                                                                "cardNo",
                                                                {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "请输入银行卡号!",
                                                                        },
                                                                    ],
                                                                    initialValue:
                                                                        profile &&
                                                                        profile.card_no,
                                                                }
                                                            )(
                                                                <Input
                                                                    className="u-inputSkin02"
                                                                    placeholder="银行卡号"
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </dd>
                                                </dl>

                                                <dl className="buypageForm__dl">
                                                    <dt className="buypageForm__dt">
                                                        <label>账户名</label>
                                                    </dt>
                                                    <dd className="buypageForm__dd">
                                                        <FormItem>
                                                            {getFieldDecorator(
                                                                "account",
                                                                {
                                                                    rules: [
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "请输入账户名!",
                                                                        },
                                                                    ],
                                                                    initialValue:
                                                                        profile &&
                                                                        profile.account,
                                                                }
                                                            )(
                                                                <Input
                                                                    className="u-inputSkin02"
                                                                    placeholder="账户名"
                                                                />
                                                            )}
                                                        </FormItem>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>

                                        <ul className="form__submitList">
                                            <li className="form__submitList__item">
                                                <input
                                                    disabled={
                                                        this.props.inProgress
                                                    }
                                                    className="u-submitSkin"
                                                    type="submit"
                                                    value="确认"
                                                />
                                            </li>
                                            <li className="form__submitList__item">
                                                <input
                                                    className="u-submitSkin--gry"
                                                    type="button"
                                                    value="取消"
                                                    onClick={this.handleCancel}
                                                />
                                            </li>
                                        </ul>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const SettlementUpdateForm = Form.create()(SettlementUpdate);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettlementUpdateForm);
