import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Cropper from 'react-cropper' // 引入Cropper
import 'cropperjs/dist/cropper.css' // 引入Cropper对应的css
import './ClassCropperModal.less'
import lrz from 'lrz';

export default class ClassCropperModal extends Component {
    static propTypes = {
        uploadedImageFile: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onShowImg:PropTypes.func.isRequired,
        onThumb:PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            src: null
        }
    }

    componentDidMount() {
        const fileReader = new FileReader()
        fileReader.onload = e => {
            const dataURL = e.target.result
            this.setState({ src: dataURL })
        }

        fileReader.readAsDataURL(this.props.uploadedImageFile)
    }

    handleSubmit = () => {
        if (!this.state.submitting) {
            // let url = `/homepage_images` // 你要上传的url

            console.log('正在上传图片')
            // TODO: 这里可以尝试修改上传图片的尺寸
            this.cropper.getCroppedCanvas().toBlob(async blob => {
                const str = URL.createObjectURL(blob);
                const {uploadedImageFile}=this.props;
                lrz(str, {quality:0.2}).then((rst)=>{
                    // 处理成功会执行
                    let files = new window.File([rst.file], uploadedImageFile.name, {type: uploadedImageFile.type});
                    //把选中裁切好的的图片传出去
                    this.props.onSubmit(str);
                    this.props.onShowImg(files);
                    this.props.onThumb(files);
                    // 关闭弹窗
                    this.props.onClose()
                })
               
            })
        }
    }

    render() {
        const {onClose}=this.props
        return (
            <div className="class-cropper-modal">
                <div className="modal-panel">
                    <div className="cropper-container-container">
                        <div className="cropper-container">
                            <Cropper
                                src={this.state.src}
                                className="cropper"
                                ref={cropper => (this.cropper = cropper)}
                                // Cropper.js options
                                viewMode={1}
                                zoomable={false}
                                aspectRatio={1} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
                                guides={false}
                                preview=".cropper-preview"
                            />
                        </div>
                        <div className="preview-container">
                            <div className="cropper-preview">
                            </div>
                            
                        </div>
                        
                    </div>
                    <div className="button-row">
                        <div className="submit-button ant-btn u-submitSkin ant-btn-primary ant-btn-block img-style" style={{marginRight:"20px",marginLeft:"115px"}} onClick={this.handleSubmit}>
                            提交
                        </div>
                        <div className="u-submitSkin--gry img-style" onClick={onClose}>
                            取消
                        </div>
                        <div className="img-style" style={{marginLeft:"175px",textAlign: "center"}}>
                            预览
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
