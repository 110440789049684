import React from 'react';
import PropTypes from 'prop-types';
import agent from 'agent';
import { connect } from 'react-redux';
import { PLAYLIST_GET_INFO } from 'constants/actionTypes';
import { redirectTo } from 'dispatch';
class OtherTopic extends React.Component {
    static propTypes = {
        playlistInfos: PropTypes.array.isRequired,
        getPlaylistInfos: PropTypes.func.isRequired,
        excludes: PropTypes.array,
        getPlaylistType: PropTypes.func.isRequired,
    };

    componentDidMount() {
        if (!this.props.playlistInfos.length) this.props.getPlaylistInfos();
    }

    linkTo(id) {
        this.props.getPlaylistType({
            topicId: id,
            pager: {
                pageSize: 10,
                current: 1,
            },
        });
        redirectTo({
            pathname: location.pathname,
            search: `?id=${id}`,
        });

        this.smoothscroll();
    }

    smoothscroll() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        const excludes = new Set(this.props.excludes || []);

        return (
            <div className="bnrArea01">
                <h2 className="bnrArea01__title">更多分类主题</h2>
                <div className="m-columnBox" data-column="4" data-column-sp="2">
                    <ul className="m-columnBox__items">
                        {this.props.playlistInfos
                            .filter(x => !excludes.has(x.playlistid))
                            .map((x, i) => {
                                const { title, playlistid, thumb,weight } = x;
                                if (weight<1) {
                                    return 
                                }
                                return (
                                    <li className="m-columnBox__item" key={i} onClick={this.linkTo.bind(this, playlistid)}>
                                        <a href="javascript:;">
                                            <img src={agent.getApiUrl(thumb)} alt={title} />
                                            <p className="m-columnBox__txt">{title}</p>
                                        </a>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        playlistInfos: state.playlist.info.infos,
    }),
    dispatch => ({
        getPlaylistInfos: send =>
            dispatch({
                type: PLAYLIST_GET_INFO,
                payload: agent.PlayList.getInfo(send),
                send,
            }),
    })
)(OtherTopic);
