import React from "react";
import PropTypes from "prop-types";
import agent from "agent";

class Download extends React.Component {
    static propTypes = {
        audioid: PropTypes.string.isRequired,
        classname: PropTypes.string,
        original: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]),
    };
    render() {
        // users/audio/:audioid
        // audio/audition/:audioid
        const { audioid, original, children, classname } = this.props;
        const token = agent.getToken();
        let href = `audio/audition/${audioid}`;
        if (original && token) {
            href = `users/audio/${audioid}`;
        }
        href = agent.getApiUrl(href);
        href += `?token=${token}`;
        return (
            <a
                className={classname}
                target="_self"
                rel="noopener noreferrer"
                href={href}
                download
            >
                {children}
            </a>
        );
    }
}

export default Download;
