import React, { Component } from "react";
import StaticWave from "utils/staticWave";
import SidebarAvatar from "./SidebarAvatar";
import { connect } from "react-redux";
import agent from "agent";
import PropTypes from "prop-types";
import { PRODUCER_GET_AUDIO, MUSIC_PLAY,MUSIC_SEARCH_LABEL_LOAD_SON } from "constants/actionTypes";
import {
    statusOnMyMusic as musicStatus,
    STATUS as MUSIC_STATUS,
    musicCanUpdate,
} from "constants/music";
import { Pagination } from "antd";
import { redirectTo,showTime,HtmlUtil,addMusicStatis } from "dispatch";
import { Link } from "react-router-dom";
class MusicBlock extends Component {
    static propTypes = {
        data: PropTypes.object,
        status: PropTypes.object,
        playMusic: PropTypes.func.isRequired,
    };

    state = {
        playing: false,
    };

    play() {
        this.setState(state => ({
            playing: !state.playing,
        }));
        const musicsta = {
            audioid:this.props.data.id,
            style:'play',
        }
        if (!this.state.playing) {
            addMusicStatis(musicsta);
        }
        this.props.playMusic({
            ...this.props.data,
            playing: !this.state.playing,
        });
    }

    render() {
        const {
            title,
            duration,
            status,
            audioid,
            thumb,
            author: { nickname, username },
            creatorInfo,
            composer,
        } = this.props.data;
        let creator="";
        if (creatorInfo) {
            creator=creatorInfo.nickname;
        }
        const urls="url("+agent.getApiUrl(thumb)+")";
        return (
            <div className="mymusicItem musicItem">
                <div className="musicItem__jacket" style={{backgroundImage: urls,width: "10.52632%"}}>
                </div>
                <div className="musicItem__txtBox">
                    <div className="musicItem__header">
                        <p className="musicItem__playBtn">
                            <button
                                className={
                                    "playBtn " +
                                    (this.state.playing
                                        ? "is-play"
                                        : "is-pause")
                                }
                                onClick={this.play.bind(this)}
                            >
                                <svg viewBox="0 0 35 35">
                                    <g className="playIcon">
                                        <use xlinkHref="#playIcon" />
                                    </g>
                                    <g className="pauseIcon">
                                        <use xlinkHref="#pauseIcon" />
                                    </g>
                                </svg>
                            </button>
                        </p>
                        <div className="musicItem__header__inner">
                            <p className="musicItem__title">{HtmlUtil.htmlDecode(title)}</p>
                            <p className="musicItem__name">
                                by {HtmlUtil.htmlDecode(creator||composer||nickname||username)}
                            </p>
                        </div>
                    </div>
                    
                    <div className="musicItem__wave">
                        <StaticWave audio={this.props.data}/>
                        <p className="musicItem__wave__time">{showTime(duration)}</p>
                    </div>
                </div>
                <div className="musicItem__statusNav">
                    <ul>
                        <li className="musicItem__statusNav__item">
                            <span
                                className={
                                    status !== "reject"
                                        ? "u-Color--grn"
                                        : "u-Color--red"
                                }
                            >
                                {this.props.status[status]}
                            </span>
                        </li>
                    </ul>
                </div>
                {musicCanUpdate.has(status) && (
                    <p className="musicItem_editBtn">
                        <a
                            href="javascript:;"
                            className="editBtn"
                            onClick={() =>
                                redirectTo(`/seller/uploadmusic?id=${audioid}`)
                            }
                        />
                    </p>
                )}
            </div>
        );
    }
}

const MusicBlockConnected = connect(
    state => ({}),
    dispatch => ({
        playMusic: musicPlay => {
            return dispatch({ type: MUSIC_PLAY, musicPlay });
        },
    })
)(MusicBlock);

class MusicTab extends Component {
    static propTypes = {
        data: PropTypes.array,
        onChange: PropTypes.func,
        default: PropTypes.string,
    };

    select(value) {
        this.props.onChange(value);
    }

    render() {
        const lis = this.props.data.map((x, i) => {
            const { label, total, value } = x;
            const active = value === this.props.default;

            return (
                <li
                    className="m-tabBox__list__item"
                    key={i}
                    onClick={this.select.bind(this, value)}
                >
                    <a
                        aria-controls="tab-01"
                        aria-selected={active ? "true" : "false"}
                        href={"#mymusic"}
                        role="tab"
                    >
                        {label}({total})
                    </a>
                </li>
            );
        });

        return (
            <ul className="m-tabBox__list" role="tablist">
                {lis}
            </ul>
        );
    }
}

class MyMusicPage extends Component {
    static propTypes = {
        getAudio: PropTypes.func,
        music: PropTypes.object,
    };

    state = {
        tabs: {
            all: {
                label: "全部",
                total: 0,
            },
            [MUSIC_STATUS.UNREVIEWED]: {
                label: musicStatus[MUSIC_STATUS.UNREVIEWED],
                total: 0,
            },
            [MUSIC_STATUS.NORMAL]: {
                label: musicStatus[MUSIC_STATUS.NORMAL],
                total: 0,
            },
            [MUSIC_STATUS.REJECT]: {
                label: musicStatus[MUSIC_STATUS.REJECT],
                total: 0,
            },
        },
        selectedTab: "all",
    };

    constructor(props) {
        super(props);
        const tabValues = Object.keys(this.state.tabs);

        tabValues.map(value => {
            this.getAudio(null, value);
        });
    }

    getAudio(page, value) {
        const { current, pageSize } = this.props.music[value].pagination;
        this.props.getAudio(
            { pager: { current: page || current, pageSize }, status: value },
            () => {
                this.setState({
                    tabs: {
                        ...this.state.tabs,
                        [value]: {
                            ...this.state.tabs[value],
                            total: this.props.music[value].total,
                        },
                    },
                });
            }
        );
    }

    getTabs() {
        const tabValues = Object.keys(this.state.tabs);
        const tabs = [];
        tabValues.map(value => {
            const { label, total } = this.state.tabs[value];
            tabs.push({
                value,
                label,
                total,
            });
        });

        return tabs;
    }

    onTabChange(value) {
        this.setState({ selectedTab: value });
    }

    onPageChange(page) {
        this.getAudio(page, this.state.selectedTab);
    }
    render() {
        const musicBlocks = this.props.music[this.state.selectedTab].list.map(
            (x, i) => {
                return (
                    <MusicBlockConnected
                        status={musicStatus}
                        data={x}
                        key={i}
                    />
                );
            }
        );

        const { total, pagination } = this.props.music[this.state.selectedTab];

        //console.log(this.props.music, this.state.selectedTab);

        const { pageSize, current } = pagination;

        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">卖家个人中心</h1>
                    </header>
                    <SidebarAvatar />

                    <div className="mainBox02">
                        <div className="mymusicBox">
                            <h2 id="mymusic" className="mymusicBox__title">
                                我发布的音乐
                            </h2>
                            <p className="mymusicBox__postBtn">
                                <Link to="/seller/uploadmusic">发布音乐</Link>
                            </p>
                            <div className="mymusicBox__tab m-tabBox3 m-tabBox--s">
                                <div className="m-tabBox__tabpanel-wrap">
                                    <div
                                        className="m-tabBox__tabpanel"
                                        aria-hidden="false"
                                        id="tab-01"
                                        role="tabpanel"
                                    >
                                        <MusicTab
                                            default={this.state.selectedTab}
                                            data={this.getTabs()}
                                            onChange={this.onTabChange.bind(
                                                this
                                            )}
                                        />
                                        <div className="mymusicItemWrapper">
                                            {musicBlocks}
                                        </div>
                                       
                                        <div className="c02b478-music-table">
                                            <div className="resultPagination " style={{textAlign:"center"}}>
                                                <Pagination
                                                    defaultPageSize={pageSize}
                                                    defaultCurrent={1}
                                                    current={current}
                                                    total={total}
                                                    hideOnSinglePage={true}
                                                    onChange={this.onPageChange.bind(
                                                        this
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        music: state.mymusic,
    }),
    dispatch => ({
        getAudio: (send, callback) => {
            return dispatch({
                type: PRODUCER_GET_AUDIO,
                payload: agent.Producer.getAudio(send),
                send,
                callback,
            });
        },
        loadLabel: () => {
            return dispatch({
                type: MUSIC_SEARCH_LABEL_LOAD_SON,
                payload: agent.Music.searchLabelAll(),
            });
        },
    })
)(MyMusicPage);
