import React from "react";
//import PropTypes from "prop-types";
//import data from "components/Common/about_us_text";

export default class AboutusPage extends React.Component {
    render() {
        return (
            <div className="mypageContents aboutContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">MUSINESS平台用户协议</h1>
                    </header>
                    <div className="aboutBox">
                        <div className="aboutBox__inner">
                            <p>&nbsp;</p>
                            <ol>
                                <li>1.1 欢迎您注册成为MUSINESS商用音乐聚合与变现平台（以下简称&ldquo;本平台&rdquo;）的用户！MUSINESS旨在建立一个互联网商用正版音乐授权平台，是一个为用户提供交易音乐服务的平台，用户可以通过线上平台完成项目交易、购买和出售产品以及使用我们提供的其他相关服务。买方和卖方要使用前述服务必须在网站上注册账号。</li>
                            </ol>
                            <p>&nbsp;</p>
                            <p>特别提示：本协议由用户与上海小仙虎文化传媒有限公司共同缔结，具有相应的法律效力。本协议中上海小仙虎文化传媒有限公司称为&ldquo;MUSINESS平台&rdquo;或&ldquo;MUSINESS&rdquo;，协议签约的另一方称为&ldquo;用户&rdquo;或&ldquo;您&rdquo;。在使用MUSINESS服务之前，您应当认真阅读并遵守《MUSINESS平台用户协议》（以下简称&ldquo;本协议&rdquo;），请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。如您对协议有任何疑问的，应向MUSINESS客服咨询。</p>
                            <p>当您在注册程序过程中在&ldquo;已阅读并同意《MUSINESS平台用户协议》&rdquo;处打勾&ldquo;&radic;&rdquo;并按照注册程序成功注册为MUSINESS用户即表示您完全接受本协议项下的全部条款，或您以其他MUSINESS允许的方式实际使用MUSINESS服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与MUSINESS达成协议。您承诺接受并遵守本协议的约定， 届时您不应以未阅读本协议的内容或者未获得MUSINESS对您问询的解答等理由，主张本协议无效或本协议中的某些条款无效，或不遵守本协议约定。</p>
                            <p>&nbsp;</p>
                            <p>名词解释</p>
                            <p>&ldquo;账号&rdquo;指的是您在本网站开设并使用的账号。</p>
                            <p>&ldquo;用户&rdquo;&ldquo;客户&rdquo;&ldquo;会员&rdquo;&ldquo;您&rdquo;指的是访问或使用本网站的自然人、法人或其他组织。</p>
                            <p>&ldquo;卖方&rdquo;&ldquo;卖家&rdquo;指的是通过本网站提供服务或作品的用户。</p>
                            <p>&ldquo;买方&rdquo;&ldquo;买家&rdquo;指的是希望或已经通过本网站从卖方手中购买卖方服务或作品的用户。</p>
                            <p>&ldquo;正版商业授权&rdquo;指的是用户将享有完整版权的作品上传至MUSINESS平台并授权MUSINESS平台其他用户购买下载版权作品用于且仅能用于一定范围的商业用途。上传或下载版权作品，均须遵循MUSINESS平台的规定，MUSINESS不对版权作品的权属负任何责任。</p>
                            <p>&ldquo;非独家销售&rdquo;是指用户将其享受完整版权的作品，既可以在MUSINESS平台www.musiness.vip及MUSINESS指定的合作网站进行销售，同时可再发布于其它网站进行有偿网络销售。卖方可以通过与MUSINESS签订《卖家协议》，约定权利义务。</p>
                            <p>&ldquo;MUSINESS&rdquo;&ldquo;我们&rdquo;指的是上海小仙虎文化传媒有限公司。</p>
                            <p>&ldquo;本网站&rdquo;&ldquo;MUSINESS平台&rdquo;指的是由上海小仙虎文化传媒有限公司运作的网站，访问网址为：www.musiness.vip，以及相关的其他MUSINESS服务、工具或应用。</p>
                            <p>&ldquo;案&rdquo;&ldquo;案子&rdquo;&ldquo;项目&rdquo;指的是由买方通过MUSINESS平台提供给卖方的工作项目或任务。</p>
                            <p>&ldquo;平台服务费&rdquo;指的是买方通过本网站提交项目需求且在需求交易成功后须向MUSINESS支付的费用。</p>
                            <p>&ldquo;项目分成费&rdquo;指的是卖方通过本网站获得项目时或者作品授权交易成功后需向MUSINESS支付的一定比例的收益分成。</p>
                            <p>&ldquo;托管支付&rdquo;指的是买方将项目所涉款项打入MUSINESS指定账户后，MUSINESS依据双方的指示将相关款项支付予卖方的行为。</p>
                            <p>&ldquo;知识产权&rdquo;指的是在世界范围内存在的部分或所有知识产权以及上述权利指涉的客体，包括但不限于：（1）专利、著作权、商标以及其他对具有商业价值的信息进行保密的权利；以及（2）就（1）项下内容进行申请登记的权利，不论上述内容是否已发表、已经登记或能够获得登记或者已在任何法律体系下实际存在。</p>
                            <p>&ldquo;完整版权&rdquo;指卖方拥有售卖作品的所有权利，并可对作品进行合法授权。</p>
                            <p>&ldquo;用户合同&rdquo;指的是（1）本用户协议；（2）本网站合理修改的行为准则；（3）卖方和买方都接受并上传到本网站的其他任何约定条款，违反用户协议和行为准则的除外；以及（4）项目中所包含的，且经本网站同意的条款，违反用户协议和行为准则的除外。</p>
                            <ol>
                                <li>2  协议范围</li>
                                <li>2.1 本协议由您与MUSINESS共同缔结，本协议具有合同效力。</li>
                                <li>2.2 除另行明确声明外，MUSINESS服务包含任何MUSINESS提供的基于互联网以及移动网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册/激活程序或停止使用MUSINESS服务。</li>
                                <li>2.3 本协议内容包括协议正文、法律声明及所有MUSINESS已经发布或将来可能发布的各类规则、公告或通知。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。</li>
                                <li>2.4 MUSINESS有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行变更公告，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用MUSINESS服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用MUSINESS服务。</li>
                                <li>2.5 本平台保留随时修改或中断服务而不需通知用户的权利。本平台行使修改或中断服务的权利，不需对用户或第三方负责。</li>
                                <li>2.6 本平台有权基于其认定的事实对用户的行为进行判断，并有权根据网站规则协议执行对违规违法行为进行惩处。本网站用户在适用本规则上一律平等。用户在本网站上传、下载、交易等行为，应当遵循平等、自愿、诚实、信用原则。</li>
                                <li>2.7 本网站用户必须是具有完全民事行为能力的自然人，或者是具有合法经营资格的实体组织，并就所提供的服务内容和范围具备法律、法规、行业规章规定的相应资质。无民事行为能力、限制民事行为能力人以及无经营或特定经营资格的组织不得注册为本网站用户或超过其民事权利或行为能力范围在本网站进行交易；否则，本网站有权采取取消订单、冻结或关闭账户、拒绝提供服务等措施。用户因不具备行为能力或缺少相应资质给任何第三方造成损失的，本网站不承担责任；用户给本网站及相关方造成损失的，还应承担赔偿责任。本网站只接受与身份证相符的真实姓名或与企业营业执照相符的法定代表人姓名进行注册，注册人（自然人）对通过该账户实施的任何行为承担全部责任。本网站保留拒绝特定个人或实体在本网站注册的权利。</li>
                                <li>2.8 本网站用户在本网站上的全部行为仅代表其自身，不代表本网站，基于本网站用户行为的所有法律责任均由用户自行承担。</li>
                                <li>2.9 用户应遵守国家法律、行政法规、部门规章、规范性文件等规定。对任何涉嫌违反国家法律、行政法规、部门规章、规范性文件等行为，本规则已有规定的，适用本规则；本规则尚无规定的，MUSINESS有权根据法律规定酌情处理。但MUSINESS对用户的任何处理措施均不能免除用户自身应负的法律责任。</li>
                                <li>2.10 MUSINESS平台仅是网站服务提供平台，不对MUSINESS用户在MUSINESS平台上的所有操作、行为负责，MUSINESS对MUSINESS平台上由用户自行上传、自行下载的作品不享有任何版权，用户间的分享、交流等行为均与MUSINESS无关。</li>
                                <li>3  用户注册与账号</li>
                                <li>3.1 用户注册是指用户登录MUSINESS平台，并按要求填写相关信息并确认同意履行相关用户协议的过程。</li>
                                <li>3.2 符合下列条件之一的自然人、法人或其他组织可以申请成为本网站会员，可以使用本网站的服务。
                                    <ol>
                                        <li>3.2.1 根据中国法律年满18周岁或16周岁以上不满18周岁但以自己的劳动收入为主要生活来源的，具备完全民事行为能力的自然人或根据其本国法律具有完全民事行为能力的外国自然人。</li>
                                        <li>3.2.2 无民事行为能力人、限制民事行为能力人应经过其监护人代理注册。</li>
                                        <li>3.2.3 根据中国或外国法律、法规、行政规章成立并合法存在的机关、企事业单位、社团组织和其他组织。未经批准设立的组织不能注册为MUSINESS平台用户。</li>
                                    </ol>
                                </li>
                                <li>3.3 不当注册为MUSINESS平台用户或超过其民事权利或行为能力范围从事交易的，其与MUSINESS之间的服务协议自始无效，一经发现，MUSINESS平台有权立即注销该用户，并追究其使用MUSINESS平台&ldquo;服务&rdquo;的一切法律责任。</li>
                                <li>3.4 MUSINESS平台账号（即MUSINESS平台用户ID）的所有权归MUSINESS，用户完成注册申请手续后，获得MUSINESS平台账号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，MUSINESS不负任何责任。用户应当对其账号中的所有活动和事件负全责。</li>
                                <li>3.5 用户需妥善保管自己注册的MUSINESS平台账号与密码，禁止用户将其账号、密码透露、泄露、对外公布或以转让或出借等方式提供给他人使用，如用户发现其账号遭他人非法使用，应立即通知MUSINESS。因黑客行为或用户的保管疏忽或不当导致账号、密码遭他人非法使用，由用户或非法使用人承担责任，MUSINESS不承担任何责任。</li>
                                <li>3.6 注销账户</li>
                            </ol>
                            <p>在同时满足以下条件的前提下，发送邮件至support@musiness.vip，您可以申请注销您的账户：</p>
                            <ol>
                                <li>（1） 没有任何通过本网站对接且未完成的项目；以及</li>
                                <li>（2） 没有任何未解决事宜（如您的账户被冻结或限制）；以及</li>
                                <li>（3） 没有任何未支付的价款；以及</li>
                                <li>（4） 没有其他本网站认为可能存在的安全、法律隐患。</li>
                                <li>3.7 账户安全</li>
                            </ol>
                            <p>若您发现或知道任何破坏或威胁网站安全的行为，您应当在第一时间通知我们并尽可能采取一切措施降低上述侵害（包括保存证据和通知有权机关）。您对您账户的密码负担安全保护责任。若您未能采取合理措施保护您的账户安全，由此产生的一切损失由您自己承担。</p>
                            <ol>
                                <li>4  用户的权利和义务</li>
                                <li>4.1 用户有权根据本协议及本网站发布的相关规则，利用本网站发布信息、查询会员公开信息，在本网站社区及相关模块发布信息，参加本网站的有关活动及有权享受本网站提供的其他有关资讯及信息服务。</li>
                                <li>4.2 用户注册用户名时请勿使用敏感词汇（包括但不限于：不雅词汇、涉及法律法规等禁止词汇以及含有MUSINESS、奕颗贝壳、一颗贝壳、版主、管理员、网站等词汇），MUSINESS有权不向此类账户提供服务。</li>
                                <li>4.3 用户承诺在使用MUSINESS平台提供的服务时遵守包括但不限于有关著作权、计算机及互联网规定的法律和法规、实施办法等。在任何情况下，本平台合理地认为用户的行为可能违反上述法律、法规时，可以在任何时候不经事先通知终止向该用户提供服务。用户应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。</li>
                                <li>4.4 用户承诺对其上传于MUSINESS平台的所有内容（由《中华人民共和国著作权法》以及我国加入的国际公约规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等）均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成MUSINESS被第三人索赔的，用户同意全额补偿MUSINESS为此支出的一切费用和损失（包括但不限于各种赔偿费用和律师费、调查费用、鉴定费用）。</li>
                                <li>4.5 当第三方认为用户上传于MUSINESS平台的内容侵犯其权利，并根据相关法律法规的等规定向MUSINESS发送权利通知书时，用户同意MUSINESS可以自行判断决定删除涉嫌侵权的用户上传或者发表于MUSINESS平台的内容，除非收到符合相关法律规定的书面说明并经审查初步认定抗辩成立，MUSINESS将不会自动恢复上述删除的信息。</li>
                                <li>4.6 无论是用户原创或是用户得到著作权人授权的作品，基于宣传推广目的，会员授权MUSINESS使用作品于MUSINESS平台或与MUSINESS有关的业务活动中，MUSINESS不再另行向会员支付使用费。</li>
                                <li>4.7 用户在使用本网站上传或下载过程中如与其他用户产生纠纷，可以请求本网站从中予以协调处理。用户如发现其他用户有违法或违反本协议的行为，可以向本网站举报。</li>
                                <li>4.8 用户应当自行承担因上传、下载、交易产生或取得的相关费用，并依法纳税。</li>
                                <li>4.9 不得为任何非法目的，利用MUSINESS平台服务进行任何非法活动或对互联网的正常运转可能造成不利影响的行为；</li>
                                <li>4.10 不得利用MUSINESS平台服务传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</li>
                                <li>4.11 用户单独对传输内容承担责任，具体包括：</li>
                                <li>（1） 从中国境内向外传输技术性资料时必须符合中国有关法规。</li>
                                <li>（2） 使用本平台服务不作非法用途。</li>
                                <li>（3） 不干扰或混乱网络服务。</li>
                                <li>（4） 不在本平台留言发表任何与政治相关的信息。</li>
                                <li>（5） 遵守所有使用网站服务的网络协议、规定、程序和惯例。</li>
                                <li>（6） 不得利用本平台危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益。</li>
                                <li>（7） 不得未经许可而非法进入其它电脑系统。</li>
                            </ol>
                            <p>若用户的行为不符合以上任何条款，本平台将采取关闭用户服务账号、终止服务、取消账号、追究法律责任等措施。本平台保留删除各类不符合法律政策或不真实信息内容而无须通知用户的权利。</p>
                            <ol>
                                <li>4.12 不得利用MUSINESS平台服务进行任何不利于MUSINESS或损害MUSINESS利益的行为；</li>
                                <li>4.13 无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本平台上的信息或由本平台链接的信息，或其他与本平台链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）。</li>
                                <li>4.14 如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通告MUSINESS平台站方。</li>
                                <li>4.15 当使用本网站时，您承诺不实施以下行为：</li>
                                <li>（1） 本网站中不恰当的类别或板块下发布内容；</li>
                                <li>（2） 任何违反任何法律法规规定、或本网站规定、或侵犯第三方合法权利的行为；</li>
                                <li>（3） 除非卖方未合理履行提供涉及服务的义务，拒绝或不合理地拖延为卖方所提供的服务支付相应价款的行为；</li>
                                <li>（4） 规避或操纵本网站的收费结构、计费程序或欠逃对本网站的应付价款；</li>
                                <li>（5） 发布错误的、不准确的、误导性的、诽谤性的或攻击性内容（包括个人信息）；</li>
                                <li>（6） 采取任何可能破坏网站反馈或评价系统的行为（如将网站反馈或评价信息导出、展示或用于本网站目的之外的其他用途）；</li>
                                <li>（7） 以任何形式擅自转让或授权他人使用自己在本网站的账户；</li>
                                <li>（8） 在本网站发布各类违法或违规信息；</li>
                                <li>（9） 以干预或操纵物品价格等不正当竞争方式扰乱网上交易秩序，从事与网上交易无关的不当行为，在交易平台上发布任何违法信息；</li>
                                <li>（10） 采取不正当手段（包括但不限于虚假交易、互换好评等方式）提高自身或他人信用度，或采用不正当手段恶意评价其他用户，降低其他用户信用度；</li>
                                <li>（11） 将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序资料，加以上载、发布、发送电子邮件或以其他方式传送，干扰或破坏本网站和服务或与本网站和服务相连的服务器和网络；</li>
                                <li>（12） 未经本网站事先书面授权，&ldquo;架构&rdquo;&ldquo;模仿&rdquo;或将本网站的任何部分内容展示或用于第三方网站；</li>
                                <li>（13） 未经本网站书面允许，将本网站资料以及在交易平台上所展示的任何信息以复制、修改、翻译等形式制作衍生作品、传播或公开展示或对和本网站相关的任何软件实施逆向工程；</li>
                                <li>（14） 侵犯或者擅自使用任何来自本网站的权利、内容或本网站享有知识产权的标志等；</li>
                                <li>（15） 未经用户同意，收集、散播在服务过程中收集的用户的受保密义务保护的信息。</li>
                            </ol>
                            <p>为避免可能引起的争议，MUSINESS平台对用户的每一步操作都将进行记录并制成操作日志，该日志仅用于内部存档供日后双方引起争议后进行查阅，不用于任何商业或除前述之外的其他用途。</p>
                            <ol>
                                <li>4.16 网站内容
                                    <ol>
                                        <li>4.16.1 您完全了解并同意：</li>
                                        <li>（1） 本网站仅仅是为用户内容提供线上传播和发布平台的门户网站。我们不保证您能够通过本网站获取特定的用户内容。我们有权利（但不负有义务）针对您提供的内容采取我们认为恰当的任何措施；</li>
                                        <li>（2） 我们对于内容的删除、无法储存内容或无法提供内容等情形不承担任何的责任；</li>
                                    </ol>
                                </li>
                            </ol>
                            <ul>
                                <li>（3） 您所提交的部分或全部内容都可能由于未获得本网站的同意而无法在本网站发布，但本网站不具有审查所有内容的义务。本网站有权自行决定对用户内容进行同意、修改或驳回；以及</li>
                            </ul>
                            <ol>
                                <li>（4） 因不可归责于本网站的原因（如第三人恶意攻击、黑客攻击、第三人侵犯知识产权等）给您造成损失的，本网站不承担责任。</li>
                                <li>4.16.2 您承诺您所提供的内容：</li>
                                <li>（1） 符合您与相对方约定的标准、质量、时间；</li>
                                <li>（2） 不会侵犯第三方的著作权、专利权、商标、商业秘密或其他知识产权或所有权、名誉权等其他合法权利；</li>
                            </ol>
                            <ul>
                                <li>（3） 不会违反任何法律或法规；</li>
                            </ul>
                            <ol>
                                <li>（4） 不包含淫秽色情内容；</li>
                                <li>（5） 与恐怖活动无关；</li>
                                <li>（6） 不包含关于用户或第三人的错误或不准确的信息；以及</li>
                            </ol>
                            <ul>
                                <li>（7） 不包含任何意在毁坏、有意干扰、秘密截取或侵占获取任何数据或个人信息的病毒、代码或其他电脑程序。</li>
                            </ul>
                            <p><strong><strong>您知道并同意本网站可以将您的个人信息披露给相关公司，且您的个人信息将可能披露给中华人民共和国境外的第三人。如果您不同意此条款或希望撤回同意，您知道并同意我们将可能无法通过本网站为您提供服务并可能关闭您的账户，同意本用户协议意味着您对本条款的知道与同意。</strong></strong></p>
                            <p>本网站可能包含法律、金融等信息。上述信息不是、也不应当被认为是本网站提供的意见或建议。您也不得将上述信息视作相应领域的专业意见。如果您有任何涉及某些专业领域的具体问题，您应当咨询该领域的专业人员。</p>
                            <p>本网站所呈现的信息中包含第三方提供的内容，包括但不限于用户反馈和包含某些内容或意见及其原始文章（或上述第三方内容的链接）。本网站仅为呈现上述内容的平台和门户网站，且不对任何基于或关于上述第三方内容的内容承担任何责任，包括但不限于违反知识产权及其相关法律、侮辱诽谤、侵犯隐私、涉及淫秽色情或其他任何法律领域所可能包含的责任。</p>
                            <p>本网站可能包含连接到第三方网站的链接。我们对上述第三方网站不具有任何的控制权；且我们不支持上述第三方网站中的内容、产品、服务、行为、规定或运作。使用上述来自第三方网站的内容或该第三方网站的链接所产生的所有风险都由使用者自身承担。</p>
                            <p>本网站可以将您公司或商业名称、logo、图案或其他媒体及公众对您项目和账户的描述用于本网站服务和／或其他与本网站相关的市场营销资料中，除非您提前明确向我们提出拒绝上述形式的请求且我们接受了该请求。为避免双方有可能产生的争议，本网站对各阶段上传的作品进行保存，该保存的文件仅用于内部存档供日后双方引起争议后进行查阅，不用于任何商业或除前述之外的其他用途。</p>
                            <ol>
                                <li>4.17 个人主页使用</li>
                            </ol>
                            <p>MUSINESS平台个人主页是MUSINESS免费提供给用户展示作品的服务，您无权实施包括但不限于下列行为：</p>
                            <ol>
                                <li>（1） 不得在&ldquo;个人主页&rdquo;以任何形式发布广告信息，进行推广、宣传个人或他人网站、产品等。</li>
                                <li>（2） 不得在&ldquo;个人主页&rdquo;提供个人联系方式，QQ、MSN、微信、E-mail、电话等。包括不允许在&ldquo;个人主页&rdquo;提供即时通讯，如QQ交谈等。</li>
                                <li>（3） 不得对&ldquo;个人主页&rdquo;进行反向工程、反向汇编、反向编译等。</li>
                                <li>（4） 对于&ldquo;个人主页&rdquo;相关信息等，未经MUSINESS书面同意，用户不得擅自实施包括但不限于下列行为：使用、复制、修改、链接、转载、发表、出版，建立镜像站点、擅自借助&ldquo;软件&rdquo;发展与之有关的衍生产品、作品。</li>
                                <li>（5） 利用&ldquo;个人主页&rdquo;发表、传送、传播、储存危害国家安全、祖国统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容。</li>
                                <li>（6） 利用&ldquo;个人主页&rdquo;发表、传送、传播、储存侵害他人知识产权、商业机密权等合法权利的内容。</li>
                                <li>4.18 网站广告
                                    <ol>
                                        <li>18.1 除非您提前与我们达成协议，您不得在本网站为第三方网站、产品或服务发布广告信息。任何在本网站发布的网页地址链接必须与项目、产品、用户或通过本网站提供的服务相关。</li>
                                        <li>18.2 本网站可以在网站平台上展示赞助商的广告和促销信息。您知道并同意本网站不对基于上述广告／促销信息或您与上述赞助商进行交易而导致的任何损失承担责任。此外，您了解并同意赞助商的广告和促销信息的内容是受到著作权、商标、专利或其他权利法律保护的。除非获得我们或第三方权利主体的明确授权，您同意不对基于上述广告／促销信息的内容进行修改、出售、传播、持有或制作演绎作品。</li>
                                    </ol>
                                </li>
                                <li>4.19 反馈
                                    <ol>
                                        <li>4.19.1 您知道并同意将通过本网站发布的反馈意见、评论或其他任何构成评价性意见或排名信息的著作权转让予本网站。您知道以上信息为本网站所独有，尽管本网站允许您在作为网站用户的期间使用上述信息。禁止在未经本网站事先同意的情况下，将上述信息以任何违反本网站规定的形式使用或进行交易。</li>
                                        <li>4.19.2 您不能故意或过失地进行任何使得或可能破坏本网站反馈系统的行为。若发现上述行为，本网站有权决定对您的账户采取暂停或取消等的措施。</li>
                                        <li>4.19.3 本网站的反馈系统及反馈系统上的信息属于本网站所有，其不得被用于本网站运行之外的其他任何目的（包括但不限于推广或披露任何关于您的综合评级或反馈意见）。未经本网站的书面许可，您不得在除本网站之外的任何真实或虚拟平台使用买方或卖方的反馈，除非该平台由MUSINESS运作。</li>
                                        <li>5  MUSINESS的权利和义务</li>
                                    </ol>
                                </li>
                                <li>5.1 MUSINESS平台承担有限责任，对任何直接、间接、偶然、特殊及继起的不可归责于MUSINESS的损害不负责任。</li>
                                <li>5.2 MUSINESS平台设置的各种积分制度是为了调动网站用户的参与积极性，MUSINESS平台有权随时修改积分制度，调整用户的相关积分属性，未经MUSINESS许可用户禁止以任何形式出售或转让积分。</li>
                                <li>5.3 MUSINESS平台有权在MUSINESS平台的任意网页位置、任意时间投放各种广告。</li>
                                <li>5.4 MUSINESS平台对任何在本平台发布信息或使用信息所带来的风险不承担任何责任。访问和使用本平台者应自行判断相关信息的准确性、完整性和合法性，并同意自行承担访问和使用相关信息可能出现的全部风险。对您使用本平台、与本平台相关的任何内容、服务或其它链接至本平台的站点、内容均不作直接、间接、法定、约定的保证。</li>
                                <li>5.5 MUSINESS平台有权不展示MUSINESS认为不适合展示、出售的作品。</li>
                                <li>5.6 用户交易过程中关于授权作品的格式、大小等文件属性的纠纷（不包括作品的版权、肖像权、物权等专有权利的纠纷），在对交易双方进行协商并核查作品后，MUSINESS有权对交易有效性、纠纷责任进行初步判定。</li>
                                <li>5.7 卖家若违反法律、法规及MUSINESS规定，与作品购买者达成作品使用权授权协议，MUSINESS将终止对该卖家提供服务，收回该卖家在MUSINESS平台注册的所有ID，并将卖家所有注册账号内积分等全部清零、注销作为处罚。</li>
                                <li>5.8 当第三方向MUSINESS提出权利通知主张MUSINESS用户上传的作品侵犯其权益，并向MUSINESS出示经MUSINESS审查认为充分的权属证明时，MUSINESS可以自行决定删除涉嫌侵权的作品。如因需要提供司法协助的情况下，MUSINESS可以将涉嫌侵权的上传者的资料提供给该第三方。</li>
                                <li>5.9 MUSINESS平台为MUSINESS卖家用户提供代收或代付款项等服务。代收代付款项服务是指MUSINESS为MUSINESS用户提供的代为收取或支付相关款项的服务, 其中：
                                    <ol>
                                        <li>5.9.1 代收, 即MUSINESS代为收取第三方向MUSINESS用户支付的各类款项。为免疑义, 代收具体是指在符合MUSINESS规定的情况下, 自用户根据本协议委托MUSINESS将用户银行卡内的资金充值到用户的MUSINESS账户或委托MUSINESS代为收取第三方向用户支付的款项之时起至根据用户的指令将该等款项的全部或部分实际划付到用户指定的银行账户之时止。</li>
                                        <li>5.9.2 代付, 即MUSINESS将用户的款项代为支付给用户指定的第三方。为免疑义, 代付具体是指在符合MUSINESS规定的情况下，自款项从用户的账户转出之时起至委托MUSINESS根据用户或有权方给出的指令将上述款项的全部或部分支付给第三方且第三方收到该款项（无论是否要求立即支付或根据届时情况不时支付）之时止的整个过程。用户同意, MUSINESS受用户指令代付款项后, 非经法律程序或者非依本协议之约定, 该支付是不可撤销的。</li>
                                        <li>5.9.3 代管：您可以使用本服务通过您本人有效的支付宝、财付通账号或其他经MUSINESS指定的银行账户向您的MUSINESS账户充值，并委托MUSINESS代为保管款项。</li>
                                        <li>5.9.4 用户确认：MUSINESS提供的代收、代付、代管款项服务，完全是根据MUSINESS用户指令进行服务。MUSINESS代收、代付、代管的款项，均是用户授权交易作品所得费用，用户是代收、代付、代管款项下交易的当事人，MUSINESS不对代收代付款项的风险承担任何责任。</li>
                                        <li>6  服务的变更、中断或终止</li>
                                    </ol>
                                </li>
                                <li><strong><strong>5.1</strong></strong> <strong><u><strong>鉴于网络服务的特殊性，用户同意MUSINESS有权随时变更、中断或终止部分或全部的网络服务</strong></u></strong><u>。</u>
                                    <ol>
                                        <li>6.1.1 如变更、中断或终止的网络服务属于免费网络服务，MUSINESS无需通知用户，也无需对任何用户或任何第三方承担任何责任，当MUSINESS变更、中断或终止全部的网络服务时，MUSINESS无须为会员在MUSINESS获得的各种积分提供赔偿、退款服务；</li>
                                        <li>6.1.2 如变更、中断或终止的网络服务涉及包含收费网络服务，MUSINESS应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的款项退还给该用户。</li>
                                    </ol>
                                </li>
                                <li>6.2 MUSINESS需要定期或不定期地对提供网络服务的平台（如互联网网站等）或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，MUSINESS无需为此承担任何责任，但MUSINESS应尽可能事先进行通告。</li>
                                <li>6.3 用户同意MUSINESS基于其自行之考虑，因任何合理理由，包含但不限于长时间未使用，或用户违反本服务协议的文字及精神，无需进行事先通知即可中断或终止您对于本服务之使用。用户承认并同意，在根据以上原因停止服务后，MUSINESS可立即关闭或删除用户的账号及账号中所有相关信息及文件，并禁止继续使用前述文件或本服务。，MUSINESS对用户或任何第三人均不对关闭账号及删除账号中相关信息和文件承担任何责任。</li>
                                <li>6.4 如用户注册的免费网络服务的账号在任何连续180日内未登陆使用，则MUSINESS有权冻结该帐号并停止为该用户提供相关的网络服务。</li>
                                <li>6.5 若用户向本网站提出注销MUSINESS注册用户身份，需经MUSINESS审核同意，注销该注册用户，用户即终止与MUSINESS的协议关系，但MUSINESS仍保留下列权利：
                                    <ol>
                                        <li>6.5.1 MUSINESS有权在法律、法规、行政规章、规范性文件等规定的时间内保留该用户的资料，包括但不限于用户资料、上传下载记录等。</li>
                                        <li>6.5.2 若MUSINESS平台用户注销前在MUSINESS平台上存在违法行为或违反本协议的行为，MUSINESS仍可行使本协议及相关规则所规定的权利，追究该用户的法律责任。</li>
                                    </ol>
                                </li>
                            </ol>
                            <p>若用户提出注销其MUSINESS平台注册用户身份前，与其它MUSINESS平台用户或MUSINESS平台尚有未完结的诉讼、仲裁、损失赔偿、欠费等纠纷，MUSINESS须在前述纠纷完结后处理用户的注销请求。用户提出注销后，不免除用户对其未注销前账户内容及账户关联内容负责。</p>
                            <ol>
                                <li>6.6 如发生下列任何一种情形，MUSINESS无需进行事先通知即可随时中断或终止向用户提供本协议项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任，MUSINESS可立即关闭或删除您的账号及您账号中所有相关信息及文件：</li>
                                <li>（1） 用户提供的个人资料不真实；</li>
                                <li>（2） 用户违反本协议中规定的使用规则；</li>
                                <li>（3） 用户有侵害MUSINESS的行为；</li>
                                <li>（4） 用户违反MUSINESS发布的各种规定；</li>
                                <li>（5） 用户以私下协商的方式恶意降低项目价格或私下交易来逃避本网站收费；</li>
                                <li>（6） 用户侵犯或可能侵犯他人的合法权利，包括但不限于侵犯他人的知识产权；</li>
                                <li>（7） 若本网站认为用户曾经实施或正在实施欺诈或非法活动；</li>
                                <li>（8） 对本网站、用户或第三方的潜在损失进行风险管理；</li>
                                <li>（9） 其它MUSINESS认为需终止服务的情况。</li>
                            </ol>
                            <p>若本网站由于您违反本用户协议而关闭您的账户，您还应当就本用户协议项下规定的内容支付相应费用。若您违反本用户协议，您应当在支付您对本网站所有欠款的同时赔偿本网站调查您违约行为以及追讨上述欠款的所有损失及合理支出（包括律师费）。上述赔偿不影响本网站要求您承担基于该协议及其他法律规定下的违约责任。</p>
                            <ol>
                                <li>7  隐私保护</li>
                                <li>7.1 MUSINESS制定本隐私条款是为了表明我们一直致力于保护用户的隐私信息。以下内容包括本网站对信息收集和传播行为的说明。若您使用本网站服务并接受用户协议，意味着您也接受本隐私条款的内容。</li>
                                <li>7.2 您知道并同意我们在您注册或访问MUSINESS平台时收集您的以下信息：</li>
                                <li>（1） 为了向您提供MUSINESS网站、服务、工具以及在本条款中声明的其他目的，我们可能收集以下种类的个人信息：姓名和联系信息，如移动电话号码、电子邮箱、地址，身份信息，如身份证号、身份证照片、企业资质照片和（依据所提供服务的需要）财务信息，如银行卡账号；基于您在本网站活动的交易信息、账单和其他您为了购买商品而提供的信息；当我们认为您存在或可能存在违反网站条款的行为时，我们要求您提供的用以验证您个人身份的其他个人信息；您与本网站互动的信息，包括但不限于设备ID、设备型号、定位信息、电脑和链接信息、网页视图数据、IP地址和标准网页记录信息。</li>
                                <li>（2） 一般信息：MUSINESS会出于改善我们的网站和服务的目的，收集不具有身份识别性的、概括性及一般性的信息。</li>
                                <li>（3） 非个人信息：MUSINESS会在您访问MUSINESS平台的时候收集非个人信息。当您访问网站时，M USINESS和第三方服务提供商（如谷歌浏览器）可能通过您的浏览器或移动设备，包括但不限于您的IP地址中的cookies、服务器日志和其他同类技术设备收集和记录您的非个人信息。</li>
                                <li>（4） 我们可能会将我们所搜集的非个人信息与个人信息结合。如果我们实施上述行为，且当结合所得的信息可能被用于与个人信息同样的用途时，即个人身份识别或定位，上述结合获得的信息将被视为个人信息。</li>
                                <li>7.3 您知道并同意我们储存并使用您的信息：</li>
                                <li>（1） 您的信息将被保证处于MUSINESS的控制范围之内。我们通过技术和安全管理措施来保护您的信息，以减少您信息遭受丢失、不当使用、未授权获取、披露和改动的风险。我们所使用的保护措施包括防火墙、数据加密、对我们数据中心的物理访问控制以及信息访问的授权控制。</li>
                                <li>（2） 当您使用MUSINESS平台时，我们可能需要您提供特定的信息。除非本条款另外规定，MUSINESS不会将具有身份识别性的信息或交易信息与任何个人或实体共享。除了与您进行交易并共享信息的主体外，没有任何第三方可以通过MUSINESS获取您具有身份识别性和交易信息。我们所搜集的信息将用于改进我们网站的内容、向用户及时告知我们网站的更新情况以及与用户的沟通，如用户服务。</li>
                                <li>（3） 市场营销：您同意我们可能将您的个人信息用于向您发送关于我们服务和工具的信息、依据您的个人偏好挑选的有针对性的市场营销和促销活动信息。在未获得您同意的情况下，我们不会基于市场营销的目的将您的个人信息与非关联性公司或第三人共享。我们可能出于市场营销的目的使用或共享非个人信息。</li>
                                <li>（4） 如非出于可归责于MUSINESS的原因，第三方使用任何手段非法获取您的信息给您造成损失的，MUSINESS不就该损失承担责任。</li>
                                <li>7.4 您知道并同意我们共享您的信息：</li>
                                <li>（1） 我们将依据法律规定、MUSINESS条款规定、回应本网站内容侵犯第三方权利控诉的需要、保护第三人权利、财产和安全的需要披露所收集的个人信息。上述信息可能将依据法律法规的规定进行披露。如前所述，未经您的明确许可，我们不会基于第三人的市场营销目的向其披露您的信息。</li>
                                <li>（2） 我们可能会出于前述目的，在关联企业或第三方同意承担与MUSINESS同等的保护用户隐私的责任的情况下，将您的个人信息披露给我们在海外的关联企业和第三方供应商和服务提供商。我们采取合理措施保证前述的海外信息接收主体不会违反关于保护您个人隐私的义务。</li>
                                <li>（3） 我们可能会与广告商或其他第三人共享一般信息，即在不透露单个用户隐私资料的前提下，MUSINESS有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。MUSINESS采用行业通用标准的加密技术保护通过网站传送和接收的用户数据和交易数据。</li>
                                <li>7.5 您可以访问并更新您的信息：
                                    <ol>
                                        <li>7.5.1 如果您认为我们对您个人信息的记录有错误、不完整或不准确，您可以要求我们进行更正。</li>
                                        <li>7.5.2 您可以通过发送电子邮件至我们的邮箱support@musiness.vip请求获取我们对您个人信息的记录。若您有权获取上述信息，我们将尽力通过恰当的途径为您提供（例如通过邮寄或发送电子邮件的方式发送给您）。我们可能会就此向您收取一定的管理和邮寄等其他合理费用。我们不会就您单纯的请求行为和更正您个人信息的行为收取任何的费用。</li>
                                        <li>7.5.3 在某些情况下，我们可能无法为您提供我们所获取的个人信息。比方说，如果上述个人信息的披露可能侵犯他人的隐私或导致保密义务的违反。在前述情形下，我们将在拒绝提供相应信息的回复中阐明无法提供的理由。</li>
                                    </ol>
                                </li>
                                <li>7.6 我们可能随时对本隐私条款进行更改。最新版本的隐私条款会及时公布在我们的网站上，请您定期查看。</li>
                                <li>8  知识产权</li>
                                <li>8.1 本网站依据相关法律、法规将对明确的举报知识产权侵权通知作出相应反应措施。本网站所制定的知识产权侵权条款将对相关行为进行规制，使得本网站能够更直接地获取举报存在侵权行为的通知，并减少欺诈或表述模糊等情况。本网站用户不能侵犯包括他人的著作权在内的知识产权以及其他权利。一旦由于用户上传或发布的内容发生权利纠纷或侵犯了任何第三方的合法权益，其责任由用户本人承担，因此给MUSINESS或任何第三方造成损失的，用户应负责全额赔偿。所有因用户上传或发布的内容而引起的知识产权纠纷，与MUSINESS无关。如果您确信您的知识产权权利受到侵害，请通过发送邮件至support@musiness.vip及时通知本网站，我们将尽一切合理义务进行审查。由于您的误报给任何第三方造成的损失，由您承担相关责任。</li>
                                <li>8.2 您完全了解并同意，当您将内容（包括但不限于提交的展示作品、向客户提交的项目作品等）交予本网站时，你即授予本网站对于内容中您所享有的部分或全部著作权、商标权等权利进行交易，上述权利为非独占、在世界范围内适用的、无期限的、不可撤销的、免费的、可进行分许可的（包括不限次的分许可），且对于任何目前已有的或将来可能产生的媒体适用。</li>
                                <li>9  费用和服务</li>
                            </ol>
                            <p>本网站对所提供的特定服务向卖方及买方收取一定费用，包括但不限于项目分成费、平台服务费、声音设计费。您可以通过产品介绍页或常见问题页来查看费用细则，费用细则将根据需要进行修改，并以网站公示的方式进行公告。本网站可以基于促销或推广活动，对上述表格中的费用进行临时性变更，且上述价格的变更自本网站以公示方式展示相关信息时立即生效。</p>
                            <ol>
                                <li>10 税费</li>
                                <li>10.1 您应当自行依法承担使用本网站而产生相关税费，包括任何依据服务提供地法域法律而需要缴纳的与货物和服务相关的税款。与音乐制作服务费用相关的发票根据买方与卖方另行签订的设计协议由提供设计服务一方开具。</li>
                                <li>10.2 MUSINESS作为一家在上海注册成立的公司，我们所收取的任何收入都将依据中华人民共和国（不含香港、澳门、台湾地区）的法律缴纳增值税，这将在相应的发票上得到体现。与平台服务费、项目发布费、声音设计费相关的发票根据您与MUSINESS的具体约定由MUSINESS开具。您也应当履行由您所在法域法律所规定的个人所得税纳税义务。</li>
                                <li>11  货币</li>
                            </ol>
                            <p>本网站的所有支付的货币单位皆为人民币，另支持台币支付至本网站官方显示的台湾账户。除台币外，本网站不提供其他任何货币兑换标准。</p>
                            <ol>
                                <li>12  用户间项目实施</li>
                                <li>12.1 当买方通过在MUSINESS平台既有作品库中选择版权作品，在未购买前，买方可下载带有声音浮水印的试听版作品，但不具有商业使用的权利。在买方购买成功后，买方依照购买流程中填写的购买用途、授权地区、授权项目名称、项目投放渠道等信息享有该作品的单次授权，即仅享有在前述购买用途、授权地区、授权项目的范围内使用该购买作品的权利。不得复制、共用、改编、转售或以其他方式使用该作品。请注意付款流程将会自动生成作品授权书，为了确保授权书的正确性，请用户正确填写该作品所应用作品之信息再进行付款。买方在购买后下载完整的版权作品，超过24小时，即视为完成交易。或买方事实上商业使用了购买后下载的完整版权作品，即视为完成交易。如果买家在购买作品24小时内发现作品的格式、大小或其他信息与卖家在平台发布作品的信息描述有严重出入并且不能正常使用时，有权向MUSINESS投诉，MUSINESS在接到买家投诉并确认事实成立后，有权要求并督促卖家进行更正或退款处理。</li>
                            </ol>
                            <p>MUSINESS平台既有作品库中的版权作品属非排他性，属于卖方财产。卖方有权将作品进行再销售、授权、重制、卖断等，不受买方的限制。</p>
                            <ol>
                                <li>12.2 当买方在MUSINESS平台发布案子，卖方有权以约稿、投标形式依据买方发布在MUSINESS平台上的案子要求创作作品后，通过网络传输的方式交稿，进行原创作品授权交易。该原创作品版权归属与授权方式由买卖双方协商确认。在该种方式下，卖方有权将原创作品的demo/小样在MUSINESS或MUSINESS以外的其他网站进行展示，但不得提供任何形式的源文件下载。</li>
                            </ol>
                            <p>当买方确认选择的demo/小样，在MUSINESS平台上操作选择该demo/小样的卖方，则应视为买方和卖方的该项合同已成立，该合同约定买方同意购买、且卖方同意提供相应的服务。买卖双方同意不得签订其他含有任何与上述合同条款相冲突内容的合同或其他具有法律效力的约定。买卖双方也不得绕开MUSINESS平台，私下交易。</p>
                            <ol>
                                <li>12.3 您以个人的身份独立对其他用户承担义务并应对该义务的履行承担责任。如果您未履行相应的义务，您可能会对该用户承担相应法律的责任。您应当确认您了解那些与您在本网站实施行为相关的国内法、国际法等法律法规与行业规章。</li>
                            </ol>
                            <p>如果有用户未履行对您所负有的任何义务，您有权以个人的身份对该用户主张您所享有的任何权利。为免生疑问，本网站无义务基于买卖双方订立的合同向其中的任一方主张任何权利。</p>
                            <p>卖方和买方依据其各自所在领域的法律，可能享有无法依据约定而排除的法定权利。本用户协议的约定若与上述法定权利产生冲突，则以相应法律的规定为准。</p>
                            <p>每一个用户都完全了解并同意，买方和卖方之间的法律关系为承揽合同法律关系。本用户协议不会导致上述主体之间形成合伙、合资企业、代理或劳动法律关系。本用户协议也不会导致用户和本网站之间形成合资企业、合伙、代理或劳动法律关系。</p>
                            <ol>
                                <li>13  用户间纠纷</li>
                            </ol>
                            <p>您了解并同意当您和其他用户之间就任何项目发生争议时，您应当首先尝试以公平、合理、符合法律规定和合同约定的方式解决任何就您和其他用户对该项目无法达成一致的问题，如因承揽项目而提供服务的质量问题。<strong><strong>对于与上述纠纷有关的事项而带来的各种损失以及法律责任，您同意赔偿并使我们（以及我们的员工、经理、代理人、分公司、合资企业和其他雇员）在该纠纷中产生的所有类型的诉请和赔偿免责。</strong></strong></p>
                            <p><strong><strong>如果您与其他用户之间存在纠纷，您同意豁免我们（包括我们的员工、经理、代理人、分公司、合资企业和其他雇员）对因该纠纷中产生的诉请和赔偿的全部责任。</strong></strong></p>
                            <ol>
                                <li>14  用户对网站的赔偿责任</li>
                            </ol>
                            <p>对于任何您违反用户协议、违反法律或使用本网站及本网站提供服务而侵害第三人合法权利导致的第三方对本网站（以及本网站的员工、主管、代理人、子公司、合资企业和员工）提起的诉讼或请求而造成的损失和损害，本网站将保留向您进行追偿一切合理支出的权利（包括但不限于律师费、交通费、鉴定费、公证费或其他合理费用）。此外，由于本条上述原因对本网站造成的损失，本网站有权使用您在MUSINESS平台特定账号中的资金赔偿。</p>
                            <ol>
                                <li>15  非保险或担保</li>
                            </ol>
                            <p>我们不提供任何形式的保险、担保，或其他类似的用户保护。本协议中的所有条款不被认为是本网站对您的行为进行担保。</p>
                            <ol>
                                <li>16  不担保网站内容</li>
                            </ol>
                            <p>本网站是一个动态的即时网站，因此本网站上的内容会被频繁地更改；同时，也可能存在一些我们不经意或第三方不经意或故意发布的，带有伤害性、侵犯性、错误性、误导性、欺骗性的信息。本网站所有的内容都是不作修理或改进而按照其原有形态呈现的、我们并不保证上述内容不存在明显的或潜在的瑕疵。我们特别声明不对任何的所有权、产品／服务的适销性、针对特定用途的适用性、质量、非侵权性提供担保。</p>
                            <ol>
                                <li><strong><strong>17  免责声明</strong></strong></li>
                                <li>17.1 用户明确同意其使用MUSINESS网络服务所存在的风险将完全由其自己承担；因其使用MUSINESS网络服务而产生的一切后果也由其自己承担，MUSINESS对用户不承担任何责任。</li>
                                <li>17.2 MUSINESS不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</li>
                                <li>17.3 MUSINESS不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由MUSINESS实际控制的任何网页上的内容，MUSINESS不承担任何责任。</li>
                                <li>17.4 对于因不可抗力或MUSINESS不能控制的原因造成的网络服务中断或其它缺陷，MUSINESS不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</li>
                                <li>17.5 用户同意，对于MUSINESS向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，MUSINESS无需承担任何责任：</li>
                                <li>（1） MUSINESS向用户免费提供的各项网络服务；</li>
                                <li>（2） MUSINESS向用户赠送的任何产品或者服务；</li>
                                <li>（3） MUSINESS向收费网络服务用户附赠的各种产品或者服务。</li>
                                <li>17.6 在任何情况下，我们、我们的相关实体、附属主体或员工都不对以下情形承担任何合同、侵权或其他任何形式的法律责任：</li>
                                <li>（1） 您遭受的任何不可归责于本网站的直接或间接、特定或非特定、附带或结果性损害；</li>
                                <li>（2） 您所遭受的任何直接或间接的收入、商业或利润损失；</li>
                                <li>（3） 您由于进行本网站交易所遭受的任何损害或损失。</li>
                                <li>（4） 您由于违反本用户协议或国家强制性法律法规规定给任何第三方造成的任何损害或损失。</li>
                                <li>17.7 不论我们、我们的相关主体、附属主体或员工是否已被告知上述损失或损害发生可能性，上述责任的限免也不受影响。若您所在法域的法律不允许上述约定的某些责任排除或例外性规定，则以该法律的规定为准。在这种情况下，我们的责任豁免程度为在法律所允许的范围内的最大限度。</li>
                                <li>17.8 MUSINESS平台所展示的作品由用户自行提供，页面信息也由用户自行填写，MUSINESS没有能力查证用户上传作品的素材来源、真实性、准确性及合法性。因此，MUSINESS不为作品提供任何保证。如因非法使用引起纠纷，一切后果由使用者承担。MUSINESS不负任何因此造成的法律责任和损失。</li>
                                <li>17.9 因MUSINESS平台为网络服务提供商，MUSINESS平台的作品均由用户自行上传提供，若版权人发现MUSINESS平台作品有存在侵害著作权等相关权利的，请及时按照MUSINESS平台公布的方式告知MUSINESS，MUSINESS将在核实相关信息后第一时间删除相关作品。用户若发现MUSINESS误删了其享有版权的作品，请在MUSINESS告知&ldquo;权利人&rdquo;维权信息后3个工作日内书面联系MUSINESS，MUSINESS将有权根据MUSINESS用户的申请恢复链接。</li>
                                <li>17.10 MUSINESS系网络服务提供商，MUSINESS仅为MUSINESS用户提供网络相关服务，不涉及网站作品的任何权利义务的授权、转移等内容，MUSINESS仅以普通非专业人士的角度对MUSINESS平台上的作品尽到网络服务提供商的注意义务。</li>
                                <li>18  可分性</li>
                            </ol>
                            <p>本用户协议之间的条款具有效力上的可分性，即部分条款的无效或被撤销不影响其他条款的效力。特别注意，本用户协议的解除不影响以下条款的效力：费用与服务（关于我们提供服务的收费）、网站使用、网站内容、网站责任限免以及用户赔偿责任。您了解并同意，当我们财产被部分或全部转让时，本用户协议项下的所有权利义务可能会在无须获得您同意的情况下，被转让给相关主体或第三方。在上述转让发生的情况下，您仍受到本用户协议条款的约束。</p>
                            <ol>
                                <li>19  通知送达</li>
                            </ol>
                            <p>本协议项下MUSINESS对于对用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等用户在注册时提供的联系方式进行；该等通知于发送之日视为已送达收件人。</p>
                            <ol>
                                <li>20  解释</li>
                            </ol>
                            <p>本用户协议条款的标题仅作参考，不视为对该条款项下内容的定义或限制。MUSINESS保留对本协议做出不时修改的权利，并有权对本协议的条款进行解释。</p>
                            <ol>
                                <li>21  争议解决</li>
                                <li>21.1 若您与MUSINESS之间产生纠纷，我们的首要目标是解决您的问题，如果我们无法让您感到满意，我们会为您提供快速解决纠纷的途径。我们鼓励您先与我们取得直接联系来寻求解决方法，您发邮件至support@musiness.vip与我们联系。您对MUSINESS提起的任何纠纷都会依据本用户协议的内容进行处理。所有违反本用户协议内容而提起的申诉都被视为不恰当的，并被视同为违反本用户协议。若您违反本用户协议提起不恰当的申诉，我们有权向您追偿就此而产生的法务费用和支出（包括使用公司外聘律师、律师助理、法务所产生的费用），前提是我们向您发出了书面通知告知您提起的是不恰当的申诉且您没有依据该通知撤回该不恰当申诉。MUSINESS未对您或他人的违约行为采取措施并不意味着我们放弃对您该项和之后的违约行为或相类似的违约行为追究责任的权利。</li>
                                <li>21.2 甲乙双方因本协议产生任何纠纷，应通过协商解决。如解决不成时，任何一方均可向MUSINESS所在地人民法院提起诉讼。本协议适用中华人民共和国法律。本合同部分条款无效或合同中止、解除的，不影响争议解决条款效力。</li>
                            </ol>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
