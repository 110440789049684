import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { redirectTo } from "dispatch";
import { PAY_CUSTOMIZED } from "constants/actionTypes";
import agent from "agent";
//import "./FixPrice.less";

const mapStateToProps = state => ({ 
    userLogin: state.app.userLogin,
    ...state.fixPrice,
});

const mapDispatchToProps = dispatch => ({
    payMusic: (info,done) =>
        dispatch({
            type: PAY_CUSTOMIZED,
            payload: agent.Customized.payCustomized(info),
            done,
        }),

});
let _val;
class FixPrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            number:0,
            info:"",
            val:"",
        };
    }
    static propTypes = {
        // loading: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        payMusic: PropTypes.func.isRequired,
        payUrl: PropTypes.string,
    };
    componentDidUpdate(prevProps) {
        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }
    }
    songNumber = e => {
      
        const num=e.target.value
        if(!/^[1-9]\d*$/.test(num)){
            this.setState({
                info:"请输入正确的数量",
            })
            return
        }else if(num.length>8){
            this.setState({
                info:"请输入正确的数量"
            })
            return
        }else{
            this.setState({
                info:""
            }) 
        }
        const monery=num*1500+num*1500*0.15;
        this.setState({
            total:monery,
            number:num,
        })
    }
    changeVal= e =>{
        var val = e.target.value;
        if(isNaN(val)){
            val = _val;
            this.setState({"info":"只能输入数字!"});
            setTimeout(function(){
                this.setState({"info":""});
            }.bind(this),1000);
        }else{
            _val = val; 
        }
        this.setState({"val":val});
        const monery=val*1500+val*1500*0.15;
        this.setState({
            total:monery,
            number:val,
        })
    }
    pay= e => {
        const {total,number}=this.state;
        const { userLogin ,payMusic} = this.props;
        if (!userLogin) {
            // login first
            //加type参数做登录之后的页面返回
            redirectTo("/login?type=3");
            return;
        }
        if (total<=0) {
            return;
        }
        const send={
            total:total*100,
            number:number,
        }
        payMusic(send);
    } 

    gotoDetail() {
        redirectTo(`/free`);
    }

    render() {
        // const { total } = this.state;

        return (
            <div className="mypageContents fixPriceContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">定价</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="fixPriceMain ">
                            <div className="fixPriceMain__inner">

                                <div className="fixPriceBox">
                                    <div className="fixPriceBox__header">
                                        <p className="fixPriceBox__title">单曲</p>
                                    </div>
                                    <div className="fixPriceBox__main">
                                        <p className="fixPriceBox__txt">
                                            <a>
                                                <span className="to_use">购买用途</span>
                                                <span className="to_use_price">价格(RMB)</span>
                                            </a>
                                            <a>
                                                <span className="to_use" style={{marginLeft:"38px"}}>购买用途</span>
                                                <span className="to_use_price">价格(RMB)</span>
                                            </a>
                                        </p>
                                        <div className="packPriceList use_style">
                                            <ul className="packPriceList__items">
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>短视频/自媒体视频/直播背景音乐</div>
                                                    <span className="price_style price_margin_right_40">¥<span className="margin_5_px">99</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>连续剧/动画/综艺</div><span className="price_style">¥<span className="margin_7_px">600</span><span className=" qi">起</span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title"><div style={{height:"18px"}}>非商用(国家官方机构发起的有公益证明文件的公益项目/非盈利教学)</div></div>
                                                    <div className="price_style price_margin_right_40" >¥<span className="margin_5_px">99</span><span className=" qi"></span></div>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>电影/微电影</div><span className="price_style">¥<span className="margin_7_px">600</span><span className=" qi">起</span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title"><div style={{height:"18px"}}>音频背景音乐/FM</div><div>/广播/有声读物</div></div>
                                                    <span className="price_style price_margin_right_40">¥<span className="margin_5_px">99</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <div className="to_use_title"><div style={{height:"18px"}}>APP/PC端</div><div>/小程序内置音乐</div></div><span className="price_style">¥<span className="margin_7_px">1000</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限: 一年/随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title"><div style={{height:"18px"}}>学校/教学机构</div><div>/公开课/网课</div></div>
                                                    <span className="price_style price_margin_right_40">¥<span className="margin_5_px">99</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>游戏</div><span className="price_style">¥<span className="margin_7_px">1000</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限:一年/随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title"><div style={{height:"18px"}}>营业场所/演出/活动等</div><div>线下播放(不可翻唱)</div></div>
                                                    <span className="price_style price_margin_right_40">¥<span className="margin_5_px">500</span><span className=" qi"></span></span>
                                                    <p className="term_style">授权期限: 一年/随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>智能硬件</div>
                                                    <span className="price_style width_87">¥<span className="margin_7_px">1000</span></span>
                                                    <p className="term_style">授权期限: 一年/随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_left">
                                                    <div className="to_use_title" style={{lineHeight:"40px"}}>商业广告/宣传片/H5</div>
                                                    <span className="price_style price_margin_right_40">¥<span className="margin_5_px">99</span><span className=" qi">起</span></span>
                                                    <p className="term_style">授权期限: 随片永久</p>
                                                </li>
                                                <li className="usePriceList__item_right">
                                                    <a href="javascript:;" className="to_use_title_free" onClick={this.gotoDetail.bind(this)}>免费曲库</a>
                                                    <span className="price_style width_87"><span className="to_use_title__price_free">¥</span><span className="margin_5_px to_use_title__price_free">0</span></span>
                                                    <p className="term_style_free">授权期限: 随片永久</p>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="fixFreeBox__txt">
                                            <a href="javascript:;"  onClick={this.gotoDetail.bind(this)}>
                                            0元免费曲库，请点击。
                                            </a>
                                        </div> */}
                                    </div>
                                </div>

                                {/* <div className="fixPriceBox">
                                    <div className="fixPriceBox__header">
                                        <p className="fixPriceBox__title">套餐包</p>
                                    </div>
                                    <div className="fixPriceBox__main">
                                        <p className="fixPriceBox__txt">对于配乐有较大需求的用户，您可以购买优惠的包数量套餐。具体音乐等确定需求时再选定，选定音乐数从套餐中抵扣，抵扣完即止。</p>
                                        <div className="packPriceList">
                                            <ul className="packPriceList__items">
                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">3首</p>
                                                    <p className="packPriceList__price">￥3,600元</p>
                                                    <p className="packPriceList__per"><span><em>8</em>.0折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>

                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">5首</p>
                                                    <p className="packPriceList__price">￥5,625元</p>
                                                    <p className="packPriceList__per"><span><em>7</em>.5折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>

                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">10首</p>
                                                    <p className="packPriceList__price">￥10,500元</p>
                                                    <p className="packPriceList__per"><span><em>7</em>.0折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>
                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">15首</p>
                                                    <p className="packPriceList__price">￥14,625元</p>
                                                    <p className="packPriceList__per"><span><em>6</em>.5折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>

                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">20首</p>
                                                    <p className="packPriceList__price">￥18,000元</p>
                                                    <p className="packPriceList__per"><span><em>6</em>.0折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>

                                                <li className="packPriceList__item">
                                                    <p className="packPriceList__title">30首</p>
                                                    <p className="packPriceList__price">￥24,750元</p>
                                                    <p className="packPriceList__per"><span><em>5</em>.5折</span></p>
                                                    <p className="packPriceList__check"><label className="u-checkbox2"><input type="checkbox" value=""/><span></span></label></p>
                                                </li>
                                            </ul>

                                            <p className="fixPriceBox__submit" style={{textAlign: "center"}}><input className="u-submitSkin" type="submit" name="submit" value="支付"/><span>*该功能暂不支持</span></p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="fixPriceBox">
                                    <div className="fixPriceBox__header">
                                        <p className="fixPriceBox__title">懒人推荐</p>
                                    </div>
                                    <div className="fixPriceBox__main">
                                        <p className="fixPriceBox__txt">如您懒得自己选择音乐，可以发送音乐需求至sales@musiness.vip。
                                        我们提供专业的音乐小能手在一个工作日内为您推荐曲目。
                                        曲目价格不变，在此基础上只加收15%的服务费。</p>
                                        <div className="fixPriceStep">
                                            <ul className="fixPriceStep__items">
                                                <li className="fixPriceStep__item">
                                                    <div className="fixPriceStep__txtBox">
                                                        <p className="fixPriceStep__txt">选择所需音乐数量，点击购买懒人推荐。</p>
                                                    </div>
                                                </li>

                                                <li className="fixPriceStep__item">
                                                    <div className="fixPriceStep__txtBox">
                                                        <p className="fixPriceStep__txt">发送音乐需求至sales@musiness.vip，邮件标题请注明“懒人推荐+您的用户名”。</p>
                                                    </div>
                                                </li>

                                                <li className="fixPriceStep__item">
                                                    <div className="fixPriceStep__txtBox">
                                                        <p className="fixPriceStep__txt">MUSINESS音乐小能手将在一个工作日内为您推荐三个方案。</p>
                                                    </div>
                                                </li>

                                                <li className="fixPriceStep__item">
                                                    <div className="fixPriceStep__txtBox">
                                                        <p className="fixPriceStep__txt">您可从推荐方案中选择一个方案回复至sales@musiness.vip。<br/>（如您选择不使用我们推荐的歌曲，因音乐小能手的服务已经产生，生存艰难，服务费照收喔）</p>
                                                    </div>
                                                </li>

                                                <li className="fixPriceStep__item">
                                                    <div className="fixPriceStep__txtBox">
                                                        <p className="fixPriceStep__txt">MUSINESS音乐小能手会在一个工作日内将高清无水印版音乐和授权证书发送给您。</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div> */}
                                {/* <p className="fixPriceBox__txt">如您放弃本次购买，因音乐小能手的服务已经产生，生存艰难，服务费照收喔！</p> */}
                                {/* <div className="fixPriceBox__table">
                                            <div className="fixPriceBox__table__cell fixPriceBox__table__cell1">1500元/単首+ 15%服务费</div>
                                            <div className="fixPriceBox__table__cell fixPriceBox__table__cell2 is-off"><input type="text" onChange={this.changeVal.bind(this)} style={{border:"none",outline: "none",textAlign: "center"}} value={this.state.val} maxLength="8" placeholder="请输入项目所需歌曲数" /></div>
                                            <div className="fixPriceBox__table__cell fixPriceBox__table__cell3"><p style={{textAlign: "center"}}>￥{total}元</p></div>
                                        </div>
                                        <p className="fixPriceBox__submit"><input className="u-submitSkin" type="button"  onClick={this.pay.bind(this)} value="支付"/></p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FixPrice);
