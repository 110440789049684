import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Form, Input, Radio, Layout} from "antd";
import { Link } from "react-router-dom";
import Loading from "components/Loading";
import {
    CART_ON_CHECK_CHANGE,
    CART_CREATE_ORDER,
    CART_CREATE_FREE_ORDER,
    CART_PAGE_UNLOAD,
    SHOW_UPDATE_CAR,
    CHECK_PRICE_GROUP_UPDATE,
    CURRENT_USER,
    CLOSE_CODE_URL,
    SHOW_CODE_URL,
} from "constants/actionTypes";
import agent from "agent";
import "./Cart.less";
import { loadCart, deleteCart, redirectTo, showMoney,HtmlUtil } from "dispatch";
import UpdateShopCar from "./UpdateShopCar";
import WxCode from "../Common/WxCode";
const jquery = require("jquery");


const { TextArea } = Input;
const { Content } = Layout;
const mapStateToProps = state => ({
    ...state.cart,
    ...state.user,
    ...state.home,
    prepayId: state.cart.prepayId,
    userLogin: state.app.userLogin,
    openId: state.home.openId,
});

const mapDispatchToProps = dispatch => ({
    unLoadCart: () => {
        return dispatch({ type: CART_PAGE_UNLOAD });
    },
    onCheckChange: payload => {
        return dispatch({ type: CART_ON_CHECK_CHANGE, payload });
    },
    createOrder: (cartids,send,callback) => {
        return dispatch({
            type: CART_CREATE_ORDER,
            payload: agent.Order.create(cartids,send),
            callback,
        });
    },
    createFreeOrder: (cartids,send,callback) => {
        return dispatch({
            type: CART_CREATE_FREE_ORDER,
            payload: agent.Order.createFree(cartids,send),
            callback,
        });
    },
    showUpdateCar: updateCar => {
        return dispatch({ type: SHOW_UPDATE_CAR, updateCar });
    },
    checkPriceGroup:id => {
        return dispatch({
            type: CHECK_PRICE_GROUP_UPDATE,
            payload: agent.PriceGroup.get(id),
        });
    },
    loadCurrentUser: () =>{
        return dispatch({ 
            type: CURRENT_USER,
            payload: agent.Auth.current() ,
        });
    },
    closeCodeUrl: () =>{
        return dispatch({ 
            type: CLOSE_CODE_URL,
        });
    },
    showCodeImg: (send) =>{
        return dispatch({ 
            type: SHOW_CODE_URL,
            send
        });
    },
   
});

class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            discount: 0,
            isShow:true,
            value:1,
            remittance:0,
            subscribeBox:false,
            freeSubbscribeBox:false,
            tempTotal:0,
            isSubmit:false,
            nocishu:false,
            useNum:0,
            loadone:false,
            orderType:"",
            openId:this.props.openId,
            codeShow:false,
            appId:'wxe8482c2b628422a9',
            time_stamp : parseInt(new Date().getTime() / 1000) + '',
            nonceStr : Math.random().toString(36).substr(2, 15),
            // package: "prepay_id="+prepay_id,
            signType :"MD5",
            prepayIds : {},
            Browser:0,
            show:false,
        };
        this.closeSubscribeHandle = this.closeSubscribeHandle.bind(this);
        this.onSubscribeHandle = this.onSubscribeHandle.bind(this);
        this.onSubmitHandle = this.onSubmitHandle.bind(this);
        this.closeNocishuHandle = this.closeNocishuHandle.bind(this);
        this.closeCode = this.closeCode.bind(this);
    }
    static propTypes = {
        plainOptions: PropTypes.array.isRequired,
        serverCartData: PropTypes.array.isRequired,
        checkedList: PropTypes.array.isRequired,
        checkAll: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        indeterminate: PropTypes.bool.isRequired,
        unLoadCart: PropTypes.func.isRequired,
        onCheckChange: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        createedOrderId: PropTypes.string.isRequired,
        userLogin: PropTypes.bool.isRequired,
        showUpdateCar: PropTypes.func.isRequired,
        checkPriceGroup:PropTypes.func.isRequired,
        loadCurrentUser:PropTypes.func.isRequired,
        closeCodeUrl:PropTypes.func.isRequired,
        showCodeImg:PropTypes.func.isRequired,
        form: PropTypes.object,
        currentUser: PropTypes.object,
        payUrl: PropTypes.string,
        remittance: PropTypes.number,
        order: PropTypes.object,
        codeUrl:PropTypes.string,
        prepayId:PropTypes.string,
        openId:PropTypes.string,
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }
    componentDidMount() {
        console.log(window.openId)    
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login?type=1");
            return false;
        }
        if((global.isWeixin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1)){
            this.setState({
                show:true
            })
        }
        loadCart();
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
    }
    componentWillUnmount() {
        this.props.unLoadCart();  
    }
    componentDidUpdate(prevProps) {
        const { userLogin, showCodeImg} = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // 免费音乐
        if (this.props.remittance=="10") {
            jquery("body").css("overflow","auto");
            jquery("body").css("height","auto");
            
            redirectTo("/user/purchase?isHistory=2");
            return;
        }
        //支付宝付款
        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }
        //微信支付
        if (this.props.codeUrl!= prevProps.codeUrl) {
            if (!this.props.order) {
                redirectTo("/user/purchase?isHistory=1");
                return;
            }
            const send ={
                codeShow:true,
                codeUrl:this.props.codeUrl,
                orderid:this.props.order.orderid,
                price:this.props.order.price/100,
            }
            showCodeImg(send);
            return;
        }
        if(this.props.prepayId!=""&&this.state.orderType=="5"){
            // this.wxpay(this.props.prepayId);

            if (typeof WeixinJSBridge == "undefined"){
                if( document.addEventListener ){
                    document.addEventListener('WeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    }, false);
                }else if (document.attachEvent){
                    document.attachEvent('WeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    });
                    document.attachEvent('onWeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    });
                }
            }else{
                this.onBridgeReady();
            }
        }
        if (this.props.remittance=="2") {
            jquery("body").css("overflow","auto");
            jquery("body").css("height","auto");
            
            redirectTo("/user/purchase?isHistory=2");
        }

        //银行卡转账
        if(this.props.remittance==1){
            if (this.state.remittance==1) {
                return;
            }
            this.setState({
                remittance:1,
            })
            jquery("body").css("overflow","hidden");
            jquery("body").css("height","100%");
        }
    }
    cartItemOnChange(e) {
        this.setState({
            subscribeBox:false,
        })
        const checked = e.target.checked;
        const value = parseInt(e.target.value);
        const { onCheckChange, plainOptions,serverCartData } = this.props;
        const checkedList = [...this.props.checkedList];
        if (checked) {
            // add value
            if (!checkedList.includes(value)) {
                checkedList.push(value);
            }
            this.setState({
                total: this.state.total - parseInt(e.target.dataset.monery),
            });
        } else {
            var y=document.getElementById(value);
            y.innerHTML="";
            if (checkedList.indexOf(value) >= 0) {
                checkedList.splice(checkedList.indexOf(value), 1);
            }
            this.setState({
                total: this.state.total + parseInt(e.target.dataset.monery),
            });
        }
        let scope=0;
        let tempTotal = 0;
        for (let i = 0; i < serverCartData.length; i++) {
            const element = serverCartData[i];
            for (let k = 0; k < checkedList.length; k++) {
                const ele = checkedList[k];
                if (ele===element.id) {
                    tempTotal += parseInt(element.price);
                    if (element.scope===1) {
                        scope=1;
                    }
                }
            }
        }
        if (scope===1) {
            this.setState({
                isShow:true,
            })
        }else{
            this.setState({
                isShow:false,
            })
        }
        onCheckChange({
            checkedList: [...checkedList],
            indeterminate:
                !!checkedList.length &&
                checkedList.length < plainOptions.length,
            checkAll: checkedList.length === plainOptions.length,
        });
        if(checkedList.length > 0 && tempTotal == 0){
            this.setState({
                freeSubbscribeBox:true,
                tempTotal:tempTotal,
            });
        }else {
            this.setState({
                freeSubbscribeBox:false,
                tempTotal:tempTotal,
            });
        }
    }
    onCheckAllChange = e => {
        const { onCheckChange, plainOptions, serverCartData } = this.props;
        let number = 0;
        for (let i = 0; i < serverCartData.length; i++) {
            number = number + serverCartData[i].price;
        }
        if (e.target.checked) {
            this.setState({
                total: 0,
            });
        } else {
            this.setState({
                total: number,
            });
        }
        onCheckChange({
            checkedList: e.target.checked ? [...plainOptions] : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
        if(serverCartData.length > 0 && number == 0){
            this.setState({
                freeSubbscribeBox:true,
                tempTotal:number,
            });
        }else {
            this.setState({
                freeSubbscribeBox:false,
                tempTotal:number,
            });
        }

    };

    //提交订单
    onSubmitHandle(e) {
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        e.preventDefault();
        const { createOrder,createFreeOrder, loadCurrentUser,serverCartData} = this.props;
        const checkedList = [...this.props.checkedList];
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loadone:true,
                })
                console.log(window.openId)
                let openId = "";
                if(window.openId===undefined){
                    openId = 1;
                }else{
                    openId = window.openId;
                }
                console.log("openId",openId)
                // var u = navigator.appVersion;
                // var qq = u.split('MQQBrowser/').length > 1 ? 2 : 0;
                // var uc = u.split('UCBrowser/').length > 1  ? 1 : 0;
                // var isChrome =  navigator.userAgent.indexOf('Chrome') > -1 ? 1 : 0;
                // var wx = ((u.match(/MicroMessenger/i)) && (u.match(/MicroMessenger/i).toString().toLowerCase() == 'micromessenger'));
                // var safari = navigator.userAgent.indexOf("Safari");
                let send = {}
                // var sUserAgent = navigator.userAgent.toLowerCase();
                // var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
                // var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
                // var bIsMidp = sUserAgent.match(/midp/i) == "midp";
                // var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
                // var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
                // var bIsAndroid = sUserAgent.match(/android/i) == "android";
                // var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
                // var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
                // var end = "";
                // if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM)) {
                //     end = "pc";
                //     // alert("当前是电脑打开")
                // }else{
                //     end = "phone";
                //     // alert("当前是手机打开")
                // }
                // if(end==="phone"&&(global.isWeixin = navigator.userAgent.toLowerCase().indexOf('micromessenger') === -1)&&(uc=== 1 || (qq=== 1&&!wx) || safari>0 ||isChrome>0)){
                //     this.setState({
                //         Browser:1
                //     })
                //     send={
                //         area: values.area,
                //         description: values.description,
                //         email: values.email,
                //         phoneNumber: values.phoneNumber,
                //         payType: '6',
                //         username: values.username,
                //         // openId:window.openId,
                //     }
                // }else{
                let tempTotol = 0;
                for (let i = 0; i < serverCartData.length; i++) {
                    tempTotol = tempTotol + serverCartData[i].price;
                }
                if((this.state.freeSubbscribeBox && this.state.tempTotal == 0) || (serverCartData.length>0 && tempTotol == 0)){
                    send={
                        area: values.area,
                        description: values.description,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        payType: "3",
                        username: values.username,
                        openId:"1",
                        // code:window.wxCode
                    }
                }else {
                    send={
                        area: values.area,
                        description: values.description,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        payType: this.state.value.toString(),
                        username: values.username,
                        openId:openId,
                        code:window.wxCode
                    }
                }
                // }
                // if(end === "pc"){
                //     send={
                //         area: values.area,
                //         description: values.description,
                //         email: values.email,
                //         phoneNumber: values.phoneNumber,
                //         payType: this.state.value.toString(),
                //         username: values.username,
                //         openId:openId,
                //         code:window.wxCode
                //     }
                // }
                
                if (this.state.subscribeBox) {
                    send.payType="3";
                }
                const callback = () => {
                    loadCurrentUser();
                };
                
                if(serverCartData.length>0 && tempTotol == 0){
                    createFreeOrder(checkedList,send,callback);
                }else {
                    createOrder(checkedList,send,callback);
                }
                if(send.payType=="5"){
                    this.setState({
                        orderType : "5"
                    });
                }
                this.setState({
                    isSubmit:false,
                })
            }
        });
    }
    
    onBridgeReady(){
        console.log(this.props.prepayId.appId);
        console.log(this.props.prepayId.timeStamp);
        console.log(this.props.prepayId.nonceStr);
        console.log(this.props.prepayId.package);
        console.log(this.props.prepayId.signType);
        console.log(this.props.prepayId.paySign);
        window.openId = this.props.prepayId.openId;
        window.wxCode = 1;
        const that = this;
        console.log("openId",window.openId)
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {//下面参数内容都是后台返回的
                // debug:true,
                "appId":that.props.prepayId.appId,//公众号名称，由商户传入
                "timeStamp":that.props.prepayId.timeStamp,//时间戳
                "nonceStr":that.props.prepayId.nonceStr,//随机串
                "package":"prepay_id="+that.props.prepayId.package,//预支付id
                "signType":that.props.prepayId.signType,//微信签名方式
                "paySign":that.props.prepayId.paySign,//微信签名
            }, res =>{
                // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                redirectTo("/user/purchase?isHistory=2");
                // alert("支付成功");
                // }
                // if(res.err_msg == "get_brand_wcpay_request:cancel"){
                //     alert("支付过程中用户取消！");
                // }
                // if(res.err_msg == "get_brand_wcpay_request:fail"){
                //     alert("支付失败")
                // }
                // else{           
                //     alert("支付失败,请跳转页面"+res.err_msg);
                // }
            }
        );
    }
    onSubscribeHandle(){
        const checkedList = [...this.props.checkedList];
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    isSubmit:true,
                    useNum:checkedList.length,
                })
            }
        });
    }
    closeSubscribeHandle(){
        this.setState({
            isSubmit:false,
        })
    }

    //删除购物车
    deleteShoppingCart(id) {
        var y=document.getElementById(id);
        y.innerHTML="";
        deleteCart(id);
        this.setState({
            total:0,
        })
    }

    //更换支付方式
    onChange = e => {
        this.setState({
            value: e.target.value,
        });
        console.log(e.target.value)
    };

    //点击修改价格
    updateCarPrice(item){
        jquery("body").css("overflow","hidden");
        jquery("body").css("height","100%");
        const { showUpdateCar, checkPriceGroup } = this.props;
        const updateCar={
            showUpdateCar:true,
            car: item,
        }
        this.setState({
            subscribeBox:false,
        })
        var y=document.getElementById(item.id);
        y.innerHTML="";
        checkPriceGroup(item.audio.price_group_id);
        showUpdateCar(updateCar);

    }
    //跳转订单未付款页
    handleClose(){
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        this.setState({
            remittance:0,
        })
        redirectTo("/user/purchase?isHistory=1");
    }
    //关闭二维码
    closeCode(){
        const {closeCodeUrl } = this.props;
        closeCodeUrl();
        loadCart();
        this.setState({
            loadone:false,
        })
        redirectTo("/user/purchase?isHistory=1");
    }
    //跳转会员订阅页
    goToSub = e => {
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        redirectTo("/subscribermembers");
    }
    closeNocishuHandle(){
        this.setState({
            nocishu:false,
        })
    }
    //点击是否使用会员权益
    subscribeChange = e =>{
        const checkedList = [...this.props.checkedList];
        const {serverCartData ,currentUser } = this.props;
        if (e.target.checked) {
            this.setState({
                subscribeBox:true,
            });
            if (checkedList.length==0) {
                this.setState({
                    subscribeBox:false,
                });
                return;
            }
            if (currentUser.use_num<checkedList.length) {
                this.setState({
                    subscribeBox:false,
                    nocishu:true,
                });
                return;
            }
            for (let i = 0; i < checkedList.length; i++) {
                const element = checkedList[i];
                for (let j = 0; j < serverCartData.length; j++) {
                    const ele = serverCartData[j];
                    if (element==ele.id) {
                        if (ele.audio.author.userid != 'IVKPCCUGYVS317TCF32BC7BE385C027F' && ele.audio.author.userid != 'WCIRSZ7MUOMGZ4RUE1A728276B7BD15B' && ele.audio.author.userid != 'XBNTVMZD2KVJ5FRK81027164D4516A4C') {
                            var y=document.getElementById(ele.id);
                            y.innerHTML="您所选的曲目不在订阅范畴内，敬请谅解!";
                            this.setState({
                                subscribeBox:false,
                            });
                        }else{
                            if ((currentUser.is_member==11||currentUser.is_member==12||currentUser.is_member==13)&&ele.purpose!=4&&ele.purpose!=10&&ele.purpose!=11) {
                                var x=document.getElementById(ele.id);
                                x.innerHTML="您所选曲目的购买用途超出订阅范围，敬请谅解！";
                                this.setState({
                                    subscribeBox:false,
                                });
                            }else if ((currentUser.is_member==21||currentUser.is_member==22||currentUser.is_member==23)&&ele.purpose!=4&&ele.purpose!=10&&ele.purpose!=11&&ele.purpose!=1&&ele.purpose!=3) {
                                var z=document.getElementById(ele.id);
                                z.innerHTML="您所选曲目的购买用途超出订阅范围，敬请谅解！";
                                this.setState({
                                    subscribeBox:false,
                                });
                            }

                        }
                    }
                }
            }
        }else{
            this.setState({
                subscribeBox:false,
            })
        }
    }
    //旧的购物车样式
    renderCell(item, i) {
        const { checkedList } = this.props;
        const { audioid, thumb, title,scope } = item.audio;
        return (
            <div key={i} className="buyMusicItem">
                <p className="buyMusicItem__check">
                    <label className="u-checkbox2">
                        <input
                            type="checkbox"
                            value={item.id}
                            data-monery={item.price}
                            checked={checkedList.includes(item.id)}
                            onChange={this.cartItemOnChange.bind(this)}
                        />
                        <span />
                    </label>
                </p>
                <div className="buyMusicItem__jacket" >
                    <img src={agent.getApiUrl(thumb)} alt="" />
                </div>
                <div className="buyMusicItem__header">
                    <p className="buyMusicItem__title">
                        <Link to={`/music/musicid/${audioid}`}> {HtmlUtil.htmlDecode(title)}</Link>
                    </p>
                    <p className="buyMusicItem__name">by  {HtmlUtil.htmlDecode(!item.audio.creatorInfo?item.audio.composer:item.audio.creatorInfo.nickname)}</p>
                    <div className="my-pc-hide">
                        <p className="buyMusicItem__scope">授权范围:{scope==0?"全球":"中国大陆"}</p>
                        <p className="buyMusicItem__scope" >购买用途:<a href="javascript:void(0)" onClick={this.updateCarPrice.bind(this,item)}>{item.touse}</a></p>
                    </div>
                </div>
                <div className="buyMusicItem__header mr-pc-show" style={{width:"40%"}}>
                    <p className="buyMusicItem__scope">授权范围:{scope==0?"全球":"中国大陆"}</p>
                    <p className="buyMusicItem__scope" >购买用途:<a className="choose-use" href="javascript:void(0)" onClick={this.updateCarPrice.bind(this,item)}>{item.touse}</a></p>
                </div>
                <p className="buyMusicItem__price">{showMoney(item.price)}</p>
                <p className="buyMusicItem__trashbox">
                    <button
                        data-id={item.id}
                        onClick={this.deleteShoppingCart.bind(this)}
                        className="trashbox"
                    />
                </p>
            </div>
        );
    }
    //修改后的样式
    renderNewCell(item, i) {
        const { checkedList, currentUser } = this.props;
        const { audioid, thumb, title } = item.audio;
        const { scope } = item;
        console.log(item.price)
        return (
            <li key={i}>
                <div className=" main-li001"> 
                    <p className="buyMusicItem__check">
                        <label className="u-checkbox2">
                            <input
                                type="checkbox"
                                value={item.id}
                                data-monery={item.price}
                                checked={checkedList.includes(item.id)}
                                onChange={this.cartItemOnChange.bind(this)}
                            />
                            <span />
                        </label>
                    </p>
                </div>
                <div className=" main-li002">
                    <div className=" left music-img"><img height="50" width="50" src={agent.getApiUrl(thumb)}/></div>
                    <div className=" left">
                        <div className="music-ti"><Link to={`/music/musicid/${audioid}`}> {HtmlUtil.htmlDecode(title).length>10?title.substr(0,10)+'...':title}</Link></div>
                        <div className="music-by" style={{textAlign:"left"}}>by {HtmlUtil.htmlDecode(!item.audio.creatorInfo?item.audio.composer:item.audio.creatorInfo.nickname)}</div>
                        <div className=" pc_hide music-phone">
                            <div className="phone_size_style">授权范围：{scope==0?"全球":"中国大陆"}</div>
                            <div className="phone_size_style">购买用途：<a className="choose-use" href="javascript:void(0)" onClick={this.updateCarPrice.bind(this,item)}>{item.touse}</a></div>
                        </div>
                    </div>
                </div>
                <div className=" main-li003">{scope==0?"全球":"中国大陆"}</div>
                <div className=" main-li004"><a className="choose-use" href="javascript:void(0)" onClick={this.updateCarPrice.bind(this,item)}>{item.touse}</a></div>
                <div className=" main-li005" >
                    {currentUser.is_member!=0&&currentUser.is_member!=3?<p style={{color:"red",fontSize:"15px"}}>{showMoney(item.price*0.8)}<span style={{fontSize:"12px"}}>会员价</span></p>:""}
                    {currentUser.is_member!=0&&currentUser.is_member!=3?<p style={{textDecorationLine:"line-through"}}>{showMoney(item.price)}</p>:<p>{showMoney(item.price)}</p>}
                </div>
                <div className=" main-li006" style={{cursor:"pointer"}} onClick={this.deleteShoppingCart.bind(this,item.id)}><img height="20" src="assets/img/clear@3x.png"/></div>
                <div className=" notice_problem" id={item.id}></div>
            </li>
        );
    }
    renderFrom = () => {
        const { getFieldDecorator } = this.props.form;
        const { currentUser, serverCartData} = this.props;
        const show = this.state.show;
        const isRequired = !show;
        const name=!currentUser.company?HtmlUtil.htmlDecode(currentUser.nickname):HtmlUtil.htmlDecode(currentUser.company_name);
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom:"15px",
            marginLeft:"40px",
        };
        const radioStyle2 = {
            height: '30px',
            lineHeight: '30px',
            marginBottom:"15px",
            marginLeft:"40px",
        };
        let sub=this.state.freeSubbscribeBox ? (!this.state.freeSubbscribeBox ? {display:"block",height:"auto"}:{display:"none"}) : (!this.state.subscribeBox ? {display:"block",height:"auto"}:{display:"none"});
        let tempTotol = 0;
        for (let i = 0; i < serverCartData.length; i++) {
            tempTotol = tempTotol + serverCartData[i].price;
        }
        if(serverCartData.length>0 && tempTotol == 0){
            sub = {display:"none"};
        }
        const redNotice=(this.state.subscribeBox || this.state.freeSubbscribeBox)?{display:"none"}:{display:"block"};
        return (
            <Form onSubmit={this.onSubmitHandle}>
                <div className="buypageForm__items my-items" id="order_one">
                    <div className="order_title_1">
                        <p className="order_title_inner">
                            <span className="font_title_1" >授权项目信息:</span>
                            <span className="font_title_2">(&nbsp;提示:以下内容是授权证书的关键信息,一旦提交不支持修改,请务必准确填写&nbsp;)</span>
                           
                        </p>  
                    </div>
                    <div className="buypageForm__item">
                        <dl className="buypageForm__dl margin_bottom_36">
                            <dt className="buypageForm__dt add_star">
                                <label htmlFor="">授权项目:</label>
                            </dt>
                            <dd className="buypageForm__dd">
                                <Form.Item>
                                    {getFieldDecorator("area", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "请输入音乐作品用于之公司/品牌及具体项目名称！",
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="text"
                                            className="u-inputSkin02 border"
                                            placeholder="音乐作品用于之公司/品牌及具体项目名称"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                       
                        <dl className="buypageForm__dl">
                            <dt className="buypageForm__dt" >
                                <label style={{marginLeft:"11px"}} htmlFor="">项目描述:</label>
                            </dt>
                            <dd className="buypageForm__dd line_height_13">
                                <Form.Item>
                                    {getFieldDecorator("description", {
                                        rules: [{required: false,}],
                                    })(
                                        <TextArea
                                            style={{resize:"none"}}
                                            type="text"
                                            className="u-inputSkin02 border"
                                            placeholder="项目投放的渠道或平台名称"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                    </div>
                    <div className="buypageForm__item">
                        <dl className="buypageForm__dl">
                            <dt className="buypageForm__dt add_star">
                                <label htmlFor="">被授权者:</label>
                            </dt>
                            <dd className="buypageForm__dd">
                                <Form.Item>
                                    {getFieldDecorator("username", {
                                        initialValue: name,
                                        rules: [
                                            {
                                                required: isRequired,
                                                message:
                                                    "请输入被授权者!",
                                            },
                                        ],
                                    })(
                                        <Input
                                            type="text"
                                            className="u-inputSkin02 border"
                                            placeholder="最终使用音乐的个人姓名/公司名"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                            <dt className="buypageForm__dt add_star margin_top_18" >
                                <label htmlFor="">联系电话:</label>
                            </dt>
                            <dd className="buypageForm__dd margin_top_18">
                                <Form.Item>
                                    {getFieldDecorator(
                                        "phoneNumber",{
                                            initialValue: HtmlUtil.htmlDecode(currentUser.phone),
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入电话!",
                                                },
                                            ],
                                        }
                                    )(
                                        <Input
                                            type="number"
                                            className="u-inputSkin02 border"
                                            placeholder="电话"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                            <dt className="buypageForm__dt add_star margin_top_18">
                                <label htmlFor="">联系邮箱:</label>
                            </dt>
                            <dd className="buypageForm__dd margin_top_18">
                                <Form.Item>
                                    {getFieldDecorator("email", {
                                        initialValue: HtmlUtil.htmlDecode(currentUser.email),
                                        rules: [ { required: true, message: "请输入邮箱!",},
                                        ],
                                    })(
                                        <Input
                                            type="email"
                                            className="u-inputSkin02 border"
                                            placeholder="邮箱"
                                        />
                                    )}
                                </Form.Item>
                            </dd>
                        </dl>
                    </div>
                    <div className="order_title_1 margin_top"  style={sub}>
                        <p className="order_title_inner">
                            <span className="font_title_1" >支付方式:</span>
                        </p>  
                    </div>
                    <div className="buypageForm__item" style={sub}>
                        <dl className="buypageForm__dl margin_bottom_36 pay_style">
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio style={radioStyle} value={1}>
                                    支付宝
                                </Radio>
                                <Radio className="wechat_code_phone" style={radioStyle2} value={4}>
                                    微信支付
                                </Radio>
                                {this.state.show==true?
                                    <Radio className="wechat_code_phone2" style={radioStyle2} value={5}>
                                    微信支付
                                    </Radio>:""}
                                <Radio style={radioStyle} value={2}>
                                    银行卡转账
                                </Radio>
                            </Radio.Group>
                        </dl>
                        <div className="notice_pay_red" style={redNotice}> * 银行卡转账支持人民币和台币。价格均为人民币，如您选择台币支付，默认汇率为4.5。</div>
                    </div>
                </div>
            </Form>
        );
    };
    render() {
        const { loading, serverCartData, checkedList, currentUser, indeterminate, checkAll, order} = this.props;
        if (!serverCartData) {
            return (
                <Loading/>
            )
        }
        let all = 0;
        let scope=0;
        for (let i = 0; i < serverCartData.length; i++) {
            all = all + serverCartData[i].price;
            if (serverCartData[i].scope==1) {
                scope=1;
            }
        }
        const isShow=(scope==1&&this.state.isShow)?true:false;
        let total=0;
        if (all!=0) {
            total = all - this.state.total;
        }
        let pay=0;
        if (all!=0) {
            pay=total - this.state.discount;
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        const dispatch={
            closeCode:this.closeCode,
        }
        //const discount = this.state.discount;
        const type2Show = this.state.remittance==1 ? "dialog-bg" : "hidden";
        const subHandle = this.state.isSubmit? "dialog-bg" : "hidden";
        const nocishu = this.state.nocishu? "dialog-bg" : "hidden";
        const cashiers=serverCartData.length?"cashiers":"cashiers hidden";
        const isMember=currentUser.is_member!=0&&currentUser.is_member!=3?{display:"block"}:{display:"none"};
        const redNotice=this.state.subscribeBox?{display:"none"}:{display:"block"};
        return (
            <div className="mypageContents c132-cart">
                <Loading loading={loading} />
                <Loading loading={this.state.loadone} />
                <div className="mypageContents__inner2 member-pay">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">购物车</h1>
                    </header>
                    <div className="buypageBox">
                        <section className="buypageMusic">
                            <header className="heading-type1">
                                <div>
                                    <span className=" member-pay-current"><img className="img_phone_hidden" height="30" src="assets/img/book3-13.png"/>填写信息</span>＞
                                    <span><img className="img_phone_hidden" height="30" src="assets/img/pay3-08.png"/>支付</span>＞
                                    <span><img className="img_phone_hidden" height="30" src="assets/img/book3-14.png"/>下载音乐</span>
                                </div>
                            </header>
                            {serverCartData.length ?
                                <div className="buypageMusic__inner">
                                    {currentUser.is_member==0||currentUser.is_member==3?<div className=" member-ad"><a><img className="pc_show_img" onClick={this.goToSub.bind(this)} src="assets/img/u303.png"/><img className="phone_show_img" onClick={this.goToSub.bind(this)} src="assets/img/u304.png"/></a></div>:""}
                                    <div className="pay-main-ti">歌曲信息:</div>
                                    <div className=" pay-main-li phone_show_box">
                                        <label className="u-checkbox2">
                                            <input
                                                type="checkbox"
                                                indeterminate={indeterminate.toString()}
                                                onChange={this.onCheckAllChange.bind(this)}
                                                checked={checkAll}
                                            />
                                            <span style={{fontSize:"12px"}}>全选</span>
                                        </label>
                                    </div>
                                    <div className=" pay-main-li">
                                        <ul>
                                            <li>
                                                <div className=" main-li001">
                                                    <p className="buyMusicItem__check">
                                                        <label className="u-checkbox2">
                                                            <input
                                                                type="checkbox"
                                                                indeterminate={indeterminate.toString()}
                                                                onChange={this.onCheckAllChange.bind(this)}
                                                                checked={checkAll}
                                                            />
                                                            <span />
                                                        </label>
                                                    </p>
                                                </div>
                                                <div className=" main-li002">歌曲</div>
                                                <div className=" main-li003">授权地区</div>
                                                <div className=" main-li004">购买用途</div>
                                                <div className=" main-li005">价格</div>
                                                <div className=" main-li006">操作</div>
                                            </li>
                                            {serverCartData.map(
                                                this.renderNewCell.bind(this)
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                : <div className="buypageMusic__inner">
                                    <div style={{textAlign:"center",marginTop:"80px",marginBottom: "60px"}}>
                                        <img style={{width:"200px" ,marginBottom:"30px"}} src="assets/img/u33.png"/>
                                        <p><span>您的购物车是空的哦，</span><Link to="/music/search" style={{color: "#339E9D",cursor:"pointer"}}>赶紧去选歌</Link><span>吧~</span></p>
                                    </div>
                                </div>}
                            {!isShow?'': <div style={{fontSize: '10px',color:'red',maxWidth:'880px',margin:'0 auto'}}>您购买的曲目中，有曲目的授权范围为中国地区，若您的项目需在中国以外的地区使用，您须选择授权范围为全球地区的曲目。  </div>}
                        </section>

                        <div className={cashiers}>
                            <div className="cashiers__inner">
                                <div className="cashiers__table">
                                    <table>
                                        <tbody>
                                            <tr className="cashiers__table__grandTotal">
                                                <th>
                                                    已选{checkedList.length}项
                                                </th>
                                                <th>订单总金额 ：</th>
                                                <td className="cashiers__table__price" style={{color:"#339E9D"}}>
                                                    {this.state.subscribeBox?"¥ 0":currentUser.is_member!=0&&currentUser.is_member!=3?showMoney(pay*0.8):showMoney( pay)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="cashiers__table__notice" style={redNotice}>
                                        * 价格均为人民币，如您选择台币支付，默认汇率为4.5
                                    </div>
                                </div>
                                <div className="buypageForm" >
                                    <div className="subscribe_use" style={isMember}>
                                        <p className="subscribe_use_p">
                                            <label className="u-checkbox2">
                                                <input
                                                    type="checkbox"
                                                    onChange={this.subscribeChange.bind(this)}
                                                    checked={this.state.subscribeBox}
                                                />
                                                <span />
                                            </label>
                                            使用订阅会员权益<span className="subscribe_use_num">（本月剩余可免费购买歌曲数量：{currentUser.use_num}首）</span>
                                        </p>
                                    </div>
                                    <div className="buypageForm__inner">
                                        {this.renderFrom()}
                                    </div>
                                </div>

                                <p className="cashiers__submit">
                                    {this.state.subscribeBox?<Button
                                        className="u-submitSkin"
                                        onClick={this.onSubscribeHandle}
                                    >
                                        确认购买
                                    </Button>:
                                        <Button
                                            className="u-submitSkin"
                                            onClick={this.onSubmitHandle}
                                        >
                                            确认购买
                                        </Button>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={subHandle}style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container modalBox__inner sub_style">
                        <div className=" close-choose sub_style_scope"><a onClick={this.closeSubscribeHandle} style={{cursor:"pointer"}}><img height="24" src="/assets/img/u50.png"/></a></div>
                        <div className="dialog__title sub_margin_top"><span>本次购买将扣除您免费歌曲数量:{this.state.useNum}首</span></div>
                        <div className=" add_car_success">
                            <div className="dialog__title"><img src="/assets/img/u306.png"/><span>您确认要提交本订单?</span></div>
                            <div className="dialog__txt"><a onClick={this.closeSubscribeHandle} className="margin_right_25"  style={{cursor:"pointer"}}>取消</a><a onClick={this.onSubmitHandle} style={{cursor:"pointer"}}>确认</a></div>
                        </div>
                    </div>
                </div> 
                <div className={nocishu}style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container modalBox__inner sub_style">
                        <div className="dialog__title nocishu_margin_top"><span>抱歉,您的订阅会员下载次数不足!</span></div>
                        <div className=" add_car_success nocishu_margin_top">
                            <div className="dialog__txt"><a onClick={this.closeNocishuHandle} style={{cursor:"pointer"}}>确认</a></div>
                        </div>
                    </div>
                </div>      
                <div className={type2Show} style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container scroll-y modalBox__inner">
                        <div className=" booker-ti"><span>订单已提交！</span></div>
                        <div className=" book-code"><span style={{color:"red"}}>订单号：</span>{!order?"":order.orderid}</div>
                        <div className=" book-de">您可自行选择币种汇款至对应账户，成功汇款后，请将<span>订单号</span>与<span>汇款账号后五位</span>发送至<a style={{fontWeight:"600"}}>sales@musiness.vip</a>； 或 至<a style={{fontWeight:"600"}}>个人中心—音乐购买记录—未付款</a>页面填写相关信息。</div>
                        <div className=" book-de">在您提交汇款信息后，MUSINESS将在24小时之内进行审核，审核通过后，您可在<a style={{fontWeight:"600"}}>个人中心—音乐购买记录—已付款</a>页面下载<span>无水印歌曲</span>及<span>授权证书</span>。</div>
                        <div>
                            <div className=" table-ti"><strong>收款账号信息如下：</strong></div>
                            <div>
                                <div className=" table-name">
                                    <div className=" wid20">币种</div>
                                    <div className=" wid20">金额</div>
                                    <div className=" wid60">银行卡信息</div>
                                </div>
                                <div className=" table-de">
                                    <ul>
                                        <li>
                                            <div className=" wid20 line-78">人民币</div>
                                            <div className=" wid20 color-green line-78">{!order?0:order.price/100}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>上海小仙虎文化传媒有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>中国工商银行股份有限公司上海市上外支行</div>
                                                <div className=" table-all"><span>账号：</span>1001274409300013770</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" wid20 line-78">台币</div>
                                            <div className=" wid20 color-green line-78">{!order?0:order.price/100*4.5}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>啟動音樂文創有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>上海商業儲蓄銀行（世貿分行）</div>
                                                <div className=" table-all"><span>账号：</span>43102000032270</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div><a onClick={this.handleClose.bind(this)} className="cancle">已阅读，点击关闭</a></div>
                    </div>
                </div>
                <UpdateShopCar/>
                <WxCode {...dispatch}></WxCode>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(Cart);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);