import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout ,Pagination} from "antd";
import Loading from "../Loading";
import moment from "moment";
import { redirectTo } from "dispatch";
import { CUSTOMIZED_LOAD } from "constants/actionTypes";
import agent from "agent";
import "./User.less";

const { Content } = Layout;

const mapStateToProps = state => ({
    ...state.user,
    ...state.customized,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
  
    loadCustomezed: (params) => {
        dispatch({
            type: CUSTOMIZED_LOAD,
            payload: agent.Customized.getcustomized(params),
            params,
        });
    },
});

class Customized extends React.Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        infos: PropTypes.array,
        pagination: PropTypes.object,
        loadCustomezed:PropTypes.func.isRequired,
    };
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }
    componentDidMount() {
        const { userLogin,loadCustomezed } = this.props;
        if (!userLogin) {
            redirectTo("/login");
            return false;
        }
        const params = {
            size: 10,
            page: 1,
            orderby: "-id",
        };
       
        loadCustomezed(params);
    }
    onPageChange(pageNumber) {
        const {loadCustomezed } = this.props;
        window.scrollTo(0, 0);
        const pager = { size: 10, page: 1, orderby: "-id" };
        pager.page = pageNumber;
    
        loadCustomezed(pager);
    }
    renderCell(item,i) {
        return (
            <div key={`purchase-${i}`} className="buyRecordBox" id={item.id}>
                <div className="buyRecordBox__header">
                    <p className="buyRecordBox__date">
                        {moment(item.create_time).format("YYYY/MM/DD")}
                    </p>
                    <p className="buyRecordBox__title">购买&quot;懒人推荐&quot;歌曲 {item.music_number}曲</p>
                    <p className="buyRecordBox__title" style={{width: "200px"}} >价格¥ {item.total/100}</p>
                </div>
            </div>
        );
    }
    back(e){
        redirectTo("/user/mypage");
    }
    render() {
        const {
            loading,
            currentUser,
            pagination,
            infos
        } = this.props;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">懒人推荐记录</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="buyRecord">
                            <div className="m-tabBox">
                                <ul className="m-tabBox__list" role="tablist">
                                    <li className="m-tabBox__list__item">
                                       
                                    </li>
                                   
                                    <li style={{width:'100%'}}><button onClick={this.back.bind(this)} className='backHome'>返回</button></li>
                                </ul>
                                
                            </div>
                            <div className="buyRecord__inner">
                                {infos.map(
                                    this.renderCell.bind(this)
                                )}
                            </div>
                            <div className="resultPagination" style={{textAlign:"center",marginTop:"10px"}}>
                                <Pagination
                                    defaultPageSize={pagination.pageSize}
                                    {...pagination}
                                    hideOnSinglePage={true}
                                    onChange={this.onPageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Customized);
