import React from "react";
import PropTypes from "prop-types";
import "./LeftItems.less";
import Loading from "components/Loading";
const queryString = require("query-string");
import {checkTiao,noShowPage} from "dispatch";

class Music extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        handleSearchClick: PropTypes.func.isRequired,
        handleChangeClick: PropTypes.func.isRequired,
        show: PropTypes.string.isRequired,
        loading: PropTypes.bool.isRequired,
        duration: PropTypes.array,
    };

    handleChangeClick = e => {
        const { handleChangeClick } = this.props;
        handleChangeClick("duration");
    };
    handleSearchClick(e) {
        const { handleSearchClick } = this.props;
        const query = queryString.parse(this.props.location.search);
        const tiao =noShowPage.baseDecode(query.tiao);
        const duration=checkTiao("duration",tiao);
        let val = e.target.id;
        if (duration == val) {
            val = "";
        }
        handleSearchClick("duration", val);
    }
    renderCell(record) {
        const query = queryString.parse(this.props.location.search);
        const tiao =noShowPage.baseDecode(query.tiao);
        const lengths = tiao.duration;
        return (
            <li key={record.id} className="navList01__item">
                <a
                    href="javascript:void(0)"
                    className={
                        lengths == record.id
                            ? "m-btn-type2-select"
                            : "m-btn-type2"
                    }
                    id={record.id}
                    onClick={this.handleSearchClick.bind(this)}
                >
                    <span>{record.title}</span>
                </a>
            </li>
        );
    }
    render() {
        const { show, loading, duration } = this.props;
        const playClass =
            "accordionBox " + (show == "duration" ? "is-open" : "is-close");

        const lengthData = duration;
        return (
            <div className={playClass}>
                <p
                    className="accordionBox__title"
                    onClick={this.handleChangeClick.bind(this)}
                >
                    长度
                </p>
                <div className="accordionBox__contents">
                    <ul className="navList01">
                        {lengthData != null ? (
                            lengthData.map(this.renderCell.bind(this))
                        ) : (
                            <Loading loading={loading} />
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}
export default Music;
