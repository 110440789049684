import {
    SELLERREGISTER,
    SELLERREGISTER_PAGE_UNLOADED,
    ASYNC_START,
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {

        case SELLERREGISTER:
            return {
                ...state,
                inProgress: false,
                registeSuccess: action.error ? false : true,
                errors: action.error ? action.payload.errors : null,
            };
        case SELLERREGISTER_PAGE_UNLOADED:
            return {};
        case ASYNC_START:
            if (action.subtype === SELLERREGISTER) {
                return { ...state, inProgress: true };
            }
            break;
        default:
            return state;
    }

    return state;
};
