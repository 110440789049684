import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import myFavorite from "./MyFavorite";

class Favorite extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}`} component={myFavorite} />
            </Switch>
        );
    }
}
export default Favorite;
