// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { 
    MUSIC_PLAY,
    MUSIC_TABLE_UNLOAD,
    MUSIC_SEARCH_LABEL_LOAD_All,
    SHOW_SHOP_CAR,
    CHECK_PRICE_GROUP,
} from "constants/actionTypes";
import { Pagination } from "antd";
import Loading from "components/Loading";
import "./MusicTable.less";
import FavoriteTableCell from "components/Music/comps/FavoriteTableCell";
import {addFavorite, rmFavorite, redirectTo } from "dispatch";
import agent from "agent";
const queryString = require("query-string");

const mapStateToProps = state => ({
    ...state.musicTable,
    musicPlay: state.player.audio,
    userLogin: state.app.userLogin,
    labels: state.labels.labelsDataAll,
});

const mapDispatchToProps = dispatch => ({
    playMusic: musicPlay => {
        return dispatch({ type: MUSIC_PLAY, musicPlay });
    },
    unLoadTable: () => {
        return dispatch({ type: MUSIC_TABLE_UNLOAD });
    },
    loadLabelAll: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD_All,
            payload: agent.Music.searchLabelAlls(),
        });
    },
    showCarPopup: addAudio => {
        return dispatch({ type: SHOW_SHOP_CAR, addAudio });
    },
    checkPriceGroup:id => {
        return dispatch({
            type: CHECK_PRICE_GROUP,
            payload: agent.PriceGroup.get(id),
        });
    },
});

class MusicTable extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        tableData: PropTypes.array,
        musicPlay: PropTypes.object,
        loadData: PropTypes.func.isRequired,
        unLoadTable: PropTypes.func.isRequired,
        playMusic: PropTypes.func.isRequired,
        one:PropTypes.bool,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        inverted_condition:PropTypes.object,
        one_id:PropTypes.number,
        loadLabelAll:PropTypes.func.isRequired,
        showCarPopup:PropTypes.func.isRequired,
        checkPriceGroup:PropTypes.func.isRequired,
    };
    componentDidMount() {
        this.loadCurrentPageData();
    }

    loadCurrentPageData = () => {
        const { loadData, pagination, location,one ,inverted_condition,one_id} = this.props;
        if (one) {
            const pager={
                current:1,
                pagerSize:4
            }
            const theSame={
                sameStyle:inverted_condition.style,
                sameEmotion:inverted_condition.emotion,
                sameScene:inverted_condition.scene,
                sameInstrument:inverted_condition.instrument,
                oneId:one_id,
            }
            const params = {
                page: 1,
                size: 4,
                ...theSame,
            };
            loadData(pager, params);
        }else{
            const pager = { ...pagination };
            pager.current = 1; // default
            const query = queryString.parse(location.search);
            const params = {
                page: pager.current,
                ...query,
                size: pagination.pageSize,
            };
            // reset from params
            pager.current = parseInt(params.page);
    
            loadData(pager, params);
        }
        
    };

    componentWillUnmount() {
        this.props.unLoadTable();
    }

    onPageChange(pageNumber) {
        const { loadData, pagination, match, location } = this.props;
        const pager = { ...pagination };
        pager.current = pageNumber;
        const query = queryString.parse(location.search);
        const params = {
            ...query,
            page: pager.current,
        };
        const stringified = queryString.stringify(params);
        redirectTo({
            pathname: match.url,
            search: `?${stringified}`,
        });
        params.size = pagination.pageSize;
        loadData(pager, params);
    }

    idFavoritePage = () => {
        const { match } = this.props;
        return match.path.indexOf("/favorite") === 0;
    };

    clickFavorite(audio) {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return;
        }
        if (audio.like) {
            let callback = null;
            if (this.idFavoritePage()) {
                // favorite list reload page
                callback = () => {
                    this.loadCurrentPageData();
                };
            }
            rmFavorite(
                { audioid: audio.audioid, like_id: audio.like.id },
                callback
            );
        } else {
            addFavorite({ audioid: audio.audioid });
        }
    }

    //显示弹出窗
    handleAddCart(audio) {
        const { showCarPopup ,checkPriceGroup} = this.props;
        const send = {
            showAddCar:true,
            addAudio: audio,
        };
        //得到歌曲的价格组
        checkPriceGroup(audio.price_group_id);
        //显示添加购物车的弹出窗
        showCarPopup(send);
    }

    renderCell(audio, i) {
        const { musicPlay, playMusic, labels } = this.props;
        audio.playing =
            !!musicPlay && musicPlay.id === audio.id && !!musicPlay.playing;
        const dispatch = {
            labels,
            playMusic,
            clickFavorite: this.clickFavorite.bind(this),
            addCart: this.handleAddCart.bind(this),
        };
        return <FavoriteTableCell key={`cel-${i}`} audio={audio} {...dispatch} />;
    }

    render() {
        const { loading, pagination, tableData: audios ,one} = this.props;
        const isShow=one?"resultPagination hidden":"resultPagination page-left";
        if (!audios || !audios.length) {
            return (
                <div className="resultItemWrapper text-center">
                    <p>没有音乐</p>
                </div>
            );
        }
        return (
            <div className="resultItemWrapper c02b478-music-table" >
                <Loading loading={loading} />
                {audios.map(this.renderCell.bind(this))}
                <div className=" creatorpage-ov" style={{textAlign:"center"}}>
                    {/**喜爱列表显示跳十页 */}
                    <div className={isShow}>
                        <Pagination
                            defaultPageSize={pagination.pageSize}
                            {...pagination}
                            hideOnSinglePage={true}
                            onChange={this.onPageChange.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MusicTable);
