import {
    SHOW_UPDATE_CAR,
    CHECK_PRICE_GROUP_UPDATE,
} from "../constants/actionTypes";
const defaultState = {
    updateCar:{
        showUpdateCar:false,
        car:{},
    },
    priceGroup:[],
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case SHOW_UPDATE_CAR: {
            return {
                ...state,
                updateCar:action.updateCar,
            };
        }
        case CHECK_PRICE_GROUP_UPDATE: {
            return {
                ...state,
                priceGroup:!action.error?action.payload.priceGroup:[],
            };
        }
        default:
            return state;
    }
};
