import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import {
    WITHDRAW_INFO,
    WITHDRAW,
    SETTLEMENT_PAGE_UNLOADED,
} from "constants/actionTypes";

import { loadSellerProfileInfo } from "dispatch";

import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import { redirectTo } from "dispatch";

const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadWithdrawInfo: payload => {
        return dispatch({
            type: WITHDRAW_INFO,
            payload: agent.Seller.loadWithdrawInfo(),
        });
    },

    withdraw: payload => {
        return dispatch({
            type: WITHDRAW,
            payload: agent.Seller.withdraw(),
        });
    },

    unloadSettlement: () => {
        return dispatch({ type: SETTLEMENT_PAGE_UNLOADED });
    },
});

class Settlement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notWithdraw: false,
            withdraw: false,
        }
        this.handleWithdraw = this.handleWithdraw.bind(this);
    }

    static propTypes = {
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        withdrawSuccess: PropTypes.bool,
        profile: PropTypes.object,
        withdrawInfo: PropTypes.object.isRequired,

        loadWithdrawInfo: PropTypes.func.isRequired,
        withdraw: PropTypes.func.isRequired,
        unloadSettlement: PropTypes.func.isRequired,
    };

    static defaultProps = {
        withdrawSuccess: false,
    };

    componentWillUnmount() {
        this.props.unloadSettlement();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (nextProps.withdrawSuccess && agent.getToken()) {
            redirectTo("/seller/settlement");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user profile
        loadSellerProfileInfo();
        this.showFormItemServerError();
    }

    handleWithdraw = e => {
        e.preventDefault();
        const { withdrawInfo } = this.props;
        console.info(withdrawInfo);
        this.setState({
            withdraw:true,
        })
        // if (withdrawInfo && withdrawInfo.unpay > 0) {
        //     this.setState({
        //         withdraw:true,
        //     })
        // } else {
        //     this.setState({
        //         notWithdraw:true,
        //     })
        // }
    };
    closeShow(){
        this.setState({
            notWithdraw:false,
            withdraw:false,
        })
    }
    showFormItemServerError() {
        const errors = this.props.errors;

        if (errors) {
            {
                Object.keys(errors).map(key => {
                    let message = errors[key];

                    switch (message.param) {
                        case "cash":
                            this.props.form.setFields({
                                cash: {
                                    value: message.value,
                                    errors: [new Error(message.msg)],
                                },
                            });
                            break;
                    }
                });
            }
        }
    }

    render() {
        const { currentUser, withdrawInfo } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }
        console.info(withdrawInfo);
        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox minemusic">
                            <h2 className="mymusicBox__title text-center">我的帐户</h2>
                            <div className="cashItemWrapper">
                                <div className="mine-cash ov">
                                    <div className=" left wid30"><span className=" f-600">总收入：</span>6000元</div>
                                    <div className=" left wid30"><span className=" f-600">一提现金额：</span>1000元</div>
                                    <div className=" left wid40">
                                        <span className=" left"><img height="18" src="../assets/img/cash.png"/></span>
                                        <span className=" f-600 margin-left-5">可提现金额：</span>5000
                                        <span className=" right"><a className="cashup" href="">申请提现</a></span>
                                    </div>
                                </div>
                            </div>

                            <div className="mymusicBox__tab m-tabBox3 m-tabBox--s" >
                                <ul className="m-tabBox__list" role="tablist" data-column="auto">
                                    <li className="m-tabBox__list__item"><a aria-controls="tab-01" aria-selected="true" href="#tab-01" role="tab">提现记录</a></li>
                                    <li className="m-tabBox__list__item"><a aria-controls="tab-02" aria-selected="false" href="#tab-02" role="tab">音乐收入</a></li>
                                </ul>
                                <div className="m-tabBox__tabpanel-wrap">
                                    <div className="m-tabBox__tabpanel" aria-hidden="false" id="tab-01" role="tabpanel">
                                        <div className="settlementWrapper">
                                            <div className=" ov settlementWrapper-tltle">
                                                <div className=" left wid20">提现时间</div>
                                                <div className=" left wid20">提现金额</div>
                                                <div className=" left wid20">收款账号</div>
                                                <div className=" left wid20">提现状态</div>
                                                <div className=" left wid20">金额明细</div>
                                            </div>
                                            <div className=" settlementWrapper-de">
                                                <table>
                                                    <tr>
                                                        <td className="settlementWrapper-deti">
                                                            <div>2019.06.30</div>
                                                            <div>14:30:00</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            1500元
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <div>20311********45555</div>
                                                            <div>（工商银行）</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            已支付
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <a className=" export" href=""><img src="../assets/img/down.png"/>导出Excel</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="settlementWrapper-deti">
                                                            <div>2019.06.30</div>
                                                            <div>14:30:00</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            1500元
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <div>20311********45555</div>
                                                            <div>（工商银行）</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            未支付
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <a className=" export" href=""><img src="../assets/img/down.png"/>导出Excel</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <div className="settlementWrapper">
                                            <div className=" ov settlementWrapper-tltle">
                                                <div className=" left wid20">音乐名称</div>
                                                <div className=" left wid20">创作人</div>
                                                <div className=" left wid20">项目名称</div>
                                                <div className=" left wid20">交易时间</div>
                                                <div className=" left wid20">税前收入</div>
                                            </div>
                                            <div className=" settlementWrapper-de">
                                                <table>
                                                    <tr>
                                                        <td className="settlementWrapper-deti">
                                                            过往的时间 Past time
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            奕颗贝壳
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            大侠
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <div>2019.06.30</div>
                                                            <div>14:30:00</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            900元
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="settlementWrapper-deti">
                                                            过往的时间 Past time
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            奕颗贝壳
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            大侠
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            <div>2019.06.30</div>
                                                            <div>14:30:00</div>
                                                        </td>
                                                        <td className="settlementWrapper-deti">
                                                            900元
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="resultPagination">
                                            <ul className="resultPaginationList">
                                                <li className="resultPagination__item"><a href="" className="is-current">1</a></li>
                                                <li className="resultPagination__item"><a href="">2</a></li>
                                                <li className="resultPagination__item"><a href="">3</a></li>
                                                <li className="resultPagination__item"><a href="">4</a></li>
                                                <li className="resultPagination__item"><a href="">5</a></li>
                                                <li className="resultPagination__item"><a href="">6</a></li>
                                                <li className="resultPagination__item"><a href="">7</a></li>
                                                <li className="resultPagination__item"><a href="">8</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="m-tabBox__tabpanel" aria-hidden="true" id="tab-02" role="tabpanel">
                                        <div className="settlementWrapper">
                                            <p className="settlement__amount u-taR">共收入　XXXX元</p>

                                            <div className="settlementItem">
                                                <dl className="settlementItem__list">
                                                    <dt className="settlementItem__dt">交易内容 （音乐收入）</dt>
                                                    <dd className="settlementItem__dd">￥○○○</dd>
                                                </dl>
                                                <dl className="settlementItem__list2">
                                                    <dt className="settlementItem__dt2">交易日期</dt>
                                                    <dd className="settlementItem__dd2">XXXX年XX月XX日</dd>
                                                </dl>
                                            </div>

                                            <div className="settlementItem">
                                                <dl className="settlementItem__list">
                                                    <dt className="settlementItem__dt">交易内容 （音乐收入）</dt>
                                                    <dd className="settlementItem__dd">￥○○○</dd>
                                                </dl>
                                                <dl className="settlementItem__list2">
                                                    <dt className="settlementItem__dt2">交易日期</dt>
                                                    <dd className="settlementItem__dd2">XXXX年XX月XX日</dd>
                                                </dl>
                                            </div>

                                            <div className="settlementItem">
                                                <dl className="settlementItem__list">
                                                    <dt className="settlementItem__dt">交易内容 （音乐收入）</dt>
                                                    <dd className="settlementItem__dd">￥○○○</dd>
                                                </dl>
                                                <dl className="settlementItem__list2">
                                                    <dt className="settlementItem__dt2">交易日期</dt>
                                                    <dd className="settlementItem__dd2">XXXX年XX月XX日</dd>
                                                </dl>
                                            </div>

                                            <div className="settlementItem">
                                                <dl className="settlementItem__list">
                                                    <dt className="settlementItem__dt">交易内容 （音乐收入）</dt>
                                                    <dd className="settlementItem__dd">￥○○○</dd>
                                                </dl>
                                                <dl className="settlementItem__list2">
                                                    <dt className="settlementItem__dt2">交易日期</dt>
                                                    <dd className="settlementItem__dd2">XXXX年XX月XX日</dd>
                                                </dl>
                                            </div>

                                            
                                        </div>

                                        <div className="resultPagination">
                                            <ul className="resultPaginationList">
                                                <li className="resultPagination__item"><a href="" className="is-current">1</a></li>
                                                <li className="resultPagination__item"><a href="">2</a></li>
                                                <li className="resultPagination__item"><a href="">3</a></li>
                                                <li className="resultPagination__item"><a href="">4</a></li>
                                                <li className="resultPagination__item"><a href="">5</a></li>
                                                <li className="resultPagination__item"><a href="">6</a></li>
                                                <li className="resultPagination__item"><a href="">7</a></li>
                                                <li className="resultPagination__item"><a href="">8</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const SettlementForm = Form.create()(Settlement);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettlementForm);
