import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import { producerStatus } from "config/producerStatus";

import GeneralSellerRegister from "./GeneralSellerRegister";
import CompanySellerRegister from "./CompanySellerRegister";
import RegisteSuccess from "./SellerRegisteSuccess";

import { loadCurrentUser, redirectTo } from "dispatch";
const mapStateToProps = state => ({ ...state.user });

class SellerRegisterBase extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        if (this.props.currentUser === null) redirectTo("/");
        else if (this.props.currentUser.producer === producerStatus.APPLY) {
            redirectTo("/sellerregister/success");
        } else if (this.props.currentUser.producer === producerStatus.YES) {
            redirectTo("/seller/profile");
        }
    }

    render() {
        if (this.props.currentUser === null) return null;
        return this.props.currentUser.company ? <CompanySellerRegister /> : <GeneralSellerRegister />;
    }
}

const SellerRegisterConnected = connect(mapStateToProps)(SellerRegisterBase);

class SellerRegister extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        loadCurrentUser();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (prevProps.currentUser.producer !== this.props.currentUser.producer) {
            if (this.props.currentUser.producer == producerStatus.YES) {
                redirectTo("/seller/profile");
            } else if (this.props.currentUser.producer == producerStatus.APPLY) {
                redirectTo("/sellerregister/success");
            }
        }
    }

    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Route path={`${match.url}/success`} component={RegisteSuccess} />
                <Route path={`${match.url}`} component={SellerRegisterConnected} />
            </Switch>
        );
    }
}
export default connect(mapStateToProps)(SellerRegister);
