import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import userRegister from "./UserRegister";
import registeSuccess from "./RegisteSuccess";

class Register extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route
                    path={`${match.url}/success`}
                    component={registeSuccess}
                />
                <Route path={`${match.url}/`} component={userRegister} />
            </Switch>
        );
    }
}
export default Register;
