// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import agent from "agent";

import { producerStatus } from "config/producerStatus";
//import Avatar from "components/Avatar";
import "./Header.less";

const mapStateToProps = state => ({ ...state.header, ...state.user });

const mapDispatchToProps = dispatch => ({});

class Webheader extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        currentUser: PropTypes.object,
        linkTo: PropTypes.func.isRequired,
    };
    myprofile() {
        const { currentUser, linkTo } = this.props;
        console.log(currentUser)
        if (currentUser) {
            const isProducer = producerStatus.isProducer(currentUser.producer);
            const avatars = !currentUser.avatar ? '/assets/img/img_avatar2.jpg' : agent.getApiUrl(currentUser.avatar);
            const style = {
                height: '42px',
                width: '42px',
                background: 'url(' + avatars + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            }
            return (
                <nav className="utilityNav" id="guide">
                    <ul className="utilityNav__items">
                        <li className="utilityNav__item">
                            {isProducer ? (
                                <a onClick={linkTo} href="/seller/profile">
                                    卖家个人中心
                                </a>
                            ) : (
                                <a onClick={linkTo} href="/become">
                                    成为卖家
                                </a>
                            )}
                        </li>
                    </ul>
                    <ul className="userNav__items">
                        <li className="userNav__item">
                            <div className="userNav__avatar">
                                <div className="userNav__avatar__img">
                                    <a onClick={linkTo} href="/user/mypage" style={style} >
                                        {/* <img
                                            src={agent.getApiUrl(
                                                currentUser.avatar
                                            )}
                                        /> */}
                                    </a>
                                </div>
                                <p className="userNav__avatar__name">
                                    <a onClick={linkTo} href="/user/mypage">
                                        {currentUser.nickname}
                                    </a>
                                </p>
                            </div>
                        </li>

                        <li className="userNav__item userNav__item--logout">
                            <a onClick={linkTo} href="/logout">
                                退出
                            </a>
                        </li>
                    </ul>
                </nav>
            );
        }
        return (
            <nav className="utilityNav">
                <ul className="utilityNav__items">
                    <li className="utilityNav__item">
                        <a onClick={linkTo} href="/login">
                            成为卖家
                        </a>
                    </li>
                </ul>
                <ul className="loginNav__items">
                    <li className="loginNav__item">
                        <a onClick={linkTo} href="/login">
                            登录
                        </a>
                    </li>
                    <li className="loginNav__item">
                        <a onClick={linkTo} href="/register">
                            注册
                        </a>
                    </li>
                </ul>
            </nav>
        );
    }

    renderLi = (title, to) => {
        const { linkTo, location } = this.props;
        let className = "globalNav__item";

        if (to && location.pathname == to) {
            className += " is-current";
        }else if(to == "/free" && location.pathname == "/free"){
            className += " is-current";
        }
        let imgPath = "/assets/img/sub_f.png";
        if (title == '金V曲库'){
            imgPath = "/assets/img/sub_v.png";
        }
        if (title == '音效素材') {
            // window.localStorage.setItem('search', JSON.stringify(true))
        }
        if (title == '金V曲库') {
            return (
                <li key={`${to}-${title}`} className={className}>
                    <a onClick={linkTo} href={to} className="globalNav__item_vip_color">
                        <img height="27px" width="27px" className="sub_img" src={imgPath} />{title}
                    </a>
                </li>
            );
        }else if (title == '免费曲库') {
            return (
                <li key={`${to}-${title}`} className={className}>
                    <a onClick={linkTo} href={to} className="globalNav__item_free_color">
                        {title}
                    </a>
                </li>
            );
        }else if (title == '音效素材') {
            return (
                <li key={`${to}-${title}`} className={className}>
                    <a onClick={linkTo} href={to} className="globalNav__item_material_color">
                        {title}
                    </a>
                </li>
            );
        }else if(title == '合作案例'){
            return (
                <li key={`${to}-${title}`} className={className}>
                    <a href="https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzU2MDcxMTM4Mw==&action=getalbum&album_id=2441165400169349122#wechat_redirect">
                        {title}
                    </a>
                </li>
            );
        }
        else {
            return (
                <li key={`${to}-${title}`} className={className}>
                    <a onClick={linkTo} href={to}>
                        {title}
                    </a>
                </li>
            );
        }

    };

    render() {
        // const {location, linkTo, cartCount} = this.props;
        const { linkTo } = this.props;
        const leftLinks = {
            搜索音乐: "/music/search",
            定价: "/fixPrice",
            创作人: "/maker",
            歌曲定制: "/composition",
            金V曲库: "/subscribermembers",
            免费曲库: "/free",
            音效素材: "/music/material",
            合作案例: "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzU2MDcxMTM4Mw==&action=getalbum&album_id=2441165400169349122#wechat_redirect",
        };
        return (
            <header className="globalHeader js-nav js-menuOffset--r">
                <div className="globalHeader__inner">
                    <div className="globalHeader__logoBox">
                        <h1 className="globalHeader__logo">
                            <a onClick={linkTo} href="/">
                                <img
                                    src="/assets/img/logo.svg"
                                    alt="MUSINESS"
                                />
                            </a>
                        </h1>
                    </div>
                    <div className="globalHeader__menu js-navMenu">
                        <div className="globalNavOverlay__close js-navClose" />
                        <div className="globalHeader__menu__inner">
                            <nav className="globalNav">
                                <ul className="globalNav__items">
                                    {Object.entries(leftLinks).map(
                                        ([key, value]) =>
                                            this.renderLi(key, value)
                                    )}
                                </ul>
                            </nav>
                            {this.myprofile()}
                        </div>
                    </div>
                    <p className="globalHeader__toggleBtn js-navToggle">
                        <span className="globalHeader__toggleBtn__icon" />
                    </p>
                </div>
            </header>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Webheader);
