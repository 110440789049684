import React from "react";
import PropTypes from "prop-types";
import { NEWS_ONE, NEWS_ONE_UNLOAD, PREVI_OUS } from "constants/actionTypes";
import agent from "agent";
import { connect } from "react-redux";
import Loading from "../Loading";
import moment from "moment";
import { HtmlUtil } from "dispatch";
import { Link } from "react-router-dom";

const mapStateToProps = state => ({
    mediaDetail: state.media.mediaDetail,
    loading: state.media.loading,
    prev: state.media,
    previous: state.media.prav.payload
});
const mapDispatchToProps = dispatch => ({

    loadPage: newsid => {
        return dispatch({
            type: NEWS_ONE,
            payload: agent.News.one(newsid),
        });
    },
    unloadPageOne: () => {
        return dispatch({ type: NEWS_ONE_UNLOAD });
    },
    prev: newsid => {
        return dispatch({
            type: PREVI_OUS,
            payload: agent.Chapter.previous(newsid),
        });
    }
})

class MediaDetail extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        loadPage: PropTypes.func.isRequired,
        mediaDetail: PropTypes.object,
        loading: PropTypes.bool.isRequired,
        unloadPageOne: PropTypes.func.isRequired,
        prev: PropTypes.object,
        previous: PropTypes.string,
    };

    onnext = newsid => {
        return (e) => {
            this.props.loadPage(newsid);
            this.props.prev(newsid)
        }
    }
    onprevious = newsid => {
        return (e) => {
            this.props.loadPage(newsid);
            this.props.prev(newsid)
        }
    }
    componentDidMount() {
        const id = this.props.match.params.newsid;
        this.props.loadPage(id);
        this.props.prev(id);
    }
    componentWillUnmount() {
        this.props.unloadPageOne();
    }
    render() {
        const { mediaDetail, loading, previous } = this.props;
        let userMessage;
        if (!mediaDetail || !previous) {
            return <Loading loading={loading} />
        }
        if (previous) {
            if (previous.next && previous.previous) {
                userMessage = (<div className="mediaContantBox__chapter">
                    <Link to={'/media/newsid/' + previous.previous}> <p onClick={this.onnext(previous.previous)}>&lt;&lt; &nbsp;上一篇 </p></Link>
                    <Link to={'/media/newsid/' + previous.next}>   <p onClick={this.onprevious(previous.next)}>下一篇&nbsp; &gt;&gt; </p></Link>
                </div>)
            } else if (previous.next == undefined) {
                userMessage =
                    (
                        <div className="mediaContantBox__chapter">
                            <p onClick={this.onnext(previous.previous)}>上一篇  </p>
                            <p onClick={this.onprevious(previous.next)}>  </p>

                        </div>)
            } else if (previous.previous == undefined) {
                userMessage =
                    (
                        <div className="mediaContantBox__chapter">
                            <p onClick={this.onnext(previous.previous)}>   </p>
                            <p onClick={this.onprevious(previous.next)}> 下一篇  </p>
                        </div>
                    )
            }
        }

        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">
                            <span>
                                {mediaDetail.title}
                            </span>
                        </h1>
                    </header>
                    <div className="mainContents__box mediaContantBox">
                        <div className="mediaContantBox__header">
                            <div className="mediaContantBox__header__inner">
                                <p className="mediaContantBox__category">
                                    {mediaDetail.creator}
                                </p>
                                <p className="mediaContantBox__date">
                                    <time dateTime="2018-10-31">
                                        {moment(mediaDetail.news_time).format('YYYY-MM-DD')}
                                    </time>
                                </p>
                            </div>
                        </div>
                        <div className="mediaContantBox__main">
                            <div className="mediaContantBox__main__inner" dangerouslySetInnerHTML={{ __html: HtmlUtil.htmlDecode(mediaDetail.news_content) }}>
                            </div>
                            <div>
                                {userMessage}
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MediaDetail);
