import { SHOW_FORGET_POPUP, FORGET_PASSWORD ,FORGOT_PASSWORD_CAPTCHA} from "../constants/actionTypes";
const defaultState = {};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case SHOW_FORGET_POPUP: {
            return {
                ...state,
                isShow: action.isShow,
            };
        }
        case FORGET_PASSWORD: {
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                isShow: { forgetShow: action.error },
            };
        }
        case FORGOT_PASSWORD_CAPTCHA:
            return {
                ...state,
                inProgress: false,
                errors: action.error ? action.payload.errors : null,
            };
        default:
            return state;
    }
};
