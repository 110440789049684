import React from "react";
import PropTypes from "prop-types";
import QRCode  from 'qrcode.react';
import agent from "agent";
import { connect } from "react-redux";
//import { redirectTo } from "dispatch";
import { CURRENT_USER, CHECK_ORDER_BY_ORDER_ID } from "constants/actionTypes";

const mapStateToProps = state => ({
    qrUrl:state.cart.codeUrl,
    codeShow:state.cart.codeShow,
    orderid:state.cart.orderid,
    orderState:state.cart.orderState,
    price:state.cart.price,
});

const mapDispatchToProps = dispatch => ({
    loadCurrentUser: () =>{
        return dispatch({ 
            type: CURRENT_USER,
            payload: agent.Auth.current() ,
        });
    },
    checkByOrderid: (orderid) =>{
        return dispatch({ 
            type: CHECK_ORDER_BY_ORDER_ID,
            payload: agent.Cart.chenckByOrderid(orderid) ,
        });
    },
})
class WxCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qrUrl: "",
            beginCheck:false,
            beginDao:false,
            sen:2,
            min:"00",
        };
        this.closeShow = this.closeShow.bind(this);
        this.checkOrderState = this.checkOrderState.bind(this);
        this.daojishi = this.daojishi.bind(this);
        this.closeTime;
        this.checkOrder;
        this.jishiqi;
    }
    static propTypes = {
        //location: PropTypes.object.isRequired,
        loadCurrentUser:PropTypes.func.isRequired,
        qrUrl: PropTypes.string,
        codeShow: PropTypes.bool.isRequired,
        closeCode: PropTypes.func.isRequired,
        checkByOrderid: PropTypes.func.isRequired,
        orderid: PropTypes.string,
        orderState: PropTypes.number,
        price: PropTypes.string,
    };
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        const { orderState, closeCode, codeShow, orderid} = this.props;
        if (orderState==1) {
            clearInterval(this.checkOrder);
            clearTimeout(this.closeTime);
            clearInterval(this.jishiqi);
            closeCode();
            if (orderid.length==25) {
                location.href ="/user/subscribe?isHistory=2";
            }else if(orderid.length==32){
                location.href ="/user/purchase?isHistory=2";
            }
            return;
        }
        if (this.state.beginDao) {
            return;
        }
       
        clearInterval(this.checkOrder);
        clearTimeout(this.closeTime);
        clearInterval(this.jishiqi);
        if (codeShow) {
            //20秒后自动关闭
            this.closeTime = setTimeout(this.closeShow, 122000);
            //3秒轮询一次订单状态
            this.checkOrder = setInterval(this.checkOrderState, 5000);
            // 调用倒计时
            this.daojishi();
           
        }else{
            clearInterval(this.checkOrder);
            clearTimeout(this.closeTime);
            clearInterval(this.jishiqi);
        }
       
       
    }
    daojishi(){
        let lock = 120;
        this.setState({ 
            sen:2,
            min:"00",
            beginDao:true,
        });
        this.jishiqi = setInterval(() => {
            lock--;
            if (lock==0) {
                clearInterval(this.jishiqi);
                this.setState({ 
                    sen:"0",
                    min:"00",
                });
            }else{
                let min=lock%60;
                if (min<10) {
                    min="0"+min; 
                }
                this.setState({ 
                    sen: parseInt(lock/60),
                    min:min, 
                });
            }
            
        }, 1000);
    }
    //检查订单状态
    checkOrderState(){
        const {orderid, checkByOrderid} = this.props;
        if (orderid) {
            checkByOrderid(orderid);
        }else{
            clearInterval(this.checkOrder);
        }
    }

    //关闭二维码
    closeShow(){
        const {closeCode } = this.props;
        clearInterval(this.checkOrder);
        clearTimeout(this.closeTime);
        clearInterval(this.jishiqi);
        this.setState({ 
            sen:2,
            min:"00",
            beginDao:false,
        });
        closeCode();
    }
    render() {
        const { qrUrl, codeShow ,price}=this.props;
        if (!qrUrl) {
            return "";
        }
        const isShow = codeShow ? "dialog-bg" : "hidden";
        return (
            <div className={isShow}  style={{WebkitOverflowScrolling: "touch"}}>
                <div className="modalOverlay" />
                <div className="choose-container scroll-y modalBox__inner code_style">
                    <div className=" close-choose" style={{overflow:"hidden"}}> <span style={{fontSize:"20px",fontWeight:"600"}}>微信支付</span><a style={{cursor:"pointer"}} onClick={this.closeShow}><img height="24" src="/assets/img/u50.png"/></a></div>
                    <div className="code_pay_monery">支付金额&nbsp;&nbsp;¥{price}</div>
                    <div className="code_pay_time"><img width="16px" style={{marginRight:"14px"}}  src="/assets/img/code_time.png" />{this.state.sen}:{this.state.min}</div>
                    <QRCode
                        value={qrUrl}  //value参数为生成二维码的链接
                        size={200} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                        style={{margin:"auto"}}
                    />
                  
                    <div className="open_wechat">
                        <div style={{float:"left"}}><img style={{marginRight:"15px"}} width="50px" src="/assets/img/code_sao.png" /></div>
                        <div>
                            <p>打开手机微信</p>
                            <p>扫一扫二维码</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WxCode);
