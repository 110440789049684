import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NEWS_LIST,NEWS_LIST_UNLOAD } from "constants/actionTypes";
import agent from "agent";
import moment from "moment";
import { Pagination } from "antd";
import Loading from "../Loading";

class News extends React.Component {
    static propTypes = {
        news: PropTypes.object.isRequired,
        getNewsList: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        unloadPageOne: PropTypes.func.isRequired,
    };
    componentDidMount() {
        this.props.getNewsList({ pager: { current: 1, pageSize: 10 , orderby: "-weight" } });
    }
    componentWillUnmount() {
        this.props.unloadPageOne();
    }
    onPageChange(page) {
        const {
            pager: { pageSize },
        } = this.props.news;

        this.props.getNewsList({
            pager: {
                pageSize,
                current: page,
            },
        });
    }
    render() {
        const {
            total,
            pager: { pageSize, current },
            data,
        } = this.props.news;
        if (!data) {
            return (
                <div className="resultItemWrapper">
                    <Loading />
                </div>
            );
        }
        const newsList = data.map((x, i) => {
            return (
                <div className="mediaItem" key={i}>
                    <p className="mediaItem__title">
                        <Link to={`/media/newsid/${x.id}`}>
                            {x.title}
                        </Link>
                    </p>
                    <div className="mediaItem__info">
                        <p className="mediaItem__category">
                            {x.creator}
                        </p>
                        <p className="mediaItem__date">
                            <time dateTime="2018-12-21" >
                                {moment(x.news_time).format('YYYY-MM-DD')}
                            </time>
                        </p>
                    </div>
                </div>
            );
        });
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">媒体报导</h1>
                    </header>
                    <div className="mainContents__box mediaBox">
                        <div className="mediaBox__main">
                            <div className="mediaBox__main__inner">
                                <div className="mediaItemWrapper">
                                    {newsList}
                                </div>
                            </div>
                        </div>
                        <div className="mediaPagination">
                            <div className="resultPagination " style={{textAlign:"center"}}>
                                <Pagination
                                    defaultPageSize={pageSize}
                                    pageSize={pageSize}
                                    defaultCurrent={1}
                                    current={current}
                                    total={total}
                                    hideOnSinglePage={true}
                                    onChange={this.onPageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({
        news: state.news,
    }),
    dispatch => ({
        getNewsList: send =>
            dispatch({
                type: NEWS_LIST,
                payload: agent.News.getList(send),
                send,
            }),
        unloadPageOne: () => 
            dispatch({
                type: NEWS_LIST_UNLOAD
            }),
    })
)(News);

