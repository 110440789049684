import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import agent from "agent";
import { connect } from "react-redux";
import {
    LOGIN,
    LOGIN_PAGE_UNLOADED,
    SHOW_FORGET_POPUP,
} from "constants/actionTypes";
import { Form, Input } from "antd";
import ListErrors from "components/ListErrors";
import "./Login.less";

import { loadCart, redirectTo ,addCart,clearCart} from "dispatch";

const FormItem = Form.Item;

const queryString = require("query-string");

const mapStateToProps = state => ({ 
    ...state.auth, 
    ...state.forgetPassword,
    audioCart: state.cart.audioCart,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: (email, password, callback, done) =>
        dispatch({
            type: LOGIN,
            payload: agent.Auth.login(email, password),
            callback,
            done,
        }),
    onUnload: () => dispatch({ type: LOGIN_PAGE_UNLOADED }),
    showForgetPopup: isShow => {
        return dispatch({ type: SHOW_FORGET_POPUP, isShow });
    },
});

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type:0
        };
    }
    static propTypes = {
        isShow: PropTypes.shape({
            forgetShow: PropTypes.bool.isRequired,
        }).isRequired,
        errors: PropTypes.array,
        location: PropTypes.object.isRequired,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        loginSuccess: PropTypes.bool,
        showForgetPopup: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
        audioCart: PropTypes.array,
    };
    static defaultProps = {
        loginSuccess: false,
        isShow: {
            forgetShow: false,
        },
    };
    state = { errors: [] };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            errors: [],
        });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(
                    values.userName,
                    values.password,
                    () => {
                        // update cart
                        const cartAudios=this.props.audioCart;
                        for (let i = 0; i < cartAudios.length; i++) {
                            const cart = cartAudios[i];
                            addCart(cart);
                        }
                        clearCart();
                        loadCart();
                        //页面跳转，从哪页进来登录之后回到哪页
                        if(this.state.type==0){
                            redirectTo("/user/mypage");
                        }else if(this.state.type==1){
                            redirectTo("/cart");
                        }else if(this.state.type==2){
                            redirectTo('/subscribermembers')
                        }else if(this.state.type==3){
                            redirectTo('/fixPrice')
                        }
                    },
                    () => {
                        const { errors } = this.props;
                        if (!errors || !errors.length) {
                            return;
                        }
                        // set error
                        const fileds = {};
                        for (const item of errors) {
                            if (item.param == "email") {
                                fileds.userName = {
                                    value: item.value,
                                    errors: [
                                        new Error("请输入正确的手机号/邮箱！"),
                                    ],
                                };
                                continue;
                            }
                            if (item.param == "password") {
                                fileds.password = {
                                    value: item.value,
                                    errors: [new Error("密码需要6位以上字符!")],
                                };
                                continue;
                            }
                            if (
                                item.message &&
                                item.message.match(/^User status Error/)
                            ) {
                                this.setState({
                                    errors: ["用户无法登录，请联系管理员"],
                                });
                                continue;
                            }
                            this.setState({
                                errors: ["用户名或密码错误"],
                            });
                        }
                        this.props.form.setFields(fileds);

                    }
                );
            }
        });
    };
    componentDidMount(){
        //页面跳转状态
        const query = queryString.parse(this.props.location.search);
        if(query.type==1){
            this.setState({
                type:1
            })
        }else if(query.type==2){
            this.setState({
                type:2
            })
        }else if(query.type==3){
            this.setState({
                type:3
            })
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }
    handleForgetClick() {
        const { isShow, showForgetPopup } = this.props;
        const send = {
            ...isShow,
            forgetShow: true,
        };
        showForgetPopup(send);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { errors } = this.state;

        return (
            <div className="mainContents c02b478-login">
                <div className="mainContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">用户登录</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="l-formBox">
                            <ListErrors errors={errors} />
                            <Form onSubmit={this.handleSubmit}>
                                <div className="l-inputTxt">
                                    <ul className="l-inputTxt__items">
                                        <li className="l-inputTxt__item">
                                            <FormItem>
                                                {getFieldDecorator("userName", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "请输入账号！",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        className="u-inputSkin01"
                                                        placeholder="手机号 / 邮箱"
                                                    />
                                                )}
                                            </FormItem>
                                        </li>
                                        <li className="l-inputTxt__item">
                                            <FormItem>
                                                {getFieldDecorator("password", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "请输入密码！",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        className="u-inputSkin01"
                                                        type="password"
                                                        placeholder="密码"
                                                    />
                                                )}
                                            </FormItem>
                                        </li>
                                    </ul>
                                    <p className="l-formBox__notelink">
                                        <a
                                            href="javascript:void(0)"
                                            onClick={this.handleForgetClick.bind(
                                                this
                                            )}
                                        >
                                            &gt; 忘记密码
                                        </a>
                                    </p>
                                </div>
                                <p className="l-formBox__submit">
                                    <input
                                        disabled={this.props.inProgress}
                                        className="u-submitSkin"
                                        type="submit"
                                        name="submit"
                                        value="登录"
                                    />
                                </p>
                                <ul className="l-formBox__linkList hidden">
                                    <li className="l-formBox__linkList__item l-formBox__linkList__wechat" style={{width:"100%"}}>
                                        <Link to="">微信登录</Link>
                                    </li>
                                    <li className="l-formBox__linkList__item l-formBox__linkList__alipay ">
                                        <Link to="">支付宝登录</Link>
                                    </li>
                                </ul>
                            </Form>
                        </div>

                        <div className="l-formBox">
                            <p className="l-formBox__linkBtn">
                                <Link to="/register" className="m-btn-type1">
                                    注册用户
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const LoginForm = Form.create()(Login);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
