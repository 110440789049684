import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Pagination, Modal, Select, Checkbox } from "antd";
import Loading from "../Loading";
import { loadPurchaseAll, loadHistoryPurchaseAll, showMoney,deleteOrder,HtmlUtil,showOrderId ,remittance, loadCart} from "dispatch";
import moment from "moment";
import { redirectTo } from "dispatch";
import agent from "agent";
import { 
    GET_PAY_URL,
    RECEIPT_MODAL_HIDE,
    RECEIPT_MODAL_SHOW,
    UPDATE_ORDER,
    UPDATE_REMITTANCE_ORDER ,
    SHOW_CODE_URL,
    CLOSE_CODE_URL,
} from "constants/actionTypes";
import Download from "components/Common/Download";
import "./User.less";
import "./Purchase.less";
import html2canvas from 'html2canvas';
import  jsPDF from 'jspdf';
import md5 from 'md5';
import Receipt from './Receipt';
import WxCode from "../Common/WxCode";

const queryString = require("query-string");
const confirm = Modal.confirm;
const { Content } = Layout;
const { Option } = Select;
const jquery = require("jquery");
const mapStateToProps = state => ({
    ...state.user,
    ...state.purchase,
    prepayId: state.purchase.prepayId,
    userLogin: state.app.userLogin,
});
const mapDispatchToProps = dispatch => ({
    getPayUrl: (orderid, send, callback) => {
        return dispatch({
            type: GET_PAY_URL,
            orderid,
            payload: agent.Purchase.getPayUrl(orderid,send),
            callback,
        });
    },
    onHideModal: () =>
        dispatch({
            type: RECEIPT_MODAL_HIDE,
        }),
    onShowModal: payload =>
        dispatch({
            type: RECEIPT_MODAL_SHOW,
            payload,
        }),
    updateOrder: (orderid, parmas,callback) =>
        dispatch({
            type: UPDATE_ORDER,
            payload: agent.Purchase.updateOrder(orderid,parmas),
            callback,
        }),
    unLoadOrder: () =>
        dispatch({
            type: UPDATE_ORDER,
        }),
    updateRemittanceOrder: (orderid, parmas,callback) =>
        dispatch({
            type: UPDATE_REMITTANCE_ORDER,
            payload: agent.Purchase.updateRemittanceOrder(orderid,parmas),
            callback,
        }),
    showCodeImg: (send) =>{
        return dispatch({ 
            type: SHOW_CODE_URL,
            send
        });
    },
    closeCodeUrl: () =>{
        return dispatch({ 
            type: CLOSE_CODE_URL,
        });
    },
});

class PurchaseAll extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: 0,
            isHistory: false,
            receipt:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
            order:"",
            showInfo:false,
            checkbox:false,
            openId:window.openId,
            type:1
        };
        this.closeCode = this.closeCode.bind(this);
    }
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        serverPurchaseData: PropTypes.array,
        pagination: PropTypes.object,
        getPayUrl: PropTypes.func.isRequired,
        payUrl: PropTypes.string,
        wxCode: PropTypes.string,
        onHideModal: PropTypes.func.isRequired,
        onShowModal: PropTypes.func.isRequired,
        updateOrder: PropTypes.func.isRequired,
        unLoadOrder: PropTypes.func.isRequired,
        updateRemittanceOrder: PropTypes.func.isRequired,
        showCodeImg: PropTypes.func.isRequired,
        closeCodeUrl: PropTypes.func.isRequired,
        orderid: PropTypes.string,
        price: PropTypes.string,
        prepayId:PropTypes.string,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        const { userLogin } = this.props;
        console.log(window.openId)
        // if(window.openId){
        //     this.setState({
        //         openId:window.openId
        //     })
        // }
        console.log(this.state.openId)
        if (!userLogin) {
            redirectTo("/login");
            return false;
        }
        loadCart();
        const query = queryString.parse(this.props.location.search);
        if (query.isHistory==1) {
            this.setState({
                isHistory:true,
            })
            const params = {
                size: 10,
                page: 1,
                total: 0,
                orderby: "-id",
            };
            loadPurchaseAll(params);
        }else{
            this.setState({
                isHistory:false,
            })
            const params = {
                size: 10,
                page: 1,
                total: 0,
                orderby: "-id",
                history: "-1",
            };
            loadHistoryPurchaseAll(params);
        }
        
    }
    componentDidUpdate(prevProps) {
        console.log(this.props.prepayId)
        console.log(this.state.type)
        const { showCodeImg,orderid,price } = this.props
        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }else if(this.props.wxCode){
            const send ={
                codeShow:true,
                codeUrl:this.props.wxCode,
                orderid:orderid,
                price:price/100,
            }
            showCodeImg(send);
            return;
        }else if(this.props.prepayId&&this.state.type==1){
            if (typeof WeixinJSBridge == "undefined"){
                if( document.addEventListener ){
                    document.addEventListener('WeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    }, false);
                }else if (document.attachEvent){
                    document.attachEvent('WeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    });
                    document.attachEvent('onWeixinJSBridgeReady', function (){
                        this.onBridgeReady();
                    });
                }
            }else{
                this.onBridgeReady();
            }
        }
    }
    onBridgeReady(){
        console.log(this.props.prepayId.appId);
        console.log(this.props.prepayId.timeStamp);
        console.log(this.props.prepayId.nonceStr);
        console.log(this.props.prepayId.package);
        console.log(this.props.prepayId.signType);
        console.log(this.props.prepayId.paySign);
        const that = this;
        console.log(window.openId)
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            {//下面参数内容都是后台返回的
                // debug:true,
                "appId":that.props.prepayId.appId,//公众号名称，由商户传入
                "timeStamp":that.props.prepayId.timeStamp,//时间戳
                "nonceStr":that.props.prepayId.nonceStr,//随机串
                "package":"prepay_id="+that.props.prepayId.package,//预支付id
                "signType":that.props.prepayId.signType,//微信签名方式
                "paySign":that.props.prepayId.paySign,//微信签名
            }, res =>{
                // this.props.prepayId = ""
                this.setState({
                    type:2
                })
                // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                redirectTo("/user/purchase?isHistory=2");
                // this.PayOrders.bind(this)
                // alert("支付成功");
                // }
                // if(res.err_msg == "get_brand_wcpay_request:cancel"){
                //     alert("支付过程中用户取消！");
                // }
                // if(res.err_msg == "get_brand_wcpay_request:fail"){
                //     alert("支付失败")
                // }
                // else{           
                //     alert("支付失败,请跳转页面"+res.err_msg);
                // }
            }
        );
    }
    showDeleteConfirm (id) {
        confirm({
            title: '您确定要删除此条记录吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                const div=document.getElementById(id);
                div.hidden=true;
                deleteOrder(id);
            },
            onCancel() {
                
            },
        });
    }
    onPageChange(pageNumber) {
        document.documentElement.scrollTop = document.body.scrollTop =0
        const pager = { size: 10, page: 1, total: 0, orderby: "-id" };
        pager.page = pageNumber;
        const isHistory = this.state.isHistory;
        if (!isHistory) {
            pager.history = 1;
            loadHistoryPurchaseAll(pager);
        } else {
            loadPurchaseAll(pager);
        }
    }
    PayOrders() {
        const { unLoadOrder }=this.props;
        unLoadOrder();
        this.setState({ isHistory: false });
        const pager = {
            size: 10,
            page: 1,
            total: 0,
            orderby: "-id",
            history: 1,
        };
        loadHistoryPurchaseAll(pager);
    }
    noPayOrders() {
        const { unLoadOrder }=this.props;
        unLoadOrder();
        this.setState({type:1});
        this.setState({ isHistory: true });
        const pager = { size: 10, page: 1, total: 0, orderby: "-id" };
        loadPurchaseAll(pager);
    }
    isShow(i) {
        if (i === this.state.isShow) {
            this.setState({
                isShow: 0,
            });
        } else {
            this.setState({
                isShow: i,
            });
        }
    }
    receipt(item){
        const { onShowModal }=this.props;
        onShowModal(item);
    }
    receiptTicket(oderid,send){
        const {onHideModal,pagination,updateOrder }=this.props;
        onHideModal();
        const pager = { size: 10, page: 1, total: pagination.total, orderby: "-id" };
        pager.page = pagination.current;
        pager.history = 1;
        updateOrder(oderid,send, loadHistoryPurchaseAll(pager));
        setTimeout(() => {
            loadHistoryPurchaseAll(pager)
        }, 300);
    }
    closeShow() {
        const { onHideModal }=this.props;
        onHideModal();
    }

    //去支付
    payMoney(payType, orderid) {
        console.log(payType)
        const send={
            payType,
            c_ip:"10.10.10.229",
            openId:this.state.openId
        }
        this.setState({
            type:1
        });
        const { getPayUrl } = this.props;
        getPayUrl(orderid,send);
    }
    //关闭二维码
    closeCode(){
        const {closeCodeUrl } = this.props;
        closeCodeUrl();
        this.noPayOrders();
    }
    //改变币种
    handleCurrency = e => {
        this.setState({ currency: e });
    };
    //汇款账号
    handleRemittance = e => {
        this.setState({ remittance: e.target.value });
    };
    //联系人电话
    handleRemittancePhone = e => {
        this.setState({ remittancePhone: e.target.value });
    };
    //添加银行转账信息
    addBankInfo(){
        const data = this.state.order;
        const {updateRemittanceOrder,pagination }=this.props;
        const attachment=eval('(' + data.attachment + ')');
        if (!this.state.currency||!this.state.remittance||!this.state.remittancePhone||!this.state.checkbox) {
            return;
        } 
        attachment.currency=this.state.currency;
        attachment.remittance=this.state.remittance;
        attachment.remittancePhone=this.state.remittancePhone;
        const pager = { size: 10, page: 1, total: pagination.total, orderby: "-id" };
        pager.page = pagination.current;
        //回调
        const callback = () => {
            loadPurchaseAll(pager);
            jquery("body").css("overflow","auto");
            jquery("body").css("height","auto");
        };
        this.setState({
            order:{},
            showInfo:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
        })
        //增加转账信息
        updateRemittanceOrder(data.id,attachment,callback);
        
    }
    //弹出转账信息页
    showAddBankInfo(data){
        jquery("body").css("overflow","hidden");
        jquery("body").css("height","100%");
        this.setState({
            order:data,
            showInfo:true,
        })
    }
    //关闭弹出转账信息页
    closeAddBankInfo(){
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        this.setState({
            order:{},
            showInfo:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
        })
    }
    onChangeBox(e){
        this.setState({
            checkbox:e.target.checked,
        })
    }
    renderDetailsCell(orderitem,item, i) {
        const title=HtmlUtil.htmlDecode(item.title);
        console.log(item.price)
        return (
            <tr key={`details-${i}`}>
                {this.state.isHistory ? (
                    <th className="song_title_size" title={title}>
                        <Link className="shengluehao" to={`/music/musicid/${item.audioid}`}>
                            {title.length>18?title.substr(0,15)+'...':title}
                        </Link>
                    </th>
                ) : (
                    <th className="song_title_size" title={title}>
                        <Download
                            audioid={item.audioid}
                            classname="dlLink shengluehao"
                            original={true}
                        >
                            {title.length>18?title.substr(0,15)+'...':title}
                        </Download>
                    </th>
                )}

                <td className="buyRecord__table__price">
                    {showMoney(item.price)}
                </td>
                {this.state.isHistory ? <td className="buyRecord__table__book phone-hide">
                    <a  href="javascript:;"></a>
                </td> : <td className="buyRecord__table__book">
                    <a  href="javascript:;" onClick={this.exportCertificate.bind(this,orderitem,item)}>授权证书</a>
                </td>}
                
            </tr>
        );
    }
    renderCell(item, i) {
        //详细信息
        const attachment=item.attachment?eval('(' + item.attachment + ')'):"";
        //支付方式
        const payType=attachment&&attachment.payType?attachment.payType:0;
        const member = item.member;
        const info=attachment&&attachment.remittance?1:0;
        /**订单详情 */
        var date=new Date(item.create_at);
        const time=date.getTime();
        const phone_show=this.state.isHistory ?"buyRecordBox__size text_align phone-hide":"buyRecordBox__size text_align";
        return (
            <div key={`purchase-${i}`} className="buyRecordBox  is-open" id={item.orderid}>
                <div className="buyRecordBox__header">
                    <div className="border_bottom">
                        <div className="buyRecordBox__size">
                            <span className="buyRecordBox__size_title">订单日期:</span>
                            <span className="buyRecordBox__size_tent">{moment(time).format("YYYY-MM-DD HH:mm:ss")}</span>
                            {this.state.isHistory?<span className=" float-right cancle-book phone-show" onClick={this.showDeleteConfirm.bind(this,item.orderid)}>删除订单</span>:""}
                        </div>
                        <div className="buyRecordId__size phone-hide">
                            <span className="buyRecordBox__size_title">&nbsp;</span>
                            <span className="buyRecordBox__size_tent">{showOrderId(item.orderid)}</span>
                        </div>
                        <div className={phone_show}>
                            <span className="buyRecordBox__size_title">订单总金额:</span>
                            <span className="buyRecordBox__size_tent">{showMoney(item.price)}</span>
                        </div>
                    </div>
                </div>
                <div className="buyRecordBox__main">
                    <div className="buyRecord__table">
                        <table>
                            <tbody> 
                                {item.audios.map(
                                    this.renderDetailsCell.bind(this,item)
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="shenqing">
                        {this.state.isHistory ?<div className=" text-right phone-price phone-show"><span className="f-600">订单总金额：</span>{showMoney(item.price)}</div>:""}
                        {/**判断状态选择 */}
                        {this.state.isHistory ? (
                            /**未付款订单去支付 */
                            <div className="pay_money">
                                {info==1?<p style={{cursor: "context-menu"}}>转账信息已提交</p>:member==2?<p>订单已失效</p>:payType==2? <button onClick={this.showAddBankInfo.bind(this,item)} className="pay_button">提交转账信息</button>: <button  onClick={this.payMoney.bind(this,payType, item.orderid)} className="pay_button">去支付</button>}
                                <p className="delete_order phone-hide"><a href="javascript:;" onClick={this.showDeleteConfirm.bind(this,item.orderid)}>删除订单</a></p>
                            </div>
                        ) : (
                            /**申请开票 */
                            <div className="pay_money">
                                {!item.price?"":
                                    item.receipt==0 ? <button onClick={this.receipt.bind(this,item)} className="pay_button">申请开票</button>:<p>已申请开票</p>
                                }
                            </div>
                        // <td className="pay_money"><button onClick={this.delete.bind(this,item.id)}>删除</button></td>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    /** 导出授权证书 */
    exportCertificate(orderitem,item){
        const {currentUser} = this.props;
        var jsonstr = eval('(' + orderitem.attachment + ')');
        var title = item.title;// 歌曲名称
        document.getElementById('title').innerHTML=title;
        document.getElementById('title2').innerHTML=title;
        var use = item.purpose;// 购买用途(商用)
        document.getElementById('use').innerHTML=use;
        document.getElementById('use2').innerHTML=use;
        var project = jsonstr.area;// 用于什么项目
        document.getElementById('project').innerHTML=project;
        document.getElementById('project2').innerHTML=project;
        var haveUser = jsonstr.username;// 被授权者
        if (!haveUser) {
            haveUser=jsonstr.companyName;
        }
        document.getElementById('haveUser').innerHTML=haveUser;
        var scope = '全球';// 
        if (item.scope===1) {
            scope='中国大陆'
        }
        var term = '随片永久';// 
        if (item.term===1) {
            term='一年'
        }
        var zuozhe = item.nickname;// 作者
        document.getElementById('zuozhe').innerHTML=zuozhe;
        var suoyouzhe = item.author;// 所有者
        document.getElementById('suoyouzhe').innerHTML=suoyouzhe;
        document.getElementById('scope').innerHTML=scope;
        document.getElementById('term').innerHTML=term;
        var shouquancode = md5(item.audio_md5+orderitem.orderid+currentUser.userid);// 授权码
        document.getElementById('shouquancode').innerHTML=shouquancode;
        var productionUrl = item.audioid;// 作品链接号
        const token = agent.getToken();
        let href = `audio/audition/${productionUrl}`;// 链接地址
        if (token) {
            href = `users/audio/${productionUrl}`;
        }
        href = agent.getApiUrl(href);
        href += `?token=${token}`;
        document.getElementById('href').innerHTML=href;
        var productionId = item.audioid;// 作品id
        document.getElementById('productionId').innerHTML=productionId;
        var authorizationDate = moment(orderitem.create_at).format("YYYY/MM/DD");// 授权时间(创建时间的YYYY/MM/DD格式)
        document.getElementById('authorizationDate').innerHTML=authorizationDate;
        var channel = item.channel;// 投放渠道
        document.getElementById('channel').innerHTML=channel;
        var describe = !jsonstr.description?" ":jsonstr.description;// 项目描述
        document.getElementById('describe').innerHTML=describe;
        var time = moment(new Date()).format("YYYY年MM月DD日");// 现在时间
        document.getElementById('time').innerHTML=time;
        
        html2canvas(document.getElementById('authorization'), {
            allowTaint: false,
            taintTest: false,
            letterRendering: true
        }).then(function(canvas) {
            var pageData = canvas.toDataURL( 'image/jpeg', 1.0);
            var pdf = new jsPDF('', 'pt', 'a4');
            pdf.addImage(pageData, 'JPEG', 0, 0, 595.28, 592.28/canvas.width * canvas.height );
            pdf.save(title + '-authorization.pdf');
        });
    }
    back(e){
        redirectTo("/user/mypage");
    }
    render() {
        const {
            loading,
            currentUser,
            serverPurchaseData,
            pagination,
            onHideModal,
            modal,
        } = this.props;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        const { data: modalData ,visible} = modal;
        const detaModel={
            closeShow:onHideModal,
            receiptTicket:this.receiptTicket.bind(this),
            visible,
            modalData,
        }
        const noPay = this.state.isHistory
            ? " m-tabBox__list__item"
            : "m-tabBox__list__item pay_money";
        const pay = !this.state.isHistory
            ? " m-tabBox__list__item"
            : "m-tabBox__list__item pay_money";
        const showInfo=this.state.showInfo?"dialog-bg":"hidden";
        const dispatch={
            closeCode:this.closeCode,
        }
        return (
            <div className="mypageContents" id="user_purchase_all">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">购买记录</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="buyRecord">
                            <div className="m-tabBox">
                                <ul className="m-tabBox__list" role="tablist">
                                    <li className={noPay}>
                                        <a
                                            to=""
                                            aria-controls="tab-01"
                                            aria-selected={this.state.isHistory}
                                            role="tab"
                                            onClick={this.noPayOrders.bind(
                                                this
                                            )}
                                        >
                                            未付款
                                        </a>
                                    </li>
                                    <li className={pay}>
                                        <a
                                            to=""
                                            aria-controls="tab-02"
                                            aria-selected={
                                                !this.state.isHistory
                                            }
                                            role="tab"
                                            onClick={this.PayOrders.bind(this)}
                                        >
                                            已付款
                                        </a>
                                    </li>
                                    <li style={{width:'50%'}}><button onClick={this.back.bind(this)} className='backHome'>返回</button></li>
                                </ul>
                                <span className="notice_price">{this.state.isHistory?"* 价格均为人民币，如您选择台币支付，默认汇率为4.5":""}</span>
                            </div>
                            <div className="buyRecord__inner">
                                {serverPurchaseData.map(
                                    this.renderCell.bind(this)
                                )}
                            </div>
                            <div className="resultPagination pag_center">
                                <Pagination
                                    defaultPageSize={pagination.pageSize}
                                    {...pagination}
                                    hideOnSinglePage={true}
                                    onChange={this.onPageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Receipt {...detaModel}></Receipt>
                <WxCode {...dispatch}></WxCode>
                <div className=" container my-container " id="authorization">
                    <div className="row">
                        <div className="padding-60 padding-bottom-120">
                            <div className=" text-center text-ti"><span><img src="/assets/img/logo.png"/></span>MUSINESS音乐使用授权书</div>
                            <div className=" text">此证书证明您可以在<span id="use"></span>用途中依照《MUSINESS平台用户协议》约定,非独家、不可转授权地使用以下音乐：<span id="title"></span>，您可以将该音乐用于项目：<span id="project"></span>，该作品的授权期限为<span id="term"></span>，本次授权地域为：<span  id='scope'></span>。</div>
                            <div className=" size-18 margin-top-30">以下为您购买作品的详细授权信息：</div>
                            <div className=" my-table">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>作者：</td>
                                            <td id="zuozhe"></td>
                                        </tr>
                                        <tr>
                                            <td>版权所有者：</td>
                                            <td id="suoyouzhe"></td>
                                        </tr>
                                        <tr>
                                            <td>被授权者：</td>
                                            <td id="haveUser"></td>
                                        </tr>
                                        <tr>
                                            <td>作品名称：</td>
                                            <td id="title2"></td>
                                        </tr>
                                        <tr>
                                            <td>作品链接：</td>
                                            <td id="href"></td>
                                        </tr>
                                        <tr>
                                            <td>作品ID：</td>
                                            <td id="productionId"></td>
                                        </tr>
                                        <tr>
                                            <td>购买用途：</td>
                                            <td id="use2"></td>
                                        </tr>
                                        <tr>
                                            <td>授权代码：</td>
                                            <td id="shouquancode"></td>
                                        </tr>
                                        <tr>
                                            <td>授权时间：</td>
                                            <td id="authorizationDate"></td>
                                        </tr>
                                        <tr>
                                            <td>授权项目：</td>
                                            <td id="project2"></td>
                                        </tr>
                                        <tr>
                                            <td>项目投放渠道：</td>
                                            <td id="channel"></td>
                                        </tr>
                                        <tr>
                                            <td>项目描述：</td>
                                            <td id="describe"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className=" size-18 text-right line50 f-600 po-re">
                                <div>上海小仙虎文化传媒有限公司</div>
                                <div className=" time"><span id="time"></span></div>
                                <div className=" po-ab post"><img height="160" src="/assets/img/post.png"/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={showInfo} style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container scroll-y modalBox__inner">
                        <div className=" close-choose"><a><img onClick={this.closeAddBankInfo.bind(this)} height="24" src="/assets/img/u50.png"/></a></div>
                        <div className=" booker-ti">汇款信息</div>
                        <div className=" book-input">
                            <ul>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>汇款账号（后五位）：</div>
                                    <div className=" book-right"><input value={this.state.remittance} type="number" onChange={this.handleRemittance} /></div>
                                </li>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>汇款币种：</div>
                                    <div className=" book-right">
                                        <Select className="select_width" getPopupContainer={triggerNode => triggerNode.parentNode} defaultValue={this.state.currency} onChange={this.handleCurrency}>
                                            <Option value="人民币">人民币</Option>
                                            <Option value="台币">台币</Option>
                                        </Select>
                                    </div>
                                </li>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>联系人电话：</div>
                                    <div className=" book-right"><input value={this.state.remittancePhone} type="number" onChange={this.handleRemittancePhone}/></div>
                                </li>
                            </ul>
                            <div className="text-center phone_margin_top" ><Checkbox onChange={this.onChangeBox.bind(this)}>我已汇款并正确填写信息</Checkbox></div>
                            <div className="text-center text-margin-top"><button onClick={this.addBankInfo.bind(this)} className=" my-sub">提交</button></div>
                            
                        </div>
                        <div>
                            <div className=" table-ti"><strong>收款账号信息如下：</strong></div>
                            <div>
                                <div className=" table-name">
                                    <div className=" wid20">币种</div>
                                    <div className=" wid20">金额</div>
                                    <div className=" wid60">银行卡信息</div>
                                </div>
                                <div className=" table-de">
                                    <ul>
                                        <li>
                                            <div className=" wid20 line-78">人民币</div>
                                            <div className=" wid20 color-green line-78">{remittance(this.state.order.price)}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>上海小仙虎文化传媒有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>中国工商银行股份有限公司上海市上外支行</div>
                                                <div className=" table-all"><span>账号：</span>1001274409300013770</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" wid20 line-78">台币</div>
                                            <div className=" wid20 color-green line-78">{remittance(this.state.order.price)*4.5}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>啟動音樂文創有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>上海商業儲蓄銀行（世貿分行）</div>
                                                <div className=" table-all"><span>账号：</span>43102000032270</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseAll);
