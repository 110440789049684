import React from "react";
import PropTypes from "prop-types";
import { Alert } from "antd";

const divStyle = {
    marginBottom: "20px",
    color: "red",
};

class ListErrors extends React.Component {
    static propTypes = {
        errors: PropTypes.array,
    };
    render() {
        const errors = this.props.errors;
        if (errors) {
            return (
                <div>
                    {Object.keys(errors).map(key => {
                        let message = errors[key];
                        if (typeof message !== "string") {
                            message = message.param + ":" + message.msg;
                        }
                        return (
                            <Alert
                                style={divStyle}
                                key={key}
                                message={`${message}`}
                                type="error"
                            />
                        );
                        // if (typeof message !== "string") {
                        //     message = JSON.stringify(message);
                        // }
                        // return (
                        //     <Alert
                        //         style={divStyle}
                        //         key={key}
                        //         message={`${message}`}
                        //         type="error"
                        //     />
                        // );
                    })}
                </div>
            );
        } else {
            return null;
        }
    }
}

export default ListErrors;
