export default jQuery =>
    (function($) {
        "use strict";

        var root = document.documentElement;
        //var _IE8 = (typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined") ? true :false;
        root.className = root.className.replace(/\bno[-_]?js\b/gi, "") + " js";
        var $window = $(window);
        var $html = $("html");
        var $body = $("body");

        function object(o) {
            var f = object.f,
                i,
                len,
                n,
                prop;
            f.prototype = o;
            n = new f();
            for (i = 1, len = arguments.length; i < len; ++i) {
                for (prop in arguments[i]) {
                    n[prop] = arguments[i][prop];
                }
            }
            return n;
        }
        object.f = function() {};

        $.extend({
            gnavToggle: function() {
                var $menuOffset = $(".js-menuOffset");
                var $menuOffset_R = $(".js-menuOffset--r");
                // var isScrollable = function(el) {
                //     return (
                //         el &&
                //         !(el.style.overflow && el.style.overflow === "hidden") &&
                //         ((el.clientWidth && el.scrollWidth > el.clientWidth) ||
                //             (el.clientHeight && el.scrollHeight > el.clientHeight))
                //     );
                // };

                //$(".js-nav").each(function(i){
                var $this = $(".js-nav");
                $this.addClass("is-close");

                var openFlg = false;
                var $navMenu = $this.find(".js-navMenu");
                var $toggleBtn = $this.find(".js-navToggle");
                var $closeBtn = $this.find(".js-navClose");

                $toggleBtn.click(function(e) {
                    e.preventDefault();
                    // var $this = $(this);
                    if (openFlg == false) {
                        openMenu(this);
                    } else {
                        closeMenu(this);
                    }
                });
                $closeBtn.click(function(e) {
                    closeMenu(this);
                });

                function openMenu(_this) {
                    $this.css("display", "block");
                    $toggleBtn.addClass("is-open");
                    $navMenu.slideDown(200, "swing", function() {
                        $this.addClass("is-open");

                        $html.addClass("overlay-lock");
                        $menuOffset.addClass("overlay-offset");
                        $menuOffset_R.addClass("overlay-offset-r");
                        openFlg = true;
                    });
                }
                function closeMenu(_this) {
                    $this.removeClass("is-open");
                    $toggleBtn.removeClass("is-open");
                    $html.removeClass("overlay-lock");
                    $menuOffset.removeClass("overlay-offset");
                    $menuOffset_R.removeClass("overlay-offset-r");
                    $navMenu.slideUp(200, "swing", function() {
                        openFlg = false;
                        // $navMenu.css("display", "block");
                    });
                }
                // var currentUrl = location.href; //現在のページの絶対パスを取得
                // currentUrl = currentUrl.split("#")[0];
                // $(".globalNav__item a").click(function(e) {
                // closeMenu(this);
                // var href = $(this).prop("href"), //リンク先を絶対パスとして取得
                //     hrefPageUrl = href.split("#")[0]; //リンク先を絶対パスについて、#より前のURLを取得
                // if (hrefPageUrl == currentUrl) {
                //     closeMenu(this);
                //     href = href.split("#");
                //     href = href[1];
                //     if (href !== undefined) {
                //         href = "#" + href;
                //     } else {
                //         href = "#";
                //     }
                //     var target = $(
                //         href == "#" || href == "" ? "html" : href
                //     );
                //     var position = target.offset().top;
                //     $("body, html")
                //         .stop(true, false)
                //         .animate({scrollTop: position}, 500, "swing");
                //     return false;
                // }
                // });

                //});

                var w1 = $(window).width();
                $html.addClass("overlay-lock-test");
                var w2 = $(window).width();
                $html.removeClass("overlay-lock-test");
                $(
                    "<style type='text/css'>.overlay-lock{padding-right:" +
                        (w2 - w1) +
                        "px;box-sizing:border-box;}</style>"
                ).appendTo("head");
                $(
                    "<style type='text/css'>.overlay-offset{margin-left:" +
                        (w2 - w1) / -2 +
                        "px;} .overlay-offset-r{margin-right:" +
                        (w2 - w1) +
                        "px;}</style>"
                ).appendTo("head");
            },
            gnavFix: function() {
                // var $globalHeader = $(".globalHeader");
                // var $globalNavTop = $globalHeader.height();
                var $logo = $(".topKeyVisual__logo");
                var logoPosiion;
                if ($logo.length) {
                    logoPosiion = $logo.offset().top + $logo.height();
                    $window.on("load resize", function() {
                        logoPosiion = $logo.offset().top + $logo.height();
                    });
                    $window.scroll(function() {
                        var position = $window.scrollTop();
                        if (position >= logoPosiion) {
                            $body.addClass("is-headerFixed");
                        } else {
                            $body.removeClass("is-headerFixed");
                        }
                    });
                }
            },
            fixedMenu: function() {
                var $fixedMenu = $(".js-fixedMenu");
                var footerTop;
                // $fixedMenu.hide();
                var $window = $(window);
                var pagetopFlg = $fixedMenu.length;
                // var prePosition = 0;
                footerTop =
                    $(".globalFooter").length > 0
                        ? $(".globalFooter").offset().top
                        : 0;
                if (pagetopFlg > 0) {
                    $window.on("load resize", function() {
                        footerTop =
                            $(".globalFooter").length > 0
                                ? $(".globalFooter").offset().top
                                : 0;
                        $window.scroll();
                        $fixedMenu.addClass("is-show");
                    });
                }
                $window.scroll(function() {
                    var position = $window.scrollTop();
                    var position2 = position + $window.height();
                    if (position2 <= footerTop) {
                        $fixedMenu.removeClass("is-abs");
                    } else {
                        $fixedMenu.addClass("is-abs");
                    }
                    $fixedMenu.addClass("is-show");
                    if (position > 100) {
                        // $fixedMenu.addClass("is-show");
                    } else {
                        // $fixedMenu.removeClass("is-show");
                    }
                    // prePosition = position;
                });
            }
        });

        // $(function() {
        //     $.gnavToggle();
        //     // $.gnavCurrent();
        //     // $.gnavFix();
        //     // $.fixedMenu();
        // });
    })(jQuery);
