import {
    SEND_CUSTOMIZED
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
       
        case SEND_CUSTOMIZED: {
            return {
                ...state,
                status: action.payload.status,
            };
        }
       
        default:
            return state;
    }
};
