import React from "react";
import PropTypes from "prop-types";
import agent from "agent";
import { connect } from "react-redux";
import { PUBLIC_MAKER_DETAIL, PUBLIC_MKAER_MUSIC,DETAIL_PAGE_UNLOAD,DETAIL_AUDIO_UNLOAD } from "constants/actionTypes";
import { MUSIC_SEARCH_LABEL_LOAD_All } from "constants/actionTypes";
import { HtmlUtil } from "dispatch"; //redirectTo
import MusicItem from "components/TopicMusic/MusicItem";
import { Pagination } from "antd";
import Loading from "components/Loading";
import "./detail.less";

class MakerDetail extends React.Component {
    static propTypes = {
        pagination: PropTypes.object.isRequired,
        detail: PropTypes.object.isRequired,
        getDetail: PropTypes.func.isRequired,
        audio: PropTypes.object.isRequired,
        getAudio: PropTypes.func.isRequired,
        unLoadDetail: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        unLoadAudio: PropTypes.func.isRequired,
        labels: PropTypes.object.isRequired,
        loadLabelAll: PropTypes.func.isRequired,
    };

    state = {
        id: null,
    };

    // UNSAFE_componentWillUpdate() {
    //     const { pathname } = useLocation();
    //     console.log("+++++++++",pathname);
    //     // if(window.history != this.state.lasturl){
    //     //     let lasturl = window.history;
    //     //     this.setState({ lasturl });
    //     //     let [, id] = location.search.match(/id=([^&]+)/) || [];
    //     //     if (!id) { 
    //     //         id = 305;
    //     //         this.setState({ id });
    //     //         this.props.getDetail(id);
    //     //         this.props.getAudio({ id, pager: this.props.audio.pager });
    //     //     }
    //     // }
    // }
    componentDidMount() {
        let [, id] = location.search.match(/id=([^&]+)/) || [];
        if (!id) {
            // redirectTo("/maker");
            id = 305;
            this.setState({ id });
            this.props.getDetail(id);
            this.props.getAudio({ id, pager: this.props.audio.pager });
        } else {
            this.setState({ id });
            this.props.getDetail(id);
            this.props.getAudio({ id, pager: this.props.audio.pager });
        }
        const { loadLabelAll } = this.props;
        loadLabelAll();
    }
    componentWillUnmount() {
        this.props.unLoadDetail();
        this.props.unLoadAudio();
    }

    onPageChange(page) {
        this.props.getAudio({
            id: this.state.id,
            pager: { ...this.props.audio.pager, current: page },
        });
    }
    /**点击向后翻十页 */
    onPageBehindChanges(){
        const { pager = {}, total } = this.props.audio;
        let totalPage = Math.ceil(total/pager.pageSize);
        /**如果当前页大于总页数-10并且小于总页数的时候，直接跳转至最后一页 */
        if(pager.current>totalPage-10 && pager.current<=totalPage){
            this.onPageChange(totalPage);
        }else{
            let pageBehind = pager.current+10;
            this.onPageChange(pageBehind);
        }
    }
    /**点击向前翻十页 */
    onPageBeforeChanges(){
        const { pager = {}, } = this.props.audio;
        /**如果当前页小于10并且大于0，直接跳转到第一页 */
        if(pager.current<=10 && pager.current>0){
            this.onPageChange(1);
        }else{
            let pageBefore = pager.current-10;
            this.onPageChange(pageBefore);
        }
    }
    render() {
        const {loading,labels}=this.props;
        const { avatar, description, nickname } = this.props.detail;
        const { data = [], pager = {}, total } = this.props.audio;
        const { pageSize, current } = pager;
        const urls="url("+agent.getApiUrl(avatar)+")";
        if (!this.props.detail) {
            return (
                <div className="resultItemWrapper">
                    <Loading loading={loading} />
                </div>
            );
        }
        
        return (
            <div className="musicContents artistContents" id="creator_detail">
                <div className="musicContents__inner">
                    <div className="artistSection">
                        <div className="artistSection__inner">
                            <div className="artistSection__img" style={{backgroundImage: urls}}>
                                {/* <img src={agent.getApiUrl(avatar)} alt="" /> */}
                            </div>
                            <div className="artistSection__txtBox">
                                <h1 className="artistSection__title">
                                    {nickname}
                                </h1>
                                <p className="artistSection__txt">
                                    {HtmlUtil.htmlDecode(HtmlUtil.htmlDecode(HtmlUtil.htmlDecode(description)))}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="otherMusicList">
                        <div className="resultItemWrapper">
                        
                            {!data?<Loading/>:data.map((x, i) => {
                                return (
                                    <MusicItem
                                        key={i}
                                        data={x}
                                        page={"maker"}
                                        labels={labels}
                                    />
                                );
                            })}
                        </div>
                        <div className="c02b478-music-table">
                            {total > 0 ? (
                                <div>
                                    <div className="creatorpage-ov" style={{textAlign: "center"}}>
                                        {/**作者详情页的显示跳十页 */}
                                        <div className="resultPagination">
                                            <Pagination
                                                defaultPageSize={pageSize}
                                                defaultCurrent={1}
                                                current={current}
                                                total={total}
                                                hideOnSinglePage={true}
                                                onChange={this.onPageChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        detail: state.maker.detail,
        audio: state.maker.audio,
        labels: state.labels.labelsDataAll,
    }),
    dispatch => ({
        getDetail: id =>
            dispatch({
                type: PUBLIC_MAKER_DETAIL,
                payload: agent.Maker.getDetail(id),
            }),
        getAudio: send =>
            dispatch({
                type: PUBLIC_MKAER_MUSIC,
                payload: agent.Maker.getAudio(send),
                send,
            }),
        unLoadDetail: () => 
            dispatch({
                type: DETAIL_PAGE_UNLOAD 
            }),
        unLoadAudio: () => 
            dispatch({
                type: DETAIL_AUDIO_UNLOAD 
            }),    
        loadLabelAll: () => 
            dispatch({
                type: MUSIC_SEARCH_LABEL_LOAD_All,
                payload: agent.Music.searchLabelAlls(),
            }),
               
               
    })
)(MakerDetail);
