import { Link } from "react-router-dom";
import React from "react";

class RegisteSuccess extends React.Component {
    render() {
        return (
            <div className="mainContents">
                <div className="mainContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">注册完成</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="l-formBox">
                            <p className="l-formBox__linkBtn">
                                <Link to="/login" className="m-btn-type0">
                                登录
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisteSuccess;
