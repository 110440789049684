import React from "react";
import agent from "agent";
import PropTypes from "prop-types";
import { QA_GET } from "constants/actionTypes";
import { connect } from "react-redux";
import { Pagination } from "antd";

class HelpCenter extends React.Component {
    static propTypes = {
        getList: PropTypes.func.isRequired,
        qa: PropTypes.object,
    };

    input = null;

    componentDidMount() {
        const { pager } = this.props.qa;
        this.props.getList({ pager });
    }

    search = e =>{
        e.preventDefault();
        const { pager } = this.props.qa;
        this.props.getList({ pager, keyword: this.input.value });
    }

    onPageChange(page) {
        const { pager } = this.props.qa;
        this.props.getList({
            pager: { ...pager, current: page },
            keyword: this.input.value,
        });
    }

    render() {
        const { data = [], pager, total } = this.props.qa;
        const { pageSize, current } = pager || {};

        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">帮助中心</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="summaryBox">
                            <div className="summaryBox__search searchBox">
                                <form onSubmit={this.search}>
                                    <input
                                        type="text"
                                        ref={ref => (this.input = ref)}
                                        className="searchSkin"
                                        placeholder="请输入问题关键词"
                                    />
                                    <input
                                        type="submit"
                                        name="submit"
                                        value="搜寻"
                                        id="searchSubmit"
                                    />
                                </form>
                            </div>

                            <div className="summaryInBox">
                                <div className="summaryInBox__inner">
                                    <div className="faqBox">
                                        <h2 className="summaryBox__sTitle hidden">
                                            问题分类
                                        </h2>
                                        <dl className="faqBox__list">
                                            {data.map((x, i) => {
                                                const { q, a } = x;
                                                return [
                                                    <dt
                                                        className="faqBox__Q"
                                                        key={i + "r0"}
                                                    >
                                                        <p>{q}</p>
                                                    </dt>,

                                                    <dd
                                                        className="faqBox__A"
                                                        key={i + "r1"}
                                                    >
                                                        <p>{a}</p>
                                                    </dd>,
                                                ];
                                            })}
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div className="c02b478-music-table">
                                <div className="resultPagination " style={{textAlign:"center"}}>
                                    <Pagination
                                        defaultPageSize={pageSize}
                                        defaultCurrent={1}
                                        current={current}
                                        total={total}
                                        onChange={this.onPageChange.bind(
                                            this
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        qa: state.qa,
    }),
    dispatch => ({
        getList: send => {
            return dispatch({
                type: QA_GET,
                send,
                payload: agent.Qa.getList(send),
            });
        },
    })
)(HelpCenter);
