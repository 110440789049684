import React from "react";
import agent from "agent";
import PropTypes from "prop-types";
import {HtmlUtil } from "dispatch";

class Recommend extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        onClick: PropTypes.func,
    };
    //渲染
    render() {
        const css= {
            overflow:"hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            width:"100%",
        }
        return (
            <ul className="topMusicList__items m-columnBox__items">
                {this.props.data.map((x, i) => {
                    const {
                        thumb: src,
                        author: { nickname: artist },
                        title,
                        audioid,
                        creatorInfo,
                        composer,
                    } = x;
                    let name=composer;
                    if (creatorInfo) {
                        name=creatorInfo.nickname;
                    }
                    return (
                        <li className="topMusicList__item m-columnBox__item" key={i}>
                            <a href="javascript:;" onClick={e => this.props.onClick(audioid) || null}>
                                <img src={agent.getApiUrl(src)} alt="" />
                                <p className="topMusicList__txt1" style={css} title={title}>{HtmlUtil.htmlDecode(title)}</p>
                                <p className="topMusicList__txt2">{HtmlUtil.htmlDecode(name?name:artist)}</p>
                            </a>
                        </li>
                    );
                })}
            </ul>
        );
    }
}

export default Recommend;
