import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { redirectTo } from "dispatch";
import { Radio } from "antd";
import { SUBSCRIBE_CREATE, UPLOAD_SUBSCRIBE, CLOSE_CODE_URL, SHOW_CODE_URL } from "constants/actionTypes";
import agent from "agent";
import WxCode from "../Common/WxCode";
import "./SubscriberMembers.less"

const queryString = require("query-string");
const jquery = require("jquery");
const mapStateToProps = state => ({
    ...state.subscribe,
    currentUser: state.user.currentUser,
    userLogin: state.app.userLogin,
    message: state.subscribe.message,
    status: state.subscribe.status
});

const mapDispatchToProps = dispatch => ({
    crteateSubscribe: (parmas) => {
        return dispatch({
            type: SUBSCRIBE_CREATE,
            payload: agent.Subscribe.crteateSubscribe(parmas),
        });
    },
    unLoadSubscribe: () => {
        return dispatch({ type: UPLOAD_SUBSCRIBE });
    },
    closeCodeUrl: () => {
        return dispatch({
            type: CLOSE_CODE_URL,
        });
    },
    showCodeImg: (send) => {
        console.log(send)
        return dispatch({
            type: SHOW_CODE_URL,
            send
        });
    },
});
class SubscribePay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            type: 0,
            bank: 0,
            price: 0,
            content: "",
            typeName: "",
            wxCode: "",
            code: '',
            inpValu: '',
            codeShow: false,
            messageShow: true,
            isdisabled: true,
        };
        this.closeCode = this.closeCode.bind(this);
    }
    static propTypes = {
        loading: PropTypes.bool,
        history: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        location: PropTypes.object.isRequired,
        crteateSubscribe: PropTypes.func.isRequired,
        unLoadSubscribe: PropTypes.func.isRequired,
        closeCodeUrl: PropTypes.func.isRequired,
        showCodeImg: PropTypes.func.isRequired,
        payUrl: PropTypes.string,
        wxCode: PropTypes.string,
        bank: PropTypes.string,
        orderid: PropTypes.string,
        price: PropTypes.string,
        currentUser: PropTypes.object,
        message: PropTypes.string,
        status: PropTypes.string
    };
    componentWillUnmount() {
        this.props.unLoadSubscribe();
    }
    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            redirectTo("/login");
            return false;
        }
        const query = queryString.parse(this.props.location.search);
        if (query.type == 1) {
            this.setState({
                type: query.type,
                typeName: "青铜会员（月）",
                price: 30,
            })
        } else if (query.type == 2) {
            this.setState({
                type: query.type,
                typeName: "青铜会员（季）",
                price: 68,
            })
        } else if (query.type == 3) {
            this.setState({
                type: query.type,
                typeName: "青铜会员（年）",
                price: 218,
            })
        } else if (query.type == 4) {
            this.setState({
                type: query.type,
                typeName: "黄金会员（月）",
                price: 199,
            })
        } else if (query.type == 5) {
            this.setState({
                type: query.type,
                typeName: "黄金会员（季）",
                price: 518,
            })
        } else if (query.type == 6) {
            this.setState({
                type: query.type,
                typeName: "黄金会员（年）",
                price: 1688,
            })
        }

    }
    componentDidUpdate(prevProps) {
        const { userLogin, showCodeImg } = this.props;

        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        //支付宝付款

        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }

        //微信
        if (this.props.wxCode != prevProps.wxCode) {
            const send = {
                codeShow: true,
                codeUrl: this.props.wxCode,
                orderid: this.props.orderid,
                price: this.props.price,
            }
            showCodeImg(send);
            return;
        }
        //银行卡转账
        if (this.props.bank) {
            if (this.state.bank == 1) {
                return;
            }
            this.setState({
                bank: 1,
            })
            jquery("body").css("overflow", "hidden");
            jquery("body").css("height", "100%");
        }

    }
    // 优惠券

    handelChange(e) {
        this.setState({
            inpValu: e.target.value,
        })
    }
    onFocus() {
        this.setState({
            messageShow: false
        })
    }
    //选择支付方式
    onChange = e => {
        if (e.target.value == 4) {
            this.setState({
                value: e.target.value,
                isdisabled: false
            })
        } else {
            this.setState({
                value: e.target.value,
                isdisabled: true
            })
        }
    };
    //付款
    payMonery() {
        const { crteateSubscribe } = this.props;
        const send = {
            type: this.state.type,
            type_name: this.state.typeName,
            price: this.state.price,
            payType: this.state.value.toString(),
            code: this.state.inpValu
        };
        crteateSubscribe(send);
        let _this = this
        setTimeout(function () {
            if (_this.props.status == 1 && _this.state.type == 4) {
                redirectTo("/user/subscribe?isHistory=1");
            }
        }, 1000)
        this.setState({
            messageShow: true,
            status: _this.props.status
        })
    }
    //点击阅读关闭
    handleClose() {
        jquery("body").css("overflow", "auto");
        jquery("body").css("height", "auto");
        this.setState({
            bank: 0,
        })
        redirectTo("/user/subscribe?isHistory=1");
    }
    //关闭二维码
    closeCode() {
        const { closeCodeUrl } = this.props;
        closeCodeUrl();
        redirectTo("/user/subscribe?isHistory=1");
    }
    render() {
        const { orderid, price } = this.props;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginBottom: "15px",
        };
        const radioStyle2 = {
            height: '30px',
            lineHeight: '30px',
            marginBottom: "15px",
        };
        const gap = {
            margin: '10px 0'
        };
        const dispatch = {
            closeCode: this.closeCode,
        }
        const isShow = this.state.bank == 1 ? "dialog-bg" : "hidden";
        const query = queryString.parse(this.props.location.search);
        const iscoupon = query.type == 4 ? "block" : "hidden";
        let message;

        if (this.state.messageShow) {
            message = (
                <p style={gap}>{this.props.message}</p>
            )
        }
        return (
            <div className="mypageContents fixPriceContents " id="subscribePay">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">订阅会员</h1>
                    </header>
                    <div className="mainContents__box">
                        <div className="fixPriceMain " style={{ background: "#fff" }}>
                            <div className="fixPriceMain__inner">
                                <div className=" member-pay">
                                    <header>
                                        <div>
                                            <span className=" member-pay-current"><img className="img_phone_hidden" height="30" src="assets/img/pay3-07.png" />订阅会员</span>＞
                                            <span><img className="img_phone_hidden" height="30" src="assets/img/pay3-08.png" />支付</span>＞
                                            <span><img className="img_phone_hidden" height="30" src="assets/img/pay3-10.png" />成为会员</span>
                                        </div>
                                    </header>
                                    <div className="memberpay-main">
                                        <div className="pay-main-ti">订阅信息</div>
                                        <div className=" pay-main-de">
                                            <ul>
                                                <li><span>订阅类型：</span><span className=" color-green">{this.state.typeName}</span></li>
                                                <li><span>订阅价格：</span><span className=" color-green">￥{this.state.price}</span></li>
                                                <li><span>可购买歌曲数量：</span><span>5首/月免费购买下载</span></li>
                                                <li><span>可授权用途：</span>{this.state.type > 3 ? <span>短视频/自媒体视频<br />非商用（国家官方机构发起的有公益证明文件的公益项目/非盈利教学）<br />音频背景音乐/FM/广播/有声读物<br />连续剧/动画/综艺<br />商业广告/宣传片/H5</span> : <span>短视频/自媒体视频/直播背景音乐<br />非商用（国家官方机构发起的有公益证明文件的公益项目/非盈利教学）<br />音频背景音乐/FM/广播/有声读物</span>}</li>
                                                <li><span>授权期限：</span><span>随片永久</span></li>
                                                <li><span>授权地区：</span><span>全球</span></li>
                                                <li><span>会员更多权益：</span><span>超出上述范围，订阅会员购买歌曲享受全场八折优惠</span></li>
                                            </ul>
                                        </div>
                                        <div className="pay-main-ti">支付方式</div>
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl margin_bottom_36 pay_style">
                                                <Radio.Group onChange={this.onChange} value={this.state.value}>
                                                    <Radio style={radioStyle} value={1}>
                                                        支付宝
                                                    </Radio>
                                                    <Radio className="wechat_code_phone" style={radioStyle2} value={3}>
                                                        微信支付
                                                    </Radio>
                                                    <Radio style={radioStyle} value={2}>
                                                        银行卡转账
                                                    </Radio>
                                                    <div className="notice_pay_red"> * 银行卡转账支持人民币和台币。价格均为人民币，如您选择台币支付，默认汇率为4.5。</div>
                                                    <div className={iscoupon}>
                                                        <Radio style={radioStyle} value={4}>
                                                            使用优惠券服务
                                                        </Radio>
                                                        <div className="notice_pay_red">                                                       <input type="text" onChange={this.handelChange.bind(this)} defaultValue={this.state.inpValu} placeholder={'请输入优惠码'} onFocus={this.onFocus.bind(this)} disabled={this.state.isdisabled} />
                                                            {message}
                                                        </div>
                                                    </div>

                                                </Radio.Group>
                                            </dl>

                                        </div>
                                        <div>

                                        </div>
                                        <div className=" member-gopay" onClick={this.payMonery.bind(this)}>
                                            <a>开通订阅会员</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isShow} style={{ WebkitOverflowScrolling: "touch" }}>
                    <div className="modalOverlay" />
                    <div className="choose-container scroll-y modalBox__inner">
                        <div className=" booker-ti"><span>订单已提交！</span></div>
                        <div className=" book-code"><span style={{ color: "red" }}>订单号：</span>{orderid}</div>
                        <div className=" book-de">您可自行选择币种汇款至对应账户，成功汇款后，请将<span>订单号</span>与<span>汇款账号后五位</span>发送至<a style={{ fontWeight: "600" }}>sales@musiness.vip</a>； 或 至<a style={{ fontWeight: "600" }}>个人中心—订阅会员记录—未付款</a>页面填写相关信息。</div>
                        <div className=" book-de">在您提交汇款信息后，MUSINESS将在24小时之内进行审核，审核通过后，您即可成为MUSINESS订阅会员，并享受会员权益。</div>
                        <div>
                            <div className=" table-ti"><strong>收款账号信息如下：</strong></div>
                            <div>
                                <div className=" table-name">
                                    <div className=" wid20">币种</div>
                                    <div className=" wid20">金额</div>
                                    <div className=" wid60">银行卡信息</div>
                                </div>
                                <div className=" table-de">
                                    <ul>
                                        <li>
                                            <div className=" wid20 line-78">人民币</div>
                                            <div className=" wid20 color-green line-78">{!price ? 0 : price}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>上海小仙虎文化传媒有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>中国工商银行股份有限公司上海市上外支行</div>
                                                <div className=" table-all"><span>账号：</span>1001274409300013770</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" wid20 line-78">台币</div>
                                            <div className=" wid20 color-green line-78">{!price ? 0 : price * 4.5}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>啟動音樂文創有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>上海商業儲蓄銀行（世貿分行）</div>
                                                <div className=" table-all"><span>账号：</span>43102000032270</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div><a onClick={this.handleClose.bind(this)} className="cancle">已阅读，点击关闭</a></div>
                    </div>
                </div>
                <WxCode {...dispatch}></WxCode>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribePay);
