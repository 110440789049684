// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { Badge } from "antd";
import { Link } from "react-router-dom";
import "./Footer.less";
import { redirectTo } from "dispatch";
const mapStateToProps = state => ({
    ...state.footer,
    ...state.cart,
    currentUser: state.user.currentUser,
});

const mapDispatchToProps = dispatch => ({});

class Webfooter extends React.Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        linkTo: PropTypes.func.isRequired,
        cartCount: PropTypes.number.isRequired,
        currentUser: PropTypes.object,
    };

    renderLi = (title, to) => {
        const { linkTo, location } = this.props;
        let className = "globalFooter__subNav__item";

        if (to && location.pathname.indexOf(to) > -1) {
            className += ""; // current
        }
        return (
            <li key={`${to}-${title}`} className={className}>
                <a onClick={linkTo} href={to}>
                    {title}
                </a>
            </li>
        );
    };
    jinru(){
        redirectTo("/subscribermembers");
    }
    render() {
        const { linkTo, cartCount, currentUser } = this.props;
        const navLinks = {
            首页: "/",
            搜索: "/music/search",
            定价: "/fixPrice",
            // 活动促销: "/page/sales",
            个人中心: "/user/mypage",
        };
        const helpLinks = {
            联系我们: "/contact",
            帮助中心: "/helpcenter",
        };
        const partnerLinks = {
            合作商: "/partner",
            创作人: "/maker",
        };
        const aboutLinks = {
            关于我们: "/aboutus",
            媒体报导: "/media",
            // 购买协议: "/terms/use"#d9d9d9,
            // 使用协议: "/terms/use",
            卖家协议: "/teamSell",
            版权声明: "/teamQuan",
            平台用户协议: "/teamUse",
        };
        return (
            <footer className="globalFooter">
                <div className="fixedMenu js-fixedMenu">
                    <ul className="fixedMenu__items">
                        <div className="my_fixedMenu " onClick={this.jinru}>
                            <img  src="/assets/img/181.jpg" />
                        </div>
                        <li className="fixedMenu__item fixedMenu__item-01">
                            <Link  to="/cart">
                                <span>购物车</span>
                                {cartCount > 0 && (
                                    <p className="fixedMenu__item__num">
                                        {cartCount}
                                    </p>
                                )}
                            </Link>
                        </li>
                        
                    </ul>
                </div>
                <div className="globalFooter__inner">
                    <div className="globalFooter__titlebox">
                        <p className="globalFooter__title">
                            <img
                                src="/assets/img/footer_title.svg"
                                alt="MUSINESS = MUSIC + BUSINESS"
                            />
                        </p>
                        <p className="globalFooter__lead">
                            因为音乐而遇见，因为遇见而找到最合适的。
                        </p>
                        <p className="globalFooter__signUp">
                            {currentUser ? (
                                <a
                                    onClick={linkTo}
                                    href="/user/mypage"
                                    className="m-btn-type1"
                                >
                                    个人中心
                                </a>
                            ) : (
                                <a
                                    onClick={linkTo}
                                    href="/register"
                                    className="m-btn-type1"
                                >
                                    注册
                                </a>
                            )}
                        </p>
                    </div>

                    <div className="globalFooter__nav">
                        <ul className="globalFooter__nav__items">
                            <li className="globalFooter__nav__item">
                                <span>网站导览</span>
                                <ul className="globalFooter__subNav__items">
                                    {Object.entries(navLinks).map(
                                        ([key, value]) =>
                                            this.renderLi(key, value)
                                    )}
                                </ul>
                            </li>

                            <li className="globalFooter__nav__item">
                                <span>需要帮助</span>
                                <ul className="globalFooter__subNav__items">
                                    {Object.entries(helpLinks).map(
                                        ([key, value]) =>
                                            this.renderLi(key, value)
                                    )}
                                </ul>
                            </li>

                            <li className="globalFooter__nav__item">
                                <span href="">合作商</span>
                                <ul className="globalFooter__subNav__items">
                                    {Object.entries(partnerLinks).map(
                                        ([key, value]) =>
                                            this.renderLi(key, value)
                                    )}
                                </ul>
                            </li>

                            <li className="globalFooter__nav__item">
                                <span>关于</span>
                                <ul className="globalFooter__subNav__items">
                                    {Object.entries(aboutLinks).map(
                                        ([key, value]) =>
                                            this.renderLi(key, value)
                                    )}
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div style={{clear:"both",height:"50px"}}></div>
                    {/* <div className="s_youqing"><span style={{marginRight:"30px"}}>友情链接：</span>
                        <a style={{color:"#fff",marginLeft:"30px"}} target="view_window" className="a_youqing" href='http://www.aigei.com/sound' >爱给网免费音效</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://www.ooopic.com/' >我图网</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://lightmv.cn' >右糖电子相册制作</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://v.paixin.com'>商业配图</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://www.mcwzg.com'>美陈网</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://muhou.net'>视频模版</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://www.htzyw.com'>ae模板</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://www.3dxy.com/'>免费模型下载</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='http://www.j-h-k.com'>建筑设计</a>
                        <a style={{color:"#fff",marginLeft:"60px"}} target="view_window" className="a_youqing" href='https://www.partnershare.cn/?source=musiness'>产品分享社区</a>
                    </div> */}
                    <div className="info">
                        <a style={{textDecoration:"none",color:"#fff"}} target="view_window" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602003125">
                            Copyright © 2019 musiness.vip &nbsp;&nbsp;版权所有&nbsp;
                            < img src="https://www.musiness.vip/ghs.png" style={{ margin: "3px 2px 2px 2px", height: "16px" }} />
                            沪公网安备31010602003125号｜
                        </a>
                        <a style={{textDecoration:"none", color:"#fff"}}>
                            沪ICP备18038256号-2｜
                        </a>
                        {/* <a  href="/culture" target="_blank" style={{textDecoration:"none", color:"#fff"}}>
                            网络文化经营许可证：沪网文（2019）0205-016号｜
                          </a> */}
                        <a  href="#" target="_self" style={{textDecoration:"none", color:"#fff"}}>
                            网络文化经营许可证：沪网文（2024）2141-136号｜
                        </a>
                        {/*<a  href="/icp" target="_blank" style={{textDecoration:"none", color:"#fff"}}>
                            增值电信业务经营许可证：沪B2-20190253  
                        </a>*/}
                        <a  href="#" target="_self" style={{textDecoration:"none", color:"#fff"}}>
                            增值电信业务经营许可证：沪B2-20240753  
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Webfooter);

