import { WITHDRAW ,APPLY_WITHDRAW} from "../constants/actionTypes";

const defaultState = {
    applywith:{
        money:"0"
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        
        case WITHDRAW:
            return { 
                errors:action.error ? action.payload.errors:null,
            };
        case APPLY_WITHDRAW: {
            return {
                ...state,
                applywith: action.applywith,
            };
        }
        default:
            return state;
    }
};
