import agent from "./agent";
import {
    ASYNC_START,
    ASYNC_END,
    LOGOUT,
    NEED_SIGIN,
} from "./constants/actionTypes";

const createPayloadWithResponse = response => {
    let payload = { errors: [] };
    try {
        // empty check
        if (!response || !typeof response === "object") {
            payload.errors.push("empty response");
            return payload;
        }
        // token
        agent.setToken(response);
        if (!response.body) {
            payload.errors.push("empty response body:", response.text);
            return payload;
        }
        // body
        const body = response.body;
        if (!Array.isArray(body.errors)) {
            body.errors = [];
        }
        if (typeof body.status === "number" && body.status <= 0) {
            // hook error
            const { status, message } = body;
            body.errors.push({ status, message });
        }
        payload = body;
        return payload;
    } catch (e) {
        payload.errors.push(e.message);
    }
    return payload;
};

const dealResponse = response => {
    if (Array.isArray(response)) {
        const payload = { errors: [], res: [] };
        for (const res of response) {
            const act = createPayloadWithResponse(res);
            payload.errors = payload.errors.concat(act.errors);
            payload.res.push(act.payload);
        }
        return payload;
    }
    return createPayloadWithResponse(response);
};

const promiseMiddleware = store => next => action => {
    if (isPromise(action.payload)) {
        store.dispatch({
            type: ASYNC_START,
            subtype: action.type,
            send: action.send,
        });

        const currentView = store.getState().viewChangeCounter;
        const skipTracking = action.skipTracking;

        (async () => {
            const errors = [];
            let response = null;
            try {
                response = await action.payload;
            } catch (e) {
                if (e.response) {
                    response = e.response;
                } else {
                    errors.push(e.message);
                }
            }
            const currentState = store.getState();
            if (
                !skipTracking &&
                currentState.viewChangeCounter !== currentView
            ) {
                return;
            }
            if (!action.skipTracking) {
                store.dispatch({
                    type: ASYNC_END,
                    promise: action.payload,
                    subtype: action.type,
                    send: action.send,
                });
            }
            action.payload = dealResponse(response);
            action.payload.errors = action.payload.errors.concat(errors);
            action.error = !!action.payload.errors.length;
            if (action.error) {
                const message = action.payload.errors[0];
                if (
                    typeof message === "string" &&
                    message.trim() === NEED_SIGIN
                ) {
                    store.dispatch({ type: NEED_SIGIN });
                }
                // TODO: cache login first error
            } else if (typeof action.callback === "function") {
                setTimeout(action.callback, 20);
            }
            delete action.callback;

            if (typeof action.done === "function") {
                const e = action.error;
                const d = action.done;
                setTimeout(() => {
                    d(e);
                }, 20);
                delete action.done;
            }

            store.dispatch(action);
        })();

        return;
    }

    next(action);
};

const localStorageMiddleware = store => next => action => {
    if (action.type === LOGOUT) {
        agent.rmToken();
    }

    next(action);
};

function isPromise(v) {
    return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware };
