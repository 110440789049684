import React from "react";
import { Form, Input } from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;

class CompanySellerInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        form: PropTypes.object.isRequired,
        currentUser: PropTypes.object,

        onRef: PropTypes.func.isRequired,
        handleCompanyInfoVal: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    companyInfoCheck = async () => {
        return new Promise((resolve, reject) => {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return reject(err);
                }
                return resolve(values);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { currentUser } = this.props;
        return (
            <Form>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>企业名称</label>
                        </dt>
                        <dd className="buypageForm__dd">{currentUser && currentUser.company_name}</dd>
                    </dl>
                </div>

                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>营业执照号</label>
                        </dt>
                        <dd className="buypageForm__dd">{currentUser && currentUser.company_number}</dd>
                    </dl>
                </div>

                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>联系人</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <FormItem
                                style={{
                                    width: "40%",
                                    display: "inline-block",
                                }}
                            >
                                {getFieldDecorator("sellerFamilyName", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入真实姓名!",
                                        },
                                    ],
                                })(
                                    <Input
                                        className="u-inputSkin02"
                                        placeholder="请输入真实姓名"
                                        onChange={this.props.handleCompanyInfoVal}
                                    />
                                )}
                            </FormItem>
                        </dd>
                    </dl>
                </div>

                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>联系电话</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <FormItem>
                                {getFieldDecorator("contactPhone", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入联系电话!",
                                        },
                                    ],
                                })(
                                    <Input
                                        type="tel"
                                        className="u-inputSkin02"
                                        placeholder="请输入联系电话"
                                        onChange={this.props.handleCompanyInfoVal}
                                    />
                                )}
                            </FormItem>
                        </dd>
                    </dl>
                </div>
            </Form>
        );
    }
}
const CompanySellerInfoForm = Form.create()(CompanySellerInfo);
export default CompanySellerInfoForm;
