import {
    ASYNC_START,
    MAKER_LIST_PAGE_UNLOAD,
    MAKER_LIST_LOAD,
    MAKER_LOAD,
    MAKER_DELETE,
    MAKER_EDIT,
    WITHDRAW_INFO,
    WITHDRAW_LIST,
    INCOME_LIST,
    //WITHDRAW,
    SETTLEMENT_PAGE_UNLOADED,
    SELLER_PROFILE,
    SELLER_PROFILE_PAGE_UNLOADED,
    SETTLEMENT_UPDATE,
    PRODUCER_CREATE_AUDIO,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,
    pagination: { pageSize: 10, current: 1 },
    incomeListPagination: { pageSize: 10, current: 1 },
    incomeListTotal: 0,
    withdDrawPagination: { pageSize: 10, current: 1 },
    withdrawTotal: 0,
    makerList: [],
    makeId: "",
    total: 0,
    withdrawInfo: {},
    withdrawList: [],
    incomeList: [],
    profile: {},
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MAKER_LIST_LOAD: {
            const error = !!action.error;
            const pagination = action.pager || state.pagination;
            const makerList = action.error ? [] : action.payload.makerList;
            const total = action.error ? 0 : action.payload.total;
            if (!action.error) {
                pagination.total = action.payload.total;
            }
            return {
                ...state,
                makerList,
                error,
                pagination,
                total,
                loading: false,
            };
        }
        case MAKER_LOAD: {
            const error = !!action.error;
            const makerId = error ? "" : action.payload.makerId;
            return {
                ...state,
                makerId,
                error,
                loading: false,
            };
        }
        case MAKER_DELETE: {
            const error = !!action.error;
            const makerId = error ? "" : action.payload.makerId;
            return {
                ...state,
                makerId,
                error,
                loading: false,
            };
        }
        case MAKER_EDIT: {
            const error = !!action.error;
            const makerId = error ? "" : action.payload.makerId;
            return {
                ...state,
                makerId,
                error,
                loading: false,
            };
        }
        case WITHDRAW_INFO: {
            const error = !!action.error;
            const withdrawInfo = error ? {} : action.payload.info;
            return {
                ...state,
                withdrawInfo,
                error,
            };
        }
        case WITHDRAW_LIST: {
            const error = !!action.error;
            const pagination = action.pager || state.pagination;
            const withdrawList = error ? [] : action.payload.withdraw;
            const total = error ? 0 : action.payload.total;
            return {
                ...state,
                withdrawList,
                withdDrawPagination: pagination,
                withdrawTotal: total,
                error,
                loading: false,
            };
        }
        case INCOME_LIST: {
            const error = !!action.error;
            const pagination = action.pager || state.pagination;
            const incomeList = error ? [] : action.payload.withdraw;
            const total = error ? 0 : action.payload.total;
            return {
                ...state,
                incomeList,
                incomeListPagination: pagination,
                incomeListTotal: total,
                error,
                loading: false,
            };
        }
        // case WITHDRAW: {
        //     return {
        //         cash: action.payload.cash,
        //         inProgress: false,
        //         withdrawSuccess: action.error ? false : true,
        //         errors: action.error ? action.payload.errors : null,
        //     };
        // }
        case SELLER_PROFILE: {
            const error = !!action.error;
            const profile = error ? {} : action.payload.user;
            return {
                ...state,
                profile,
                error,
            };
        }
        case SETTLEMENT_UPDATE:
            return {
                ...state,
                inProgress: false,
                updateSuccess: action.error ? false : true,
                errors: action.error ? action.payload.errors : null,
            };
        case ASYNC_START: {
            if (action.subtype === MAKER_LOAD) {
                if (!state.makerList || action.error) {
                    return { ...state, loading: true };
                }
            }
            if (
                action.subtype === MAKER_DELETE ||
                action.subtype === MAKER_EDIT
            ) {
                if (action.error) {
                    return { ...state, loading: true };
                }
            }
            if (
                action.subtype === WITHDRAW_LIST ||
                action.subtype === INCOME_LIST
            ) {
                if (action.error) {
                    return { ...state, loading: true };
                }
            }
            if (
                action.subtype === PRODUCER_CREATE_AUDIO 
            ) {
                return { ...state, loading: true };
            }
            return state;
        }
        case MAKER_LIST_PAGE_UNLOAD: {
            return { ...defaultState };
        }
        case SETTLEMENT_PAGE_UNLOADED: {
            return { ...defaultState };
        }
        case SELLER_PROFILE_PAGE_UNLOADED: {
            return { ...defaultState };
        }
        case PRODUCER_CREATE_AUDIO:{
            const errors= action.error ? action.payload.errors : null;
            return {
                ...state,
                errors,
                loading:false,
            };
        }
        default:
            return state;
    }
};
