import React from "react";
import PropTypes from "prop-types";

// First way to import
import { RingLoader } from "react-spinners";
import "./Loading.less";

class Loading extends React.Component {
    static propTypes = {
        loading: PropTypes.bool,
    };
    static defaultProps = {
        loading: true,
    };
    updateHeight() {
        const component = this.elem;
        if (!component || !component.parentElement) {
            return;
        }
        const { clientHeight, clientWidth } = component.parentElement;
        component.style.height = clientHeight + "px";
        component.style.width = clientWidth + "px";
    }
    componentDidMount() {
        this.updateHeight();
    }
    componentDidUpdate() {
        this.updateHeight();
    }
    render() {
        const { loading } = this.props;
        let className = "c325-loading";
        if (!loading) {
            return null;
        }
        return (
            <div
                ref={c => {
                    this.elem = c;
                }}
                className={className}
            >
                <RingLoader
                    sizeUnit={"px"}
                    size={60}
                    color={"#36D7B7"}
                    loading={loading}
                />
            </div>
        );
    }
}
export default Loading;
