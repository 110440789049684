import React from "react";
import {Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

import OrderOne from "./OrderOne";

import "./Order.less";

class Music extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/:orderone`} component={OrderOne} />
                <Route path={`${match.url}`} component={OrderOne} />
            </Switch>
        );
    }
}
export default Music;
