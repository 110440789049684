import {
    PUBLIC_MAKER_LIST,
    PUBLIC_MAKER_DETAIL,
    PUBLIC_MKAER_MUSIC,
    FAVORITE_ADD,
    FAVORITE_DELETE,
    DETAIL_PAGE_UNLOAD
} from "constants/actionTypes";

const defaultState = {
    list: {
        total: 0,
        pager: { pageSize: 12, current: 1 },
        error: false,
    },
    detail: {},
    audio: {
        data: [],
        total: 0,
        pager: { pageSize: 10, current: 1 },
        error: false,
    },
    loading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PUBLIC_MAKER_LIST: {
            const error = !!action.error;
            const {
                send,
                payload: { total, creators: data },
            } = action;

            const { pager } = send || {};
            const pagination = pager || state.pager;
            console.info('data',data)
            return {
                ...state,
                list: {
                    error,
                    total,
                    data,
                    pager: pagination,
                },
            };
        }
        case PUBLIC_MAKER_DETAIL: {
            const {
                payload: { creator },
            } = action;
            return {
                ...state,
                detail: creator,
                loading: true,
            };
        }
        case PUBLIC_MKAER_MUSIC: {
            const error = !!action.error;
            const {
                send,
                payload: { audios: data = [], total },
            } = action;

            const { pager } = send || {};
            const pagination = pager || state.pager;

            return {
                ...state,
                audio: {
                    data,
                    error,
                    total,
                    pager: pagination,
                },
            };
        }
        case FAVORITE_ADD:
        case FAVORITE_DELETE: {
            const { error, send, payload } = action;
            if (error) return state;
            const { audioid, page } = send;
            if (!page || page !== "maker") return state;

            return {
                ...state,
                audio: {
                    ...state.audio,
                    data: state.audio.data.map(x => {
                        if (x.audioid === audioid) {
                            x.like = action.type === FAVORITE_DELETE ? null : { id: payload.like.id };
                        }
                        return { ...x };
                    }),
                },
            };
        }
        case DETAIL_PAGE_UNLOAD: {
            return {
                ...state,
                detail: "",
                audio: {
                    data: "",
                    total: 0,
                    pager: { pageSize: 10, current: 1 },
                    error: false,
                },
                loading: false,
            };
        }
        default:
            return state;
    }
};
