import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Radio,Input } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import {
    WITHDRAW_INFO,
    WITHDRAW,
    SETTLEMENT_PAGE_UNLOADED,
} from "constants/actionTypes";
import { loadSellerProfileInfo } from "dispatch";
import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import { redirectTo } from "dispatch";
import moment from "moment";
import "./ApplyForCash.less";
const mapStateToProps = state => ({
    ...state.user,
    ...state.applyForCash,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadWithdrawInfo: payload => {
        return dispatch({
            type: WITHDRAW_INFO,
            payload: agent.Seller.loadWithdrawInfo(),
        });
    },

    withdraw: (send) => {
        return dispatch({
            type: WITHDRAW,
            payload: agent.Seller.withdraw(send),
        });
    },

    unloadSettlement: () => {
        return dispatch({ type: SETTLEMENT_PAGE_UNLOADED });
    },
});

class ApplyForCash extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taxStyle: 1,
            alipay:1,
            open:false,
        }
    }

    static propTypes = {
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        withdrawSuccess: PropTypes.bool,
        profile: PropTypes.object,
        withdraw: PropTypes.func.isRequired,
        unloadSettlement: PropTypes.func.isRequired,
        applywith:PropTypes.object.isRequired,
    };

    static defaultProps = {
        withdrawSuccess: false,
        applywith:{money:""},
    };

    componentWillUnmount() {
        this.props.unloadSettlement();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (nextProps.withdrawSuccess && agent.getToken()) {
            redirectTo("/seller/settlement");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin,applywith } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user profile
        if (applywith.money<=0) {
            redirectTo("/seller/settlement");
            return false;
        }
        loadSellerProfileInfo();
    }
    componentDidUpdate(prevProps) {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
        
    }
    handleSubmit(e) {
        const { currentUser,withdraw,applywith } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const send={
                    tax:this.state.taxStyle,
                    price:applywith.money,
                    withdraw:this.state.alipay,
                    alipay:values.alipay,
                    realname:values.realname,
                    bankname:values.bankname,
                    branchname:values.branchname,
                    banknumber:values.banknumber,
                    accountname:values.accountname,
                }
                const name=currentUser.family_name;
                if (this.state.alipay==1) {
                    if (!values.alipay) {
                        this.props.form.setFields({
                            alipay: {
                                value: values.alipay,
                                errors: [new Error("请输入支付宝账号!")],
                            },
                        });
                        return false;
                    }
                }
                if (this.state.alipay==2) {
                    let flag=0;
                    if (!values.bankname) {
                        this.props.form.setFields({
                            bankname: {
                                value: values.bankname,
                                errors: [new Error("请输入银行名称!")],
                            },
                        });
                        flag=1;
                    }
                    if (!values.branchname) {
                        this.props.form.setFields({
                            branchname: {
                                value: values.branchname,
                                errors: [new Error("请输入支行名称!")],
                            },
                        });
                        flag=1;
                    }
                    if (!values.banknumber) {
                        this.props.form.setFields({
                            banknumber: {
                                value: values.banknumber,
                                errors: [new Error("请输入银行卡号!")],
                            },
                        });
                        flag=1;
                    }
                    if (flag==1) {
                        return false;
                    }
                }
                if (currentUser.company) {
                    if (this.state.alipay==1&&currentUser.family_name!=values.realname) {
                        this.props.form.setFields({
                            realname: {
                                value: values.realname,
                                errors: [new Error("提现账户的姓名须与卖家认证姓名一致！")],
                            },
                        });
                        return false;
                    }
                    if (this.state.alipay==2&&currentUser.family_name!=values.accountname) {
                        this.props.form.setFields({
                            accountname: {
                                value: values.accountname,
                                errors: [new Error("提现账户的姓名须与卖家认证姓名一致！")],
                            },
                        });
                        return false;
                    }
                }
                if (!currentUser.company) {
                    if (this.state.alipay==1&&name!=values.realname) {
                        this.props.form.setFields({
                            realname: {
                                value: values.realname,
                                errors: [new Error("提现账户的姓名须与卖家认证姓名一致！")],
                            },
                        });
                        return false;
                    }
                    if (this.state.alipay==2&&name!=values.accountname) {
                        this.props.form.setFields({
                            accountname: {
                                value: values.accountname,
                                errors: [new Error("提现账户的姓名须与卖家认证姓名一致！")],
                            },
                        });
                        return false;
                    }
                   
                }
                withdraw(send);
            }
        })
    }

    onChange = e => {
        this.setState({
            taxStyle: e.target.value,
        });
    };
    onChangeAlipay = e => {
        this.setState({
            alipay: e.target.value,
        });
        if (e.target.value==1) {
            this.props.form.resetFields("bankname");
            this.props.form.resetFields("branchname");
            this.props.form.resetFields("banknumber");
            this.props.form.resetFields("accountname");
        }else if (e.target.value==2) {
            this.props.form.resetFields("alipay");
            this.props.form.resetFields("realname");
        }
    };
    closeShow(){
        this.setState({
            open:false,
        });
        redirectTo("/seller/settlement");
    }
    showFormItemServerError() {
        const errors = this.props.errors;
        if (!errors) {
            this.setState({
                open:true,
            })
        }
    }

    render() {
        const { currentUser ,applywith} = this.props;
        let notice="hidden";
        if (applywith.money==50000) {
            notice="two_font_notice";
        }
        const { getFieldDecorator } = this.props.form;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }
        const { open } = this.state;
        const showClass = open ? "isShow" : "modalBox";
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const isSHowOne=this.state.taxStyle==2?"one_font_notice hidden":"one_font_notice";
        const isSHowTwo=this.state.taxStyle==1?"two_font_notice hidden":"two_font_notice";
        const isSHowAlipayOne=this.state.alipay==2?"cashItem__list hidden":"cashItem__list";
        const isSHowAlipayTwo=this.state.alipay==1?"cashItem__list hidden":"cashItem__list";
        
        let time=moment().add(1, 'months').format("YYYY.MM");
        time+=".15 23:59:59前";
        return (
            <div className="mymusicContents"  id = "apply_for_cash">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox">
                            <h2 className="mymusicBox__title" >申请提现</h2>
                            <div className="cashItemWrapper">
                                <div className="cashItem">
                                    <dl className="cashItem__list">
                                        <dt className="cashItem__dt">
                                            纳税方式：
                                        </dt>
                                        <dd className="cashItem__dd">
                                            <Radio.Group onChange={this.onChange} value={this.state.taxStyle}>
                                                <Radio style={radioStyle} value={1}>
                                                    <span className="one_font_size">MUSINESS代扣代缴税费</span>
                                                </Radio>
                                                <span className={isSHowOne}>*（将统一扣除税前金额的22%作为代缴税费及代扣手续费）</span>
                                                <Radio style={radioStyle} value={2}>
                                                    <span className="one_font_size">卖家自行缴纳</span>
                                                </Radio>
                                                <div className={isSHowTwo}>*（根据可提现金额开具等额增值税普通发票或者增值税专用发票寄至上海市徐汇区肇嘉浜路789号均瑶国际大厦23层F2 MUSINESS财务部，或有效电子发票发送至sales@musiness.vip）</div>
                                            </Radio.Group>
                                        </dd>
                                    </dl>
                                </div>

                                <div className="cashItem">
                                    <dl className="cashItem__list">
                                        <dt className="cashItem__dt">
                                            提现金额：
                                        </dt>
                                        <dd className="cashItem__dd margin_left_44">
                                            <span className="font_size_15 color_monery padding_px_3">¥ {applywith.money}</span>
                                            <span className={notice}>*最大提现金额为50000元</span>
                                        </dd>
                                    </dl>
                                    <dl className="cashItem__list">
                                        <dt className="cashItem__dt">
                                            提现方式：
                                        </dt>
                                        <dd className="cashItem__dd padding_top_2">
                                            <Radio.Group onChange={this.onChangeAlipay} value={this.state.alipay}>
                                                <Radio className="font_size_15" value={1}>支付宝</Radio>
                                                <Radio className="margin_left_60 font_size_15" value={2}>银行卡</Radio>
                                            </Radio.Group>
                                        </dd>
                                    </dl>
                                    <dl className={isSHowAlipayOne}>
                                        <Form >
                                            <dd className="cashItem__dd padding_left_9">
                                                <Form.Item label="支付宝账号">
                                                    {getFieldDecorator('alipay', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==1?true:false,
                                                                message: '请输入支付宝账号',
                                                            },
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                            <dd className="cashItem__dd clear_both padding_left_9" >
                                                <Form.Item label="真实姓名">
                                                    {getFieldDecorator('realname', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==1?true:false,
                                                                message: '提现账户的姓名须与卖家认证姓名一致！',
                                                            },
                                                        ],
                                                        initialValue: currentUser.family_name,
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                        </Form>
                                    </dl>
                                    <dl className={isSHowAlipayTwo}>
                                        <Form>
                                            <dd className="cashItem__dd padding_left_9">
                                                <Form.Item label="银行名称">
                                                    {getFieldDecorator('bankname', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==2?true:false,
                                                                message: '请输入银行名称!',
                                                            },
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                            <dd className="cashItem__dd clear_both padding_left_9" >
                                                <Form.Item label="支行名称">
                                                    {getFieldDecorator('branchname', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==2?true:false,
                                                                message: '请输入支行名称!',
                                                            },
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                            <dd className="cashItem__dd padding_left_9">
                                                <Form.Item label="银行卡号">
                                                    {getFieldDecorator('banknumber', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==2?true:false,
                                                                message: '请输入银行卡号',
                                                            },
                                                        ],
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                            <dd className="cashItem__dd clear_both padding_left_9" >
                                                <Form.Item label="账户名">
                                                    {getFieldDecorator('accountname', {
                                                        rules: [
                                                            {
                                                                required: this.state.alipay==2?true:false,
                                                                message: '提现账户的姓名须与卖家认证姓名一致！',
                                                            },
                                                        ],
                                                        initialValue: currentUser.family_name,
                                                    })(<Input />)}
                                                </Form.Item>
                                            </dd>
                                        </Form>
                                    </dl>
                                    <dl className="cashItem__list">
                                        <dt className="cashItem__dt phone_width">
                                            预计到账时间：
                                        </dt>
                                        <dd className="cashItem__dd font_size_15 padding_px_3">
                                            {time}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="button_center">
                                <button  onClick={this.handleSubmit.bind(this)} className="pay_button">提交</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={showClass}>
                    <div className="modalOverlay" />
                    <div className="modalBox__inner">
                        <div id="modalForm1" className="modalForm" tabIndex="0">
                            <div className="modalForm__header">
                                <p className="modalForm__close">
                                    <button
                                        onClick={this.closeShow.bind(this)}
                                        type="button"
                                    />
                                </p>
                            </div>
                            <div className="apply_for_notice">
                                <img  src="../assets/img/u306.png"/>提现申请提交成功!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const ApplyForCashForm = Form.create()(ApplyForCash);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplyForCashForm);
