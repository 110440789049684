import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Pagination ,Tooltip} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
    WITHDRAW_LIST,
    INCOME_LIST,
    WITHDRAW_INFO,
} from "constants/actionTypes";

import agent from "agent";

import Loading from "components/Loading";
import "./pager.less";
import { redirectTo, showMoney, HtmlUtil ,showWithDrawMoney} from "dispatch";
import ExportJsonExcel from 'js-export-excel';
const text = <div>
    <p>卖家享有60%的收入分成，例如一首定价为1500元的音乐，卖家税前收入为：1500元×60%=900元。</p>
</div>;
const queryString = require("query-string");

const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    incomeList: state.seller.incomeList,
    withdrawList: state.seller.withdrawList,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadWithdrawList: (pager) => {
        return dispatch({
            type: WITHDRAW_LIST,
            pager,
            payload: agent.Seller.loadWithdrawList(pager),
        });
    },
    loadIncomeList: (pager) => {
        return dispatch({
            type: INCOME_LIST,
            pager,
            payload: agent.Seller.loadIncomeList(pager),
        });
    },
    loadWithdrawInfo: () => {
        return dispatch({
            type: WITHDRAW_INFO,
            payload: agent.Seller.loadWithdrawInfo(),
        });
    },
});

class RecordList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tabSelectedFlg: "0" };
    }

    static propTypes = {
        withdrawList: PropTypes.array,
        withdDrawPagination: PropTypes.object.isRequired,
        withdrawTotal: PropTypes.number.isRequired,
        incomeList: PropTypes.array,
        incomeListPagination: PropTypes.object.isRequired,
        incomeListTotal: PropTypes.number.isRequired,
        withdrawInfo: PropTypes.object,

        loadWithdrawList: PropTypes.func.isRequired,
        loadIncomeList: PropTypes.func.isRequired,
        loadWithdrawInfo: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { userLogin } = this.props;

        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        this.loadWithdrawInfo();
        this.loadCurrentPageData("0");
        this.loadCurrentPageData("1");
    }

    loadWithdrawData = (pager, params) => {
        const { loadWithdrawList } = this.props;
        loadWithdrawList(params);
    };

    loadIncomeData = (pager, params) => {
        const { loadIncomeList } = this.props;
        loadIncomeList(params);
    };

    loadWithdrawInfo = () => {
        const { loadWithdrawInfo } = this.props;
        loadWithdrawInfo();
    };

    loadCurrentPageData = flg => {
        const {
            incomeListPagination,
            withdDrawPagination,
            location,
        } = this.props;

        let pager =
            flg === "0"
                ? { ...withdDrawPagination }
                : { ...incomeListPagination };

        pager.current = 1; // default
        let query = "";
        if (location) {
            query = queryString.parse(location.search);
        }

        const params = {
            page: pager.current,
            ...query,
            size:
                flg === "0"
                    ? withdDrawPagination.pageSize
                    : incomeListPagination.pageSize,
        };
        // reset from params
        pager.current = parseInt(params.page);
        if (flg === "0") {
            this.loadWithdrawData(pager, params);
        } else {
            this.loadIncomeData(pager, params);
        }
        this.loadWithdrawInfo();
    };

    onPageChange(pageNumber) {
        const {
            incomeListPagination,
            withdDrawPagination,
            location,
        } = this.props;

        let pager =
            this.state.tabSelectedFlg === "0"
                ? { ...withdDrawPagination,orderby:'-id', }
                : { ...incomeListPagination,orderby:'-id', };

        pager.current = pageNumber;
        let query = "";
        if (location) {
            query = queryString.parse(location.search);
        }
        const params = {
            ...query,
            page: pager.current,
            orderby:'-id',
        };
        params.size =
            this.state.tabSelectedFlg === "0"
                ? 10
                : 10;

        if (this.state.tabSelectedFlg === "0") {
            this.loadWithdrawData(pager, params);
        } else {
            this.loadIncomeData(pager, params);
        }
    }

    changeToIncome(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ tabSelectedFlg: "1" });
        // this.loadCurrentPageData("1");
    }

    changeToWithdraw(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ tabSelectedFlg: "0" });
    }

    // 导出提现详细数据
    downloadExcel = (order) => {
        
        const data = order.musicInfo ? order.musicInfo : '';//表格数据
        const tradeDate = moment(order.create_at).format("YYYY.MM.DD HH:mm:ss");
        const money=showWithDrawMoney(order.price);
        const account=order.withdraw==1?"支付宝 "+order.alipay:order.bankname+" "+order.banknumber;
        var option={};
        console.info(money);
        let dataTable = [
            {one:'提现时间:',two:tradeDate},
            {one:'提现金额:',two:money},
            {one:'收款账号:',two:account},
            {one:'详细如下:'},
            {one:'序号',two:'音乐名称',three:'创作人',four:'项目名称',five:'交易时间',six:'税前收入'},
        ];
        if (data) {
            for (let i in data) {
                if(data){
                    const id=parseInt(i)+1
                    let obj = {
                        '序号': id,
                        '音乐名称': data[i].title,
                        '创作人': data[i].creator||data[i].composer,
                        '项目名称': data[i].attachment?HtmlUtil.htmlDecode(JSON.parse(data[i].attachment).area):"",
                        '交易时间': moment(data[i].create_at).format("YYYY.MM.DD HH:mm:ss"),
                        '税前收入': data[i].price/100*0.6+"元",
                        
                        //'买家': data[i].attachment?HtmlUtil.htmlDecode(JSON.parse(data[i].attachment).username):"",
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = tradeDate+"_提现详细记录表"
        option.datas=[
            {
                sheetData:dataTable,
                sheetName:'sheet',
                columnWidths : [4, 20,5,20,10,5],
                //sheetFilter:['ID','音乐名称','创作人','项目名称','交易时间','税前收入','提现时间','提现金额',],
                //sheetHeader:['ID','音乐名称','创作人','项目名称','交易时间','税前收入'],
            }
        ];
        
        var toExcel = new ExportJsonExcel(option); 
        toExcel.saveExcel();        
        
    }
    render() {
        const {
            currentUser,
            loading,
            withdrawList,
            incomeList,
            incomeListPagination,
            incomeListTotal,
            withdDrawPagination,
            withdrawTotal,
        } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }

        const pageWithdrawOptions = {
            defaultCurrent: withdDrawPagination.current,
            defaultPageSize: withdDrawPagination.pageSize,
            total: withdrawTotal,
        };

        const pageIncomeOptions = {
            defaultCurrent: incomeListPagination.current,
            defaultPageSize: incomeListPagination.pageSize,
            total: incomeListTotal,
        };

        const statusInfo = { yes: "已支付", no: "未支付", reject: "已拒绝" };
        const withdrawlist =
            withdrawList &&
            withdrawList.map((data, i) => {
                
                const  tradeDate = moment(data.create_at).format("YYYY.MM.DD HH:mm:ss");
                const date=tradeDate.split(" ")[0];
                const time=tradeDate.split(" ")[1];
                const number=data.withdraw==1?(data.alipay.substr(0,4)+"********"+data.alipay.substr(-4)):(data.banknumber.substr(0,4)+"********"+data.banknumber.substr(-4))
                return (
                    <tbody key={i}>
                        <tr>
                            <td className="settlementWrapper-deti">
                                <div>{date}</div>
                                <div>{time}</div>
                            </td>
                            <td className="settlementWrapper-deti">
                                {showWithDrawMoney(data.price)}
                            </td>
                            <td className="settlementWrapper-deti phone_hide">
                                <div>{number}</div>
                                <div>({data.withdraw==1?"支付宝":data.bankname})</div>
                            </td>
                            <td className="settlementWrapper-deti phone_padding">
                                {`${statusInfo[data.paid]}`}
                            </td>
                            <td className="settlementWrapper-deti phone_hide">
                                <a onClick={this.downloadExcel.bind(this,data)} className=" export"><img src="../assets/img/down.png"/>导出Excel</a>
                            </td>
                            <td className="settlementWrapper-deti phone_show">
                                <a onClick={this.downloadExcel.bind(this,data)} className=" export"><img src="../assets/img/down.png"/>导出</a>
                            </td>
                        </tr>
                    </tbody>
                );
            });
        const incomelist =
            incomeList &&
            incomeList.map((data, i) => {
                const  tradeDate = moment(data.create_at).format("YYYY.MM.DD HH:mm:ss");
                const date=tradeDate.split(" ")[0];
                const time=tradeDate.split(" ")[1];
                const area=data.attachment?JSON.parse(data.attachment).area:"";
                return (
                    <tbody key={i}>
                        <tr>
                            <td className="settlementWrapper-deti" title={HtmlUtil.htmlDecode(data.title)}>
                                {data.title.length>12?HtmlUtil.htmlDecode(data.title.substr(0,8))+'...':HtmlUtil.htmlDecode(data.title)}
                            </td>
                            <td className="settlementWrapper-deti">
                                {HtmlUtil.htmlDecode(data.creator|| "奕颗贝壳")}
                            </td>
                            <td className="settlementWrapper-deti phone_hide" title={HtmlUtil.htmlDecode(area)}>
                                {area&&area.length>13?HtmlUtil.htmlDecode(area.substr(0,8))+'...':HtmlUtil.htmlDecode(area)}
                            </td>
                            <td className="settlementWrapper-deti phone_padding">
                                <div>{date}</div>
                                <div>{time}</div>
                            </td>
                            <td className="settlementWrapper-deti">
                                {showMoney(data.price*0.6)}
                            </td>
                        </tr>
                    </tbody>
                );
            });
        const pager0 = withdrawTotal > 0 && (
            <Pagination
                {...pageWithdrawOptions}
                hideOnSinglePage={true}
                onChange={this.onPageChange.bind(this)}
            />
        );

        const pager1 = incomeListTotal > 0 && (
            <Pagination
                {...pageIncomeOptions}
                hideOnSinglePage={true}
                onChange={this.onPageChange.bind(this)}
            />
        );

        return (
            <div className="mymusicBox__tab m-tabBox3 m-tabBox--s">
                <ul
                    className="m-tabBox__list"
                    role="tablist"
                    data-column="auto"
                >
                    <li className="m-tabBox__list__item">
                        <Link
                            to=""
                            aria-controls="tab-01"
                            aria-selected={
                                this.state.tabSelectedFlg === "0" ? true : false
                            }
                            role="tab"
                            onClick={this.changeToWithdraw.bind(this)}
                        >
                            提现记录
                        </Link>
                    </li>
                    <li className="m-tabBox__list__item">
                        <Link
                            to=""
                            aria-controls="tab-02"
                            aria-selected={
                                this.state.tabSelectedFlg === "1" ? true : false
                            }
                            role="tab"
                            onClick={this.changeToIncome.bind(this)}
                        >
                            音乐收入
                        </Link>
                    </li>
                </ul>
                <div className="m-tabBox__tabpanel-wrap">
                    <div
                        className="m-tabBox__tabpanel"
                        aria-hidden={
                            this.state.tabSelectedFlg === "0" ? false : true
                        }
                        id="tab-01"
                        role="tabpanel"
                    >
                        <div className="settlementWrapper">
                            <div className=" ov settlementWrapper-tltle">
                                <div className=" left wid20">提现时间</div>
                                <div className=" left wid20">提现金额</div>
                                <div className=" left wid20 phone_hide">收款账号</div>
                                <div className=" left wid20">提现状态</div>
                                <div className=" left wid20">金额明细</div>
                            </div>
                            <div className=" settlementWrapper-de">
                                <table>
                                    {withdrawlist}
                                </table>
                            </div>
                            <div className="c02b478-comanymakerlist" style={{marginTop:"10px"}}>
                                <div className="resultPagination">{pager0}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="m-tabBox__tabpanel"
                    aria-hidden={
                        this.state.tabSelectedFlg === "1" ? false : true
                    }
                    id="tab-02"
                    role="tabpanel"
                >
                    <div className="settlementWrapper">
                        <Loading loading={loading} />
                        <div className=" ov settlementWrapper-tltle">
                            <div className=" left wid20">音乐名称</div>
                            <div className=" left wid20">创作人</div>
                            <div className=" left wid20 phone_hide">项目名称</div>
                            <div className=" left wid20">交易时间</div>
                            <div className=" left wid20">税前收入
                                <Tooltip title={text} placement="bottomRight" trigger="click">
                                    <span className="withdraw_shouru"></span>
                                </Tooltip>
                            </div>
                            
                        </div>
                        <div className=" settlementWrapper-de">
                            <table>
                                {incomelist}
                            </table>
                        </div>
                        <div className="c02b478-comanymakerlist" style={{marginTop:"10px"}}>
                            <div className="resultPagination">{pager1}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecordList);
