import React from "react";
import SocailShare from "social-share-react";
import PropTypes from "prop-types";

class Share extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        visible: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        url: PropTypes.string,
    };

    render() {
        const { title, visible, onClose, url = location.origin } = this.props;

        return (
            visible && (
                <div className="modalBox" style={{ display: "block" }}>
                    <div className="modalOverlay" onClick={() => onClose()} />
                    <div className="modalBox__inner">
                        <div id="modalForm1" className="modalForm">
                            <div className="modalForm__header">
                                <p className="modalForm__title" style={{ color: "black" }}>
                                    分享
                                </p>
                                <p className="modalForm__close">
                                    <button type="button" onClick={() => onClose()} />
                                </p>
                            </div>
                            <div className="modalForm__inner">
                                <div className="buypageBox">
                                    <div className="buypageForm" style={{ fontSize: "1.4rem" }}>
                                        <div
                                            style={{ height: 100, width: "100%", display: "flex", justifyContent: "center" }}
                                        >
                                            <SocailShare
                                                url={url}
                                                title={title}
                                                disabled={["google", "facebook", "twitter","tencent"]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    }
}

export default Share;
