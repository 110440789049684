import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import agent from "agent";

//import Avatar from "components/Avatar";

class SidebarAvatar extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        currentPath: PropTypes.string.isRequired,
    };

    links = [
        // {
        //     // 第一个路由未默认链接路由
        //     route: ["/seller/mypage"],
        //     label: "我的首页",
        // },
        {
            route: ["/seller/profile", "/seller/mypage"],
            label: "个人资料",
        },
        {
            route: ["/seller/mymusic", "/seller/uploadmusic"],
            label: "我发布的音乐",
        },
        {
            route: ["/seller/makerlist", "/seller/makeredit", "/seller/makerdetail"],
            label: "创作人",
        },
        {
            route: ["/seller/settlement"],
            label: "我的账户",
        },
        // {
        //     route: [""],
        //     label: "修改密码",
        // },
    ];

    render() {
        const { avatar, nickname } = this.props.user;
        const style={
            height:'126px',
            width:'126px',
            background:'url('+agent.getApiUrl(avatar)+')',
            backgroundSize:'cover',
            backgroundPosition:'center center',
        }
        const list = this.links.map((x, i) => {
            const classes = ["m-btn-type2"];
            const [defaultRoute] = x.route;

            let isCurrent = false;

            for (let i = 0; i < x.route.length; i++) {
                const url = x.route[i];
                if (url === this.props.currentPath || this.props.currentPath.indexOf(url) >= 0) {
                    isCurrent = true;
                    break;
                }
            }

            if (isCurrent) classes.push("is-current");

            return (
                <li className="navList01__item" key={i}>
                    <Link to={defaultRoute} className={classes.join(" ")}>
                        <span>{x.label}</span>
                    </Link>
                </li>
            );
        });

        return (
            <div className="sideBox02">
                <div className="mymusicBox__avatar">
                    <div className="avatarBox">
                        <div className="avatarBox__img" style={style}>
                        </div>
                        {nickname && <p className="avatarBox__name">ID : {nickname}</p>}
                    </div>
                </div>
                <div className="mymusicBox__menu">
                    <ul className="navList01">{list}</ul>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    user: state.user.currentUser,
    currentPath: state.router.location.pathname,
}))(SidebarAvatar);
