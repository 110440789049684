import {
    APP_LOAD,
    CURRENT_USER,
    LOGIN,
    NEED_SIGIN,
    LOGOUT,
} from "../constants/actionTypes";

const defaultState = {
    loading: true,
    userLogin: false,
};

export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                loading: false,
            };
        case CURRENT_USER: {
            const userLogin = action.error ? state.userLogin : true;
            return {
                ...state,
                userLogin,
            };
        }
        case LOGIN: {
            return {
                ...state,
                userLogin: !action.error,
            };
        }
        case NEED_SIGIN:
        case LOGOUT: {
            return {
                ...state,
                userLogin: false,
            };
        }
        default:
            return state;
    }
};
