const STATUS = {
    UNREVIEWED: "unreviewed",
    SUSPEND: "suspend",
    NORMAL: "normal",
    PENDDING: "pendding",
    REJECT: "reject",
    DELETE: "delete",
};

const status = {
    [STATUS.UNREVIEWED]: "审核中",
    [STATUS.SUSPEND]: "暂停出售",
    [STATUS.NORMAL]: "销售中",
    [STATUS.PENDDING]: "编辑中",
    [STATUS.REJECT]: "审核未通过",
    [STATUS.DELETE]: "已删除",
};

const statusOnMyMusic = {
    ...status,
    [STATUS.NORMAL]: "已通过",
};

const STYLE_KEYS = {
    AREA: "area",
    DURATION: "duration",
    EMOTION: "emotion",
    INSTRUMENT: "instrument",
    SCENE: "scene",
    SPEED: "speed",
    STYLE: "style",
    VOCAL: "vocal",
};

const styleLabels = {
    [STYLE_KEYS.STYLE]: "风格",
    [STYLE_KEYS.INSTRUMENT]: "乐器",
    [STYLE_KEYS.EMOTION]: "情绪",
    [STYLE_KEYS.SCENE]: "场景",
    [STYLE_KEYS.DURATION]: "长度",
    [STYLE_KEYS.AREA]: "国家",
    [STYLE_KEYS.SPEED]: "速度",
    [STYLE_KEYS.VOCAL]: "男女声",
};

const styleLabelsOnHome = [
    STYLE_KEYS.STYLE,
    STYLE_KEYS.INSTRUMENT,
    STYLE_KEYS.EMOTION,
    STYLE_KEYS.SCENE,
    STYLE_KEYS.DURATION,
];

const styleLabelMulti = new Set([STYLE_KEYS.INSTRUMENT, STYLE_KEYS.EMOTION]);

const DISCOUNT_OPEN = false;

const musicCanUpdate = new Set([STATUS.PENDDING, STATUS.UNREVIEWED]);

export { status, STATUS, statusOnMyMusic, styleLabels, styleLabelsOnHome, styleLabelMulti, DISCOUNT_OPEN, musicCanUpdate };
