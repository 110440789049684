import React, { Component } from "react";
import PropTypes from "prop-types";
import "./TermModal.less";
import {
    TermsOfUsePage,
    TermsOfViolationrulesPage,
    MediaContantPage,
} from "../StaticPages/index";
import TeamUse from "components/StaticPages/TeamUse";
import TeamSell from "components/StaticPages/TeamSell";

class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    static propTypes = {
        modalVisible: PropTypes.bool.isRequired,
        termsType: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        maskClick: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.setState({ visible: this.props.modalVisible });
    }

    static getDerivedStateFromProps(props) {
        return { visible: props.modalVisible };
    }

    render() {
        const { termsType } = this.props;
        const { visible } = this.state;

        return (
            visible && (
                <div>
                    <div className="modal">
                        <div style={{ height: "400px", overflow: "auto" }}>
                            {termsType === "TermsOfSalePage" ? (
                                <TeamSell />
                            ) : (
                                ""
                            )}
                            {termsType === "TermsOfUsePage" ? (
                                <TermsOfUsePage />
                            ) : (
                                ""
                            )}
                            {termsType === "TermsOfViolationrulesPage" ? (
                                <TermsOfViolationrulesPage />
                            ) : (
                                ""
                            )}
                            {termsType === "TermsOfLogupPage" ? (
                                <TeamUse />
                            ) : (
                                ""
                            )}
                            {termsType === "MediaContantPage" ? (
                                <MediaContantPage />
                            ) : (
                                ""
                            )}
                        </div>
                        <br />
                        <br />
                        <p className="buypageForm__submit">
                            <button
                                onClick={this.props.onClose}
                                className="u-submitSkin is-on"
                                style={{
                                    margin: "0 auto",
                                    top: "0",
                                    width: "120px",
                                    bottom: "30px",
                                    height: "40px",
                                }}
                            >
                                关闭
                            </button>
                        </p>
                        <br />
                    </div>
                    <div className="mask" onClick={this.props.maskClick} />
                </div>
            )
        );
    }
}
export default Modal;
