import {
    PURCHASE_LOAD,
    HISTORY_PURCHASE_LOAD,
    GET_PAY_URL,
    RECEIPT_MODAL_HIDE,
    RECEIPT_MODAL_SHOW,
    UPDATE_ORDER,
} from "../constants/actionTypes";
const defaultState = {
    serverPurchaseData: [],
    loading: false,
    plainOptions: [],
    pagination: { pageSize: 10, current: 1, total: 0 },
    modal: {
        data: null,
        visible: false,
    },
    orderid:"",
    prepayId:"",
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case PURCHASE_LOAD: {
            const fetchError = !!action.error;
            const serverPurchaseData = fetchError ? [] : action.payload.orders;
            const pagination = { ...state.pagination };
            pagination.current = action.params.page;
            pagination.total = fetchError ? 0 : action.payload.total;
            return {
                ...state,
                fetchError,
                serverPurchaseData,
                loading: false,
                pagination,
                wxCode:"",
                prepayId:""
            };
        }
        case HISTORY_PURCHASE_LOAD: {
            const fetchError = !!action.error;
            const serverPurchaseData = fetchError ? [] : action.payload.orders;
            const pagination = { ...state.pagination };
            pagination.current = action.params.page;
            pagination.total = fetchError ? 0 : action.payload.total;
            return {
                ...state,
                fetchError,
                serverPurchaseData,
                loading: false,
                pagination,
                wxCode:"",
                prepayId:""
            };
        }
        case GET_PAY_URL: {
            const fetchError = !!action.error;
            const payUrl = fetchError ? "" : action.payload.pay_url;
            const wxCode = fetchError ? "" : action.payload.wxCode;
            const orderid = action.orderid;
            const price =  fetchError ? "" : action.payload.price;
            const prepayId = fetchError ? "" : action.payload.prepayId;
            console.log(prepayId)
            return {
                ...state,
                fetchError,
                payUrl,
                wxCode,
                orderid,
                price,
                prepayId,
            };
        }
        case RECEIPT_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case RECEIPT_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }
        case UPDATE_ORDER: {
            return {
                ...defaultState,
                visible: true,
            };
        }
        default:
            return state;
    }
};
