import {
    MUSIC_SEARCH,
    ASYNC_START,
    MUSIC_TABLE_UNLOAD,
    FAVORITE_DELETE,
    FAVORITE_ADD,
    FAVORITE,
} from "../constants/actionTypes";
const defaultState = {
    pagination: { pageSize: 10, current: 1, total: 0 },
    loading: false,
    tableData: null,
    fetchError: false,
    musicone: "",
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case FAVORITE:
        case MUSIC_SEARCH: {
            const pagination = action.pager;
            let tableData = null;
            if (!action.error) {
                pagination.total = action.payload.total;
                tableData = action.payload.audios;
            }
            if (action.exclude && tableData) {
                const audios = [];
                for (const record of tableData) {
                    if (action.exclude == record.audioid) {
                        continue;
                    }
                    audios.push(record);
                }
                tableData = audios;
            }

            return {
                ...state,
                fetchError: !!action.error,
                tableData,
                pagination,
                loading: false,
            };
        }
        case FAVORITE_DELETE:
        case FAVORITE_ADD: {
            const tableData = state.tableData;
            if (!tableData || action.error) {
                return state;
            }
            const data = {};
            if (tableData) {
                const audios = [];
                for (const record of tableData) {
                    if (action.send.audioid == record.audioid) {
                        record.likeUpdate = false;
                        if (action.type == FAVORITE_DELETE) {
                            record.like = null;
                        } else {
                            record.like = action.payload.like;
                        }
                    }
                    audios.push(record);
                }
                data.tableData = audios;
            }
            return { ...state, ...data };
        }
        case ASYNC_START:
            if (action.subtype === MUSIC_SEARCH) {
                return { ...state, loading: true };
            }
            if (
                action.subtype === FAVORITE_DELETE ||
                action.subtype === FAVORITE_ADD
            ) {
                const data = {};
                const tableData = state.tableData;
                if (tableData) {
                    const audios = [];
                    for (const record of tableData) {
                        if (action.send.audioid == record.audioid) {
                            record.likeUpdate = true;
                        }
                        audios.push(record);
                    }
                    data.tableData = audios;
                }
                return { ...state, ...data };
            }
            break;
        case MUSIC_TABLE_UNLOAD:
            return { ...defaultState };
        default:
            return state;
    }

    return state;
};
