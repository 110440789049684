import { PLAYLIST_GET_INFO, PLAYLIST_GET_TYPE, FAVORITE_ADD, FAVORITE_DELETE ,TOPIC_DETAIL_UNLOAD} from "../constants/actionTypes";

const defaultState = {
    info: {
        infos: [],
        total: 0,
        pager: { pageSize: 50, current: 1 },
        error: false,
    },
    list: {},
    loading:false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PLAYLIST_GET_INFO: {
            const error = !!action.error;
            const {
                send,
                payload: { total, infos },
            } = action;

            const { pager } = send || {};
            const pagination = pager || state.info.pager;

            return {
                info: {
                    total: total || 0,
                    pager: pagination,
                    infos,
                    error,
                },
                list: { ...state.list },
            };
        }
        case FAVORITE_ADD:
        case FAVORITE_DELETE: {
            const { error, send, payload } = action;
            if (error) return state;
            const { audioid, type, page } = send;
            if (!page || page !== "playlist") return state;

            if (!type) return state;

            return {
                info: state.info,
                list: {
                    ...state.list,
                    [type]: {
                        ...state.list[type],
                        audios: state.list[type].audios.map(x => {
                            if (x.audioid === audioid) {
                                x.like = action.type === FAVORITE_DELETE ? null : { id: payload.like.id };
                            }
                            return { ...x };
                        }),
                    },
                },
            };
        }
        case PLAYLIST_GET_TYPE: {
            const error = !!action.error;
            const {
                send,
                payload: { total, audios },
            } = action;
            const { pager, topicId } = send || {};
            const pagination = pager || state.list.pager;

            return {
                info: state.info,
                list: {
                    ...state.list,
                    [topicId]: {
                        total: total || 0,
                        pager: pagination,
                        audios,
                        error,
                    },
                },
                loading:true,
            };
        }
        case TOPIC_DETAIL_UNLOAD: {
            return {
                info: {
                    infos: "",
                    total: 0,
                    pager: { pageSize: 50, current: 1 },
                    error: false,
                },
                list: "",
                loading:false,
            };
        }
        default:
            return state;
    }
};
