import {LOGOUT} from "../constants/actionTypes";
const defaultState = {};
export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case LOGOUT: {
            return {
                ...defaultState
            };
        }

        default:
            return state;
    }
};
