import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Loading from "components/Loading";
import { Form,Input } from "antd";
import agent from "agent";
import { SEND_CUSTOMIZED } from "constants/actionTypes";
import "./Composition.less"
const { TextArea } = Input;
const mapStateToProps = state => ({ ...state.composition });

const mapDispatchToProps = dispatch => ({

    sendCustomized: (info,done) =>
        dispatch({
            type: SEND_CUSTOMIZED,
            payload: agent.Captcha.sendCustomized(info),
            done,
        }),
});

class Composition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }
    static propTypes = {
        history: PropTypes.object.isRequired,
        form: PropTypes.object,
        sendCustomized: PropTypes.func.isRequired,
        status: PropTypes.number,
    };
    handleSubmit = e => {
        const {sendCustomized}=this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.info(values)
                if (!values.demo) {
                    values.demo="此用户没有填写"
                }
                if (!values.use) {
                    values.use="此用户没有填写"
                }
                sendCustomized(
                    values,
                    () => {
                        const { status } = this.props;
                        if (status==0) {
                            this.setState({
                                show:true,
                            });
                            setTimeout(() => {
                                this.closeInfo();
                            }, 3000); 
                           
                        }
                    });
               
            }
        })
        
    }
    closeInfo () {
        this.props.form.resetFields("name");
        this.props.form.resetFields("link");
        this.props.form.resetFields("use");
        this.props.form.resetFields("remark");
        this.props.form.resetFields("demo");
        this.setState({
            show:false,
        })
    }
    close = e => {
        this.props.form.resetFields("name");
        this.props.form.resetFields("link");
        this.props.form.resetFields("use");
        this.props.form.resetFields("remark");
        this.props.form.resetFields("demo");
        this.setState({
            show:false,
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { show } = this.state;
        let sty={display:"none"};
        if (show) {
            sty={display:"block"};
        }
        return (
            <div className="mypageContents aboutContents compositionContents" id="composition_style">
                <div className="modalDialog" style={sty} onClick={this.close.bind(this)}>
                    <div className="dialogOverlay"></div>
                    <div id="dialog1" className="dialog">
                        <p className="dialog__title">需求发布成功！</p>
                        <p className="dialog__txt"><span className="u-inlineBlock">管理员会在一个工作日内与您联系，请您耐心等待。</span></p>
                    </div>
                </div>
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">歌曲定制</h1>
                    </header>
                    <div className="compositionBox">
                        <div className="compositionBox__inner">
                            <div className="compositionService">
                                <h2 className="compositionService__title">定制服务</h2>
                                <p className="compositionService__lead">“为您打造专属音乐！”</p>
                                <p className="compositionService__txt">选不到您想要的音乐？有特别的词曲需求？奕颗贝壳凭借强大的编曲配乐、录音后制团队，严谨规范的合作流程，深耕广告、商业配乐领域多年。我们将为您匹配符合您需求的创作者，从提案、词曲、编曲、录音、混音到后制，以专业服务协助您完成专案。</p>
                            </div>
            
                            <Form >
                                <div className="buypageForm__inner">
                                    <div className="buypageForm__items">
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt compositon_width_82 add_star"><label htmlFor="">如何称呼您 </label></dt>
                                                <dd className="buypageForm__dd compositon_width_100_109">
                                                    <Form.Item>
                                                        {getFieldDecorator("name", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "姓名或昵称不能为空",
                                                                },
                                                            ],
                                                        })(
                                                            <Input type="text" placeholder="姓名或昵称" className="u-inputSkin02"/>
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>
                                        </div>
                                        
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt compositon_width_82 add_star" ><label htmlFor="">您的联系方式 </label></dt>
                                                <dd className="buypageForm__dd compositon_width_100_109" >
                                                    <Form.Item>
                                                        {getFieldDecorator("link", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "联系方式不能为空",
                                                                },
                                                            ],
                                                        })(
                                                            <Input type="text" placeholder="手机号或微信" className="u-inputSkin02"/>
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>
                                        </div>
            
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt compositon_width_82"><label htmlFor="">定制歌曲的用途</label></dt>
                                                <dd className="buypageForm__dd compositon_width_100_109" >
                                                    <Form.Item>
                                                        {getFieldDecorator("use", {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: "",
                                                                },
                                                            ],
                                                        })(
                                                            <Input type="text" placeholder="广告影片，APP应用，企业主题曲..." className="u-inputSkin02"/>
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>
                                        </div>
            
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt compositon_width_82"><label htmlFor="">歌曲的风格要求</label></dt>
                                                <dd className="buypageForm__dd compositon_width_100_109" >
                                                    <Form.Item>
                                                        {getFieldDecorator("demo", {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: "",
                                                                },
                                                            ],
                                                        })(
                                                            <Input type="text" placeholder="可稍作乐器描述或需求描述,也可以附上试听DEMO链接" className="u-inputSkin02"/>
                                                        )}
                                                    </Form.Item>
                                               
                                                </dd>
                                            </dl>
                                        </div>
            
                                        <div className="buypageForm__item">
                                            <dl className="buypageForm__dl">
                                                <dt className="buypageForm__dt u-alsT compositon_width_82 add_star" ><label htmlFor="">留言</label></dt>
                                                <dd className="buypageForm__dd compositon_width_100_109" >
                                                    <Form.Item>
                                                        {getFieldDecorator("remark", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "留言不能为空",
                                                                },
                                                            ],
                                                        })(
                                                            <TextArea name="kanso" placeholder="如：希望一周内完成，预算等内容" rows="4" cols="40" className="u-textareaSkin "></TextArea>
                                                        )}
                                                    </Form.Item>
                                                </dd>
                                            </dl>
                                        </div>
                                        
                                    </div>
                                    <p className="compositionBox__submit"><input className="u-submitSkin" type="button" onClick={this.handleSubmit}  value="提交需求"/></p>				
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(Composition);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
