const gnav = require("./gnav").default;
const utility = require("./utility").default;

export default $ => {
    gnav($);
    utility($);
    return {
        ready: () => {
            $.gnavToggle();
            $.tabPanel();
            $.smoothScroll();
            // $.modalOpen();
            $.toggleAccordion();
            $.moreBox();
            $.pagtopBtn();
            $.scrollAnimate();
            // close first
            $(".js-navClose").click();
        },
    };
};
