import {
    SUBSCRIBE_LOAD,
    UPLOAD_SUBSCRIBE,
    SUBSCRIBE_GET_PAY_URL,
    RECEIPT_MODAL_HIDE,
    RECEIPT_MODAL_SHOW,
    SUBSCRIBE_UPDATE,
    SUBSCRIBE_CREATE,
    KEEP_IDENTIY,
} from "../constants/actionTypes";
const defaultState = {
    subscribe: [],
    loading: false,
    plainOptions: [],
    pagination: { pageSize: 10, current: 1, total: 0 },
    modal: {
        data: null,
        visible: false,
    },
    identiy:"",
    message:'',
    status: 0
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case SUBSCRIBE_LOAD: {
            const fetchError = !!action.error;
            const subscribe = fetchError ? [] : action.payload.subscribe;
            const pagination = { ...state.pagination };
            pagination.current = action.parmas.page;
            pagination.total = fetchError ? 0 : action.payload.total;
            return {
                ...state,
                fetchError,
                subscribe,
                loading: false,
                pagination,
                wxCode:"",
            };
        }
        case SUBSCRIBE_GET_PAY_URL: {
            const fetchError = !!action.error;
            const payUrl = fetchError ? "" : action.payload.pay_url;
            const wxCode = fetchError ? "" : action.payload.wxCode;
            const price = fetchError ? "" : action.payload.price;
            const orderid = fetchError||!action.payload.orderid?"":action.payload.orderid;
            return {
                ...state,
                fetchError,
                payUrl,
                wxCode,
                orderid,
                price,
            };
        }
        case SUBSCRIBE_CREATE: {
            const fetchError = !!action.error;
            const payUrl = fetchError||!action.payload.pay_url ? "" : action.payload.pay_url;
            const bank = fetchError||!action.payload.bank?"":action.payload.bank;
            const orderid = fetchError||!action.payload.orderid?"":action.payload.orderid;
            const price = fetchError||!action.payload.price?"":action.payload.price;
            const wxCode = fetchError||!action.payload.wxCode ? "" : action.payload.wxCode;
            const message = action.payload.message;
            const status = action.payload.status;
            return {
                ...state,
                fetchError,
                loading: false,
                payUrl,
                bank,
                orderid,
                price,
                wxCode,
                message,
                status,
            };
        }
        case RECEIPT_MODAL_SHOW: {
            const modal = {
                data: action.payload,
                visible: true,
            };
            return {
                ...state,
                modal,
            };
        }
        case RECEIPT_MODAL_HIDE: {
            const modal = {
                data: null,
                visible: false,
            };
            return {
                ...state,
                modal,
            };
        }
        case UPLOAD_SUBSCRIBE: {
            return {
                ...defaultState,
                visible: true,
            };
        }
        case SUBSCRIBE_UPDATE: {
            return {
                ...defaultState,
                visible: true,
            };
        }
        case KEEP_IDENTIY: {
            const identiy=action.send;
            console.info(action)
            return {
                ...state,
                identiy,
            };
        }
        
        default:
            return state;
    }
};
