import React from "react";
//import PropTypes from "prop-types";
//import data from "components/Common/about_us_text";

export default class AboutusPage extends React.Component {
    render() {
        return (
            <div className="mypageContents aboutContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">关于我们</h1>
                    </header>
                    <div className="aboutBox">
                        <div className="aboutBox__header">
                            <p className="aboutBox__header__logo"><img src="assets/img/logo.svg" alt="MUSINESS"/></p>
                        </div>
                        <div className="aboutSection01">
                            <div className="aboutSection01__inner">
                                <p className="sectionTxt"><em>MUSINESS商用版权音乐平台，专注商用版权音乐的授权与交易，营造中国良好的商用音乐版权意识及交易环境。</em>聚合了约300000首大牌音乐、配乐、音效曲库。</p>
                                <h2 className="sectionTitle">MUSINESS = MUSIC + BUSINESS</h2>
                                <p className="sectionTxt">广告制作、短视频制作、影视制作、游戏制作、动漫制作等用户可在平台上依据标签和关键词搜索，购买下载所需配乐BGM，并获得音乐使用授权证书。用户也可订阅会员，包月使用正版音乐与配乐。MUSINESS还可为用户定制音乐。<em>让用户尽情享受正版音乐商用的自由。</em></p>
                                
                            </div>
                        </div>
                        <div className="aboutSection02">
                            <div className="aboutSection02__inner">
                                <div className="aboutSecBox">
                                    <p className="sectionTxt">MUSINESS版权音乐平台，为上海市科技型创新项目、华为-上海5G+XR Cloud明星示范企业，搭载云计算、大数据、AI人工智能、区块链等方面技术，并在www.musiness.vip版权音乐网站上运用明水印与隐水印技术做侵权追踪以维护音乐创作人权益。自主研发拥有AI智能获客及数字人客服系统。</p>

                                    <p className="sectionTxt">MUSINESS版权音乐，立足中国、布局全球。和多家知名唱片公司有直接音乐版权合作，更拿到了世界经典名曲Bandari（班得瑞）全部15张专辑在中国大陆地区的独家授权代理，还引进了百位海外知名/好莱坞音乐创作人入驻，这些世界级音乐人为多国电影、电视节目、剧集、国际电影节、广告、大型游戏等内容提供音乐素材。作品被国家地理、Fox Sports、BBC、探索频道、维亚康姆集团等国际广播媒体使用。参与创作和制作包括《黑客帝国》《变形金刚》《复仇者联盟》《数码宝贝》《加勒比海盗》《神奇四侠》……等众多巨作之音乐与配乐。MUSIENSS版权音乐也与来自美国、英国、法国、韩国、日本、马来西亚、新加坡的数千位业内著名音乐创作人和制作人深度链接，提供源源不断的优质音乐内容。 </p>

                                    <p className="sectionTxt">MUSINESS商用版权音乐平台，与众多知名广告公司、游戏公司、影视公司、短视频MCN机构达成长期战略及业务合作。为中宣部宣传舆情研究中心、学习强国、央视网、新华网、新华日报、农民日报、浙江日报集团、阿里巴巴、腾讯科技、亚运城、浙江卫视、北京卫视、人教数字出版有限公司、辽宁出版集团、广告人文化集团、招商银行、中国光大银行、中国建设银行、肯德基、华为、元祖、星巴克、苏宁电器、王者荣耀、李子柒、Papitube、丝芭文化……等众多知名品牌提供正版音乐与配乐。通过版权音乐和创作人的IP价值给使用者赋能，让商用音乐的选择从未如此简单。</p>
                                    
                                    <p className="sectionTxt">MUSINESS版权音乐，为企业提供音乐曲库SaaS服务，提供系统化版权音乐、配乐、音效、配音的解决方案，帮助企业规避音乐版权相关的商业、法律风险，推动商用音乐正版化普及。</p>

                                    <p className="sectionTxt">MUSINESS音乐元宇宙，为2022年度上海市虹口科委重点孵化项目（元宇宙方向），拥有数字人内容制作、虚实演播等系统，基于MUSINESS版权音乐素材平台30万首优质音乐、配乐、音效曲库，为元宇宙提供全方位音乐及声音赋能、虚实演播/直播、数字明星孵化与经纪（MCN）等服务。2022年2月推出超写实虚拟歌手兼MUSINESS首席数字人客服官小缪Metamuse，一月内全网5000万+流量，首支单曲MV冲上微博热门视频「全站总榜」，是「支持正版从我做起」公益活动的形象大使，话题3000万+阅读，3万+讨论，两次登上微博热搜。</p>
                                    
                                    <p className="sectionTxt">MUSINESS音乐元宇宙，以音乐、虚拟人和动画为介，协助品牌进入元宇宙赛道，共同探索元宇宙新生态下的远景与未来，开启品牌数字营销和价值传递的新方式，助力企业营销创收，提升品牌影响力。</p> 
                                    <p className="sectionTxt">奕颗贝壳音乐，为MUSINESS旗下厂牌，专业音乐定制及MG动画制作。凭借MUSINESS版权音乐曲库优势，拥有林俊杰《江南》《一千年以后》《她说》《可惜没如果》、陈奕迅《你的背包》、蔡依林《舞娘》、SHE《ringringring》等作品的强大编曲录音制作团队，为中国好声音众歌手、鹿晗、林俊杰、蔡依林、吴莫愁、秦岚、钟汉良、李若彤、NINEPERCENT、鞠婧祎、SNH48、印小天、薛之谦、阿信、金莎、吴奇隆、孔令奇、吴克群、李健、阿朵、杨洋、阿兰、大张伟、周传雄、汪苏泷、郁可唯、邱泽、希林娜依•高、欧得洋……等众多艺人进行音乐制作及编曲合作。</p> 
                                    <p className="sectionTxt">奕颗贝壳音乐，以规范高效的合作流程，深耕广告、影视、游戏、动漫、短视频等领域多年，为客户匹配符合需求的音乐、配乐、音效、配音、MG动画制作，以专业服务完成专案。</p> 
                                </div>

                               
                            </div>
                        </div>
                        <div className="aboutSection02">
                            <div className="aboutSection02__inner">
                                <div className="aboutSecBox">
                                    <h2 className="sectionTitle">LOGO的含义：</h2>
                                    <div className="aboutLogo__img"><img src="assets/img/about_logo.png" alt="MUSINESS"/></div>
                                    <div className="aboutSecBox1">
                                        <p className="sectionTxt3">由三位日本得奖设计师共同设计。</p>
                                        <p className="sectionTxt2">
                                            设计主旨：因为音乐而遇见（交融、合作）因为遇见而找到最合适的。
                                        </p>
                                        <p className="sectionTxt5">logo有三个形似：</p>
                                        <p className="sectionTxt1">
                                    （1）形似四个音叉叠在一起，代表音乐；<br/>
                                    （2）形似互联网的两个链接符号交叉在一起，代表链接；<br/>
                                    （3）形似x，“x贝壳”谐音“奕颗贝壳”谐音“incubator”，代表孵化。</p>
                                    </div>
                                    <p className="sectionTxt4">蓝色，代表天空及海洋。呼应神采奕奕的贝壳。</p>
                                </div>
                            </div>
                        </div>    
                        <div className="aboutBox__footer">
                            <div className="aboutBox__footer__inner">
                                <h2 className="aboutBox__footer__title"><span className="large"><strong>Musiness = </strong>Music + Business.　</span><span className="small">代表我们是“音乐的事业”</span></h2>
                                <p className="sectionTxt">体现了奕颗贝壳商用音乐供需媒合的平台本质与孵化一个崭新的蓝海音乐市场的目标期望。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
