import {
    CUSTOMIZED_LOAD
} from "../constants/actionTypes";
const defaultState = {
    infos: [],
    loading: false,
    pagination: { pageSize: 10, current: 1, total: 0 },
};

export default (state = {...defaultState}, action) => {
    switch (action.type) {
        case CUSTOMIZED_LOAD: {
            console.info(action)
            const fetchError = !!action.error;
            const infos = fetchError ? [] : action.payload.infos;
            const pagination = { ...state.pagination };
            pagination.current = action.params.page;
            pagination.total = fetchError ? 0 : action.payload.total;
            return {
                ...state,
                fetchError,
                infos,
                loading: false,
                pagination,
            };
        }
        default:
            return state;
    }
};
