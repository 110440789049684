import {
    LOGIN,
    REGISTER,
    LOGIN_PAGE_UNLOADED,
    REGISTER_PAGE_UNLOADED,
    ASYNC_START,
    IS_REGISTER,
    CAPTCHA,
} from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                inProgress: false,
                loginSuccess: action.error ? false : true,
                errors: action.error ? action.payload.errors : null,
            };
        case REGISTER:
            return {
                ...state,
                inProgress: false,
                registeSuccess: action.error ? false : true,
                errors: action.error ? action.payload.errors : null,
            };
        case IS_REGISTER:
            return {
                ...state,
                inProgress: false,
                errors: action.error ? action.payload.errors : null,
            };
        case CAPTCHA:
            return {
                ...state,
                inProgress: false,
                errors: action.error ? action.payload.errors : null,
            };
        
        case LOGIN_PAGE_UNLOADED:
        case REGISTER_PAGE_UNLOADED:
            return {};
        case ASYNC_START:
            if (action.subtype === LOGIN || action.subtype === REGISTER) {
                return { ...state, inProgress: true };
            }
            break;
        default:
            return state;
    }

    return state;
};
