import React from "react";
import { connect } from "react-redux";
import { SHOW_POPUP, UPDATE_PASSWORD } from "../../constants/actionTypes";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import agent from "agent";
import "./User.less";

const mapStateToProps = state => ({
    ...state.user,
    ...state.updatePassword,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    showUpdatePopup: isShow => {
        return dispatch({ type: SHOW_POPUP, isShow });
    },
    onSubmit: ({ old_password, password }) =>
        dispatch({
            type: UPDATE_PASSWORD,
            payload: agent.User.userUpdateInfo({
                old_password,
                password,
            }),
        }),
});

class UpdatePassword extends React.Component {
    static propTypes = {
        isShow: PropTypes.shape({
            show: PropTypes.bool.isRequired,
        }).isRequired,
        showUpdatePopup: PropTypes.func.isRequired,
        form: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };
    static defaultProps = {
        isShow: {
            show: false,
        },
    };
    componentDidUpdate(prevProps) {
        if (this.props.errors !== prevProps.errors) {
            this.showFormItemServerError();
        }
    }
    showFormItemServerError() {
        const errors = this.props.errors;
        if (!errors) {
            return;
        }
        const { form } = this.props;
        Object.keys(errors).map(key => {
            const message = errors[key];
            if (message.message === "Incorrect old password.") {
                message.param = "oldPassword";
                message.msg = "原密码输入错误！";
                message.value = form.getFieldValue("oldPassword");
            } else if (
                message.message === "password must be at least 6 chars long"
            ) {
                message.param = "newPassword";
                message.msg = "密码至少6位！";
                message.value = form.getFieldValue("newPassword");
            }
            switch (message.param) {
                case "oldPassword":
                    form.setFields({
                        oldPassword: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
                case "newPassword":
                    form.setFields({
                        newPassword: {
                            value: message.value,
                            errors: [new Error(message.msg)],
                        },
                    });
                    break;
            }
        });
    }
    closeShow() {
        this.props.form.resetFields("oldPassword");
        this.props.form.resetFields("newPassword");
        this.props.form.resetFields("confimPassword");
        const { isShow, showUpdatePopup } = this.props;
        const send = {
            ...isShow,
            show: false,
        };
        showUpdatePopup(send);
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.newPassword !== values.confimPassword) {
                    this.props.form.setFields({
                        confimPassword: {
                            value: values.confimPassword,
                            errors: [new Error("输入密码不一致！")],
                        },
                    });
                    return false;
                }
                this.props.onSubmit({
                    old_password: values.oldPassword,
                    password: values.newPassword,
                });
            }
        });
    };
    render() {
        const { show } = this.props.isShow;
        if (!show) {
            return null;
        }
        const showClass = show ? "isShow" : "modalBox";
        const { getFieldDecorator } = this.props.form;
        return (
            <div className={showClass}>
                <div className="modalOverlay" />
                <div className="modalBox__inner">
                    <div id="modalForm1" className="modalForm" tabIndex="0">
                        <div className="modalForm__header">
                            <p className="modalForm__title">修改密码</p>
                            <p className="modalForm__close">
                                <button
                                    onClick={this.closeShow.bind(this)}
                                    type="button"
                                />
                            </p>
                        </div>
                        <div className="modalForm__inner">
                            <div className="buypageBox">
                                <div className="buypageForm">
                                    <Form onSubmit={this.handleSubmit}>
                                        <div className="buypageForm__inner">
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                输入原密码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "oldPassword",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入原密码",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="password"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                重设密码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "newPassword",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入新密码",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="password"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                再次输入密码
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "confimPassword",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请确认新密码",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="password"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <p className="buypageForm__submit">
                                                <Button
                                                    className="u-submitSkin"
                                                    type="primary"
                                                    block
                                                    htmlType="submit"
                                                >
                                                    确认修改
                                                </Button>
                                            </p>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(UpdatePassword);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
