import React from "react";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
const FormItem = Form.Item;
import TermModal from "../Common/TermModal";

class CompanyAgreement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
        };

        this.closeTermModal = this.closeTermModal.bind(this);
    }
    static propTypes = {
        form: PropTypes.object.isRequired,
        onRef: PropTypes.func.isRequired,
    };

    closeTermModal() {
        this.setState({
            modalVisible: false,
        });
    }

    handleModalClick = e => {
        e.preventDefault();
        this.setState({ modalVisible: true });
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    companyAgreementCheck = async () => {
        return new Promise((resolve, reject) => {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return reject(err);
                }
                if (!values.userAgreementCheck) {
                    err = new Error("请阅读并同意Musiness卖家协议！");
                    this.props.form.setFields({
                        userAgreementCheck: {
                            value: false,
                            errors: [err],
                        },
                    });
                    return reject(err);
                }

                return resolve(values);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { modalVisible } = this.state;
        return (
            <Form>
                <br />
                <div className="buypageForm__agreeCheck">
                    <TermModal
                        modalVisible={modalVisible}
                        termsType={"TermsOfSalePage"}
                        onClose={this.closeTermModal}
                        maskClick={this.closeTermModal}
                    />
                    <FormItem>
                        {getFieldDecorator("userAgreementCheck", {
                            rules: [
                                {
                                    required: true,
                                    message: "请阅读并同意Musiness卖家协议!",
                                },
                            ],
                            initialValue: false,
                        })(
                            <label className="u-checkbox2">
                                <Input type="checkbox" />
                                <span>我已阅读并同意</span>
                                <Link
                                    to="javascript:void(0);"
                                    onClick={this.handleModalClick.bind(this)}
                                >
                                    《Musiness卖家协议》
                                </Link>
                            </label>
                        )}
                    </FormItem>
                </div>
            </Form>
        );
    }
}
const CompanyAgreementForm = Form.create()(CompanyAgreement);
export default CompanyAgreementForm;
