import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import Loading from "../Loading";
import { redirectTo } from "dispatch";

// import {ORDER_CREATE_PURCHASE} from "constants/actionTypes";

const { Content } = Layout;

const mapStateToProps = state => ({
    ...state.user,
    ...state.userPurchaseOne,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({});

class PurchaseOne extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
    }

    componentWillUnmount() {
        //
    }

    render() {
        const { loading, currentUser } = this.props;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        return (
            <Content>
                <div>PurchaseOne</div>
            </Content>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseOne);
