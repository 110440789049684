export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const HOME_PAGE_LOADED = "HOME_PAGE_LOADED";
export const HOME_OPENID = "HOME_OPENID";
export const HOME_PAGE_UNLOADED = "HOME_PAGE_UNLOADED";
export const PROFILE_PAGE_LOADED = "PROFILE_PAGE_LOADED";
export const PROFILE_PAGE_UNLOADED = "PROFILE_PAGE_UNLOADED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const NEED_SIGIN = "-403 REQUIRE SIGNIN";
export const CAPTCHA = "CAPTCHA";
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED";
export const REGISTER_PAGE_UNLOADED = "REGISTER_PAGE_UNLOADED";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
// jgq
export const CURRENT_USER = "CURRENT_USER";
export const MUSIC_SEARCH = "MUSIC_SEARCH";
export const MUSIC_SEARCH_PAGE_UNLOAD = "MUSIC_SEARCH_PAGE_UNLOAD";
export const MUSIC_ONE_PAGE_UNLOAD = "MUSIC_ONE_PAGE_UNLOAD";
export const MUSIC_PLAY = "MUSIC_PLAY";
export const MUSIC_TABLE_UNLOAD = "MUSIC_TABLE_UNLOAD";
export const MUSIC_ONE = "MUSIC_ONE";
export const CART_ADD = "CART_ADD";
export const CART_LOAD = "CART_LOAD";
export const CART_CREATE_ORDER = "CART_CREATE_ORDER";
export const CART_CREATE_FREE_ORDER = "CART_CREATE_FREE_ORDER";
export const CART_ON_CHECK_CHANGE = "CART_ON_CHECK_CHANGE";
export const CART_PAGE_UNLOAD = "CART_PAGE_UNLOAD";
export const ORDER_ONE = "ORDER_ONE";
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_ONE_PAGE_UNLOAD = "ORDER_ONE_PAGE_UNLOAD";
export const ORDER_CREATE_PURCHASE = "ORDER_CREATE_PURCHASE";

// hqy
export const PEAKS_ADD = "PEAKS_ADD";
export const MUSIC_SEARCH_LEFT = "MUSIC_SEARCH_LEFT";
export const MUSIC_SEARCH_LABEL_LOAD = "MUSIC_SEARCH_LABEL_LOAD";
export const CART_DELETE = "CART_DELETE";

// zq
export const SHOW_POPUP = "SHOW_POPUP";
export const UPDATEINFO = "UPDATEINFO";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const PURCHASE_LOAD = "PURCHASE_LOAD";
export const HISTORY_PURCHASE_LOAD = "HISTORY_PURCHASE_LOAD";
export const SHOW_FORGET_POPUP = "SHOW_FORGET_POPUP";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const GET_PAY_URL = "GET_PAY_URL";

// hj
//ljm
export const SELLERREGISTER = "SELLERREGISTER";
export const SELLERREGISTER_PAGE_UNLOADED = "SELLERREGISTER_PAGE_UNLOADED";
export const FAVORITE_DELETE = "FAVORITE_DELETE";
export const FAVORITE = "FAVORITE";
export const FAVORITE_ADD = "FAVORITE_ADD";
export const FAVORITE_PAGE_UNLOADED = "FAVORITE_PAGE_UNLOADED";
export const MAKER_LIST_LOAD = "MAKER_LIST_LOAD";
export const MAKER_DELETE = "MAKER_DELETE";
export const MAKER_EDIT = "MAKER_EDIT";
export const MAKER_LOAD = "MAKER_LOAD";
export const MAKER_LIST_PAGE_UNLOAD = "MAKER_LIST_PAGE_UNLOAD";
export const WITHDRAW_INFO = "WITHDRAW_INFO";
export const WITHDRAW_LIST = "WITHDRAW_LIST";
export const INCOME_LIST = "INCOME_LIST";
export const WITHDRAW = "WITHDRAW";
export const SETTLEMENT_PAGE_UNLOADED = "SETTLEMENT_PAGE_UNLOADED";
export const SELLER_PROFILE = "SELLER_PROFILE";
export const SELLER_PROFILE_PAGE_UNLOADED = "SELLER_PROFILE_PAGE_UNLOADED";
export const SETTLEMENT_UPDATE = "SETTLEMENT_UPDATE";
export const SETTLEMENT_UPDATE_PAGE_UNLOADED = "SETTLEMENT_UPDATE_PAGE_UNLOADED";

export const PRODUCER_CREATE_AUDIO = "PRODUCER_CREATE_AUDIO";

export const PRODUCER_GET_AUDIO = "PRODUCER_GET_AUDIO";
export const PRODUCER_UPDATE_AUDIO = "PRODUCER_UPDATE_AUDIO";
export const PRODUCER_GET_AUDIO_DETAIL = "PRODUCER_GET_AUDIO_DETAIL";
export const PRODUCER_GET_CREATOR = "PRODUCER_GET_CREATOR";
export const PRODUCER_CREATE_CREATOR = "PRODUCER_CREATE_CREATOR";
export const PRODUCER_DELETE_CREATOR = "PRODUCER_DELETE_CREATOR";
export const PRODUCER_UPDATE_CREATOR = "PRODUCER_UPDATE_CREATOR";
export const PRODUCER_GET_CREATOR_DETAIL = "PRODUCER_GET_CREATOR_DETAIL";

export const PLAYLIST_GET_INFO = "PLAYLIST_GET_INFO";
export const PLAYLIST_GET_TYPE = "PLAYLIST_GET_TYPE";

export const AUDIO_SUSUME = "AUDIO_SUSUME";
export const PARTNER_LIST = "PARTNER_LIST";
export const QA_GET = "QA_GET";

export const CONTACT_SEND = "CONTACT_SEND";
export const ORDER_DELETE = "ORDER_DELETE";
export const MUSIC_SEARCH_LABEL_LOAD_All = "MUSIC_SEARCH_LABEL_LOAD_All";
export const MUSIC_SEARCH_LABEL_LOAD_SON = "MUSIC_SEARCH_LABEL_LOAD_SON";

export const PUBLIC_MAKER_LIST = "PUBLIC_MAKER_LIST";
export const PUBLIC_MAKER_DETAIL = "PUBLIC_MAKER_DETAIL";
export const PUBLIC_MKAER_MUSIC = "PUBLIC_MKAER_MUSIC";
export const IS_REGISTER = "IS_REGISTER";
export const NO_LOGIN_ADD_CART = "NO_LOGIN_ADD_CART";
export const CLEAR_CART_AUDIOS = "CLEAR_CART_AUDIOS";
export const FORGOT_PASSWORD_CAPTCHA = "FORGOT_PASSWORD_CAPTCHA";
export const NEWS_LIST = "NEWS_LIST";
export const NEWS_ONE = "NEWS_ONE";
export const PREVI_OUS = "PREVI_OUS";
export const SHOW_TORT_POPUP = "SHOW_TORT_POPUP";
export const SEND_CORT_POPUP = "SEND_CORT_POPUP";
export const SEND_CUSTOMIZED = "SEND_CUSTOMIZED";
export const PAY_CUSTOMIZED = "PAY_CUSTOMIZED";
export const CUSTOMIZED_LOAD = "CUSTOMIZED_LOAD";
export const NEWS_ONE_UNLOAD = "NEWS_ONE_UNLOAD";
export const NEWS_LIST_UNLOAD = "NEWS_LIST_UNLOAD";
export const UPDATEINFO_PAGE_UNLOADED = "UPDATEINFO_PAGE_UNLOADED";
export const MUSIC_PAGE_UNLOADED = "MUSIC_PAGE_UNLOADED";
export const DETAIL_PAGE_UNLOAD = "DETAIL_PAGE_UNLOAD";
export const TOPIC_DETAIL_UNLOAD = "TOPIC_DETAIL_UNLOAD";
export const PLAYER_UNLOADED = "PLAYER_UNLOADED";
export const DETAIL_AUDIO_UNLOAD = "DETAIL_AUDIO_UNLOAD";
export const ADD_MUSIC_STATIS = "ADD_MUSIC_STATIS";
export const CAPTCHA_UPDATE_INFO = "CAPTCHA_UPDATE_INFO";
export const ADD_ON_CLICK = "ADD_ON_CLICK";
export const RECEIPT_MODAL_HIDE = "RECEIPT_MODAL_HIDE";
export const RECEIPT_MODAL_SHOW = "RECEIPT_MODAL_SHOW";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const MUSIC_NOISE = "MUSIC_NOISE";
export const UNLOAD_CREATOR = "UNLOAD_CREATOR";
export const APPLY_WITHDRAW = "APPLY_WITHDRAW";
export const HIDDEN_SHOP_CAR = "HIDDEN_SHOP_CAR";
export const SHOW_SHOP_CAR = "SHOW_SHOP_CAR";
export const CHECK_PRICE_GROUP = "CHECK_PRICE_GROUP";

export const SHOW_UPDATE_CAR = "SHOW_UPDATE_CAR";
export const HIDE_UPDATE_CAR = "HDIE_UPDATE_CAR";
export const CHECK_PRICE_GROUP_UPDATE = "CHECK_PRICE_GROUP_UPDATE";
export const UPDATE_CAR = "UPDATE_CAR";
export const UPDATE_REMITTANCE_ORDER = "UPDATE_REMITTANCE_ORDER";
//会员订阅
export const SUBSCRIBE_LOAD = "SUBSCRIBE_LOAD";
export const UPLOAD_SUBSCRIBE = "UPLOAD_SUBSCRIBE";
export const SUBSCRIBE_DELETE = "SUBSCRIBE_DELETE";
export const SUBSCRIBE_UPDATE = "SUBSCRIBE_UPDATE";
export const SUBSCRIBE_GET_PAY_URL = "SUBSCRIBE_GET_PAY_URL";
export const SUBSCRIBE_CREATE = "SUBSCRIBE_CREATE";
export const KEEP_IDENTIY = "KEEP_IDENTIY";
export const ADD_QR_URL = "ADD_QR_URL";
export const CLOSE_CODE_URL = "CLOSE_CODE_URL";
export const SHOW_CODE_URL = "SHOW_CODE_URL";
export const CHECK_ORDER_BY_ORDER_ID = "CHECK_ORDER_BY_ORDER_ID";
export const ADD_COUPON = "ADD_COUPON";

