import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "components/Loading";

import { SELLER_PROFILE_PAGE_UNLOADED } from "constants/actionTypes";

import agent from "agent";
import { loadSellerProfileInfo, redirectTo } from "dispatch";
import SidebarAvatar from "./SidebarAvatar";

const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    unloadProfile: () => {
        return dispatch({ type: SELLER_PROFILE_PAGE_UNLOADED });
    },
});

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        userLogin: PropTypes.bool.isRequired,
        profile: PropTypes.object,

        unloadProfile: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.unloadProfile();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user profile
        loadSellerProfileInfo();
    }

    render() {
        const { currentUser, profile } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }

        if (!Object.keys(profile).length) {
            return <Loading />;
        }

        const attachment = JSON.parse(profile.attachment);
        let fileList = [];
        let i = 0;
        for (const key in attachment) {
            fileList[i] = attachment[key];
            i++;
        }
        const showAttachment =
            fileList.length > 0 &&
            fileList.map((data, i) => {
                return (
                    <div className="form__img" key={i}>
                        <img src={agent.getImgApiUrl(data)} alt="" />
                    </div>
                );
            });

        const sellerInfo = profile && !profile.company && (
            <div className="mymusicBox">
                <h2 className="mymusicBox__title">个人资料</h2>
                <div className="mymusicForm">
                    <div className="buypageForm__items">
                        <div className="buypageForm__item">
                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>姓名</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.family_name}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>证件类型</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.certificate}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>证件号码</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.identify_number}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>联系电话</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.contact_number}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt u-alsT">
                                    <label>证件照片</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {showAttachment}
                                </dd>
                            </dl>
                            <p className="u-noteTxt u-Color--red2">
                                注：如需更改联系平台客服。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );

        const companyInfo = profile && profile.company && (
            <div className="mymusicBox">
                <h2 className="mymusicBox__title">企业资料</h2>
                <div className="mymusicForm">
                    <div className="buypageForm__items">
                        <div className="buypageForm__item">
                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>企业名称</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.company_name}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>营业执照号</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.company_number}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>联系人</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.family_name}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt">
                                    <label>联系电话</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {profile.contact_number}
                                </dd>
                            </dl>

                            <dl className="buypageForm__dl">
                                <dt className="buypageForm__dt u-alsT">
                                    <label>营业执照照片</label>
                                </dt>
                                <dd className="buypageForm__dd">
                                    {showAttachment}
                                </dd>
                            </dl>
                            <p className="u-noteTxt u-Color--red2">
                                注：如需更改联系平台客服。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">卖家个人中心</h1>
                    </header>
                    <SidebarAvatar />
                    <div className="mainBox02">
                        {profile && profile.company ? companyInfo : sellerInfo}
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);
