import React from "react";
//import PropTypes from "prop-types";

class InfoImages extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center"}}>
                <img src="/assets/img/culture.jpg" style={{ width: "50%"}}/>
            </div>
        );
    }
}
export default InfoImages ;
