// import agent from "../agent";
import React from "react";
import {connect} from "react-redux";
// import PropTypes from "prop-types";
import {Layout} from "antd";
import Loading from "../Loading";

const {Content} = Layout;

const mapStateToProps = state => ({...state.musicTopicAll});

const mapDispatchToProps = dispatch => ({});

class TopicAll extends React.Component {
    static propTypes = {
        // redirectTo: PropTypes.string,
        // appName: PropTypes.string.isRequired,
        // appLoaded: PropTypes.bool,
        // onRedirect: PropTypes.func.isRequired,
        // linkTo: PropTypes.func.isRequired
    };
    state = {
        loading: true,
        topicall: ""
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        let loading = false;
        const param = nextProps.match.params.topicall;
        if (prevState.topicall != param) {
            // TODO: send loading
            loading = true;
        }
        return {topicall: param, loading};
    }

    id = 0;
    componentWillUnmount() {
        clearTimeout(this.id);
    }
    play() {
        console.info(111);
    }
    render() {
        if (this.state.loading) {
            clearTimeout(this.id);
            this.id = setTimeout(
                (() => {
                    this.setState(state => {
                        state.loading = false;
                        return state;
                    });
                }).bind(this),
                500
            );

            return (
                <Content>
                    <Loading />
                </Content>
            );
        }
        return <Content>topicall {this.state.topicall}
            <div>
                <div>
                    <button onClick={this.play} className="btn btn-primary btn-block">
                        播放
                    </button>
                </div>
            </div>
        </Content>;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopicAll);
