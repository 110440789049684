// Create a defaults context
const request = require("superagent-use")(require("superagent"));
/* A sample superagent plugin/middleware. */
const prefix = require("superagent-prefix");
const queryString = require("query-string");
import Base64 from "base-64";
const apiurl = (() => {
    // 线上部署
    if (process.env.REACT_APP_PRODUCT === "yes") {
        return "https://api.musiness.vip";
    }
    // 线上测试
    if (process.env.REACT_APP_PRODUCT === "test") {
        return "http://beta-api.musiness.vip";
    }
    // 内网测试
    if (process.env.REACT_APP_PRODUCT === "bmi") {
        return "http://api.musiness.bmi";
    }
    // 开发测试
    // return "http://api.musiness.bmi";
    return "http://localhost:9004";
    // return "https://api.musiness.vip";
    // return "http://1.119.145.114:9004";
    
})();

const imgapiurl = (() => {
    if (process.env.REACT_APP_PRODUCT === "yes") {
        return "https://backend-api.musiness.vip";
    }
    if (process.env.REACT_APP_PRODUCT === "test") {
        return "https://beta-backend-api.musiness.vip";
    }
    return "http://backend-api.musiness.bmi";
})();

request.use(prefix(apiurl));

const buildURLQuery = obj => {
    return Object.entries(obj)
        .map(pair => pair.map(encodeURIComponent).join("="))
        .join("&");
};
const tokenKey = "X-API-TOKEN";
const setToken = res => {
    try {
        const _token = res.headers[tokenKey.toLowerCase()];
        if (typeof _token === "string" && _token.length > 10) {
            window.localStorage.setItem("jwt", _token);
        }
    } catch (e) {
        console.error("setToken:", e, res);
    }
};
const getToken = () => {
    const _token = window.localStorage.getItem("jwt");
    return _token;
};
const rmToken = () => {
    window.localStorage.setItem("jwt", "");
};

request.use(req => {
    const _token = getToken();
    req.set(tokenKey, `${_token}`);
    return req;
});

const Auth = {
    current: () => request.get("/users/profile"),
    login: (email, password) =>
        request.post("/users/signin", { email, password }),
    isRegister: email => request.post("/users/isregister", { email }),
};

const Register = {
    userRegister: ({
        company_name,
        company_number,
        email,
        password,
        captcha,
        userImage,
        interest,
        industry,
        inviter,
        sourceuser,
        source,
    }) =>
        request.post("/users/signup", {
            company_name,
            company_number,
            email,
            password,
            captcha,
            userImage,
            interest,
            industry,
            inviter,
            sourceuser,
            source,
        }),
};

const SellerRegister = {
    register: (send = {}) => {
        const req = request.post(`/producer/apply`);
        for (const [key, value] of Object.entries(send)) {
            if (typeof value === "undefined") {
                continue;
            }
            req.field(key, value);
        }
        return req;
    },
};

const createFormData = (req, send) => {
    for (const [key, value] of Object.entries(send)) {
        if (typeof value === "undefined") {
            continue;
        }
        req.field(key, value);
    }
};

const Seller = {
    loadMakerList: data => {
        const query = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        return request.get(
            `/maker/search?` +
            buildURLQuery({
                ...query,
                ...data,
            })
        );
    },
    loadMaker: maker_id => {
        return request.get(`/maker/${maker_id}`);
    },
    deleteMaker: maker_id => {
        return request.delete(`/maker/${maker_id}`, {});
    },
    editMaker: maker_id => {
        return request.post(`/maker`, maker_id);
    },
    loadWithdrawList: () => {
        const query = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        return request.get(
            `/withdraw/pay?` +
            buildURLQuery({
                ...query,
            })
        );
    },
    loadIncomeList: pager => {
        const query = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        return request.get(
            `/withdraw?` +
            buildURLQuery({
                ...query,
                ...pager,
            })
        );
    },
    loadWithdrawInfo: () => {
        return request.get(`/withdraw/info`);
    },
    withdraw: send => {
        return request.post(`/withdraw`, { ...send });
    },
    loadSellerProfileInfo: () => {
        return request.get(`/producer/profile`);
    },
    settlementUpdate: data => request.put("/producer/profile", data),
};
const Captcha = {
    create: email => request.post("/captcha", { email }),
    getCreate: email => request.post("/captcha/getcaptcha", { email }),
    sendEmail: email => request.post("/captcha/sendemail", { ...email }),
    sendCustomized: email =>
        request.post("/captcha/sendcustomized", { ...email }),
};

// examples
const omitSlug = article => Object.assign({}, article, { slug: undefined });
const Articles = {
    favoritedBy: (author, page) => {
        const count = 5;
        const query = buildURLQuery({
            favorited: author,
            limit: count,
            offset: page ? page * count : 0,
        });
        return request.get(`/articles?${query}`);
    },

    update: article =>
        request.put(`/articles/${article.slug}`, {
            article: omitSlug(article),
        }),
    create: article => request.post("/articles", { article }),
};

const Comments = {
    forArticle: slug => request.get(`/articles/${slug}/comments`),
};

const Profile = {
    get: username => request.get(`/profiles/${username}`),
};
// end ...
const Music = {
    search: data => {
        let a = {};
        if (data.tiao) {
            a = queryString.parse(Base64.decode(data.tiao));
        }
        let s1 = {};

        if (a.keyword) {
            let s2 = a.keyword.replace("&", "&amp;").replace("'", "&#x27;");
            s1.keyword = s2;
        }
        const search = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        if (!data.size) {
            data.size = 10;
        }
        return request.get(
            `/audio/search?` +
            buildURLQuery({
                ...search,
                ...data,
                ...a,
                ...s1,
            })
        );
    },
    one: audioid => {
        return request.get(`/audio/audioid/${audioid}`);
    },
    addPeaks: (audioid, peaks) => {
        return request.post(`/audio/peaks/${audioid}`, {
            merged_peaks: peaks,
        });
    },
    searchLabels: () => {
        return request.get(`/audio/condition`);
    },
    searchLabelAll: () => {
        return request.get(`/audio/conditionall`);
    },
    searchLabelAlls: () => {
        return request.get(`/audio/conditionalls`);
    },
    audition: audioid => {
        return request.get(`/audio/audition/${audioid}`);
    },
};
const Cart = {
    add: send => {
        return request.post(`/cart`, {
            ...send,
        });
    },
    load: () => {
        return request.get(`/cart`);
    },
    delete: cartid => {
        return request.delete(`/cart/${cartid}`);
    },
    chenckByOrderid: orderid => {
        return request.get(`/subscribe/checkbyorderid/${orderid}`);
    },
};
const Order = {
    create: (cart_id, send = {}) => {
        return request.post(`/order`, {
            cart_id,
            ...send,
        });
    },
    createFree: (cart_id, send = {}) => {
        return request.put(`/order/free`, {
            cart_id,
            ...send,
        });
    },
    list: (params = {}) => {
        return request.get(`/order`, { ...params });
    },
    listHistory: (params = {}) => {
        return request.get(`/order`, { ...params });
    },
    one: orderid => {
        return request.get(`/order/${orderid}`);
    },
    deleteOrder: orderid => {
        return request.delete(`/order/${orderid}`);
    },
};
const Purchase = {
    create: (orderid, parmas) => {
        return request.post(`/purchase`, {
            ...parmas,
            orderid,
            auth: "b026324c6904b2a9cb4b88d6d61c81d1",
        });
    },
    list: (params = {}) => {
        const query = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        return request.get(`/users/purchased`, { ...query, ...params });
    },
    one: orderid => {
        return request.get(`/purchase/${orderid}`);
    },
    getPayUrl: (orderid, send) => {
        return request.post(`/purchase/payurl`, { ...send, orderid });
    },
    updateOrder: (orderid, parmas) => {
        return request.post(`/purchase/updateOrder`, {
            ...parmas,
            orderid,
        });
    },
    updateRemittanceOrder: (orderid, parmas) => {
        return request.post(`/purchase/updateremittanceorder`, {
            ...parmas,
            orderid,
        });
    },
};
const Favorite = {
    load: data => {
        const query = {
            page: 1,
            size: 10,
            orderby: "-id",
        };
        return request.get(
            `/like?` +
            buildURLQuery({
                ...query,
                ...data,
            })
        );
    },
    delete: like_id => {
        return request.delete(`/like/${like_id}`, {});
    },
    add: audioid => {
        const send = { audioid };
        return request.post(`/like`, send);
    },
};

const Producer = {
    createAudio: (send = {}) => {
        const req = request.post(`/producer/audio`);
        for (const [key, value] of Object.entries(send)) {
            req.field(key, value || "");
        }
        return req;
    },
    updateAudio: (send = {}, id) => {
        const req = request.put(`/producer/audio/${id}`);
        for (const [key, value] of Object.entries(send)) {
            req.field(key, value || "");
        }
        return req;
    },
    getAudio: (send = {}) => {
        let { pager, status } = send;
        const { current: page, pageSize: size } = pager;
        status = status !== "all" ? { status } : {};
        const req = request.get(
            "/producer/audio?" +
            buildURLQuery({ page, size, ...status, orderby: "-id" })
        );
        return req;
    },
    getAudioDetail: audioId => {
        const req = request.get(`/producer/audio/${audioId}`);
        return req;
    },
    updateCreator: (send = {}) => {
        const { creatorId, ...params } = send;

        if (params.avatar && typeof params.avatar === "string")
            delete params.avatar;

        const req = request.put(`/producer/creator/${creatorId}`);
        createFormData(req, params);
        return req;
    },
    getCreators: send => {
        let { pager } = send;
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            `/producer/creator?${buildURLQuery({ page, size, orderby: "-id" })}`
        );
        return req;
    },
    createCreator: (send = {}) => {
        const req = request.post(`/producer/creator`);
        createFormData(req, send);
        return req;
    },
    deleteCreator: (send = {}) => {
        const { id } = send;
        const req = request.delete(`/producer/creator/${id}`);
        return req;
    },
    getCreatorDetail: (send = {}) => {
        const { id } = send;
        const req = request.get(`/producer/creator/${id}`);
        return req;
    },
    noise: () => {
        const req = request.get(`/producer/noise`);
        return req;
    },
};
const User = {
    userUpdateInfo: (send = {}) => {
        const req = request.put(`/users/profile`);
        for (const [key, value] of Object.entries(send)) {
            if (typeof value === "undefined") {
                continue;
            }
            req.field(key, value);
        }
        return req;
    },
    userForgetPassword: parma =>
        request.put("/users/repassword", {
            ...parma,
        }),
};

const PlayList = {
    getInfo: (
        send = {
            pager: { page: 1, size: 50, orderby: "-weight" },
        }
    ) => {
        const { pager } = send;
        const req = request.get("/playlist/info?" + buildURLQuery(pager));
        return req;
    },
    getType: (
        send = {
            pager: { page: 1, size: 50, orderby: "-id" },
        }
    ) => {
        const { topicId, pager } = send;
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            `/playlist/${topicId}?` +
            buildURLQuery({ page, size, orderby: "-id" })
        );
        return req;
    },
};

const Audio = {
    getSusume: (send = { pager: { page: 1, size: 10, orderby: "-id" } }) => {
        const { pager } = send;
        const req = request.get("/audio/susume?" + buildURLQuery(pager));
        return req;
    },
};

const Partner = {
    getList: (send = { pager: { page: 1, size: 10, orderby: "-weight" } }) => {
        const { pager } = send;
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            "/page/partner?" + buildURLQuery({ page, size, orderby: "-weight" })
        );
        return req;
    },
};

const Qa = {
    getList: (
        send = { pager: { page: 1, size: 10, orderby: "-id" }, keyword: "" }
    ) => {
        const { pager, keyword } = send;
        const { current: page, pageSize: size } = pager;
        const params = { page, size, orderby: "-id", keyword };
        if (!keyword) delete params["keyword"];
        const req = request.get("/page/qa?" + buildURLQuery(params));
        return req;
    },
};

const Contact = {
    send: send => {
        const req = request.post(`/page/contactus`, send);
        // createFormData(req, send);
        return req;
    },
};

const Maker = {
    getList: send => {
        const { pager, keyword = "" } = send;
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            "/creator?" + buildURLQuery({ keyword, page, size, orderby: "-id" })
        );
        return req;
    },
    getDetail: id => {
        const req = request.get("/creator/" + id);
        return req;
    },
    getAudio: ({ id, pager }) => {
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            "/creator/audio/" +
            id +
            "?" +
            buildURLQuery({ page, size, orderby: "-id" })
        );
        return req;
    },
};
const News = {
    getList: (send = { pager: { page: 1, size: 10, orderby: "-weight" } }) => {
        const { pager } = send;
        const { current: page, pageSize: size } = pager;
        const req = request.get(
            "/page/news?" + buildURLQuery({ page, size, orderby: "-weight" })
        );
        return req;
    },
    one: newsid => {
        return request.get(`/page/newsid/${newsid}`);
    },
};
const Chapter = {
    previous: newsid => {
        return request.get(`/page/newsid/around/${newsid}`);
    },
};
const Customized = {
    payCustomized: (send = {}) => {
        return request.post(`/purchase/paycustomized`, { ...send });
    },
    getcustomized: (params = {}) => {
        return request.get(`/purchase/getcustomized`, { ...params });
    },
};

const MusicStatis = {
    addMusicStatis: (send = {}) => {
        return request.post(`/like/addMusicStatis`, { ...send });
    },
    addMusicSta: (send = {}) => {
        return request.post(`/like/addMusicSta`, { ...send });
    },
};
const PriceGroup = {
    get: id => {
        const req = request.get("/cart/pricegroup/" + id);
        return req;
    },
    updateCar: (send = {}) => {
        const req = request.post(`/cart/updateCar`, { ...send });
        return req;
    },
};
//会员订阅功能接口
const Subscribe = {
    loadSubscribe: (params = {}) => {
        return request.post(`/subscribe`, { ...params });
    },
    deleteSubScribe: (params = {}) => {
        return request.put(`/subscribe/delete`, { ...params });
    },
    updateSubscribe: (orderid, parmas) => {
        return request.post(`/subscribe/update`, {
            ...parmas,
            orderid,
        });
    },
    crteateSubscribe: send => {
        return request.post(`/subscribe/create`, {
            ...send,
        });
    },
    getPayUrl: (orderid, send) => {
        return request.post(`/subscribe/payurl`, { ...send, orderid });
    },
};
export default {
    Articles,
    Auth,
    Register,
    SellerRegister,
    Seller,
    Captcha,
    Comments,
    Profile,
    Music,
    Maker,
    Qa,
    Cart,
    Contact,
    Order,
    Purchase,
    Favorite,
    setToken,
    getToken,
    rmToken,
    Producer,
    PlayList,
    Audio,
    Partner,
    User,
    buildURLQuery,
    News,
    Customized,
    MusicStatis,
    PriceGroup,
    Subscribe,
    Chapter,
    getApiUrl: uri => {
        if (typeof uri !== "string") {
            return apiurl;
        }
        if (uri.indexOf("data:") === 0) {
            return uri;
        }
        if (uri.indexOf("http:") === 0) {
            return uri;
        }
        return apiurl + "/" + uri;
    },
    getImgApiUrl: uri => {
        if (typeof uri !== "string") {
            return imgapiurl;
        }
        if (uri.indexOf("data:") === 0) {
            return uri;
        }
        if (uri.indexOf("http:") === 0) {
            return uri;
        }
        return imgapiurl + "/" + uri;
    },
};
