import React from "react";
import { Form, Input, Radio } from "antd";
import PropTypes from "prop-types";

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class SellerInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        form: PropTypes.object.isRequired,
        onRef: PropTypes.func.isRequired,

        handleSellerInfoVal: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    sellerInfoCheck = async () => {
        return new Promise((resolve, reject) => {
            this.props.form.validateFields((err, values) => {
                if (err) {
                    return reject(err);
                }
                return resolve(values);
            });
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>姓名</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <FormItem
                                style={{
                                    width: "40%",
                                    display: "inline-block",
                                }}
                            >
                                {getFieldDecorator("sellerFamilyName", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入真实姓名!",
                                        },
                                    ],
                                })(
                                    <Input
                                        className="u-inputSkin02"
                                        placeholder="请输入真实姓名"
                                        onChange={this.props.handleSellerInfoVal}
                                    />
                                )}
                            </FormItem>
                        </dd>
                    </dl>
                </div>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>证件类型</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <ul className="buypageForm__list">
                                <li className="buypageForm__list__item">
                                    <RadioGroup
                                        name="credentialsType"
                                        defaultValue="1"
                                        onChange={this.props.handleSellerInfoVal}
                                    >
                                        <Radio value="1">身份证&nbsp;&nbsp;&nbsp;&nbsp;</Radio>
                                        <Radio value="2">港澳台证件 (港澳台地区当地身份证件)&nbsp;&nbsp;&nbsp;&nbsp;</Radio>
                                        <Radio value="3">护照&nbsp;&nbsp;&nbsp;&nbsp;</Radio>
                                    </RadioGroup>
                                </li>
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>证件号码</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <FormItem>
                                {getFieldDecorator("credentialsNumber", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入证件号码!",
                                        },
                                    ],
                                })(
                                    <Input
                                        className="u-inputSkin02"
                                        placeholder="请输入证件号码"
                                        onChange={this.props.handleSellerInfoVal}
                                    />
                                )}
                            </FormItem>
                        </dd>
                    </dl>
                </div>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt">
                            <label>联系电话</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <FormItem>
                                {getFieldDecorator("telephone", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入联系电话!",
                                        },
                                    ],
                                })(
                                    <Input
                                        type="tel"
                                        className="u-inputSkin02"
                                        placeholder="请输入联系电话"
                                        onChange={this.props.handleSellerInfoVal}
                                    />
                                )}
                            </FormItem>
                        </dd>
                    </dl>
                </div>
            </Form>
        );
    }
}
const SellerInfoForm = Form.create()(SellerInfo);
export default SellerInfoForm;
