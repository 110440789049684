import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { NEWS_LIST } from "constants/actionTypes";
import agent from "agent";

class Media extends React.Component {
    static propTypes = {
        news: PropTypes.object.isRequired,
        getNewsList: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
    };
    componentDidMount() {
        this.props.getNewsList({ pager: { current: 1, pageSize: 10 , orderby: "-weight" } });
    }
    onPageChange(page) {
        const {
            pager: { pageSize },
        } = this.props.news;

        this.props.getNewsList({
            pager: {
                pageSize,
                current: page,
            },
        });
    }
    render() {
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">媒体报导</h1>
                    </header>
                    <div className="mainContents__box mediaBox">
                        <div className="mediaBox__main">
                            <div className="mediaBox__main__inner">
                                <div className="mediaItemWrapper">
                                    <div className="mediaItem">
                                        <p className="mediaItem__title">
                                            <Link to="/media/mediathree">
                                                战略合作|头部IP公司忘语文化与奕颗贝壳签订音乐独家采购协议
                                            </Link>
                                        </p>
                                        <div className="mediaItem__info">
                                            <p className="mediaItem__category">
                                            IP孵化器公众号
                                            </p>
                                            <p className="mediaItem__date">
                                                <time dateTime="2018-12-21">
                                                    2018-11-21
                                                </time>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mediaItem">
                                        <p className="mediaItem__title">
                                            <Link to="/media/mediatwo">
                                                战略合作 |
                                                近30万首BGM曲库的互联网商用音乐聚合与变现平台
                                            </Link>
                                        </p>
                                        <div className="mediaItem__info">
                                            <p className="mediaItem__category">
                                            IP孵化器公众号
                                            </p>
                                            <p className="mediaItem__date">
                                                <time dateTime="2018-11-16">
                                                    2018-11-16
                                                </time>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mediaItem">
                                        <p className="mediaItem__title">
                                            <Link to="/media/mediaone">
                                                数据智能助力文创产业发展主题活动在沪举行
                                            </Link>
                                        </p>
                                        <div className="mediaItem__info">
                                            <p className="mediaItem__category">
                                            中国网
                                            </p>
                                            <p className="mediaItem__date">
                                                <time dateTime="2018-10-31">
                                                    2018-10-31
                                                </time>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mediaPagination hidden">
                            <ul className="resultPaginationList">
                                <li className="resultPagination__item">
                                    <a href="" className="is-current">
                                        1
                                    </a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">2</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">3</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">4</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">5</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">6</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">7</a>
                                </li>
                                <li className="resultPagination__item">
                                    <a href="">8</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({
        news: state.news,
    }),
    dispatch => ({
        getNewsList: send =>
            dispatch({
                type: NEWS_LIST,
                payload: agent.News.getList(send),
                send,
            }),
    })
)(Media);

