import {
    ASYNC_START,
    MUSIC_ONE_PAGE_UNLOAD,
    MUSIC_ONE,
    FAVORITE_DELETE,
    FAVORITE_ADD,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,

    fetchError: false,
    musicone: "",
    musiconeData: null,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case MUSIC_ONE: {
            const fetchError = !!action.error;
            const musiconeData = fetchError ? null : action.payload.audio;
            return {
                ...state,
                musiconeData,
                fetchError,
                loading: false,
                musicone: action.audioid,
            };
        }
        case FAVORITE_DELETE:
        case FAVORITE_ADD: {
            if (!state.musiconeData || action.error) {
                return state;
            }
            const musiconeData = { ...state.musiconeData };

            if (action.send.audioid == musiconeData.audioid) {
                musiconeData.likeUpdate = false;
                if (action.type == FAVORITE_DELETE) {
                    musiconeData.like = null;
                } else {
                    musiconeData.like = action.payload.like;
                }
                return { ...state, musiconeData };
            }
            return state;
        }

        case ASYNC_START:
            if (action.subtype === MUSIC_ONE) {
                return { ...state, loading: true };
            }
            if (
                action.subtype === FAVORITE_DELETE ||
                action.subtype === FAVORITE_ADD
            ) {
                if (!state.musiconeData || action.error) {
                    return state;
                }
                const musiconeData = { ...state.musiconeData };

                if (action.send.audioid == musiconeData.audioid) {
                    musiconeData.likeUpdate = true;

                    return { ...state, musiconeData };
                }
                return state;
            }
            break;
        case MUSIC_ONE_PAGE_UNLOAD:
            return { ...defaultState };
        default:
            return state;
    }

    return state;
};
