import React from "react";
import { Upload, Form, Input, Avatar } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import agent from "agent";

const FormItem = Form.Item;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class UploadSellerImg extends React.Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        form: PropTypes.object.isRequired,
        onRef: PropTypes.func.isRequired,

        // handleSellerInfoVal: PropTypes.func.isRequired,
        handleImageVal: PropTypes.func.isRequired,
    };
    state = {
        fileFrontList: [],
        fileReverseList: [],
        fileHoldList: [],
    };

    sellerImgCheck = async () => {
        return new Promise((resolve, reject) => {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    console.log("Received values of form: ", values);

                    this.props.handleImageVal({
                        credentialsFrontImg: this.state.fileFrontList[0],
                        credentialsReverseImg: this.state.fileReverseList[0],
                        credentialsHoldImg: this.state.fileHoldList[0],
                    });
                    return resolve(values);
                }
                return reject(err);
            });
        });
    };

    handleDeleteFrontAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            imgFrontPreviewInfo: {
                value: "",
            },
        });
        this.setState(state => ({
            fileFrontList: [],
        }));
    };

    handleDeleteReverseAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            imgReversePreviewInfo: {
                value: "",
            },
        });
        this.setState(state => ({
            fileReverseList: [],
        }));
    };

    handleDeleteHoldAvatarClick = e => {
        e.preventDefault();
        e.stopPropagation();
        this.props.form.setFields({
            imgHoldPreviewInfo: {
                value: "",
            },
        });
        this.setState(state => ({
            fileHoldList: [],
        }));
    };

    componentDidMount() {
        this.props.onRef(this);
    }

    render() {
        const { fileFrontList, fileReverseList, fileHoldList } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const uploadFrontProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileFrontList: [],
                    };
                });
                this.props.form.setFields({
                    imgFrontPreviewInfo: {
                        value: "",
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileFrontList: [file],
                }));

                getBase64(file).then(data => {
                    this.props.form.setFields({
                        imgFrontPreviewInfo: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            showUploadList: false,
            fileFrontList,
        };

        const uploadReverseProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileReverseList: [],
                    };
                });
                this.props.form.setFields({
                    imgReversePreviewInfo: {
                        value: "",
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileReverseList: [file],
                }));

                getBase64(file).then(data => {
                    this.props.form.setFields({
                        imgReversePreviewInfo: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            showUploadList: false,
            fileReverseList,
        };

        const uploadHoldProps = {
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileHoldList: [],
                    };
                });
                this.props.form.setFields({
                    imgHoldPreviewInfo: {
                        value: "",
                    },
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileHoldList: [file],
                }));

                getBase64(file).then(data => {
                    this.props.form.setFields({
                        imgHoldPreviewInfo: {
                            value: data,
                        },
                    });
                });

                return false;
            },
            accept: "image/*",
            listType: "picture",
            showUploadList: false,
            fileHoldList,
        };

        return (
            <Form>
                <div className="buypageForm__item">
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt u-alsT">
                            <label>证件照片</label>
                        </dt>
                        <dd className="buypageForm__dd">
                            <div className="form__columnBox">
                                <div className="form__columnBox__drop">
                                    <div className="form__dropBox form__dropBox--s">
                                        <div className="form__dropBox__inner">
                                            <p className="form__dropBox__txt">
                                                证件正面
                                            </p>
                                            <div className="form__dropBox__img">
                                                <img
                                                    src="/assets/img/id-photo_01.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="form__dropBox__btn">
                                                <Upload {...uploadFrontProps}>
                                                    <div className="m-btn-type0--s">
                                                        点击上传
                                                    </div>
                                                </Upload>
                                            </div>
                                        </div>
                                    </div>
                                    <FormItem>
                                        {getFieldDecorator(
                                            "imgFrontPreviewInfo",
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            "请上传证件正面照片!",
                                                    },
                                                ],
                                            }
                                        )(<Input type="hidden" />)}
                                    </FormItem>
                                    <p className="buypageForm__note">
                                        *请上传 2M 以内, JPG/RNG图片
                                    </p>
                                </div>

                                <div className="form__sampleImg">
                                    <div className="form__sampleImg__inner">
                                        <div>
                                            <Avatar
                                                key={getFieldValue(
                                                    "imgFrontPreviewInfo"
                                                )}
                                                size={64}
                                                // icon="user"
                                                src={agent.getApiUrl(
                                                    getFieldValue(
                                                        "imgFrontPreviewInfo"
                                                    )
                                                )}
                                            />
                                        </div>
                                        <div>
                                            {fileFrontList.length == 0 ? (
                                                <img
                                                    src="/assets/img/id-photo_01.png"
                                                    alt=""
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <Link
                                                    // style={{
                                                    //     display: "block",
                                                    // }}
                                                    to="#"
                                                    onClick={
                                                        this
                                                            .handleDeleteFrontAvatarClick
                                                    }
                                                >
                                                    删除图片
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt u-alsT">
                            <label />
                        </dt>
                        <dd className="buypageForm__dd">
                            <div className="form__columnBox">
                                <div className="form__columnBox__drop">
                                    <div className="form__dropBox form__dropBox--s">
                                        <div className="form__dropBox__inner">
                                            <p className="form__dropBox__txt">
                                                证件反面
                                            </p>
                                            <div className="form__dropBox__img">
                                                <img
                                                    src="/assets/img/id-photo_02.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="form__dropBox__btn">
                                                <Upload {...uploadReverseProps}>
                                                    <div className="m-btn-type0--s">
                                                        点击上传
                                                    </div>
                                                </Upload>
                                            </div>
                                        </div>
                                    </div>
                                    <FormItem>
                                        {getFieldDecorator(
                                            "imgReversePreviewInfo",
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            "请上传证件反面照片!",
                                                    },
                                                ],
                                            }
                                        )(<Input type="hidden" />)}
                                    </FormItem>
                                    <p className="buypageForm__note">
                                        *请上传 2M 以内, JPG/RNG图片
                                    </p>
                                </div>

                                <div className="form__sampleImg">
                                    <div className="form__sampleImg__inner">
                                        <div>
                                            <Avatar
                                                key={getFieldValue(
                                                    "imgReversePreviewInfo"
                                                )}
                                                size={64}
                                                // icon="user"
                                                src={agent.getApiUrl(
                                                    getFieldValue(
                                                        "imgReversePreviewInfo"
                                                    )
                                                )}
                                            />
                                        </div>
                                        <div>
                                            {fileReverseList.length == 0 ? (
                                                <img
                                                    src="/assets/img/id-photo_02.png"
                                                    alt=""
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <Link
                                                    // style={{
                                                    //     display: "block",
                                                    // }}
                                                    to="#"
                                                    onClick={
                                                        this
                                                            .handleDeleteReverseAvatarClick
                                                    }
                                                >
                                                    删除图片
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                    <dl className="buypageForm__dl">
                        <dt className="buypageForm__dt u-alsT">
                            <label />
                        </dt>
                        <dd className="buypageForm__dd">
                            <div className="form__columnBox">
                                <div className="form__columnBox__drop">
                                    <div className="form__dropBox form__dropBox--s">
                                        <div className="form__dropBox__inner">
                                            <p className="form__dropBox__txt">
                                                手持证件照
                                            </p>
                                            <div className="form__dropBox__img">
                                                <img
                                                    src="/assets/img/id-photo_03.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="form__dropBox__btn">
                                                <Upload {...uploadHoldProps}>
                                                    <div className="m-btn-type0--s">
                                                        点击上传
                                                    </div>
                                                </Upload>
                                            </div>
                                        </div>
                                    </div>
                                    <FormItem>
                                        {getFieldDecorator(
                                            "imgHoldPreviewInfo",
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            "请上传手持证件照照片!",
                                                    },
                                                ],
                                            }
                                        )(<Input type="hidden" />)}
                                    </FormItem>
                                    <p className="buypageForm__note">
                                        *请上传 2M 以内, JPG/RNG图片
                                    </p>
                                </div>

                                <div className="form__sampleImg">
                                    <div className="form__sampleImg__inner">
                                        <div>
                                            <Avatar
                                                key={getFieldValue(
                                                    "imgHoldPreviewInfo"
                                                )}
                                                size={64}
                                                // icon="user"
                                                src={agent.getApiUrl(
                                                    getFieldValue(
                                                        "imgHoldPreviewInfo"
                                                    )
                                                )}
                                            />
                                        </div>
                                        <div>
                                            {fileHoldList.length == 0 ? (
                                                <img
                                                    src="/assets/img/id-photo_03.png"
                                                    alt=""
                                                    style={{ width: "100%" }}
                                                />
                                            ) : (
                                                <Link
                                                    // style={{
                                                    //     display: "block",
                                                    // }}
                                                    to="#"
                                                    onClick={
                                                        this
                                                            .handleDeleteHoldAvatarClick
                                                    }
                                                >
                                                    删除图片
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </Form>
        );
    }
}
const UploadSellerImgForm = Form.create()(UploadSellerImg);
export default connect()(UploadSellerImgForm);
