import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "antd";
import agent from "agent";
// import { Link } from "react-router-dom";

import SellerInfo from "./SellerInfo";
import MakerInfoBlock from "./MakerInfoBlock";
import SellerAgreement from "./SellerAgreement";
import UploadSellerImg from "./UploadSellerImg";
import { redirectTo } from "dispatch";

import { SELLERREGISTER, SELLERREGISTER_PAGE_UNLOADED } from "constants/actionTypes";

const mapStateToProps = state => ({
    ...state.user,
    ...state.sellerRegister,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    onSubmit: send => {
        dispatch({
            type: SELLERREGISTER,
            payload: agent.SellerRegister.register(send),
        });
    },

    onUnload: () => dispatch({ type: SELLERREGISTER_PAGE_UNLOADED }),
});

class GeneralSellerRegister extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sellerFamilyName: "",
            sellerGivenName: "",
            credentialsType: "1",
            credentialsFrontImg: "",
            credentialsReverseImg: "",
            credentialsHoldImg: "",
            credentialsNumber: "",
            telephone: "",
            makerHeadImg: [],
            makerList: [{ id: 1 }],
            makerCheckResult: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static propTypes = {
        userLogin: PropTypes.bool.isRequired,
        errors: PropTypes.array,
        inProgress: PropTypes.bool,
        registeSuccess: PropTypes.bool,
        currentUser: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        onUnload: PropTypes.func.isRequired,
    };
    static defaultProps = {
        registeSuccess: false,
    };

    makerInfoBlock = null;
    certificate = ["身份证", "港澳台号码", "护照"];

    _submit = async () => {
        let makerInf = {};
        let files = {};

        const makerInfo = await this.makerInfoBlock.fireCheck();
        files[`creator_avatar1`] = makerInfo.avatar;
        delete makerInfo.avatar;
        makerInf[1] = makerInfo;

        let send = {
            company: false,
            family_name: this.state.sellerFamilyName,
            given_name: this.state.sellerGivenName,
            certificate: this.certificate[this.state.credentialsType - 1],
            shenfenzheng: this.state.credentialsFrontImg,
            zhengjian: this.state.credentialsReverseImg,
            shouchi: this.state.credentialsHoldImg,
            identify_number: this.state.credentialsNumber,
            contact_number: this.state.telephone,
            creator: JSON.stringify(makerInf),
            ...files,
        };
        this.props.onSubmit(send);
    };

    handleSubmit = e => {
        e.preventDefault();
        (async () => {
            try {
                await Promise.all([
                    this.SellerInfo.sellerInfoCheck(),
                    this.UploadSellerImg.sellerImgCheck(),
                    this.makerInfoBlock.fireCheck(),
                    this.SellerAgreement.sellerAgreementCheck(),
                ]);
                // submit
                await this._submit();
            } catch (e) {
                console.log(e);
                return false;
            }
        })();
    };

    handleCredentialsImageVal = send => {
        this.setState(send);
    };

    handleSellerInfoVal = e => {
        const state = {};
        if (typeof e.target.id == "undefined") {
            state[e.target.name] = e.target.value;
        } else {
            state[e.target.id] = e.target.value;
        }
        this.setState(state);
    };

    getSubNodeValue(mainNode, tagName) {
        let tagValue = "";
        let tags = mainNode.getElementsByTagName("*");
        for (let i = 0; i < tags.length; i++) {
            if ((!!tags[i].id && tags[i].id == tagName) || (!!tags[i].name && tags[i].name == tagName)) {
                tagValue = tags[i].value;
            }
        }
        return tagValue;
    }

    onRefSellerInfo = ref => {
        this.SellerInfo = ref;
    };

    onRefSellerImg = ref => {
        this.UploadSellerImg = ref;
    };

    onRefMakerInfo(ref) {
        this.makerInfoBlock = ref;
    }

    onRefSellerAgreement = ref => {
        this.SellerAgreement = ref;
    };

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
    }

    componentWillUnmount() {
        this.props.onUnload();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.registeSuccess && agent.getToken()) {
            redirectTo("/sellerregister/success");
            return false;
        }
        return true;
    }

    render() {
        const { currentUser } = this.props;
        return (
            <div className="mypageContents">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">成为卖家</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="buypageForm">
                            <div className="buypageForm__inner">
                                <div className="buypageForm__items">
                                    <p style={{color:'red',fontSize:'14px',margin:'5px 0'}}>{currentUser.producer == 2 ? '* 您上次的成为卖家申请失败,请重新提交' : ''}</p>
                                    <SellerInfo
                                        onRef={this.onRefSellerInfo}
                                        handleSellerInfoVal={this.handleSellerInfoVal.bind(this)}
                                    />
                                    <UploadSellerImg
                                        onRef={this.onRefSellerImg}
                                        handleImageVal={this.handleCredentialsImageVal.bind(this)}
                                    />
                                    <MakerInfoBlock isList={false} onRef={this.onRefMakerInfo.bind(this)} />

                                    <SellerAgreement onRef={this.onRefSellerAgreement} />

                                    <p className="buypageForm__submit">
                                        <input
                                            className="u-submitSkin is-on"
                                            name="submit"
                                            type="button"
                                            value="下一步"
                                            onClick={this.handleSubmit}
                                            readOnly
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const GeneralSellerRegisterForm = Form.create()(GeneralSellerRegister);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralSellerRegisterForm);
