import React from "react";
import WaveSurfer from "wavesurfer.js";
import PropTypes from "prop-types";
import agent from "agent";
//import Canvas from "./canvas";

import { addPeaks } from "dispatch";

export default class StaticWave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            sound: true,
        };
        this.wavesurfer = null;
        this.backend = "WebAudio ";
    }
    static propTypes = {
        audio: PropTypes.shape({
            id: PropTypes.number.isRequired,
            audioid: PropTypes.string.isRequired,
            thumb: PropTypes.string.isRequired,
            audio: PropTypes.string.isRequired,
            author: PropTypes.object.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            duration: PropTypes.number.isRequired,
            speed: PropTypes.number.isRequired,
            playing: PropTypes.bool,
            merged_peaks: PropTypes.string,
        }).isRequired,
    };
    static defaultProps = {
        musicUrl: "",
    };
    componentDidMount() {
        const { audio, audioid, merged_peaks } = this.props.audio;
        // if (merged_peaks) {
        //     return;
        // }
        const conid = "#a" + audioid;
        if (!this.wavesurfer) {
            //const backend = merged_peaks ? "MediaElement" : "WebAudio";
            this.wavesurfer = WaveSurfer.create({
                container: conid,
                waveColor: "#5E5E5E",
                progressColor: "#5E5E5E",
                normalize: true,
                barWidth: 2,
                height: 25,
                pos: 0,
                volume: 0.5,
                audioRate: 1,
                backend:"WebAudio",
            });
            this.wavesurfer.on("ready", () => {
                if (this.wavesurfer.backend.mergedPeaks) {
                    let peaks = this.wavesurfer.backend.mergedPeaks.toString();
                    addPeaks(audioid, peaks);
                }
            });
        }
        if (merged_peaks) {
            const arr = merged_peaks.split(",");
            let arrs = [];
            for (let i = 0; i < arr.length; i++) {
                arrs.push(parseFloat(arr[i]));
            }
            this.wavesurfer.load(agent.getApiUrl(audio),arrs);
        }else{
            this.wavesurfer.load(agent.getApiUrl(audio));
        }
        
    }
    render() {
        const { audioid } = this.props.audio;
        // if (merged_peaks) {
        //     return <Canvas {...this.props} />;
        // }
        let waveid = "a" + audioid;
        return <div className="musicItem__wave__img" style={{pointerEvents: "none"}} id={waveid} />;
    }
}
