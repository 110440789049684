import {NEWS_LIST ,NEWS_LIST_UNLOAD} from "../constants/actionTypes";

const defaultState = {
    data: "",
    total: 0,
    pager: { pageSize: 36, current: 1 },
    error: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case NEWS_LIST: {
            const error = !!action.error;
            const {
                send,
                payload: { total, data },
            } = action;
            console.info(action.payload)
            const { pager } = send || {};
            const pagination = pager || state.pager;
            
            return {
                total: total || 0,
                pager: { ...pagination },
                data,
                error,
            };
        }
        case NEWS_LIST_UNLOAD:
            return { ...defaultState };
        default:
            return state;
    }
};
