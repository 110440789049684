import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Layout, Pagination, Modal, Select, Checkbox } from "antd";
import Loading from "../Loading";
import { showMoney,HtmlUtil} from "dispatch";
//import moment from "moment";
import { redirectTo } from "dispatch";
import agent from "agent";
import { 
    SUBSCRIBE_GET_PAY_URL,
    RECEIPT_MODAL_HIDE,
    RECEIPT_MODAL_SHOW,
    SUBSCRIBE_LOAD,
    UPLOAD_SUBSCRIBE,
    SUBSCRIBE_DELETE, 
    SUBSCRIBE_UPDATE,
    SHOW_CODE_URL,
    CLOSE_CODE_URL,
} from "constants/actionTypes";
import Download from "components/Common/Download";
import "./User.less";
import "./Subscribe.less";
import Receipt from './Receipt';
import WxCode from "../Common/WxCode";

const queryString = require("query-string");
const confirm = Modal.confirm;
const { Content } = Layout;
const { Option } = Select;
const jquery = require("jquery");
const mapStateToProps = state => ({
    ...state.user,
    ...state.subscribe,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    //订阅会员订单支付
    getPayUrl: (orderid, send, callback) => {
        return dispatch({
            type: SUBSCRIBE_GET_PAY_URL,
            orderid,
            payload: agent.Subscribe.getPayUrl(orderid,send),
            callback,
        });
    },
    //关闭开票弹窗
    onHideModal: () =>
        dispatch({
            type: RECEIPT_MODAL_HIDE,
        }),
    //打开开票弹窗
    onShowModal: payload =>
        dispatch({
            type: RECEIPT_MODAL_SHOW,
            payload,
        }),
    //清空数据
    unLoadSubscribe: () =>
        dispatch({
            type: UPLOAD_SUBSCRIBE,
        }),
    //加载订阅订单
    loadSubscribe :(parmas) =>
        dispatch({
            type: SUBSCRIBE_LOAD,
            payload: agent.Subscribe.loadSubscribe(parmas),
            parmas,
        }),
    //删除订单
    deleteSubScribe :(parmas) =>
        dispatch({
            type: SUBSCRIBE_DELETE,
            payload: agent.Subscribe.deleteSubScribe(parmas),
        }),
    //申请开票&增加银行转账信息
    updateSubscribe: (orderid, parmas,callback) =>
        dispatch({
            type: SUBSCRIBE_UPDATE,
            payload: agent.Subscribe.updateSubscribe(orderid,parmas),
            callback,
        }),
    showCodeImg: (send) =>{
        return dispatch({ 
            type: SHOW_CODE_URL,
            send
        });
    },
    closeCodeUrl: () =>{
        return dispatch({ 
            type: CLOSE_CODE_URL,
        });
    },
});

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: 0,
            isHistory: false,
            receipt:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
            order:"",
            showInfo:false,
            checkbox:false,
        };
        this.closeCode = this.closeCode.bind(this);
    }
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        subscribe: PropTypes.array,
        pagination: PropTypes.object,
        getPayUrl: PropTypes.func.isRequired,
        payUrl: PropTypes.string,
        onHideModal: PropTypes.func.isRequired,
        onShowModal: PropTypes.func.isRequired,
        loadSubscribe: PropTypes.func.isRequired,
        deleteSubScribe: PropTypes.func.isRequired,
        updateSubscribe: PropTypes.func.isRequired,
        unLoadSubscribe: PropTypes.func.isRequired,
        showCodeImg: PropTypes.func.isRequired,
        closeCodeUrl: PropTypes.func.isRequired,
        wxCode: PropTypes.string,
        orderid: PropTypes.string,
        price: PropTypes.string,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.loading && !nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin,loadSubscribe } = this.props;
        if (!userLogin) {
            redirectTo("/login");
            return false;
        }
        const query = queryString.parse(this.props.location.search);
        let status=1;
        if (query.isHistory==1) {
            this.setState({
                isHistory:true,
            })
            status=0;
        }else{
            this.setState({
                isHistory:false,
            })
            status=1;
        }
        const params = {
            size: 10,
            page: 1,
            total: 0,
            orderby: "-id",
            status:status,
        };
        loadSubscribe(params);
    }
    componentDidUpdate(prevProps) {
        const { showCodeImg } = this.props
        if (this.props.payUrl) {
            location.replace(this.props.payUrl);
        }else if(this.props.wxCode){
            const send ={
                codeShow:true,
                codeUrl:this.props.wxCode,
                orderid:this.props.orderid,
                price:this.props.price,
            }
            showCodeImg(send);
            return;
        }
    }
    //删除订阅记录
    showDeleteConfirm (id) {
        const {deleteSubScribe}=this.props;
        confirm({
            title: '您确定要删除此条记录吗?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                const div=document.getElementById(id);
                div.hidden=true;
                deleteSubScribe({id:id,flag:1});
            },
            onCancel() {
                
            },
        });
    }
    onPageChange(pageNumber) {
        const { loadSubscribe } = this.props;
        document.documentElement.scrollTop = document.body.scrollTop =0
        const pager = { size: 10, page: 1, total: 0, orderby: "-id" };
        pager.page = pageNumber;
        const isHistory = this.state.isHistory;
        if (!isHistory) {
            pager.status = 1;
            loadSubscribe(pager);
        } else {
            pager.status = 0;
            loadSubscribe(pager);
        }
    }

    //订阅已付款
    PayOrders() {
        const { unLoadSubscribe ,loadSubscribe}=this.props;
        unLoadSubscribe();
        this.setState({ isHistory: false });
        const params = {
            size: 10,
            page: 1,
            total: 0,
            orderby: "-id",
            status:1,
        };
        loadSubscribe(params);
    }
    //订阅未付款
    noPayOrders() {
        const { unLoadSubscribe, loadSubscribe }=this.props;
        unLoadSubscribe();
        this.setState({ isHistory: true });
        const params = {
            size: 10,
            page: 1,
            total: 0,
            orderby: "-id",
            status:0,
        };
        loadSubscribe(params);
    }
    isShow(i) {
        if (i === this.state.isShow) {
            this.setState({
                isShow: 0,
            });
        } else {
            this.setState({
                isShow: i,
            });
        }
    }
    //打开申请开票弹窗
    receipt(item){
        const { onShowModal }=this.props;
        item.receiptType=1;
        onShowModal(item);
    }
    //添加开票信息
    receiptTicket(oderid,send){
        const {onHideModal, pagination, updateSubscribe ,loadSubscribe}=this.props;
        onHideModal();
        const pager = { size: 10, page: 1, total: pagination.total, orderby: "-id" };
        pager.page = pagination.current;
        pager.status = 1;
        const callback = () => {
            loadSubscribe(pager);
        }
        updateSubscribe(oderid,send,callback);
    }
    //关闭
    closeShow() {
        const { onHideModal }=this.props;
        onHideModal();
    }
    //去支付
    payMoney(payType,orderid) {
        const send={
            payType,
            c_ip:"10.10.10.229",
        }
        const { getPayUrl } = this.props;
        getPayUrl(orderid,send);
        
    }
    //关闭二维码
    closeCode(){
        const {closeCodeUrl } = this.props;
        closeCodeUrl();
    }
    //改变币种
    handleCurrency = e => {
        this.setState({ currency: e });
    };
    //汇款账号
    handleRemittance = e => {
        this.setState({ remittance: e.target.value });
    };
    //联系人电话
    handleRemittancePhone = e => {
        this.setState({ remittancePhone: e.target.value });
    };
    //添加银行转账信息
    addBankInfo(){
        const data = this.state.order;
        const {updateSubscribe, pagination, loadSubscribe}=this.props;
        const attachment=eval('(' + data.attachment + ')');
        if (!this.state.currency||!this.state.remittance||!this.state.remittancePhone||!this.state.checkbox) {
            return;
        } 
        attachment.currency=this.state.currency;
        attachment.remittance=this.state.remittance;
        attachment.remittancePhone=this.state.remittancePhone;
        const pager = { size: 10, page: 1, total: pagination.total, orderby: "-id" };
        pager.page = pagination.current;
        pager.status = 0;
        //回调
        const callback = () => {
            loadSubscribe(pager);
            jquery("body").css("overflow","auto");
            jquery("body").css("height","auto");
        };
        this.setState({
            order:{},
            showInfo:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
        })
        //增加转账信息
        updateSubscribe(data.id,attachment,callback);
        
    }
    //弹出转账信息页
    showAddBankInfo(data){
        jquery("body").css("overflow","hidden");
        jquery("body").css("height","100%");
        this.setState({
            order:data,
            showInfo:true,
        })
    }
    //关闭弹出转账信息页
    closeAddBankInfo(){
        jquery("body").css("overflow","auto");
        jquery("body").css("height","auto");
        this.setState({
            order:{},
            showInfo:false,
            currency:"人民币",
            remittance:"",
            remittancePhone:"",
        })
    }
    onChangeBox(e){
        this.setState({
            checkbox:e.target.checked,
        })
    }
    renderDetailsCell(orderitem,item, i) {
        const title=HtmlUtil.htmlDecode(item.title);
        return (
            <tr key={`details-${i}`}>
                {this.state.isHistory ? (
                    <th className="song_title_size" title={title}>
                        <Link className="shengluehao" to={`/music/musicid/${item.audioid}`}>
                            {title.length>18?title.substr(0,15)+'...':title}
                        </Link>
                    </th>
                ) : (
                    <th className="song_title_size" title={title}>
                        <Download
                            audioid={item.audioid}
                            classname="dlLink shengluehao"
                            original={true}
                        >
                            {title.length>18?title.substr(0,15)+'...':title}
                        </Download>
                    </th>
                )}

                <td className="buyRecord__table__price">
                    {showMoney(item.price)}
                </td>
                {this.state.isHistory ? <td className="buyRecord__table__book phone-hide">
                    <a  href="javascript:;"></a>
                </td> : <td className="buyRecord__table__book">
                    <a  href="javascript:;" onClick={this.exportCertificate.bind(this,orderitem,item)}>授权证书</a>
                </td>}
                
            </tr>
        );
    }
    renderCell(item, i) {
        //详细信息
        const attachment=item.attachment?eval('(' + item.attachment + ')'):"";
        //支付方式
        const payType=attachment&&attachment.payType?attachment.payType:0;

        const info=attachment&&attachment.remittance?1:0;
        /**订单详情 */
        // var date=new Date(item.update_at);
        // const time=date.getTime();
        // var startTime=moment(time).format("YYYY.MM.DD");
        // var tim=30;
        // if (item.type==2||item.type==5) {
        //     tim=90;
        // }else if (item.type==3||item.type==6) {
        //     tim=365;
        // }
        // var endTime = moment(time).add(tim, 'days').format("YYYY.MM.DD");
        return (
            <ul className=" border-li" key={i} id={item.id}>
                {!this.state.isHistory ? (
                    <li>
                        <div className="order-time">
                            <span>订单日期：</span>{item.create_at}
                            <div className="order-end"><span>会员有效期：</span>{item.start_time}至{item.end_time}</div>
                        </div>
                        
                        <div className=" order-ti">
                            <div className=" order-ti001">订阅类型</div>
                            <div className=" order-ti002">价格</div>
                            <div className=" order-ti003">操作</div>
                        </div>
                        <div className=" order-de">
                            <div className=" order-ti001">{item.type_name}</div>
                            <div className=" order-ti002">¥{item.price}</div>
                            <div className=" order-ti003">
                                {item.receipt==0 ?<div><a className=" go-pay"> <button onClick={this.receipt.bind(this,item)} className="pay_button">申请开票</button></a></div>:<div><p>已申请开票</p></div>}
                            </div>
                        </div>
                    </li>):(
                    <li>
                        <div className="order-time"><span>订单日期：</span>{item.create_at}</div>
                        <div className=" order-ti">
                            <div className=" order-ti001">订阅类型</div>
                            <div className=" order-ti002">价格</div>
                            <div className=" order-ti003">操作</div>
                        </div>
                        <div className=" order-de">
                            <div className=" order-ti001">{item.type_name}</div>
                            <div className=" order-ti002">¥{item.price}</div>
                            <div className=" order-ti003">
                                <div>{info==1?<p style={{cursor: "context-menu"}}>转账信息已提交</p>:payType==2? <button onClick={this.showAddBankInfo.bind(this,item)} className="pay_button">提交转账信息</button>: <button  onClick={this.payMoney.bind(this,payType, item.id)} className="pay_button">去支付</button>}</div>
                                <div><a href="javascript:;" onClick={this.showDeleteConfirm.bind(this,item.id)} className=" go-cancle">删除</a></div>
                            </div>
                        </div>
                    </li>)}
            </ul>
        );
        // return (
        //     <div key={`purchase-${i}`} className="buyRecordBox  is-open" id={item.id}>
        //         <div className="buyRecordBox__main">
        //             <div className="buyRecord__table">
        //                 <table>
        //                     <tbody> 
        //                         {/**判断状态选择 */}
        //                         {this.state.isHistory ? (
        //                             <tr>
        //                                 <td className="buyRecordBox__size" >
        //                                     {item.type_name}
        //                                 </td>
        //                                 <td className="buyRecordBox__size">
        //                                     ¥{item.price}
        //                                 </td>
        //                                 <td className="buyRecordBox__size">
        //                                     <div className="pay_money">
        //                                         {info==1?<p style={{cursor: "context-menu"}}>转账信息已提交</p>:payType==2? <button onClick={this.showAddBankInfo.bind(this,item)} className="pay_button">提交转账信息</button>: <button  onClick={this.payMoney.bind(this, item.id)} className="pay_button">去支付</button>}
        //                                         <p className="delete_order phone-hide"><a href="javascript:;" onClick={this.showDeleteConfirm.bind(this,item.id)}>删除订单</a></p>
        //                                     </div>
        //                                 </td> 
        //                             </tr>) : ( 
        //                             <tr>
        //                                 <td className="buyRecordBox__size" >
        //                                     {item.type_name}
        //                                 </td>
        //                                 <td className="buyRecordBox__size">
        //                                     ¥{item.price}
        //                                 </td>
        //                                 <td className="buyRecordBox__size">
        //                                     {startTime}至{endTime}
        //                                 </td> 
        //                                 <td className="buyRecordBox__size">
        //                                     <div className="pay_money">
        //                                         {item.receipt==0 ? <button onClick={this.receipt.bind(this,item)} className="pay_button">申请开票</button>:<p>已申请开票</p>}
        //                                     </div>
        //                                 </td> 
        //                             </tr>
        //                         )}
        //                     </tbody>
        //                 </table>
        //             </div>
        //         </div>
        //     </div>
        // );
    }
    back(e){
        redirectTo("/user/mypage");
    }
    render() {
        const {
            loading,
            currentUser,
            subscribe,
            pagination,
            onHideModal,
            modal,
        } = this.props;
        if (loading) {
            return (
                <Content>
                    <Loading loading={loading} />
                </Content>
            );
        }
        if (!currentUser) {
            return (
                <Content>
                    <Link to="/login">login</Link>
                </Content>
            );
        }
        const { data: modalData ,visible} = modal;
        const detaModel={
            closeShow:onHideModal,
            receiptTicket:this.receiptTicket.bind(this),
            visible,
            modalData,
        }
        const noPay = this.state.isHistory
            ? " m-tabBox__list__item"
            : "m-tabBox__list__item pay_money";
        const pay = !this.state.isHistory
            ? " m-tabBox__list__item"
            : "m-tabBox__list__item pay_money";
        const showInfo=this.state.showInfo?"dialog-bg":"hidden";
        console.info(this.props.payUrl);
        const dispatch={
            closeCode:this.closeCode,
        }
        return (
            <div className="mypageContents" id="user_subscribe_order">
                <div className="mypageContents__inner2">
                    <header className="mainHeading">
                        <h1 className="mainHeading__title">会员订阅记录</h1>
                    </header>
                    <div className="buypageBox">
                        <div className="buyRecord">
                            <div className="m-tabBox">
                                <ul className="m-tabBox__list" role="tablist">
                                    <li className={noPay}>
                                        <a
                                            to=""
                                            aria-controls="tab-01"
                                            aria-selected={this.state.isHistory}
                                            role="tab"
                                            onClick={this.noPayOrders.bind(
                                                this
                                            )}
                                        >
                                            未付款
                                        </a>
                                    </li>
                                    <li className={pay}>
                                        <a
                                            to=""
                                            aria-controls="tab-02"
                                            aria-selected={
                                                !this.state.isHistory
                                            }
                                            role="tab"
                                            onClick={this.PayOrders.bind(this)}
                                        >
                                            已付款
                                        </a>
                                    </li>
                                    <li style={{width:'50%'}}><button onClick={this.back.bind(this)} className='backHome'>返回</button></li>
                                </ul>
                                <span className="notice_price">{this.state.isHistory?"* 价格均为人民币，如您选择台币支付，默认汇率为4.5":""}</span>
                            </div>
                            <div className="buyRecord__inner">
                                {subscribe.map(
                                    this.renderCell.bind(this)
                                )}
                                
                            </div>
                            <div className="resultPagination pag_center">
                                <Pagination
                                    defaultPageSize={pagination.pageSize}
                                    {...pagination}
                                    hideOnSinglePage={true}
                                    onChange={this.onPageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Receipt {...detaModel}></Receipt>
               
                <div className={showInfo} style={{WebkitOverflowScrolling: "touch"}}>
                    <div className="modalOverlay" />
                    <div className="choose-container scroll-y modalBox__inner">
                        <div className=" close-choose"><a><img onClick={this.closeAddBankInfo.bind(this)} height="24" src="/assets/img/u50.png"/></a></div>
                        <div className=" booker-ti">汇款信息</div>
                        <div className=" book-input">
                            <ul>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>汇款账号（后五位）：</div>
                                    <div className=" book-right"><input value={this.state.remittance} type="number" onChange={this.handleRemittance} /></div>
                                </li>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>汇款币种：</div>
                                    <div className=" book-right">
                                        <Select className="select_width" getPopupContainer={triggerNode => triggerNode.parentNode} defaultValue={this.state.currency} onChange={this.handleCurrency}>
                                            <Option value="人民币">人民币</Option>
                                            <Option value="台币">台币</Option>
                                        </Select>
                                    </div>
                                </li>
                                <li>
                                    <div className=" book-left margin_bottom_20"><span>*</span>联系人电话：</div>
                                    <div className=" book-right"><input value={this.state.remittancePhone} type="number" onChange={this.handleRemittancePhone}/></div>
                                </li>
                            </ul>
                            <div className="text-center phone_margin_top" ><Checkbox onChange={this.onChangeBox.bind(this)}>我已汇款并正确填写信息</Checkbox></div>
                            <div className="text-center text-margin-top"><button onClick={this.addBankInfo.bind(this)} className=" my-sub">提交</button></div>
                            
                        </div>
                        <div>
                            <div className=" table-ti"><strong>收款账号信息如下：</strong></div>
                            <div>
                                <div className=" table-name">
                                    <div className=" wid20">币种</div>
                                    <div className=" wid20">金额</div>
                                    <div className=" wid60">银行卡信息</div>
                                </div>
                                <div className=" table-de">
                                    <ul>
                                        <li>
                                            <div className=" wid20 line-78">人民币</div>
                                            <div className=" wid20 color-green line-78">{this.state.order.price}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>上海小仙虎文化传媒有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>中国工商银行股份有限公司上海市上外支行</div>
                                                <div className=" table-all"><span>账号：</span>1001274409300013770</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className=" wid20 line-78">台币</div>
                                            <div className=" wid20 color-green line-78">{(!this.state.order.price?0:this.state.order.price)*4.5}</div>
                                            <div className=" wid60">
                                                <div className=" table-all"><span>户名：</span>啟動音樂文創有限公司</div>
                                                <div className=" table-all"><span>开户行：</span>上海商業儲蓄銀行（世貿分行）</div>
                                                <div className=" table-all"><span>账号：</span>43102000032270</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <WxCode {...dispatch}></WxCode>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscribe);
