import React from "react";
import SidebarAvatar from "./SidebarAvatar";
import Header from "./Header";
import { connect } from "react-redux";
import { DlWraper } from "./MakerEdit";
import PropTypes from "prop-types";
import agent from "agent";
import Loading from "components/Loading";
import { loadCreatorDetail ,HtmlUtil} from "dispatch";
import { Link } from "react-router-dom";
import { UNLOAD_CREATOR } from "constants/actionTypes";
const queryString = require("query-string");

class MakerDetail extends React.Component {
    data = [
        {
            type: "avatar",
            label: "头像",
            name: "avatar",
            dtClassList: ["buypageForm__dt", "u-alsT"],
        },
        {
            type: "input",
            label: "艺名",
            name: "nickname",
            dtClassList: ["buypageForm__dt"],
        },
        {
            type: "textarea",
            label: "个人介绍",
            row: 6,
            name: "description",
            dtClassList: ["buypageForm__dt", "u-alsT"],
        },
        {
            type: "input",
            label: "作品链接",
            name: "link_a",
            dtClassList: ["buypageForm__dt"],
        },
       
    ];

    static propTypes = {
        creator: PropTypes.object,
        location: PropTypes.object.isRequired,
        unLoadCreator: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { location } = this.props;
        const query = queryString.parse(location.search);
        loadCreatorDetail({ ...query });
    }
    componentWillUnmount() {
        this.props.unLoadCreator();
    }
    render() {
        let data = {};
        if (this.props.creator) {
            data = this.props.creator;
        }
        if(!this.props.creator){
            return (
                <Loading />
            );
        }
        
        const dls = this.data.map((x, i) => {
            const { type, label, name, dtClassList, } = x;
            let content = "";
            switch (type) {
                case "avatar":
                    content = (
                        <div
                            className="form__avatarBox"
                            style={{ maxHeight: "160px" }}
                        >
                            <label htmlFor="avatar">
                                <img src={agent.getApiUrl(data[name])} alt="" />
                            </label>
                        </div>
                    );
                    break;
                default:
                    content =HtmlUtil.htmlDecode(data[name])|| "";
                    break;
            }
            return (
                <DlWraper
                    key={i}
                    label={label}
                    dtClassList={dtClassList}
                    content={content}
                />
            );
        });

        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox">
                            <h2 className="mymusicBox__title">
                                {"创作人详情"}
                            </h2>

                            <div className="mymusicForm">
                                <div className="buypageForm__items">
                                    <div className="buypageForm__item">
                                        {dls}
                                    </div>
                                </div>

                                <ul className="form__submitList">
                                    <li className="form__submitList__item">
                                        <Link to={"/seller/makerlist"} className="u-submitSkin--gry"
                                            type="submit">
                                            返回
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        creator: state.creator.creator,
    }),
    dispatch => ({
        unLoadCreator: () =>
            dispatch({
                type:UNLOAD_CREATOR,
            }),
    })

)(MakerDetail);
