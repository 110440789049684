import React from "react";
import PropTypes from "prop-types";

class Header extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };

    render() {
        return (
            <header className="mainHeading">
                <h1 className="mainHeading__title">{this.props.title}</h1>
            </header>
        );
    }
}

export default Header;
