import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Tooltip } from "antd";
import { Link } from "react-router-dom";
import agent from "agent";
import {
    WITHDRAW_INFO,
    WITHDRAW,
    SETTLEMENT_PAGE_UNLOADED,
    APPLY_WITHDRAW,
} from "constants/actionTypes";

import { loadSellerProfileInfo } from "dispatch";

import SidebarAvatar from "./SidebarAvatar";
import RecordList from "./RecordList";
import Header from "./Header";
import { redirectTo, showMoney } from "dispatch";
const text = <div>
    <p>1. 提现金额显示为税前收入；</p>
    <p>2. 提现金额大于100元，小于5万元；</p>
    <p>3. 提现流程：卖家根据可提现金额开具等额增值税普通发票或者增值税专用发票寄至上海市徐汇区肇嘉浜路789号均瑶国际大厦23层F2 MUSINESS财务部，或有效电子发票发送至sales@musiness.vip后，点击“提现”按钮。 若无发票，点击“提现”按钮，直接选择由MUSINESS代扣代缴您的个人所得税费及手续费（合计22%）。</p>
    <p>4. 在您递交提现申请后，MUSINESS将对您的提现操作进行审核，并于次月15日之前向您实名认证账户支付相应的提现费用。</p>
</div>;
const mapStateToProps = state => ({
    ...state.user,
    ...state.seller,
    userLogin: state.app.userLogin,
});

const mapDispatchToProps = dispatch => ({
    loadWithdrawInfo: payload => {
        return dispatch({
            type: WITHDRAW_INFO,
            payload: agent.Seller.loadWithdrawInfo(),
        });
    },

    withdraw: payload => {
        return dispatch({
            type: WITHDRAW,
            payload: agent.Seller.withdraw(),
        });
    },

    unloadSettlement: () => {
        return dispatch({ type: SETTLEMENT_PAGE_UNLOADED });
    },
    applyWithdraw: (applywith) => {
        dispatch({ type: APPLY_WITHDRAW, applywith,});
    },
});

class Settlement extends React.Component {

    static propTypes = {
        currentUser: PropTypes.object,
        userLogin: PropTypes.bool.isRequired,
        errors: PropTypes.array,
        form: PropTypes.object.isRequired,
        inProgress: PropTypes.bool,
        withdrawSuccess: PropTypes.bool,
        profile: PropTypes.object,
        withdrawInfo: PropTypes.object.isRequired,

        loadWithdrawInfo: PropTypes.func.isRequired,
        withdraw: PropTypes.func.isRequired,
        unloadSettlement: PropTypes.func.isRequired,
        applyWithdraw: PropTypes.func.isRequired,
    };

    static defaultProps = {
        withdrawSuccess: false,
    };

    componentWillUnmount() {
        this.props.unloadSettlement();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.currentUser) {
            // login first
            redirectTo("/login");
            return false;
        }
        if (nextProps.withdrawSuccess && agent.getToken()) {
            redirectTo("/seller/settlement");
            return false;
        }
        return true;
    }

    componentDidMount() {
        const { userLogin } = this.props;
        if (!userLogin) {
            // login first
            redirectTo("/login");
            return false;
        }
        // load user profile
        loadSellerProfileInfo();
    }
    // 申请提现方法
    applyforcash(){
        const {withdrawInfo,applyWithdraw } = this.props;
        let aa=withdrawInfo.unpay*0.6/100;
        //可提现金额不能超过50000
        if (aa<100) {
            return;
        }
        const send={
            money:aa,
        }
        applyWithdraw(send);
        redirectTo("/seller/applyforcash");
    }
    render() {
        const { currentUser, withdrawInfo } = this.props;
        if (!currentUser) {
            return <Link to="/login">login</Link>;
        }
        const wpaing=!withdrawInfo.paing?0:withdrawInfo.paing;
        const wpaid=!withdrawInfo.paid?0:withdrawInfo.paid;
        const a=parseFloat(wpaing+wpaid).toFixed(2);
        return (
            <div className="mymusicContents">
                <div className="mymusicContents__inner">
                    <Header title={"卖家个人中心"} />
                    <SidebarAvatar />
                    <div className="mainBox02">
                        <div className="mymusicBox minemusic">
                            <h2 className="mymusicBox__title text-center">我的帐户</h2>
                            <div className="cashItemWrapper" id="withdraw_info">
                                <div className="mine-cash ov">
                                    <div className=" left wid30 phone_clear"><span className=" f-600 settlement_style">总收入:</span>¥{!withdrawInfo.total?0:withdrawInfo.total*0.006}</div>
                                    <div className=" left wid30 phone_clear"><span className=" f-600 settlement_style">已提现金额:</span>¥{a}</div>
                                    <div className=" left wid40 phone_clear">
                                        <span className=" left phone_hide"><img height="18" src="../assets/img/cash.png"/></span>
                                        <p className=" left f-600 margin-left-5 phone_hide"> <span className=" f-600">可提现金额:</span><span style={{fontWeight:"normal"}}>{showMoney(!withdrawInfo.unpay?0:withdrawInfo.unpay*0.6)}</span>
                                            <Tooltip placement="bottomLeft" className="backend_color" title={text} trigger="click">
                                                <span className="notice_withdraw"></span> 
                                            </Tooltip>
                                        </p>
                                        <p className=" left f-600 margin-left-5 pc_hide"> 
                                            <span className=" f-600 settlement_style">  <Tooltip placement="bottom" trigger="click" className="backend_color" title={text}>
                                                <span className="notice_withdraw"></span> 
                                            </Tooltip>可提现金额:</span><span style={{fontWeight:"normal"}}>{showMoney(withdrawInfo.unpay*0.6)}</span>
                                        </p>
                                        <span className=" right"><a className="cashup" onClick={this.applyforcash.bind(this)} >申请提现</a></span>
                                    </div>
                                </div>
                            </div>
                            <RecordList />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const SettlementForm = Form.create()(Settlement);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettlementForm);
