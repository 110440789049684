// import agent from "../agent";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    MUSIC_SEARCH_LABEL_LOAD,
    MUSIC_SEARCH,
    MUSIC_SEARCH_PAGE_UNLOAD,
    MUSIC_SEARCH_LEFT,
    MUSIC_SEARCH_LABEL_LOAD_All,
    SHOW_TORT_POPUP,
} from "constants/actionTypes";
import { Input } from "antd";
import agent from "agent";
import MusicTable from "components/Music/comps/MusicTable";
import MusicStyle from "components/Music/LeftItems/Style";
import MusicInstruments from "components/Music/LeftItems/Instruments";
import MusicEmotion from "components/Music/LeftItems/Emotion";
import MusicScene from "components/Music/LeftItems/Scene";
import MusicLength from "components/Music/LeftItems/Length";
import MusicArea from "components/Music/LeftItems/Area";
import MusicVocal from "components/Music/LeftItems/Vocal";
import MusicSpeed from "components/Music/LeftItems/Speed";
import Loading from "components/Loading";
import { redirectTo, noShowPage } from "dispatch";

import Tags from "components/Music/Tags";
import "./Search.less";
const queryString = require("query-string");
const jquery = require("jquery");

const mapStateToProps = state => ({
    ...state.musicSearch,
    pagination: state.musicTable.pagination,
    labelsData: state.labels.labelsData,
    labelsDataAll: state.labels.labelsDataAll,
});

let tags = {};

const mapDispatchToProps = dispatch => ({
    loadSearch: (pager, payload) => {
        return dispatch({ type: MUSIC_SEARCH, pager, payload });
    },
    unLoadSearch: () => {
        return dispatch({ type: MUSIC_SEARCH_PAGE_UNLOAD });
    },
    changeStatus: changes => {
        return dispatch({ type: MUSIC_SEARCH_LEFT, changes });
    },
    loadLabel: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD,
            payload: agent.Music.searchLabels(),
        });
    },
    loadLabelAll: () => {
        return dispatch({
            type: MUSIC_SEARCH_LABEL_LOAD_All,
            payload: agent.Music.searchLabelAlls(),
        });
    },
    showTortPopup: isTortShow => {
        return dispatch({ type: SHOW_TORT_POPUP, isTortShow });
    },
});

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: "style",
            keyword: "",
            stringified: "--",
            tag: {},
            subscribe: "",
            checkedVip: false,
            checkedFree: false,
            checkedMaterial: props.location.pathname === '/music/material' ? true : false
        };
    }
    static propTypes = {
        isTortShow: PropTypes.shape({
            tortShow: PropTypes.bool.isRequired,
        }),
        loadSearch: PropTypes.func.isRequired,
        unLoadSearch: PropTypes.func.isRequired,
        changeStatus: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        total: PropTypes.number.isRequired,
        showTortPopup: PropTypes.func.isRequired,
    };

    componentWillUnmount() {
        this.props.unLoadSearch();
    }

    componentDidMount() {
        this.props.loadLabel();
        this.props.loadLabelAll();
        const query = queryString.parse(this.props.location.search);
        if (!this.state.keyword && query.keyword) {
            this.setState({ keyword: query.keyword });
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const { location, pagination, loadSearch } = nextProps;
        const query = queryString.parse(location.search);
        let qu = {};
        if (query) {
            qu = noShowPage.baseDecode(query.tiao);
        }
        tags = {
            ...qu,
        }
        const page = qu && qu.page ? qu.page : 1;
        const params = {
            ...query,
            subscribe: prevState.subscribe,
            keyword: prevState.keyword,
            page,
        };
        if (location.pathname === '/music/material') {
            params.subscribe = 'material'
        }
        // delete query.page;
        const stringified = queryString.stringify(query);
        if (prevState.stringified != stringified) {
            const pager = { ...pagination };
            pager.current = parseInt(page);
            params.size = pagination.pageSize;
            loadSearch(pager, agent.Music.search(params));
        }
        return { stringified };
    }
    loadData(pager, params) {
        // do not
    }

    handleKeywordSubmit = e => {
        e.preventDefault(); 
        this.handleSearchClick("keyword", this.state.keyword);
        //埋点 用户搜索关键词提交到后台        
        jquery.ajax({
            url:agent.getApiUrl()+'/userdata/inssearchkw',
            data:{"keyword":this.state.keyword},
            success:function(data){
                console.log(JSON.stringify(data));
                console.log("搜索成功");
            },
            error:function(err){
                console.log("搜索错误 "+err);
            }
        });
    };
    handleSearchClick(search, value) {
        const { match } = this.props;
        const query = queryString.parse(this.props.location.search);
        let tiao = noShowPage.baseDecode(query.tiao);
        const params = {
            ...tiao,
            page: 1,
        };
        params[search] = value;
        const stringTiao = {
            tiao: noShowPage.baseEecode(params),
        }
        const stringified = queryString.stringify(stringTiao);
        //搜索关键词保存到服务器
        redirectTo({
            pathname: match.url,
            search: `?${stringified}`,
        });
    }
    handleResetClick(search) {
        this.handleSearchClick("subscribe", this.state.subscribe);
    }
    handleChangeClick(show) {
        window.scrollTo(0, 0);
        if (this.state.show == show) {
            this.setState({
                show: "",
            });
        } else {
            this.setState({
                show: show,
            });
        }
    }
    handleKeywordChange = e => {
        this.setState({ keyword: e.target.value });
    };
    handleCheckBoxChange = e => {
        let subscribe = "";

        if (e.target.checked) {
            if (e.target.value == "vip") {
                subscribe = "subscribe" 
            }else if (e.target.value == "free") {
                subscribe = "free" 
            }else {
                subscribe = "material" 
            }
            this.setState({
                checkedVip:e.target.value == "vip" ? e.target.checked : !e.target.checked,
                checkedFree:e.target.value == "free" ? e.target.checked : !e.target.checked,
                checkedMaterial:e.target.value == "material" ? e.target.checked : !e.target.checked,
                subscribe: "subscribe",
            })
        } else {
            subscribe = "";
            this.setState({
                checkedVip:false,
                checkedFree:false,
                checkedMaterial: false,
                subscribe: ""
            })
        }
        this.handleSearchClick("subscribe", subscribe);
    }
    callBackTort() {
        const { isTortShow, showTortPopup } = this.props;
        const send = {
            ...isTortShow,
            tortShow: true,
        };
        showTortPopup(send);
    }
    render() {
        const { location, match, labelsData } = this.props;
        const { show, keyword } = this.state;
        const labelProps = {
            handleSearchClick: this.handleSearchClick.bind(this),
            handleResetClick: this.handleSearchClick.bind(this),
            handleChangeClick: this.handleChangeClick.bind(this),
            show: show,
            ...labelsData,
        };


        var tmpstr=document.location.toString(); 
        
        tmpstr=tmpstr.split("?");
        var source={source:0}; 
        
        if(tmpstr.length>1)
        {
            var param=tmpstr[1];  
            source.source=param.split("=")[1];
            sessionStorage.setItem("source",param.split("=")[1]);  
            if(tmpstr[1].indexOf("source=ip")>-1) 
            {
                sessionStorage.setItem("source","ip");
                console.log("来自IP");
            }
                  
        }   


        if (!labelsData) {

            return (
                <Loading />
            )
        }
        return (

            <div className="mainContents" id="search_less">
                <div className="mainBoxWrapper">
                    <div className="sideBox">
                        <div className="accordionWrapper is-open">
                            <p className="accordionWrapper__title">
                                <em>标签</em>
                                搜索
                            </p>
                            <div className="accordionWrapper__contents">
                                <MusicStyle {...this.props} {...labelProps} />
                                <MusicInstruments
                                    {...this.props}
                                    {...labelProps}
                                />
                                <MusicEmotion {...this.props} {...labelProps} />
                                <MusicScene {...this.props} {...labelProps} />
                                <MusicLength {...this.props} {...labelProps} />
                                <MusicArea {...this.props} {...labelProps} />
                                <MusicVocal {...this.props} {...labelProps} />
                                <MusicSpeed {...this.props} {...labelProps} />
                            </div>
                        </div>
                    </div>

                    <div className="mainBox">
                        <div className="searchBox">
                            <form onSubmit={this.handleKeywordSubmit}>
                                <input
                                    type="text"
                                    name=""
                                    value={keyword}
                                    className="searchSkin"
                                    onChange={this.handleKeywordChange}
                                    placeholder="您可以搜索：用途、情绪、风格、乐器、创作家……"
                                />
                                <input
                                    type="submit"
                                    name="submit"
                                    value="搜寻"
                                    id="searchSubmit"
                                />
                            </form>
                        </div>

                        <div className="resultContents">
                            <div style={{ overflow: "hidden" }}>
                                <label className="u-checkbox2" style={{ lineHeight: "42px" }}>
                                    <Input type="checkbox" value="vip" checked={this.state.checkedVip} onChange={this.handleCheckBoxChange} />
                                    <span style={{ color: "rgb(207,152,35)", fontWeight: '600' }}>
                                        金V曲库
                                    </span>
                                </label>

                                <label className="u-checkbox2 freeDiv" style={{ lineHeight: "42px" }}>
                                    <Input type="checkbox" value="free" checked={this.state.checkedFree} onChange={this.handleCheckBoxChange} />
                                    <span style={{ color: "#9A0000", fontWeight: '600' }}>
                                        免费曲库
                                    </span>
                                </label>
                                <label className="u-checkbox2 materialDiv" style={{ lineHeight: "42px" }}>
                                    <Input type="checkbox" value="material" checked={this.state.checkedMaterial} onChange={this.handleCheckBoxChange} />
                                    <span style={{ color: "#336633", fontWeight: '600' }}>
                                        音效素材
                                    </span>
                                </label>

                                <div className="sty">
                                    <a href="javascript:;" className="callBackTort " onClick={this.callBackTort.bind(this)}>
                                        反馈侵权
                                    </a>
                                </div>
                            </div>
                            <div style={{ overflow: "hidden" }}>
                                <Tags search={tags} handleSearchClick={this.handleSearchClick.bind(this)} handleResetClick={this.handleResetClick.bind(this)} />
                            </div>
                            <MusicTable
                                loadData={this.loadData.bind(this)}
                                location={location}
                                match={match}
                            />
                        </div>

                        <div className="bnrArea01 hidden">
                            <h2 className="bnrArea01__title">优惠折扣</h2>
                            <div
                                className="m-columnBox"
                                data-column="1"
                                data-column-sp="1"
                            >
                                <ul className="m-columnBox__items">
                                    <li className="m-columnBox__item">
                                        <a href="">
                                            <img
                                                src="/assets/img/bnr_dmy.jpg"
                                                alt=""
                                            />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Search);
