import React from "react";
import CommonContent from "../Common/Content";
import datas from "components/Common/static_text";
class MediaContantPage extends React.Component {
    render() {
        const { contents, title } = datas["media_contant"];
        return <CommonContent data={contents} title={title} />;
    }
}

class TermsOfSalePage extends React.Component {
    render() {
        const { contents, title, publish } = datas["termsof_sale"];
        return <CommonContent data={contents} title={title} publish={publish} />;
    }
}

class TermsOfUsePage extends React.Component {
    render() {
        const { contents, title, publish } = datas["termsof_use"];
        return <CommonContent data={contents} title={title} publish={publish} />;
    }
}

class TermsOfViolationrulesPage extends React.Component {
    render() {
        const { contents, title, publish } = datas["termsof_violationrules"];
        return <CommonContent data={contents} title={title} publish={publish} />;
    }
}

class TermsOfLogupPage extends React.Component {
    render() {
        const { contents, title, publish } = datas["termsof_logup"];
        return <CommonContent data={contents} title={title} publish={publish} />;
    }
}
export { TermsOfSalePage, TermsOfUsePage, TermsOfViolationrulesPage, TermsOfLogupPage, MediaContantPage };
