import React from "react";
import { connect } from "react-redux";
import { SHOW_TORT_POPUP, SEND_CORT_POPUP } from "../../constants/actionTypes";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import agent from "agent";
import "./Search.less";
const mapStateToProps = state => ({
    ...state.musicSearch,
});

const mapDispatchToProps = dispatch => ({
    showTortPopup: isTortShow => {
        return dispatch({ type: SHOW_TORT_POPUP, isTortShow });
    },
    onSubmit: ({ link, songName, tortReason},done) =>
        dispatch({
            type: SEND_CORT_POPUP,
            payload: agent.Captcha.sendEmail({
                link,
                songName,
                tortReason,
            }),
            done,
        }),
});

class CallBackTort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            num:0,
        };
        this.intervalcounter = -1;
    }
    static propTypes = {
        isTortShow: PropTypes.shape({
            tortShow: PropTypes.bool.isRequired,
        }).isRequired,
        showTortPopup: PropTypes.func.isRequired,
        form: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
        errors: PropTypes.array,
    };
    static defaultProps = {
        isTortShow: {
            tortShow: false,
        },
    };
    componentDidUpdate(prevProps) {
        const num =this.state.num;
        if (num==3) {
            clearInterval(this.intervalcounter);
            this.setState({
                num: 0,
            });
            this.closeShow();
            return;
        }
    }

    closeShow() {
        this.props.form.resetFields("link");
        this.props.form.resetFields("songName");
        this.props.form.resetFields("tortReason");
        const { isTortShow, showTortPopup } = this.props;
        const send = {
            ...isTortShow,
            tortShow: false,
        };
        showTortPopup(send);
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    link: values.link,
                    songName: values.songName,
                    tortReason:values.tortReason,
                    
                }); 
                this.intervalcounter =setInterval(() => {
                    const num=this.state.num;
                    this.setState({
                        num: num+1,
                    });
                }, 1000); 
            }
        });
    };
    render() {
        const { tortShow } = this.props.isTortShow;
        const showClass = tortShow ? "isShow" : "modalBox";
        const { getFieldDecorator } = this.props.form;
        if (!tortShow) {
            return null;
        }
        if (tortShow==="1") {
            return (
                <div className={showClass} id="call_back_cort">
                    <div className="modalOverlay" />
                    <div className="modalBox__inner">
                        <div id="modalForm1" className="modalForm" tabIndex="0">
                            <div className="modalForm__header">
                                <p className="modalForm__title">反馈成功</p>
                                <p className="modalForm__close">
                                    <button
                                        onClick={this.closeShow.bind(this)}
                                        type="button"
                                    />
                                </p>
                            </div>
                            <div className="modalForm__inner">
                                <div className="buypageBox">
                                    <div className="buypageForm" style={{ textAlign: "center"}}>
                               感谢您的反馈,管理员会尽快处理.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
       
        return (
            <div className={showClass} id="call_back_cort">
                <div className="modalOverlay" />
                <div className="modalBox__inner">
                    <div id="modalForm1" className="modalForm" tabIndex="0">
                        <div className="modalForm__header">
                            <p className="modalForm__title">反馈侵权</p>
                            <p className="modalForm__close">
                                <button
                                    onClick={this.closeShow.bind(this)}
                                    type="button"
                                />
                            </p>
                        </div>
                        <div className="modalForm__inner">
                            <div className="buypageBox">
                                <div className="buypageForm">
                                    <Form onSubmit={this.handleSubmit}>
                                        <div className="buypageForm__inner">
                                            <div className="buypageForm__items">
                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                联系方式
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "link",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入联系方式",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                歌曲链接
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "songName",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入歌曲名称",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <Input
                                                                        type="text"
                                                                        className="u-inputSkin02"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>

                                                <div className="buypageForm__item">
                                                    <dl className="buypageForm__dl">
                                                        <dt className="buypageForm__dt">
                                                            <label htmlFor="">
                                                                侵权情况说明
                                                            </label>
                                                        </dt>
                                                        <dd className="buypageForm__dd">
                                                            <Form.Item>
                                                                {getFieldDecorator(
                                                                    "tortReason",
                                                                    {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message:
                                                                                    "请输入侵权情况说明",
                                                                            },
                                                                        ],
                                                                    }
                                                                )(
                                                                    <textarea
                                                                        type="text"
                                                                        className="u-inputSkin021"
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                        <Form.Item>
                                            <p className="buypageForm__submit">
                                                <Button
                                                    className="u-submitSkin01"
                                                    type="primary"
                                                    block
                                                    htmlType="submit"
                                                >
                                                    确认提交
                                                </Button>
                                            </p>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const WrappedDemo = Form.create()(CallBackTort);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedDemo);
