// import agent from "../agent";
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/Loading";
import { Link } from "react-router-dom";
import "./Search.less";

const mapStateToProps = state => ({
    labels: state.labels.labelsData,

});

const mapDispatchToProps = dispatch => ({});

class Tags extends React.Component {
    
    static propTypes = {
        search: PropTypes.object.isRequired,
        labels: PropTypes.object.isRequired,
        handleSearchClick:PropTypes.func.isRequired,
        handleResetClick:PropTypes.func.isRequired,
    };
    clearTag(id,key){
        const { handleSearchClick,search } = this.props;
        if (key =='instrument'||key =='emotion') {
            let  value = (search[key] + "").split(",");
            for (let k = 0; k < value.length; k++) {
                if (id==value[k]) {
                    value.splice(k,1);
                    k--;
                }
            }
            handleSearchClick(key,value.toString());
        }else{
            handleSearchClick(key,'');
        }
       
    }
    tagHandleResetClick(){
        const { handleResetClick } = this.props;
        handleResetClick();
    }
    showTags() {
        const {  labels,search} = this.props;
        let show = [];
        for (let key in search) {
            let  value = (search[key] + "").split(",");
            if(labels[key]){
                for (let i = 0; i < labels[key].length; i++) {
                    const element = labels[key][i];
                    for (let k = 0; k < value.length; k++) {
                        const val = value[k];
                        if (element.id == val) {
                            show.push( <li key={element.title}><a>{element.title}<span onClick={this.clearTag.bind(this,element.id,key)} className="cancle-img"><img src="/assets/img/cancle.png"/></span></a></li>)
                        }
                    }
                }
            }
        }
        if (!show) {
            return (
                <span>
                    &nbsp;
                    <br /> &nbsp;
                </span>
            );
        }else if(show.length>0){
            show.push( <li key='clear'><Link className="clear-tag" to="#" onClick={this.tagHandleResetClick.bind(this)} >清空标签</Link></li>)
        }
        
        return show;
    }
    render() {
        const{labels}=this.props;
        if (!labels) {
            return <Loading/>
        }
        return (
            <div className="my-tabs"style={{marginBottom: "10px", width: "80%"}}>
                <ul className="tabs-li">
                    {this.showTags()}
                </ul>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tags);
