import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import UploadMusic from "./UploadMusic";
import MakerEdit from "./MakerEdit";
import MakerDetail from "./MakerDetail";
import MyMusic from "./MyMusic";
import SellerMakerList from "./SellerMakerList";
import Settlement from "./Settlement";
import Settlement2 from "./Settlement2";
import SettlementUpdate from "./SettlementUpdate";
import Profile from "./Profile";
import ApplyForCash from "./ApplyForCash";
import { producerStatus } from "config/producerStatus";
import { loadCurrentUser, redirectTo } from "dispatch";
const mapStateToProps = state => ({ ...state.user });

class Seller extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        currentUser: PropTypes.object.isRequired,
    };
    componentDidMount() {
        // load user again
        loadCurrentUser();
    }

    render() {
        const { currentUser } = this.props;
        if (!currentUser) {
            // login first
            redirectTo("/login");
            return null;
        }
        if (currentUser.producer != producerStatus.YES) {
            redirectTo("/user/mypage");
            return null;
        }

        const { match } = this.props;
        return (
            <Switch>
                <Route
                    path={`${match.url}/uploadmusic`}
                    component={UploadMusic}
                />
                <Route path={`${match.url}/makeredit`} component={MakerEdit} />
                <Route
                    path={`${match.url}/makerview`}
                    component={MakerDetail}
                />
                <Route path={`${match.url}/mymusic`} component={MyMusic} />
                <Route
                    path={`${match.url}/makerlist`}
                    component={SellerMakerList}
                />
                <Route
                    path={`${match.url}/settlement/update`}
                    component={SettlementUpdate}
                />
                <Route
                    path={`${match.url}/settlement`}
                    component={Settlement}
                />
                <Route
                    path={`${match.url}/settlement2`}
                    component={Settlement2}
                />
                <Route
                    path={`${match.url}/applyforcash`}
                    component={ApplyForCash}
                />
                <Route path={`${match.url}/profile`} component={Profile} />
                <Route path={`${match.url}/`} component={Profile} />
            </Switch>
        );
    }
}
export default connect(mapStateToProps)(Seller);
