import {
    ASYNC_START,
    ORDER_ONE_PAGE_UNLOAD,
    ORDER_ONE,
    ORDER_LIST,
    ORDER_CREATE_PURCHASE,
} from "../constants/actionTypes";
const defaultState = {
    loading: false,
    createedPurchase: false,
    fetchError: false,
    orderone: "",
    orderoneData: null,
};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case ORDER_ONE: {
            const fetchError = !!action.error;
            const orderoneData = fetchError ? null : action.payload.order;
            return {
                ...state,
                orderoneData,
                fetchError,
                loading: false,
                orderone: action.orderid,
            };
        }
        case ORDER_LIST: {
            const fetchError = !!action.error;
            let orderoneData = null;
            if (!fetchError && action.payload.order.length) {
                orderoneData = action.payload.order[0];
            }
            return {
                ...state,
                orderoneData,
                fetchError,
                loading: false,
                orderone: action.orderid,
            };
        }
        case ORDER_CREATE_PURCHASE: {
            const fetchError = !!action.error;
            const payUrl = action.payload.pay_url;
            const remittance = action.payload.remittance;
            return {
                ...state,
                fetchError,
                loading: false,
                payUrl,
                remittance,
            };
        }

        case ASYNC_START:
            if (
                action.subtype === ORDER_ONE ||
                action.subtype === ORDER_LIST ||
                action.subtype === ORDER_CREATE_PURCHASE
            ) {
                return { ...state, loading: true };
            }
            break;
        case ORDER_ONE_PAGE_UNLOAD:
            return { ...defaultState };

        default:
            return state;
    }

    return state;
};
