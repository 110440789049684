import { HOME_PAGE_LOADED, HOME_PAGE_UNLOADED,HOME_OPENID } from "../constants/actionTypes";

const defaultState = { currentAdmin: null, currentError: false,openId:""};
export default (state = defaultState, action) => {
    switch (action.type) {
        case HOME_PAGE_LOADED: {
            return {
                ...state,
                currentError: !!action.error,
                currentAdmin: action.payload,
            };
        }
        case HOME_OPENID: {
            console.log(action.openId)
            const openId = action.openId;
            return {
                ...state,
                openId,
            };
        }
        case HOME_PAGE_UNLOADED:
            return defaultState;
        default:
            return state;
    }
};
