import React from "react";
import PropTypes from "prop-types";

class Avatar extends React.Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
    };

    render() {
        const { src } = this.props;
        return (
            <img
                src={src}
                onError={e => {
                    e.target.src = "/assets/img/img_avatar2.jpg";
                }}
            />
        );
    }
}

export default Avatar;
