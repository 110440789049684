import { SHOW_POPUP, UPDATE_PASSWORD} from "../constants/actionTypes";
const defaultState = {};
export default (state = { ...defaultState }, action) => {
    switch (action.type) {
        case SHOW_POPUP: {
            return {
                ...state,
                isShow: action.isShow,
            };
        }
        case UPDATE_PASSWORD:
            return {
                ...state,
                errors: action.error ? action.payload.errors : null,
                isShow: { show: action.error },
            };
        default:
            return state;
    }
};
