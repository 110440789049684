import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import app from "./reducers/app";
import header from "./reducers/header";
import auth from "./reducers/auth";
import home from "./reducers/home";
import profile from "./reducers/profile";
import player from "./reducers/player";
import musicSearch from "./reducers/musicSearch";
import musicOne from "./reducers/musicOne";
import musicTable from "./reducers/musicTable";
import cart from "./reducers/cart";
import orderOne from "./reducers/orderOne";
import user from "./reducers/user";
import userProfile from "./reducers/userProfile";
import userMypage from "./reducers/userMypage";
import userPurchaseAll from "./reducers/userPurchaseAll";
import userPurchaseOne from "./reducers/userPurchaseOne";
import purchase from "./reducers/purchase";
import seller from "./reducers/seller";
import updateInfo from "./reducers/updateInfo";
import favorite from "./reducers/favorite";
import sellerRegister from "./reducers/sellerRegister";
import updatePassword from "./reducers/updatePassword";
import forgetPassword from "./reducers/forgetPassword";
import mymusic from "./reducers/mymusic";
import creator from "./reducers/creator";
import susume from "./reducers/susume";
import partner from "./reducers/partner";
import playlist from "./reducers/playlist";
import qa from "./reducers/qa";
import maker from "./reducers/maker";
import labels from "./reducers/labels";
import media from "./reducers/media";
import composition from "./reducers/composition";
import fixPrice from "./reducers/fixPrice";
import customized from "./reducers/customized";
import news from "./reducers/news";
import applyForCash from "./reducers/applyForCash";
import updateCar from "./reducers/updateCar";
import subscribe from "./reducers/subscribe";
export default combineReducers({
    app,
    header,
    auth,
    home,
    profile,
    player,
    playlist,
    susume,
    partner,
    musicOne,
    musicSearch,
    musicTable,
    mymusic,
    creator,
    orderOne,
    user,
    userProfile,
    userMypage,
    userPurchaseAll,
    userPurchaseOne,
    purchase,
    cart,
    seller,
    qa,
    maker,
    updateInfo,
    favorite,
    sellerRegister,
    updatePassword,
    forgetPassword,
    labels,
    media,
    composition,
    fixPrice,
    customized,
    news,
    applyForCash,
    updateCar,
    subscribe,
    router: routerReducer,
});
